import { useLocales } from "src/hooks";
import LabelBadge from "src/components/generic/LabelBadge";

type Props = {
  priority: number;
  active: boolean;
};

export default function AlarmStatusLabel({ priority, active }: Props) {
  const { translate } = useLocales();
  if (!active)
    return (
      <LabelBadge
        color={"success"}
        sx={{ textTransform: "capitalize" }}
        text={translate("Inactive")}
      />
    );
  if (priority === 2)
    return (
      <LabelBadge
        color={"warning"}
        sx={{ textTransform: "capitalize" }}
        text={translate("Warning")}
      />
    );
  if (priority === 3)
    return (
      <LabelBadge
        color={"error"}
        sx={{ textTransform: "capitalize" }}
        text={translate("Alert")}
      />
    );
  return (
    <LabelBadge
      color={"primary"}
      sx={{ textTransform: "capitalize" }}
      text={translate("Unknown")}
    />
  );
}
