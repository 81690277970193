import { Box, Card, Stack, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import numeral from "numeral";
import { useMemo } from "react";
import { Measure } from "src/@types";
import MeasureWidgetHeader from "src/components/app/measure/MeasureWidgetHeader";
import SquareBox from "src/components/generic/SquareBox";
import { useLocales } from "src/hooks";
import { ColorSchema } from "src/theme/palette";
import thresholdsToRanges from "src/utils/thresholdsToRanges";
import AlarmStatusLabel from "./AlarmStatusLabel";
import Speedometer from "./Speedometer";

type Props = {
  measure: Measure;
  onMenuClick: () => void;
};

export default function AlarmCard({ measure, onMenuClick }: Props) {
  const theme = useTheme();
  const { translate } = useLocales();

  const alarmStatusColorSchema = useMemo(() => {
    return (
      measure.strongestAlarm?.status === undefined
        ? "primary"
        : measure.strongestAlarm.status.active === false
        ? "success"
        : measure.strongestAlarm.priority === 2
        ? "warning"
        : measure.strongestAlarm.priority === 3
        ? "error"
        : "primary"
    ) as ColorSchema;
  }, [measure.strongestAlarm]);

  const speedometerRanges = useMemo(
    () =>
      thresholdsToRanges(measure.thresholds || []).reduce(
        (acc, r) => [
          ...acc,
          {
            from: r.from,
            to: r.to,
            colorSchema:
              r.priority === 2
                ? "warning"
                : r.priority === 3
                ? "error"
                : "success",
          },
        ],
        [] as any[]
      ),
    [measure.thresholds]
  );

  const displayAlarmStatusValue = () => {
    if (
      measure.strongestAlarm?.status?.extractionsValues?.measureValue ===
      undefined
    ) {
      return translate("No data");
    }
    if (measure.type === "boolean") {
      return measure?.strongestAlarm?.status?.extractionsValues
        ?.measureValue === false
        ? translate("False")
        : translate("True");
    }
    if (measure.type === "string") {
      return measure.strongestAlarm?.status?.extractionsValues?.measureValue;
    }
    if (measure.type === "number") {
      const formattedValue = numeral(
        measure.strongestAlarm?.status?.extractionsValues?.measureValue
      ).format("0.00");
      return measure.unit
        ? `${formattedValue} ${measure.unit}`
        : formattedValue;
    }
  };

  return (
    <Card>
      <MeasureWidgetHeader
        measure={measure}
        // onMenuClick={() => setDrawerMeasure(measure)}
        onMenuClick={onMenuClick}
        showLocation={true}
        showEquipment={true}
      />

      <Stack direction="column" gap={2} sx={{ p: 2 }}>
        {true /*measure.thresholds*/ && (
          <SquareBox sx={{ position: "relative" }}>
            <Box
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                zIndex: 9,
              }}
            >
              {measure.strongestAlarm && (
                <Stack alignItems="center" sx={{ maxWidth: "80%" }}>
                  <Typography
                    variant="h3"
                    color={
                      theme.palette.mode === "dark"
                        ? theme.palette[alarmStatusColorSchema].light
                        : theme.palette[alarmStatusColorSchema].main
                    }
                    sx={{ textAlign: "center" }}
                  >
                    {displayAlarmStatusValue()}
                  </Typography>
                  <AlarmStatusLabel
                    priority={measure.strongestAlarm.priority}
                    active={measure.strongestAlarm.status.active}
                  />
                </Stack>
              )}
            </Box>
            {measure.type === "number" && (
              <Speedometer
                ranges={speedometerRanges}
                currentValue={
                  measure.strongestAlarm?.status?.extractionsValues
                    ?.measureValue !== undefined
                    ? Number(
                        measure.strongestAlarm?.status?.extractionsValues
                          ?.measureValue
                      )
                    : undefined
                  // measureState.data?.last?.value !== undefined
                  //   ? Number(measureState.data?.last?.value)
                  //   : undefined
                }
                bgColor={theme.palette.background.paper}
                // unit={measure.unit || ""}
              />
            )}
            {measure.type !== "number" && (
              <Box
                sx={{
                  borderColor: theme.palette.grey[400],
                  // margin: "calc(16px + 6px)",
                  margin: "calc(50% - (200px + 16px + 16px) / 2)",
                  // width: "calc(100% - 32px - 12px)",
                  width: "calc(200px + 16px + 16px)",
                  // height: "calc(100% - 32px - 12px)",
                  height: "calc(200px + 16px + 16px)",
                  borderRadius: "50%",
                  boxShadow: `0px 0px 0px 16px ${
                    theme.palette.mode === "dark"
                      ? theme.palette[alarmStatusColorSchema].light
                      : theme.palette[alarmStatusColorSchema].main
                  }`,
                }}
              />
            )}
          </SquareBox>
        )}
      </Stack>
    </Card>
  );
}
