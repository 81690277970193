import { useContext } from "react";
import { FormatContext } from "src/contexts/FormatContext";

// ----------------------------------------------------------------------

export default function useFormat() {
  const context = useContext(FormatContext);

  if (!context)
    throw new Error(
      "FormatContext must be use inside FormatContext Provider"
    );

  return context;
}
