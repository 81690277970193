import { Collapse, List } from "@mui/material";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { MenuItem } from "src/@types";
import { isActive } from "..";
import NavItemRoot from "./NavItemRoot";
import NavListSub from "./NavListSub";

type Props = {
  item: MenuItem;
  isCollapse: boolean;
};

export default function NavListRoot({ item, isCollapse }: Props) {
  const { pathname } = useLocation();

  const active =
    item.path !== undefined ? isActive(item.path, pathname) : false;

  const [open, setOpen] = useState(active);
  const hasChildren = item.children;
  if (hasChildren) {
    return (
      <>
        <NavItemRoot
          item={item}
          isCollapse={isCollapse}
          active={active}
          open={open}
          onOpen={() => setOpen(!open)}
        />

        {!isCollapse && (
          <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {(item.children || []).map((item) => (
                <NavListSub key={item.label + item.path} item={item} />
              ))}
            </List>
          </Collapse>
        )}
      </>
    );
  }

  return <NavItemRoot item={item} active={active} isCollapse={isCollapse} />;
}
