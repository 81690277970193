import { alpha, Theme } from "@mui/material";
import { Threshold } from "../../../../../@types";
import scaleGradientBase, {
  ScaleStop,
} from "../../../../generic/chart/uPlot/functions/scaleGradient";
import thresholdsToRanges from "../../../../../utils/thresholdsToRanges";

type Props = {
  thresholds: Threshold[];
  theme: Theme;
};

export default function thresholdsGradient({ thresholds, theme }: Props) {
  let scaleStops: ScaleStop[];
  if (!thresholds) {
    scaleStops = [
      { value: 0, color: theme.palette.primary.main, discrete: true },
    ];
  } else {
    const ranges = thresholdsToRanges(thresholds);
    // Get scalestops from ranges
    scaleStops = ranges.map((range, i) => ({
      value: range.from,
      color:
        range.priority === 2
          ? theme.palette.warning.main
          : range.priority === 3
          ? theme.palette.error.main
          : theme.palette.primary.main,
    }));
  }

  return scaleGradientBase({
    scaleKey: "y",
    orientation: "y",
    scaleStops: scaleStops,
    discrete: true,
  });
}
