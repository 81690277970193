import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Card,
  FormLabel,
  Grid,
  Stack,
  TextField,
  useTheme,
} from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers";
import { UppyFile } from "@uppy/core";
import { DateTime } from "luxon";
import { useSnackbar } from "notistack";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { sprintf } from "sprintf-js";
import { Logbook, LogbookFromServer, LogbookType } from "src/@types/Logbook";
import { Attachment, LocalAttachment } from "src/@types/Attachment";
import AttachmentsList from "src/components/generic/AttachmentsList";
import UppyDashboard from "src/components/generic/UppyDashboard";
import { useLocales } from "src/hooks";
import { PATH_DASHBOARD } from "src/routes/paths";
import { logbookService } from "src/services";
import { useSelector } from "src/redux/store";

type Props = {
  logbook: Logbook | LogbookFromServer;
};

export default function LogbookForm({ logbook }: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const { currentLanguage, translate } = useLocales();
  const navigate = useNavigate();
  const authState = useSelector((state) => state.auth);
  const logbookSelector = useSelector((state) => state.logbook);
  const theme = useTheme();

  const type = useMemo(
    () =>
      logbookSelector.types.find(
        (e) => e.identifier === logbook.type.identifier
      ),
    [logbookSelector.types]
  );
  const [logbookState, setLogbookState] = useState(logbook);
  const [customPropsModels, setCustomPropsModels] = useState<{
    [key: string]: any;
  }>({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    let result: { [key: string]: any } = {};
    if (type?.customProperties) {
      type.customProperties.forEach((p) => {
        // result[p.identifier] = logbookState[p.identifier as keyof LogbookFromServer];
        result[p.identifier] = (logbookState as any)[p.identifier];
      });
    }
    setCustomPropsModels(result);
  }, [type]);

  const handleTimeChange = (newTime: DateTime | null) => {
    if (newTime) setLogbookState({ ...logbookState, time: newTime });
  };

  const handleActionResultChange = (_actionResult: boolean) => {
    setLogbookState({ ...logbookState, actionResult: _actionResult });
  };

  const handleNoteChange = (_note: string) => {
    setLogbookState({ ...logbookState, note: _note });
  };

  const onSubmit = async () => {
    setIsSubmitting(true);
    // Errors checking
    const msg = [
      !logbookState.location
        ? sprintf(translate("%s is required"), translate("Location"))
        : undefined,
      !logbookState.equipmentType
        ? sprintf(translate("%s is required"), translate("Equipment type"))
        : undefined,
      !logbookState.equipment
        ? sprintf(translate("%s is required"), translate("Equipment"))
        : undefined,
      !logbookState.category
        ? sprintf(translate("%s is required"), translate("Category"))
        : undefined,
      !logbookState.type
        ? sprintf(translate("%s is required"), translate("Type"))
        : undefined,
      !logbookState.time
        ? sprintf(translate("%s is required"), translate("Date"))
        : undefined,
      logbookState.type.identifier === "action" &&
      logbookState.actionResult === undefined
        ? sprintf(translate("%s is required"), translate("Action result"))
        : undefined,
    ].filter((e) => e) as string[];

    if (msg.length) {
      enqueueSnackbar(msg, { variant: "error" });
      return;
    }

    // Save
    try {
      if (!logbookState._id) {
        const { data: newLogbook } = await logbookService.post(
          logbookState as Logbook,
          customPropsModels
        );
        if (logbookState.files.length)
          await logbookService.saveAttachments({
            ...logbookState,
            _id: newLogbook._id,
          });
      } else {
        await logbookService.put(
          logbookState as LogbookFromServer,
          customPropsModels
        );
        if (logbookState.files.length)
          await logbookService.saveAttachments(logbookState);
      }
      enqueueSnackbar(translate("Update success"));
      setIsSubmitting(false);
      navigate(PATH_DASHBOARD.logbook.list);
    } catch (e) {
      setIsSubmitting(false);
      console.error(e);
      if (Array.isArray(e)) {
        const msg = (
          <div>
            {e.map((e) => (
              <div>{e.msg}</div>
            ))}
          </div>
        );
        enqueueSnackbar(msg, { variant: "error" });
      } else {
        enqueueSnackbar(e.message || e.toString(), { variant: "error" });
      }
    }
  };

  const onKeyDown = (e: any) => {
    e.preventDefault();
  };

  return (
    <form onSubmit={onSubmit}>
      <Stack direction={{ xs: "column", md: "row" }} gap={2}>
        <Card sx={{ p: 2, width: { xs: 1, md: 2 / 3 } }}>
          <Stack direction="column" gap={2}>
            <Stack gap={1}>
              <FormLabel>{translate("Date")}</FormLabel>
              <DateTimePicker
                value={logbookState.time}
                onChange={handleTimeChange}
                renderInput={(params: any) => (
                  <TextField {...params} onKeyDown={onKeyDown} />
                )}
              />
            </Stack>

            <Stack gap={1}>
              <FormLabel>{translate("Note")}</FormLabel>
              <TextField
                value={logbookState.note}
                multiline
                onChange={(e) => handleNoteChange(e.target.value)}
                minRows={3}
              />
            </Stack>

            <Stack gap={1}>
              <FormLabel>{translate("Attachments")}</FormLabel>
              <Box
                sx={{
                  p: 2,
                  borderWidth: 1,
                  borderStyle: "dashed",
                  borderColor: theme.palette.text.secondary,
                  borderRadius: 1,
                }}
              >
                <AttachmentsList
                  attachments={logbookState.attachments || []}
                  getBaseUrl={(attachment: Attachment) =>
                    [
                      window.env.REACT_APP_EYE_BACKEND_URL,
                      "logbook",
                      logbook._id,
                      "attachments",
                      encodeURIComponent(attachment["aws-s3"].Key),
                    ].join("/")
                  }
                  onFileRemoved={(attachment: Attachment | LocalAttachment) => {
                    setLogbookState({
                      ...logbookState,
                      attachments: logbookState.attachments.filter(
                        (file) => file.id !== attachment.id
                      ),
                    });
                  }}
                />
                <AttachmentsList
                  attachments={logbookState.files || []}
                  onFileRemoved={(attachment: Attachment | LocalAttachment) => {
                    setLogbookState({
                      ...logbookState,
                      files: logbookState.files.filter(
                        (file) => file.id !== attachment.id
                      ),
                    });
                  }}
                />
                <UppyDashboard
                  locale={authState.user?.language || "en"}
                  onFileAdded={(addedFile: UppyFile) => {
                    setLogbookState({
                      ...logbookState,
                      files: [...logbookState.files, addedFile],
                    });
                  }}
                />
              </Box>
            </Stack>
          </Stack>
        </Card>
        <Stack sx={{ width: { xs: 1, md: 1 / 3 } }}>
          {type && (type.type === "action" || type.customProperties) ? (
            <Card sx={{ p: 2 }}>
              <Stack direction="column" gap={2}>
                {type.customProperties?.map((property, index) => (
                  <Stack key={index} gap={1}>
                    <FormLabel>{property.label}</FormLabel>
                    {property.type === "string" ? (
                      <TextField
                        value={customPropsModels[property.identifier]}
                        required={property.required || false}
                        onChange={(event) => {
                          customPropsModels[property.identifier] =
                            event.target.value;
                          setCustomPropsModels({ ...customPropsModels });
                        }}
                      />
                    ) : property.type === "number" ? (
                      <TextField
                        type="number"
                        required={property.required || false}
                        value={customPropsModels[property.identifier]}
                        onChange={(event) => {
                          customPropsModels[property.identifier] =
                            event.target.value;
                          setCustomPropsModels({ ...customPropsModels });
                        }}
                      />
                    ) : property.type === "boolean" ? (
                      <Stack direction="row" gap={2}>
                        <Button
                          variant={
                            customPropsModels[property.identifier] === true
                              ? "contained"
                              : "outlined"
                          }
                          onClick={() => {
                            customPropsModels[property.identifier] = true;
                            setCustomPropsModels({ ...customPropsModels });
                          }}
                        >
                          {property.choices?.true || translate("True")}
                        </Button>
                        <Button
                          variant={
                            customPropsModels[property.identifier] === false
                              ? "contained"
                              : "outlined"
                          }
                          onClick={() => {
                            customPropsModels[property.identifier] = false;
                            setCustomPropsModels({ ...customPropsModels });
                          }}
                        >
                          {property.choices?.false || translate("False")}
                        </Button>
                      </Stack>
                    ) : null}
                  </Stack>
                ))}
                {type.type === "action" ? (
                  <Stack gap={1}>
                    <FormLabel>{translate("Action result")}</FormLabel>
                    <Stack direction="row" gap={2}>
                      <Button
                        variant={
                          logbookState.actionResult === true
                            ? "contained"
                            : "outlined"
                        }
                        color={
                          logbookState.actionResult === true
                            ? "success"
                            : undefined
                        }
                        onClick={() => handleActionResultChange(true)}
                      >
                        {translate("Good")}
                      </Button>
                      <Button
                        variant={
                          logbookState.actionResult === false
                            ? "contained"
                            : "outlined"
                        }
                        color={
                          logbookState.actionResult === false
                            ? "error"
                            : undefined
                        }
                        onClick={() => handleActionResultChange(false)}
                      >
                        {translate("Bad")}
                      </Button>
                    </Stack>
                  </Stack>
                ) : null}
              </Stack>
            </Card>
          ) : null}
          <Grid container justifyContent="flex-end" sx={{ mt: 3 }}>
            <LoadingButton
              type="button"
              variant="contained"
              loading={isSubmitting}
              style={{ textTransform: "none" }}
              sx={{ marginLeft: 2 }}
              onClick={() => onSubmit()}
            >
              {translate("Save")}
            </LoadingButton>
          </Grid>
        </Stack>
      </Stack>
    </form>
  );
}
