import { Box, Card, CardProps, Stack, Typography } from "@mui/material";
import { Theme } from "@mui/material/styles";
import { DateTime } from "luxon";
import { sprintf } from "sprintf-js";
import { Measure } from "src/@types";
import { fShortenNumber } from "src/utils/formatNumber";
import thresholdsToRanges from "src/utils/thresholdsToRanges";
import Label from "../../generic/Label";
// import AlarmStatusLabel from "../alarms/AlarmStatusLabel";

interface Props extends Omit<CardProps, "translate"> {
  measure: Measure;
  time: number;
  value: number | null | undefined;
  translate: (text: any, options?: any) => string;
  theme: Theme;
}

export default function MeasureTooltip({
  measure,
  time,
  value,
  translate,
  theme,
  sx,
  ...other
}: Props) {
  const thresholdsRanges = thresholdsToRanges(measure.thresholds);
  const ranges =
    typeof value === "number"
      ? thresholdsRanges
          .intersect(value)
          .sortByProp("priority")
          .filter((range) => range.priority > 0)
      : [];
  const range = ranges.length ? ranges[0] : undefined;

  return (
    <Card
      elevation={5}
      sx={{
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.text.primary,
      }}
    >
      <Stack sx={{ p: 1 }}>
        <Typography sx={{ typography: "body2" }}>
          {DateTime.fromSeconds(time).toLocaleString(DateTime.DATETIME_FULL)}
        </Typography>
        {/*<Divider />*/}
        <Stack direction="row" justifyContent="space-between" gap={1}>
          <Typography sx={{ typography: "body2" }}>{measure.label}</Typography>
          <Typography sx={{ typography: "body2" }}>
            {sprintf(
              "%s %s",
              value !== null && value !== undefined
                ? fShortenNumber(value)
                : "",
              measure.unit || ""
            )}
          </Typography>
        </Stack>
        {/*range && (
          <AlarmStatusLabel
            priority={range.priority}
            active={true}
          />
        )*/}
        {range && (
          <Box>
            {range.priority === 2 && (
              <Label
                theme={theme}
                variant={theme.palette.mode === "light" ? "ghost" : "filled"}
                color={"warning"}
              >
                {translate("Warning")}
              </Label>
            )}
            {range.priority === 3 && (
              <Label
                theme={theme}
                variant={theme.palette.mode === "light" ? "ghost" : "filled"}
                color={"error"}
              >
                {translate("Alert")}
              </Label>
            )}
          </Box>
        )}
      </Stack>
    </Card>
  );
}
