import { AppBar, Box, Breakpoint, Stack, Toolbar } from "@mui/material";
import { styled } from "@mui/material/styles";
import { IconButtonAnimate } from "src/components/generic/animate";
import Iconify from "src/components/generic/Iconify";
import Logo from "src/components/generic/Logo";
import { HEADER, NAVBAR } from "src/config";
import { useOffSetTop, useResponsive, useResponsiveShortcut } from "src/hooks";
import cssStyles from "src/utils/cssStyles";
import AccountPopover from "./AccountPopover";
import DataTimer from "./DataTimer";
// import LanguagePopover from "./LanguagePopover";
import Searchbar from "./Searchbar";
import ShortcutMenu from "./ShortcutMenu";
import breakpoints from "src/theme/breakpoints";

// ----------------------------------------------------------------------

type RootStyleProps = {
  isCollapse: boolean;
  isOffset: boolean;
  verticalLayout: boolean;
  breakpoint: Breakpoint;
};

const RootStyle = styled(AppBar, {
  shouldForwardProp: (prop) =>
    // prop !== "isCollapse" && prop !== "isOffset" && prop !== "verticalLayout",
    !["isCollapse", "isOffset", "verticalLayout", "breakpoint"].includes(
      prop as string
    ),
})<RootStyleProps>(
  ({ isCollapse, isOffset, verticalLayout, breakpoint, theme }) => ({
    ...cssStyles(theme).bgBlur(),
    boxShadow: "none",
    height: HEADER.MOBILE_HEIGHT,
    zIndex: theme.zIndex.appBar + 1,
    transition: theme.transitions.create(["width", "height"], {
      duration: theme.transitions.duration.shorter,
    }),
    // [theme.breakpoints.up("lg")]: {
    [theme.breakpoints.up(breakpoint)]: {
      height: HEADER.DASHBOARD_DESKTOP_HEIGHT,
      width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH + 1}px)`,
      ...(isCollapse && {
        width: `calc(100% - ${NAVBAR.DASHBOARD_COLLAPSE_WIDTH}px)`,
      }),
      ...(isOffset && {
        height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
      }),
      ...(verticalLayout && {
        width: "100%",
        height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
        backgroundColor: theme.palette.background.default,
      }),
    },
  })
);

// ----------------------------------------------------------------------

type Props = {
  onOpenSidebar: VoidFunction;
  isCollapse?: boolean;
  verticalLayout?: boolean;
  breakpoint: Breakpoint;
};

export default function DashboardHeader({
  onOpenSidebar,
  isCollapse = false,
  verticalLayout = false,
  breakpoint,
}: Props) {
  const isOffset =
    useOffSetTop(HEADER.DASHBOARD_DESKTOP_HEIGHT) && !verticalLayout;

  // const { isDesktop } = useResponsiveShortcut();
  const isDesktop = useResponsive("up", breakpoint);

  return (
    <RootStyle
      isCollapse={isCollapse}
      isOffset={isOffset}
      verticalLayout={verticalLayout}
      breakpoint={breakpoint}
    >
      <Toolbar
        sx={{
          minHeight: "100% !important",
          // px: { lg: 5 },
          px: { [breakpoint]: 5 },
        }}
      >
        {isDesktop && verticalLayout && <Logo sx={{ mr: 2.5 }} />}

        {!isDesktop && (
          <IconButtonAnimate
            onClick={onOpenSidebar}
            sx={{ mr: 1, color: "text.primary" }}
          >
            <Iconify icon="eva:menu-2-fill" />
          </IconButtonAnimate>
        )}

        <Searchbar />
        <Box sx={{ flexGrow: 1 }} />

        <Stack direction="row" gap={2}>
          <DataTimer />
          <ShortcutMenu />
          <AccountPopover />
        </Stack>
      </Toolbar>
    </RootStyle>
  );
}
