import { Box, SxProps, useTheme } from "@mui/material";
import useEmblaCarousel from "embla-carousel-react";
import { useEffect, useState } from "react";
import Iconify from "src/components/generic/Iconify";
import { DotButton, useDotButton } from "./EmblaCarouselDotButton";

type Props = {
  sx?: SxProps;
  children: JSX.Element[];
};

export default function ({ sx, children }: Props) {
  const theme = useTheme();
  const [emblaRef, emblaApi] = useEmblaCarousel();
  const { selectedIndex, scrollSnaps, onDotButtonClick } =
    useDotButton(emblaApi);
  const [emblaChildren, setEmblaChildren] = useState<JSX.Element[]>([]);

  useEffect(() => {
    setEmblaChildren(
      children.map((child) => {
        return {
          ...child,
          props: {
            ...child.props,
            className: "embla__slide",
          },
        };
      })
    );
  }, [children]);

  return (
    <Box
      className="embla"
      sx={{
        ".embla": {
          overflow: "hidden",
        },
        ".embla__container": {
          display: "flex",
        },
        ".embla__slide": {
          flex: "0 0 100%",
          minWidth: 0,
        },
        ".embla__dots": {
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: 1,
          p: 1,
        },
        ".embla__dot": {
          "-webkit-appearance": "none",
          backgroundColor: "transparent",
          "touch-action": "manipulation",
          display: "inline-flex",
          textDecoration: "none",
          cursor: "pointer",
          border: 0,
          padding: 0,
          margin: 0,
        },
      }}
    >
      <div className="embla__viewport" ref={emblaRef}>
        <div className="embla__container">
          {emblaChildren.map((child, index) => (
            <div className="embla__slide" key={index}>
              {child}
            </div>
          ))}
        </div>
      </div>
      <div className="embla__dots">
        {scrollSnaps.map((_, index) => (
          <DotButton
            className="embla__dot"
            key={index}
            onClick={() => onDotButtonClick(index)}
          >
            <Iconify
              icon="fa-pro-solid:circle"
              color={
                index === selectedIndex
                  ? theme.palette.primary.main
                  : theme.palette.background.neutral
              }
              sx={{ fontSize: "10px" }}
            />
          </DotButton>
        ))}
      </div>
    </Box>
  );
}
