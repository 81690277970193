import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { ReactNode } from "react";

type Props = {
  title: ReactNode;
  content?: ReactNode;
  actions: ReactNode;
  open: boolean;
  onClose: VoidFunction;
};

export default function ConfirmDialog({
  title,
  content,
  actions,
  open,
  onClose,
}: Props) {
  return (
    <Dialog fullWidth maxWidth="xs" open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{content}</DialogContent>
      <DialogActions>{actions}</DialogActions>
    </Dialog>
  );
}
