import { Equipment } from "src/@types";
import axios from "src/utils/axios";

class EquipmentService {
  get() {
    return axios.get<Equipment[]>("/equipments");
  }
}

const equipmentService = new EquipmentService();
export default equipmentService;
