export type ScaleStop = {
  value: number;
  color: string;
  discrete?: boolean;
};

type Props = {
  scaleKey: string;
  orientation: "x" | "y";
  scaleStops: ScaleStop[];
  discrete?: boolean;
};

export default function scaleGradient({
  scaleKey,
  orientation = "y",
  scaleStops,
  discrete = false,
}: Props) {
  return (u: uPlot) => {
    if (!scaleStops.length) throw new Error("Please provide scale stops");

    // console.log("scaleStops", scaleStops, discrete);

    const scale = u.scales[scaleKey];

    // we want the stop below or at the scaleMax
    // and the stop below or at the scaleMin, else the stop above scaleMin
    let minStopIdx: number | undefined;
    let maxStopIdx: number | undefined;

    for (let i = 0; i < scaleStops.length; i++) {
      let stopVal = scaleStops[i].value;
      if (
        (scale.min !== undefined && stopVal <= scale.min) ||
        minStopIdx === undefined
      )
        minStopIdx = i;
      maxStopIdx = i;
      if (scale.max !== undefined && stopVal >= scale.max) break;
    }
    // console.log("minStopIdx", minStopIdx, "maxStopIdx", maxStopIdx)

    if (minStopIdx === undefined || maxStopIdx === undefined)
      return scaleStops[0].color;

    if (minStopIdx === maxStopIdx) return scaleStops[minStopIdx].color;

    let minStopVal: number | undefined =
      minStopIdx !== undefined ? scaleStops[minStopIdx].value : undefined;
    let maxStopVal: number | undefined =
      maxStopIdx !== undefined ? scaleStops[maxStopIdx].value : undefined;

    if (minStopVal === -Infinity) minStopVal = scale.min;

    if (maxStopVal === Infinity) maxStopVal = scale.max;

    // console.log("minStopVal", minStopVal, "maxStopVal", maxStopVal);

    if (minStopVal === undefined || maxStopVal === undefined)
      return scaleStops[0].color;

    const minStopPos = u.valToPos(minStopVal, scaleKey, true);
    const maxStopPos = u.valToPos(maxStopVal, scaleKey, true);

    const range = minStopPos - maxStopPos;

    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    if (!ctx) return scaleStops[0].color;

    let gradient;
    if (orientation === "y") {
      gradient = ctx.createLinearGradient(0, minStopPos, 0, maxStopPos);
    } else {
      gradient = ctx.createLinearGradient(minStopPos, 0, maxStopPos, 0);
    }

    // Compute gradient
    let prevColor;
    for (let i = minStopIdx; i <= maxStopIdx; i++) {
      const scaleStop = scaleStops[i];
      const stopPos =
        i === minStopIdx
          ? minStopPos
          : i === maxStopIdx
          ? maxStopPos
          : u.valToPos(scaleStop.value, scaleKey, true);
      const offset = (minStopPos - stopPos) / range;
      if (prevColor && (discrete || scaleStop.discrete)) {
        // console.log("Adding", offset, prevColor)
        gradient.addColorStop(offset, prevColor);
      }
      prevColor = scaleStop.color;
      // console.log("Adding", offset, prevColor);
      gradient.addColorStop(offset, prevColor);
    }
    return gradient;
  };
}
