import { Box, Fab, MenuItem } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import Iconify from "src/components/generic/Iconify";
import MenuPopover from "src/components/generic/MenuPopover";
import { useLocales } from "src/hooks";
import { useSelector } from "src/redux/store";
import { PATH_DASHBOARD } from "src/routes/paths";

export default function ShortcutMenu() {
  const theme = useTheme();
  const { translate } = useLocales();
  const { user } = useSelector((state) => state.auth);
  const [open, setOpen] = useState<HTMLElement | null>(null);

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  if (!user) {
    return null;
  }

  type Link = {
    path: string;
    label: string;
    icon: string;
  };

  const links = [
    user?.accessRights.includes("logbook-write")
      ? {
          path: PATH_DASHBOARD.logbook.create,
          label: translate("Create a logbook entry"),
          icon: "fa-pro-duotone:ballot-check",
        }
      : undefined,
    user?.accessRights.includes("patrols-write")
      ? {
          path: PATH_DASHBOARD.patrols.create,
          label: translate("Create a patrol"),
          icon: "fa-pro-duotone:calendar-clock",
        }
      : undefined,
  ].filter((l) => l) as Link[];

  if (!links.length) {
    return null;
  }

  return (
    <Box display="flex" alignItems="center" paddingX={2}>
      <Fab
        onClick={handleOpen}
        sx={{ width: "36px", height: "36px", zIndex: 1 }}
      >
        <Iconify
          icon={"eva:plus-fill"}
          sx={{
            fontSize: "24px",
            color: theme.palette.text.primary,
          }}
        />
      </Fab>

      <MenuPopover open={Boolean(open)} anchorEl={open} onClose={handleClose}>
        {links.map((link, index) => {
          return (
            <MenuItem
              key={index}
              sx={{ my: 1, px: 2 }}
              to={link.path}
              component={RouterLink}
              onClick={handleClose}
            >
              <Iconify
                icon={link.icon}
                color={theme.palette.text.secondary}
                sx={{ mr: 1 }}
              />
              {link.label}
            </MenuItem>
          );
        })}
      </MenuPopover>
    </Box>
  );
}
