import { configureStore } from "@reduxjs/toolkit";
import {
  useDispatch as useAppDispatch,
  useSelector as useAppSelector,
  TypedUseSelectorHook,
} from "react-redux";
import { persistStore } from "redux-persist";
import { AuthUser } from "src/@types";
import { setToken as setAxiosToken } from "src/utils/axios";
import { rootReducer } from "./rootReducer";
import { load as loadTimeControl } from "./slices/baseTimeControl";
import { load as loadFavorites } from "./slices/favorites";
import { load as loadAccessRights } from "./slices/accessRights";
import { load as loadEquipments } from "./slices/equipments";
import { load as loadEquipmentsLocations } from "./slices/equipmentsLocations";
import { load as loadEquipmentsTypes } from "./slices/equipmentsTypes";
import { load as loadLanguages } from "./slices/languages";
import { load as loadMenu } from "./slices/menu";
import { load as loadOrganization } from "./slices/organization";
import { load as loadRoles } from "./slices/roles";
import { load as loadSettings } from "./slices/settings";
import {
  loadData as loadMeasuresData,
  load as loadMeasures,
} from "./slices/measures";
import { start as startSocketIo } from "./slices/socketIo";

// window.env = Object.assign({}, process?.env || {}, window.env || {});
// console.log("process.env", process.env);
// console.log("window.env", window.env);

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    }),
});

export async function setup(user: AuthUser) {
  setAxiosToken(user.token);
  // Restart patrols sync
  window.commPorts.patrolSync.postMessage({ type: "restart" });
  // Load
  await Promise.all([
    dispatch(loadLanguages()),
    dispatch(loadSettings(user)),
    dispatch(loadFavorites(user)),
    dispatch(loadTimeControl({ name: "alarmsLog", user })),
    dispatch(loadTimeControl({ name: "logbook", user })),
    dispatch(loadTimeControl({ name: "measures", user })),
    dispatch(loadTimeControl({ name: "patrols", user })),
    dispatch(loadMenu()),
    dispatch(loadOrganization()),
    dispatch(loadRoles()),
    dispatch(loadAccessRights()),
    dispatch(loadEquipments()),
    dispatch(loadEquipmentsLocations()),
    dispatch(loadEquipmentsTypes()),
  ]);
  // Load measures and measures data
  await dispatch(loadMeasures());
  await dispatch(loadMeasuresData());
  // Start socket.io
  dispatch(startSocketIo());
}

const persistor = persistStore(store, null, async () => {
  const state = store.getState();
  console.log("Loaded persisted state", state);
  if (state.auth.user) {
    setup(state.auth.user);
  }
});

export type RootState = ReturnType<typeof rootReducer>;

export type AppDispatch = typeof store.dispatch;

const { dispatch } = store;

const useDispatch = () => useAppDispatch<AppDispatch>();

const useSelector: TypedUseSelectorHook<RootState> = useAppSelector;

export { store, persistor, dispatch, useSelector, useDispatch };
