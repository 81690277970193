import { FormLabel, Stack, SxProps, Typography, useTheme } from "@mui/material";
import { ReactNode, forwardRef } from "react";
import { useLocales } from "src/hooks";

export const NotSet = () => {
  const theme = useTheme();
  const { translate } = useLocales();
  return (
    <Typography color={theme.palette.text.disabled}>
      {translate("Not set")}
    </Typography>
  );
};

type Props = {
  label?: ReactNode;
  component: ReactNode;
  sx?: SxProps;
};

export default forwardRef<HTMLDivElement, Props>(function BaseBlock(
  { label, component, sx }: Props,
  ref
) {
  return (
    <Stack ref={ref} gap={1} sx={sx}>
      {label ? <FormLabel>{label}</FormLabel> : null}
      {component}
    </Stack>
  );
});
