import { Box, Container } from "@mui/material";
import { DateTime, Duration } from "luxon";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { sprintf } from "sprintf-js";
import {
  Equipment,
  EquipmentType,
  Location,
  Measure,
  TimeControl,
} from "src/@types";
import { useLocales } from "src/hooks";
import BooleanDetails from "src/components/app/measure/details/BooleanDetails";
import NumericDetails from "src/components/app/measure/details/NumericDetails";
import StringDetails from "src/components/app/measure/details/StringDetails";
import MeasureDrawer from "src/components/app/measure/drawer/MeasureDrawer";
import DateRangePicker from "src/components/generic/DateRangePicker";
import HeaderBreadcrumbs from "src/components/generic/HeaderBreadcrumbs";
import Page from "src/components/generic/Page";
import { useSelector } from "src/redux/store";
import { PATH_DASHBOARD } from "src/routes/paths";
import { toStartEndTimeControl } from "src/@types/TimeControl";

export default function MeasureDetails() {
  const settingsState = useSelector((state) => state.settings);
  const { id = "" } = useParams();
  const organizationState = useSelector((state) => state.organization);
  const equipmentsState = useSelector((state) => state.equipments);
  const equipmentsLocationsState = useSelector(
    (state) => state.equipmentsLocations
  );
  const equipmentsTypesState = useSelector((state) => state.equipmentsTypes);
  const { translate } = useLocales();
  const { measures: allMeasures } = useSelector((state) => state.measures);
  const [timeControl, setTimeControl] = useState<TimeControl>();
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const [measure, setMeasure] = useState<Measure>();
  const [drawerOpen, setDrawerOpen] = useState(false);

  const startEndTimeControl = useMemo(() => {
    return timeControl && toStartEndTimeControl(timeControl);
  }, [timeControl]);

  useEffect(() => {
    if (!organizationState.organization) return;
    if (!organizationState.organization.defaultTimeSelectorInterval) return;
    setTimeControl({
      interval: Duration.fromISO(
        organizationState.organization.defaultTimeSelectorInterval
      ),
    });
  }, [organizationState.organization?.defaultTimeSelectorInterval]);

  useEffect(() => {
    const measureFound = allMeasures.find((m: Measure) => m.identifier === id);
    measureFound && setMeasure(measureFound);
  }, [allMeasures, id]);

  const equipment = useMemo(() => {
    return equipmentsState.equipments.find(
      (e: Equipment) => e.identifier === measure?.equipmentId
    );
  }, [equipmentsState.equipments, measure]);

  const equipmentType = useMemo(() => {
    return equipmentsTypesState.equipmentsTypes.find(
      (et: EquipmentType) => et.identifier === equipment?.type
    );
  }, [equipmentsTypesState.equipmentsTypes, equipment]);

  const location = useMemo(() => {
    return equipmentsLocationsState.equipmentsLocations.find(
      (l: Location) => l.identifier === equipment?.locationId
    );
  }, [equipmentsLocationsState.equipmentsLocations, equipment]);

  return (
    <Page
      title={sprintf(translate("Measure: %s"), measure?.label)}
      sx={{ height: "100%" }}
    >
      <Container
        maxWidth={settingsState.themeStretch ? false : "xl"}
        sx={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <HeaderBreadcrumbs
          heading={measure?.label || " "}
          flexGrow={0}
          // links={[
          //   {
          //     name: translate("Home"),
          //     href: PATH_DASHBOARD.root,
          //   },
          //   {
          //     name: location?.label || "",
          //     href: PATH_DASHBOARD.measures.location(
          //       location?.identifier || ""
          //     ),
          //   },
          //   {
          //     name: equipmentType?.label || "",
          //     href: PATH_DASHBOARD.measures.equipment_type_location(
          //       equipmentType?.identifier || "",
          //       location?.identifier || ""
          //     ),
          //   },
          //   {
          //     name: equipment?.label || "",
          //     href: PATH_DASHBOARD.measures.equipment(
          //       equipment?.identifier || ""
          //     ),
          //   },
          //   {
          //     name: measure?.label || "",
          //   },
          // ]}
          action={
            <DateRangePicker
              label={translate("Display time range")}
              intervals={
                organizationState.organization?.timeSelectorIntervals?.details
              }
              maxDate={DateTime.now()}
              open={openDatePicker}
              setOpen={setOpenDatePicker}
              format={"f"}
              // format={"DDDD"}
              value={
                timeControl
                  ? timeControl
                  : { interval: Duration.fromISO("PT12H") }
              }
              onChange={(timeControl) => setTimeControl(timeControl)}
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                width: { xs: "100%", sm: "24rem" },
              }}
            />
          }
        />

        <MeasureDrawer
          measure={measure}
          timeControl={startEndTimeControl}
          displayLinkToMeasure={false}
          open={drawerOpen}
          onClose={() => setDrawerOpen(false)}
        />

        {timeControl ? (
          measure?.type === "number" ? (
            <NumericDetails
              measure={measure}
              timeControl={timeControl}
              setTimeControl={setTimeControl}
              onMenuClick={() => setDrawerOpen(true)}
              sx={{ height: "100%", overflowY: "auto" }}
            />
          ) : measure?.type === "boolean" ? (
            <BooleanDetails
              measure={measure}
              timeControl={timeControl}
              onMenuClick={() => setDrawerOpen(true)}
            />
          ) : measure?.type === "string" ? (
            <StringDetails
              measure={measure}
              timeControl={timeControl}
              onMenuClick={() => setDrawerOpen(true)}
            />
          ) : null
        ) : null}
      </Container>
    </Page>
  );
}
