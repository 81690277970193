import uPlot from "uplot";

type Props = {
  delay?: string;
  duration: string;
  timingFunction?: string;
};

export default function transitionPlugin({ delay = "0s", duration, timingFunction = "ease" }: Props) {
  return {
    hooks: {
      init: [
        (u: uPlot) => {
          const cursorElement =
            u.over.querySelector<HTMLDivElement>(".u-cursor-pt");
          if (!cursorElement) return;
          cursorElement.style.transition = `transform ${duration} ${timingFunction} ${delay}`;
        },
      ],
    },
  };
}
