import { Box, SxProps } from "@mui/material";
import { alpha, styled } from "@mui/material/styles";
import { forwardRef } from "react";
import { isMobile } from "react-device-detect";
import SimpleBarReact, { Props as ScrollbarProps } from "simplebar-react";

import "simplebar/src/simplebar.css";
import "slick-carousel/slick/slick-theme.css";

// ----------------------------------------------------------------------

const RootStyle = styled("div")(() => ({
  flexGrow: 1,
  height: "100%",
  overflow: "hidden",
}));

const SimpleBarStyle = styled(SimpleBarReact)(({ theme }) => ({
  maxHeight: "100%",
  "& .simplebar-scrollbar": {
    "&:before": {
      backgroundColor: alpha(theme.palette.grey[600], 0.48),
    },
    "&.simplebar-visible:before": {
      opacity: 1,
    },
  },
  "& .simplebar-track.simplebar-vertical": {
    width: 10,
  },
  "& .simplebar-track.simplebar-horizontal .simplebar-scrollbar": {
    height: 6,
  },
  "& .simplebar-mask": {
    zIndex: "inherit",
  },
}));

// ----------------------------------------------------------------------

interface Props extends ScrollbarProps {
  sx?: SxProps;
}

export default forwardRef(({ children, sx, ...other }: Props, ref) => {
  /*
  const userAgent =
    typeof navigator === "undefined" ? "SSR" : navigator.userAgent;

  const isMobile =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      userAgent
    );

  if (isMobile) {
    return (
      <Box ref={ref} sx={{ overflowX: "auto", ...sx }} {...other}>
        {children}
      </Box>
    );
  }

  return (
    <RootStyle>
      <SimpleBarStyle
        scrollableNodeProps={{ ref }}
        timeout={500}
        clickOnTrack={false}
        sx={sx}
        {...other}
      >
        {children}
      </SimpleBarStyle>
    </RootStyle>
  );
  */

  if (isMobile) {
    return (
      <Box ref={ref} sx={{ overflowX: "auto", ...sx }} {...other}>
        {children}
      </Box>
    );
  } else {
    return (
      <RootStyle>
        <SimpleBarStyle
          scrollableNodeProps={{ ref }}
          timeout={500}
          clickOnTrack={false}
          sx={sx}
          {...other}
        >
          {children}
        </SimpleBarStyle>
      </RootStyle>
    );
  }

  // return (
  //   <>
  //     <MobileView>
  //       <Box ref={ref} sx={{ overflowX: "auto", ...sx }} {...other}>
  //         {children}
  //       </Box>
  //     </MobileView>
  //     <BrowserView style={{ height: "100%" }}>
  //       <RootStyle>
  //         <SimpleBarStyle
  //           scrollableNodeProps={{ ref }}
  //           timeout={500}
  //           clickOnTrack={false}
  //           sx={sx}
  //           {...other}
  //         >
  //           {children}
  //         </SimpleBarStyle>
  //       </RootStyle>
  //     </BrowserView>
  //   </>
  // );
});
