import axios from "../utils/axios";
import {
  MeasureAggregatesData,
  MeasureData,
  MeasuresHistoryDataFromServer,
  MeasuresLastDataFromServer,
  TimeControl,
} from "../@types";
import { DateTime, Duration } from "luxon";

class DataService {
  getLast() {
    return axios.get<MeasuresLastDataFromServer>("/data/last");
  }
  getHistory(timeControlInterval: Duration) {
    return axios.get<MeasuresHistoryDataFromServer>(
      `/data/history/${timeControlInterval.toISO()}`
    );
  }
  getMeasureHistory(
    measureId: string,
    start: DateTime,
    end: DateTime,
    pagesize: number = -1,
    page: number = 1
  ) {
    return axios.get<MeasureData[]>(
      `/data/measure/${encodeURIComponent(measureId)}/history`,
      {
        params: {
          start: start.setZone("utc").toISO(),
          end: end.setZone("utc").toISO(),
          pagesize: pagesize,
          page: page,
        },
      }
    );
  }
  getMeasureAggregates(
    measureId: string,
    start: DateTime,
    end: DateTime,
    metrics?: ("mean" | "stdev" | "min" | "max")[]
  ) {
    return axios.get<MeasureAggregatesData>(
      `/data/measure/${encodeURIComponent(measureId)}/aggregates`,
      {
        params: {
          start: start.setZone("utc").toISO(),
          end: end.setZone("utc").toISO(),
          metrics: metrics || ["mean", "stdev"],
        },
      }
    );
  }
}

export default new DataService();
