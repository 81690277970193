import Iconify from "../../Iconify";

type Props = {
  open: boolean;
};

export function ArrowIcon({ open }: Props) {
  return (
    <Iconify
      icon={open ? "eva:arrow-ios-downward-fill" : "eva:arrow-ios-forward-fill"}
      sx={{ width: 16, height: 16, ml: 1 }}
    />
  );
}
