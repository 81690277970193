const register = () => {
  const patrolSync = new Worker(new URL("./patrolSync", import.meta.url));
  const lokiStore = new Worker(new URL("./lokiStore.ts", import.meta.url));

  //
  // Set up communication between window and web workers
  //
  window.commPorts = {};

  let channel;

  // Main thread <-> Loki store
  channel = new MessageChannel();
  window.commPorts.lokiStore = channel.port1;
  lokiStore.postMessage(
    { addPort: { name: "mainThread", port: channel.port2 } },
    [channel.port2]
  );

  // Main thread <-> patrolSync
  channel = new MessageChannel();
  window.commPorts.patrolSync = channel.port1;
  patrolSync.postMessage(
    { addPort: { name: "mainThread", port: channel.port2 } },
    [channel.port2]
  );

  // patrolSync <-> Loki store
  channel = new MessageChannel();
  patrolSync.postMessage(
    { addPort: { name: "lokiStore", port: channel.port1 } },
    [channel.port1]
  );
  lokiStore.postMessage(
    { addPort: { name: "patrolSync", port: channel.port2 } },
    [channel.port2]
  );

  //
  // Set patrolSync environment variables
  //
  window.commPorts.patrolSync.postMessage({
    type: "environment",
    data: window.env,
  });

  //
  // Start patrolSync
  //
  setTimeout(
    () => window.commPorts.patrolSync.postMessage({ type: "restart" }),
    3000
  );
};

export { register };
