import {
  SerializedError,
  createAsyncThunk,
  createSlice,
} from "@reduxjs/toolkit";
import { Alarm } from "src/@types";
import { alarmService } from "src/services";

interface AlarmsState {
  currentRequestId?: string;
  loading: boolean;
  loaded: boolean;
  alarms: Alarm[];
  groupedAlarms: Record<string, Alarm[]>;
  error?: SerializedError;
}

const initialState: AlarmsState = {
  loading: false,
  loaded: false,
  alarms: [],
  groupedAlarms: {},
};

export const load = createAsyncThunk<
  { alarms: Alarm[]; groupedAlarms: { [key: string]: Alarm[] } },
  undefined,
  { state: { alarms: AlarmsState } }
>("alarms", async (params, { getState, requestId }) => {
  const { currentRequestId, loading } = getState().alarms;
  if (!loading || requestId !== currentRequestId) {
    return initialState;
  }
  const response = await alarmService.getAlarms();
  const alarms = response.data;

  const groupedAlarms = alarms.reduce((acc, elem) => {
    (acc[elem.metadata.measureId] = acc[elem.metadata.measureId] || []).push(
      elem
    );
    return acc;
  }, {} as Record<string, Alarm[]>);

  return {
    alarms,
    groupedAlarms,
  };
});

const slice = createSlice({
  name: "alarms",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(load.pending, (state, action) => {
        if (!state.loading) {
          state.loading = true;
          state.currentRequestId = action.meta.requestId;
        }
      })
      .addCase(load.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (state.loading && state.currentRequestId === requestId) {
          state.loading = false;
          state.alarms = action.payload.alarms;
          state.groupedAlarms = action.payload.groupedAlarms;
          state.currentRequestId = undefined;
          state.loaded = true;
        }
      })
      .addCase(load.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (state.loading && state.currentRequestId === requestId) {
          state.loading = false;
          // console.log("action.error", JSON.stringify(action.error, null, 2));
          state.error = action.error;
          state.currentRequestId = undefined;
        }
      });
  },
});

export default slice;
