import { Button, Grid } from "@mui/material";
import Step, { StepProps } from "./Step";

type Props = {
  choices: any[];
  emptySentence: string;
  isSelected: (choice: any) => boolean;
  onChange: (choice: any) => void;
} & StepProps;

export default function ChoiceStep({
  title,
  choices,
  emptySentence,
  isSelected,
  onChange,
  goToPreviousStep,
}: Props) {
  return (
    <Step title={title} goToPreviousStep={goToPreviousStep}>
      {choices.length ? (
        <Grid
          container
          direction="row"
          justifyContent="start"
          gap={1}
          sx={{ mt: 1 }}
        >
          {choices.map((choice, index) => (
            <Grid item key={index}>
              <Button
                variant={isSelected(choice) ? "contained" : "outlined"}
                onClick={() => onChange(choice)}
              >
                {choice.label}
              </Button>
            </Grid>
          ))}
        </Grid>
      ) : (
        <div>{emptySentence}</div>
      )}
    </Step>
  );
}
