import { ReactNode, useRef, useState } from "react";
import MenuPopover from "../MenuPopover";
import MoreButton from "../MoreButton";

type Props = {
  children: ReactNode;
};

export default function TableMoreMenu({ children }: Props) {
  const buttonRef = useRef(null);
  const [open, setOpen] = useState(false);

  return (
    <>
      <MoreButton onClick={() => setOpen(true)} ref={buttonRef} />

      {buttonRef.current ? (
        <MenuPopover
          open={open}
          anchorEl={buttonRef.current}
          onClose={() => setOpen(false)}
          anchorOrigin={{ vertical: "top", horizontal: "left" }}
          transformOrigin={{ vertical: "top", horizontal: "right" }}
          // arrow="right-top"
          sx={{
            mt: -1,
            width: 160,
            "& .MuiMenuItem-root": {
              px: 1,
              typography: "body2",
              borderRadius: 0.75,
              "& svg": { mr: 2, width: 20, height: 20 },
            },
          }}
        >
          {children}
        </MenuPopover>
      ) : null}
    </>
  );
}
