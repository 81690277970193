import { BoxProps } from "@mui/material";
import { useTheme } from "@mui/system";
import Label from "./Label";

type LabelColor =
  | "default"
  | "primary"
  | "secondary"
  | "info"
  | "success"
  | "warning"
  | "error";

type Props = {
  text: string;
  color?: LabelColor;
} & BoxProps;

const LabelBadge = ({ text, color = "primary", sx }: Props) => {
  const theme = useTheme();
  return (
    <Label
      variant={theme.palette.mode === "light" ? "filled" : "ghost"}
      color={color}
      sx={{ ...sx, textTransform: "capitalize" }}
      title={text}
    >
      {text}
      {/*<Typography variant="caption" noWrap>
        {text}
      </Typography>*/}
    </Label>
  );
};

export default LabelBadge;
