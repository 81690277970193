import { CardProps } from "@mui/material";
import { Theme, useTheme } from "@mui/material/styles";
import { DateTime, Duration } from "luxon";
import { useMemo } from "react";
import uPlot from "uplot";
import { Measure, TimeControl } from "src/@types";
import useLocales from "src/hooks/useLocales";
import { ColorSchema } from "src/theme/palette";
import { computeMinMax } from "src/components/generic/chart/uPlot/functions";
import {
  cursorTransitionPlugin,
  tooltipPlugin,
} from "src/components/generic/chart/uPlot/plugins";
import UplotChart, {
  UPlotChartOptions,
} from "src/components/generic/chart/uPlot/UplotChart";
import { fillGradient, thresholdsGradient } from "../chart/uPlot/functions";
import { thresholdsLines } from "../chart/uPlot/plugins";
import MeasureSummary from "./MeasureSummary";
import MeasureTooltip from "./MeasureTooltip";
import { isIntervalTimeControl } from "src/@types/TimeControl";

interface Props extends CardProps {
  measure: Measure;
  linkTo?: string;
  onMenuClick: () => void;
  color?: ColorSchema;
  showYOrigin?: boolean;
  timeControl: TimeControl;
}

export default function NumericSummaryChart({
  measure,
  linkTo,
  onMenuClick,
  color = "primary",
  showYOrigin = true,
  timeControl,
  sx,
  ...other
}: Props) {
  const theme = useTheme();
  const { translate } = useLocales();

  const uplotData = useMemo(() => {
    return [
      // X
      Uint32Array.from(
        (measure.data?.history || []).map((d) =>
          DateTime.fromISO(d.time).toUnixInteger()
        )
      ),
      // Y
      Float32Array.from(
        (measure.data?.history || []).map((d) => d.value as number)
      ),
    ];
  }, [measure?.data?.history]);

  const [min, max] = computeMinMax(uplotData)[1];

  const uplotOptions: UPlotChartOptions = {
    series: [
      {},
      {
        points: { show: false },
        paths: uPlot.paths.spline && uPlot.paths.spline(),
        stroke: (u, seriesIdx) =>
          thresholdsGradient({ thresholds: measure.thresholds, theme })(u),
        fill:
          min !== undefined && max !== undefined
            ? (u, seriesIdx) =>
                fillGradient({ color: theme.palette.primary.main, min, max })(u)
            : undefined,
        width: 3,
      },
    ],
    legend: { show: false },
    scales: {
      x: {
        range: isIntervalTimeControl(timeControl)
          ? [
              DateTime.now().minus(timeControl.interval).toUnixInteger(),
              DateTime.now().toUnixInteger(),
            ]
          : [
              timeControl.start.toUnixInteger(),
              timeControl.end.toUnixInteger(),
            ],
      },
      y: {
        range:
          min !== undefined && max !== undefined
            ? [
                // Min
                Math.min.apply(
                  null,
                  [showYOrigin ? 0 : min, min]
                    .concat(
                      measure.thresholds.map((threshold) =>
                        threshold.value !== undefined
                          ? (threshold.value as number)
                          : min
                      )
                    )
                    .filter((e) => e !== undefined)
                ),
                // Max
                Math.max.apply(
                  null,
                  [showYOrigin ? 0 : min, max]
                    .concat(
                      measure.thresholds.map((threshold) =>
                        threshold.value !== undefined
                          ? (threshold.value as number)
                          : min
                      )
                    )
                    .filter((e) => e !== undefined)
                ),
              ]
            : undefined,
      },
    },
    axes: [{ show: false }, { show: false }],
    cursor: {
      points: {
        size: 14,
        width: 2,
        fill: theme.palette.primary.main,
        stroke: theme.palette.background.paper,
      },
      x: false,
      y: false,
      focus: { prox: 500 },
    },
    hooks: {},
    plugins: [
      thresholdsLines({
        thresholds: measure.thresholds,
        translate,
        theme,
        showLabels: false,
      }),
      cursorTransitionPlugin({ duration: "100ms" }),
      tooltipPlugin({
        transition: { duration: "100ms" },
        content: (time, values) => {
          return (
            <MeasureTooltip
              measure={measure}
              time={time}
              value={values[0]}
              translate={translate}
              theme={theme}
            />
          );
        },
      }),
    ],
  };

  return (
    <MeasureSummary
      measure={measure}
      linkTo={linkTo}
      onMenuClick={onMenuClick}
      color={color}
      sx={sx}
      {...other}
    >
      <UplotChart
        data={uplotData}
        options={uplotOptions}
        width={"100%"}
        height={"100%"}
      />
    </MeasureSummary>
  );
}
