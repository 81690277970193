import {
  Box,
  Container,
  Stack,
  Step,
  StepContent,
  useTheme,
} from "@mui/material";
import { DateTime } from "luxon";
import { useEffect, useMemo, useState } from "react";
import {
  Equipment,
  EquipmentType,
  Location,
  LogbookCategory,
  LogbookType,
  Step as StepType,
} from "src/@types";
import LogbookForm from "src/components/app/logbook/LogbookForm";
import HeaderBreadcrumbs from "src/components/generic/HeaderBreadcrumbs";
import LoadingAnimation from "src/components/generic/LoadingAnimation";
import Page from "src/components/generic/Page";
import StepButton from "src/components/generic/stepper/StepButton";
import StyledStepper from "src/components/generic/stepper/StyledStepper";
import { ChoiceStep } from "src/components/generic/step";
import { useLocales, useResponsiveShortcut } from "src/hooks";
import { filterEquipmentTypes } from "src/redux/slices/equipmentsTypes";
import { loadCategories, loadTypes } from "src/redux/slices/logbook";
import { setThemeMode } from "src/redux/slices/settings";
import { dispatch, useSelector } from "src/redux/store";
import { PATH_DASHBOARD } from "src/routes/paths";

export default function LogbookCreate() {
  const { translate } = useLocales();
  const settingsState = useSelector((state) => state.settings);
  const theme = useTheme();
  const { isLgUp } = useResponsiveShortcut();
  const equipmentsState = useSelector((state) => state.equipments);
  const equipmentsLocationsState = useSelector(
    (state) => state.equipmentsLocations
  );
  const equipmentsTypesState = useSelector((state) => state.equipmentsTypes);
  const logbookState = useSelector((state) => state.logbook);

  const [activeStep, setActiveStep] = useState(0);

  const [locations, setLocations] = useState<Location[]>([]);
  const [location, setLocation] = useState<Location>();

  const [equipmentTypes, setEquipmentTypes] = useState<EquipmentType[]>([]);
  const [equipmentType, setEquipmentType] = useState<EquipmentType>();

  const [equipments, setEquipments] = useState<Equipment[]>([]);
  const [equipment, setEquipment] = useState<Equipment>();

  const [categories, setCategories] = useState<LogbookCategory[]>([]);
  const [category, setCategory] = useState<LogbookCategory>();

  const [types, setTypes] = useState<LogbookType[]>([]);
  const [type, setType] = useState<LogbookType>();

  const [loading, setLoading] = useState(false);

  const steps: StepType[] = useMemo(
    () => [
      {
        label: translate("Location"),
        component: (
          <ChoiceStep
            title={translate("Location")}
            choices={locations}
            emptySentence={translate("No location found")}
            isSelected={(_location) =>
              _location !== undefined &&
              location !== undefined &&
              _location.identifier === location.identifier
            }
            onChange={(location) => {
              setLocation(location);
              setEquipmentType(undefined);
              setEquipment(undefined);
              setCategory(undefined);
              setType(undefined);
              nextStep();
            }}
          />
        ),
        value: location?.label,
      },
      {
        label: translate("Equipment type"),
        component: (
          <ChoiceStep
            title={translate("Equipment type")}
            choices={equipmentTypes}
            emptySentence={translate("No equipment type found")}
            isSelected={(_equipmentType) =>
              _equipmentType !== undefined &&
              equipmentType !== undefined &&
              _equipmentType.identifier === equipmentType.identifier
            }
            onChange={(equipmentType) => {
              setEquipmentType(equipmentType);
              setEquipment(undefined);
              setCategory(undefined);
              setType(undefined);
              nextStep();
            }}
            goToPreviousStep={() => previousStep()}
          />
        ),
        value: equipmentType?.label,
      },
      {
        label: translate("Equipement"),
        component: (
          <ChoiceStep
            title={translate("Equipment")}
            choices={equipments}
            emptySentence={translate("No equipment found")}
            isSelected={(_equipment) =>
              _equipment !== undefined &&
              equipment !== undefined &&
              _equipment.identifier === equipment.identifier
            }
            onChange={(equipment) => {
              setEquipment(equipment);
              setCategory(undefined);
              setType(undefined);
              nextStep();
            }}
            goToPreviousStep={() => previousStep()}
          />
        ),
        value: equipment?.label,
      },
      {
        label: translate("Category"),
        component: (
          <ChoiceStep
            title={translate("Category")}
            choices={categories}
            emptySentence={translate("No category found")}
            isSelected={(_category) =>
              _category !== undefined &&
              category !== undefined &&
              _category.identifier === category.identifier
            }
            onChange={(category) => {
              setCategory(category);
              setType(undefined);
              nextStep();
            }}
            goToPreviousStep={() => previousStep()}
          />
        ),
        value: category?.label,
      },
      {
        label: translate("Type"),
        component: (
          <ChoiceStep
            title={translate("Type")}
            choices={types}
            emptySentence={translate("No type found")}
            isSelected={(_type) =>
              _type !== undefined &&
              type !== undefined &&
              _type.identifier === type.identifier
            }
            onChange={(type) => {
              setType(type);
              nextStep();
            }}
            goToPreviousStep={() => previousStep()}
          />
        ),
        value: type?.label,
      },
      {
        label: translate("Content"),
        component:
          location && equipmentType && equipment && category && type ? (
            <LogbookForm
              logbook={{
                location,
                equipmentType,
                equipment,
                category,
                type,
                files: [],
                attachments: [],
                // actionResult: undefined,
                // note: "",
                time: DateTime.now(),
                // customProperties: {},
              }}
            />
          ) : null,
      },
    ],
    [location, equipmentType, equipment, category, type, translate]
  );

  useEffect(() => {
    setLoading(logbookState.loadingCategories);
  }, [logbookState.loadingCategories]);

  useEffect(() => {
    setLoading(logbookState.loadingLogbook);
  }, [logbookState.loadingLogbook]);

  useEffect(() => {
    setLoading(logbookState.loadingTypes);
  }, [logbookState.loadingTypes]);

  useEffect(() => {
    if (!logbookState.loadedCategories) {
      dispatch(loadCategories());
    }
  }, [logbookState.loadedCategories]);

  useEffect(() => {
    if (!logbookState.loadedTypes) {
      dispatch(loadTypes());
    }
  }, [logbookState.loadedTypes]);

  useEffect(() => {
    if (equipmentsLocationsState.equipmentsLocations.length)
      setLocations(equipmentsLocationsState.equipmentsLocations);
  }, [equipmentsLocationsState.equipmentsLocations]);

  useEffect(() => {
    if (location?.identifier)
      setEquipmentTypes(
        filterEquipmentTypes(
          location.identifier,
          equipmentsState.equipments,
          equipmentsTypesState.equipmentsTypes
        )
      );
  }, [
    location,
    equipmentsState.equipments,
    equipmentsTypesState.equipmentsTypes,
  ]);

  useEffect(() => {
    if (equipmentType?.identifier && equipmentsState.equipments && location) {
      setEquipments(
        equipmentsState.equipments.filter(
          (e) =>
            e.type === equipmentType.identifier &&
            e.locationId === location.identifier
        )
      );
    }
  }, [
    equipmentsState.equipments,
    equipmentsTypesState.equipmentsTypes,
    equipmentType,
  ]);

  useEffect(() => {
    if (equipment?.identifier && equipmentType?.identifier) {
      setCategories(
        logbookState.categories.filter((e) =>
          e.equipmentsTypesIds.includes(equipmentType?.identifier)
        )
      );
    }
  }, [equipment, equipmentType, logbookState.categories]);

  useEffect(() => {
    if (
      equipmentType?.identifier &&
      category?.identifier &&
      logbookState.types
    ) {
      setTypes(
        logbookState.types.filter(
          (t) =>
            t.equipmentsTypesIds.includes(equipmentType.identifier) &&
            t.categoriesIds.includes(category.identifier)
        )
      );
    }
  }, [equipmentType, category, logbookState.types]);

  const nextStep = () => {
    setActiveStep(activeStep + 1);
  };

  const previousStep = () => {
    if (activeStep >= 1) setActiveStep(activeStep - 1);
  };

  const canClick = (index: number) => {
    return index === 0 || steps[index - 1].value !== undefined;
  };

  const stepClicked = (index: number) => {
    if (canClick(index)) {
      setActiveStep(index);
    }
  };

  return (
    <Page title={translate("Logbook: create")}>
      <Container maxWidth={settingsState.themeStretch ? false : "xl"}>
        <HeaderBreadcrumbs
          heading={translate("Logbook: create")}
          links={[
            {
              name: translate("Home"),
              href: PATH_DASHBOARD.root,
            },
            {
              name: translate("Tools"),
            },
            {
              name: translate("Logbook"),
              href: PATH_DASHBOARD.logbook.list,
            },
            {
              name: translate("Create"),
            },
          ]}
        />
        <Stack gap={4}>
          <StyledStepper
            nonLinear
            alternativeLabel={isLgUp}
            orientation={isLgUp ? "horizontal" : "vertical"}
            activeStep={activeStep}
          >
            {steps.map((step, index) => (
              <Step key={index} completed={steps[index].value !== undefined}>
                <StepButton
                  step={step}
                  index={index}
                  active={activeStep === index}
                  completed={steps[index].value !== undefined}
                  onClick={
                    canClick(index) ? () => stepClicked(index) : undefined
                  }
                />
                {!isLgUp ? (
                  <StepContent>
                    {loading ? (
                      <Box sx={{ height: "20vh", position: "relative" }}>
                        <LoadingAnimation
                          sx={{
                            backgroundColor: "#ffffff00",
                            transform: "scale(0.7)",
                          }}
                        />
                      </Box>
                    ) : (
                      steps[activeStep].component
                    )}
                  </StepContent>
                ) : null}
              </Step>
            ))}
          </StyledStepper>
          {isLgUp ? (
            loading ? (
              <Box sx={{ height: "20vh", position: "relative" }}>
                <LoadingAnimation
                  sx={{
                    backgroundColor: "#ffffff00",
                    transform: "scale(0.7)",
                  }}
                />
              </Box>
            ) : (
              steps[activeStep].component
            )
          ) : null}
        </Stack>
      </Container>
    </Page>
  );
}
