import { Language } from "src/@types";
import axios from "src/utils/axios";

class LanguageService {
  get() {
    return axios.get<Language[]>("/languages");
  }
}

const languageService = new LanguageService();
export default languageService;
