// @mui
import { Box, Divider, Link, MenuItem, Stack, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { User } from "src/@types";
import UserAvatar from "src/components/app/user/UserAvatar";
import MenuPopover from "src/components/generic/MenuPopover";
import SettingMode from "src/components/generic/settings/drawer/SettingMode";
import { useIsMountedRef, useLocales } from "src/hooks";
import { dispatch, useSelector } from "src/redux/store";
import { logout } from "src/redux/slices/auth";
import { stop as stopSocketIo } from "src/redux/slices/socketIo";
import { PATH_AUTH, PATH_DASHBOARD } from "src/routes/paths";
import SettingStretch from "src/components/generic/settings/drawer/SettingStretch";

export default function AccountPopover() {
  const theme = useTheme();
  const { translate } = useLocales();
  const MENU_OPTIONS = [
    {
      label: translate("Profile"),
      linkTo: PATH_DASHBOARD.user.account,
    },
  ];
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);
  const settingsState = useSelector((state) => state.settings);
  const isMountedRef = useIsMountedRef();
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState<HTMLElement | null>(null);

  const logoUrl =
    settingsState.themeMode === "light"
      ? `${window.env.REACT_APP_EYE_BACKEND_URL}/logo-light?token=${user?.token}`
      : `${window.env.REACT_APP_EYE_BACKEND_URL}/logo-dark?token=${user?.token}`;
  // const logoUrl =
  //   user?.settings.themeMode === "light"
  //     ? `${window.env.REACT_APP_EYE_BACKEND_URL}/logo-light?token=${user?.token}`
  //     : `${window.env.REACT_APP_EYE_BACKEND_URL}/logo-dark?token=${user?.token}`;

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleLogout = async () => {
    await dispatch(logout());
    // try {
    //   dispatch(logout());
    //   navigate(PATH_AUTH.login, { replace: true });

    //   if (isMountedRef.current) {
    //     handleClose();
    //   }
    // } catch (error) {
    //   console.error(error);
    //   enqueueSnackbar("Unable to logout!", { variant: "error" });
    // }
  };

  return (
    <Box>
      <Stack
        onClick={handleOpen}
        direction="row"
        alignItems="center"
        gap={2}
        sx={{
          p: { xs: "6px", lg: "10px" },
          backgroundColor: theme.palette.background.default,
          borderStyle: "solid",
          borderWidth: 1,
          borderRadius: 1,
          borderColor: Boolean(open)
            ? theme.palette.text.primary
            : theme.palette.divider,
          cursor: "pointer",
          "&:hover": {
            borderColor: theme.palette.text.primary,
          },
        }}
      >
        <Box component="img" src={logoUrl} alt="" sx={{ maxWidth: "90px" }} />
        <UserAvatar user={user as User} />
      </Stack>
      <MenuPopover open={Boolean(open)} anchorEl={open} onClose={handleClose}>
        <Box sx={{ my: 1, px: 2 }}>
          {/*<Typography variant="subtitle2" noWrap>
            {user?.displayName}
          </Typography>*/}
          <Typography variant="body2" sx={{ color: "text.secondary" }} noWrap>
            {user?.name}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: "dashed" }} />

        {MENU_OPTIONS.map((option) => (
          <MenuItem
            sx={{ my: 1, px: 2 }}
            key={option.label}
            to={option.linkTo}
            component={RouterLink}
            onClick={handleClose}
          >
            {option.label}
          </MenuItem>
        ))}

        <Divider sx={{ borderStyle: "dashed" }} />

        <Stack gap={1} sx={{ my: 2, px: 2 }}>
          <SettingMode />
          {/* <SettingStretch /> */}
        </Stack>

        <Divider sx={{ borderStyle: "dashed" }} />

        <MenuItem
          onClick={handleLogout}
          // sx={{ m: 1 }}
          sx={{ my: 1, px: 2 }}
        >
          {translate("Logout")}
        </MenuItem>
      </MenuPopover>
    </Box>
  );
}
