// @mui
import { SxProps } from "@mui/material";
//
import LoadingAnimation from "./LoadingAnimation";
import ProgressBar from "./ProgressBar";

// ----------------------------------------------------------------------

type Props = {
  isDashboard?: boolean;
  sx?: SxProps;
};

export default function LoadingScreen({ isDashboard, ...other }: Props) {
  return (
    <>
      <ProgressBar />
      {!isDashboard && (
        <LoadingAnimation sx={{ position: "fixed", ...other }} />
      )}
    </>
  );
}
