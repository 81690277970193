import {
  Box,
  Checkbox,
  Collapse,
  Divider,
  FormControlLabel,
  IconButton,
  Link,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import { DateTimePicker } from "@mui/x-date-pickers";
import saveAs from "file-saver";
import { DateTime } from "luxon";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { sprintf } from "sprintf-js";
import { Equipment } from "src/@types";
import { StartEndTimeControl } from "src/@types/TimeControl";
import Iconify from "src/components/generic/Iconify";
import LoadingPanel from "src/components/generic/LoadingPanel";
import Panel from "src/components/generic/Panel";
import SwipeableDrawer from "src/components/generic/SwipeableDrawer";
import { useFormat, useLocales } from "src/hooks";
import { alarmService } from "src/services";

type ExportSectionProps = {
  timeControl: StartEndTimeControl;
  equipments: Equipment[];
  search: string;
};

function ExportSection({
  timeControl,
  equipments,
  search,
}: ExportSectionProps) {
  const { translate } = useLocales();
  const { formatDate } = useFormat();
  const { enqueueSnackbar } = useSnackbar();

  const optionalFields = [
    { value: "operator", label: translate("Operator") },
    { value: "note", label: translate("Note") },
  ];

  const [startDate, setStartDate] = useState<DateTime>(timeControl.start);
  const [endDate, setEndDate] = useState<DateTime>(timeControl.end);
  // const [optionalFieldsState, setOptionalFieldsState] = useState(
  //   optionalFields.map((field) => field.value)
  // );
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setStartDate(timeControl.start);
    setEndDate(timeControl.end);
  }, [timeControl]);

  const LabelStyle = styled(Typography)(({ theme }) => ({
    ...theme.typography.body2,
    width: 140,
    fontSize: 13,
    flexShrink: 0,
    color: theme.palette.text.secondary,
  }));

  const exportToFile = async (fileType: "ods" | "xlsx") => {
    try {
      setLoading(true);
      const response = await alarmService.getAlarmLogsExportFile(
        {
          start: startDate,
          end: endDate,
          equipments: equipments.map((equipment) => equipment.identifier),
          search: search,
        },
        fileType
      );
      const fileName = response.headers["filename"];
      // const fileName = response.headers["content-disposition"]
      //   .split("=")[1]
      //   .replace(/"/g, "");
      console.log("Exporting data to", fileName);
      saveAs(response.data, fileName);
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
      if (e.text) {
        const error = JSON.parse(await e.text());
        enqueueSnackbar(sprintf(translate("Unable to export: %s"), error), {
          variant: "error",
        });
      }
    }
  };

  const onKeyDown = (e: any) => {
    e.preventDefault();
  };

  return (
    <Stack gap={2} sx={{ px: 2, py: 2, position: "relative" }}>
      {loading ? (
        <LoadingPanel>
          <Box sx={{ textAlign: "center" }}>
            {translate("Please wait while exporting...")}
          </Box>
        </LoadingPanel>
      ) : null}
      <Stack>
        <LabelStyle>{translate("Start date")}</LabelStyle>
        {/* <Typography>{formatDate(startDate)}</Typography> */}
        <DateTimePicker
          value={startDate}
          onChange={(newDate: DateTime | null) =>
            newDate && setStartDate(newDate)
          }
          renderInput={(params: any) => (
            <TextField {...params} onKeyDown={onKeyDown} />
          )}
        />
      </Stack>
      <Stack>
        <LabelStyle>{translate("End date")}</LabelStyle>
        {/* <Typography>{formatDate(endDate)}</Typography> */}
        <DateTimePicker
          value={endDate}
          onChange={(newDate: DateTime | null) =>
            newDate && setEndDate(newDate)
          }
          renderInput={(params: any) => (
            <TextField {...params} onKeyDown={onKeyDown} />
          )}
        />
      </Stack>
      <Stack>
        <LabelStyle>{translate("Equipments")}</LabelStyle>
        <Typography>
          {equipments.length
            ? equipments.map((equipment) => equipment.label).join(", ")
            : translate("All")}
        </Typography>
      </Stack>
      <Stack>
        <LabelStyle>{translate("Search")}</LabelStyle>
        <Typography>{search ? search : translate("Undefined")}</Typography>
      </Stack>
      {/* <Stack>
        <LabelStyle>{translate("Optional fields")}</LabelStyle>
        <RadioGroup>
          {optionalFields.map((field) => (
            <FormControlLabel
              label={field.label}
              control={
                <Checkbox
                  checked={optionalFieldsState.includes(field.value)}
                  value={field}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    if (event.target.checked) {
                      setOptionalFieldsState([
                        ...optionalFieldsState,
                        field.value,
                      ]);
                    } else {
                      setOptionalFieldsState(
                        optionalFieldsState.filter((f) => field.value !== f)
                      );
                    }
                  }}
                />
              }
            ></FormControlLabel>
          ))}
        </RadioGroup>
      </Stack> */}
      <Stack direction="row" justifyContent={"space-around"}>
        <Link
          title={translate("Export to XLSX file")}
          onClick={() => exportToFile("xlsx")}
        >
          <Iconify width={24} height={24} icon="bi:filetype-xlsx" />
          {translate("XLSX export")}
        </Link>
        <Link
          title={translate("Export to ODS file")}
          onClick={() => exportToFile("ods")}
        >
          <Iconify width={24} height={24} icon="bi:file-earmark-spreadsheet" />
          {translate("ODS export")}
        </Link>
      </Stack>
    </Stack>
  );
}

type AlarmLogListDrawerProps = {
  open: boolean;
  onClose: VoidFunction;
  timeControl: StartEndTimeControl;
  equipments: Equipment[];
  search: string;
};

export default function AlarmLogListDrawer({
  open,
  onClose,
  timeControl,
  equipments,
  search,
}: AlarmLogListDrawerProps) {
  const { translate } = useLocales();
  const theme = useTheme();

  const [expandExport, setExpandExport] = useState(true);

  return (
    <SwipeableDrawer open={open} onClose={onClose} anchor="right">
      <Stack>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ px: 2, py: 2 }}
        >
          <Stack
            direction="row"
            gap={1}
            justifyContent="normal"
            alignItems="center"
          >
            <IconButton
              onClick={() => onClose()}
              sx={{
                p: 0,
                ml: "-6px",
              }}
            >
              <Iconify icon="eva:close-fill" width={24} height={24} />
            </IconButton>

            <Typography variant="h6" sx={{ fontSize: 20 }}>
              {translate("Logbook")}
            </Typography>
          </Stack>
        </Stack>

        {/*<GeneralSection
              measure={measure}
              displayLinkToMeasure={displayLinkToMeasure}
            />*/}

        <Stack
          divider={<Divider sx={{ borderStyle: "dashed" }} />}
          gap={2}
          sx={{ pt: 2 }}
        >
          <Stack>
            <Panel
              label={translate("Export")}
              toggle={expandExport}
              onToggle={() => setExpandExport(!expandExport)}
              sx={{ px: 2, cursor: "pointer" }}
            />
            <Collapse in={expandExport}>
              <ExportSection
                timeControl={timeControl}
                equipments={equipments}
                search={search}
              />
            </Collapse>
          </Stack>
        </Stack>
      </Stack>
    </SwipeableDrawer>
  );
}
