import { Container } from "@mui/material";
import { DateTime } from "luxon";
import { useState } from "react";
import AlarmLogListCmp from "src/components/app/alarmlogs/AlarmLogList";
import DateRangePicker from "src/components/generic/DateRangePicker";
import HeaderBreadcrumbs from "src/components/generic/HeaderBreadcrumbs";
import Page from "src/components/generic/Page";
import { useLocales } from "src/hooks";
import { set as setTimeControl } from "src/redux/slices/alarmsLogTimeControl";
import { dispatch, useSelector } from "src/redux/store";
import { PATH_DASHBOARD } from "src/routes/paths";

export default function AlarmLogList() {
  const { translate } = useLocales();
  const timeControlState = useSelector((state) => state.alarmsLogTimeControl);
  const settingsState = useSelector((state) => state.settings);
  const organizationState = useSelector((state) => state.organization);
  const [openDatePicker, setOpenDatePicker] = useState(false);

  return (
    <Page title={translate("Alarms log")}>
      <Container maxWidth={settingsState.themeStretch ? false : "xl"}>
        <HeaderBreadcrumbs
          heading={translate("Alarms log")}
          links={[
            {
              name: translate("Home"),
              href: PATH_DASHBOARD.root,
            },
            {
              name: translate("Tools"),
            },
            {
              name: translate("Alarms"),
              // href: PATH_DASHBOARD.alarms.root,
            },
            {
              name: translate("Alarms log"),
            },
          ]}
          action={
            <DateRangePicker
              label={translate("Display time range")}
              intervals={
                organizationState.organization?.timeSelectorIntervals
                  ?.details || []
              }
              maxDate={DateTime.now()}
              open={openDatePicker}
              setOpen={setOpenDatePicker}
              format={"f"}
              value={timeControlState.timeControl}
              onChange={(timeControl) => dispatch(setTimeControl(timeControl))}
              sx={{ width: { xs: "100%", sm: "24rem" } }}
            />
          }
        />

        <AlarmLogListCmp />
      </Container>
    </Page>
  );
}
