import {
  SerializedError,
  createAsyncThunk,
  createSlice,
} from "@reduxjs/toolkit";
import async from "async-es";
import {
  Measure,
  MeasuresHistoryDataFromServer,
  MeasuresLastDataFromServer,
} from "src/@types";
import { dataService, measureService } from "src/services";
import { TimeControlState } from "./baseTimeControl";
import { EquipmentsState } from "./equipments";
import {
  TimeControl,
  getTimeControlDuration,
  isIntervalTimeControl,
} from "src/@types/TimeControl";

interface MeasuresState {
  currentRequestId?: string;
  loading: boolean;
  loaded: boolean;
  measures: Measure[];
  error?: SerializedError;
  dataLoading: boolean;
  dataLoaded: boolean;
}

const initialState: MeasuresState = {
  loading: false,
  loaded: false,
  measures: [],
  dataLoading: false,
  dataLoaded: false,
};

export const load = createAsyncThunk<
  Measure[],
  undefined,
  { state: { equipments: EquipmentsState; measures: MeasuresState } }
>("measures-load", async (params, { getState, requestId }) => {
  const { currentRequestId, loading } = getState().measures;
  if (!loading || requestId !== currentRequestId) {
    return initialState.measures;
  }
  const response = await measureService.get();
  const measures = response.data;
  // Add equipement to each measure
  const { equipments, loaded: equipmentsLoaded } = getState().equipments;
  measures.forEach((measure) => {
    const equipment = equipments.find(
      (equipment) => equipment.identifier === measure.equipmentId
    );
    if (!equipment) return;
    measure.equipment = equipment;
  });
  return measures;
});

// export const loadOne = createAsyncThunk<
//   Measure | undefined,
//   string,
//   { state: { equipments: EquipmentsState; measures: MeasuresState } }
// >("measure-load", async (measureId, { getState, requestId }) => {
//   const { currentRequestId, loading } = getState().measures;
//   if (!loading || requestId !== currentRequestId) {
//     return initialState.measures.find((m) => m.identifier === measureId);
//   }
//   const response = await measureService.getOne(measureId);
//   const measure = response.data;
//   // Add equipement to measure
//   const { equipments, loaded: equipmentsLoaded } = getState().equipments;
//   const equipment = equipments.find(
//     (equipment) => equipment.identifier === measure.equipmentId
//   );
//   if (!equipment)
//     return initialState.measures.find((m) => m.identifier === measureId);
//   measure.equipment = equipment;
//   return measure;
// });

export const update = createAsyncThunk<
  Measure | undefined,
  Measure,
  { state: { equipments: EquipmentsState; measures: MeasuresState } }
>("measure-load", async (measure, { getState, requestId }) => {
  const { currentRequestId, loading } = getState().measures;
  if (!loading || requestId !== currentRequestId) {
    return;
  }
  const response = await measureService.put(measure);
  const updatedMeasure = response.data;
  // Add equipement
  const { equipments, loaded: equipmentsLoaded } = getState().equipments;
  const equipment = equipments.find(
    (equipment) => equipment.identifier === measure.equipmentId
  );
  updatedMeasure.equipment = equipment;
  return updatedMeasure;
});

export const loadData = createAsyncThunk<
  Measure[],
  TimeControl | undefined,
  { state: { measures: MeasuresState; measuresTimeControl: TimeControlState } }
>("measures-data-load", async (timeControlParam, { getState, requestId }) => {
  const { currentRequestId, dataLoading, measures } = getState().measures;
  if (!dataLoading || requestId !== currentRequestId) {
    return initialState.measures;
  }
  const timeControl =
    timeControlParam || getState().measuresTimeControl.timeControl;

  // Load measures data from server
  const measuresData = timeControl
    ? ((await async.series({
        // Last
        last: async.asyncify(async () => {
          const { data } = await dataService.getLast();
          return data;
        }),
        // History
        history: async.asyncify(async () => {
          const { data } = await dataService.getHistory(
            getTimeControlDuration(timeControl)
          );
          return data;
        }),
      })) as {
        last: MeasuresLastDataFromServer[];
        history: MeasuresHistoryDataFromServer[];
      })
    : {
        last: [],
        history: [],
      };
  // Update measures with data
  const measuresWithData = measures.map((measure) => ({
    ...measure,
    data: {
      last: measuresData.last.find((d) => d.measureId === measure.identifier),
      history: measuresData.history.find(
        (d) => d.measureId === measure.identifier
      )?.values,
    },
  }));
  return measuresWithData;
});

const slice = createSlice({
  name: "measures",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(load.pending, (state, action) => {
        if (!state.loading) {
          state.loading = true;
          state.currentRequestId = action.meta.requestId;
        }
      })
      .addCase(load.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (state.loading && state.currentRequestId === requestId) {
          state.loading = false;
          state.measures = action.payload;
          state.currentRequestId = undefined;
          state.loaded = true;
        }
      })
      .addCase(load.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (state.loading && state.currentRequestId === requestId) {
          state.loading = false;
          // console.log("action.error", JSON.stringify(action.error, null, 2));
          state.error = action.error;
          state.currentRequestId = undefined;
        }
      })
      .addCase(update.pending, (state, action) => {
        if (!state.loading) {
          state.loading = true;
          state.currentRequestId = action.meta.requestId;
        }
      })
      .addCase(update.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (state.loading && state.currentRequestId === requestId) {
          state.loading = false;
          if (action.payload) {
            const measure = action.payload;
            const index = state.measures.findIndex(
              (m) => m.identifier === measure.identifier
            );
            if (index < 0) {
              state.measures = [...state.measures, action.payload];
            } else {
              const measures = [...state.measures];
              measures.splice(index, 1, measure);
              state.measures = measures;
            }
          }
          state.currentRequestId = undefined;
          state.loaded = true;
        }
      })
      .addCase(update.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (state.loading && state.currentRequestId === requestId) {
          state.loading = false;
          state.error = action.error;
          state.currentRequestId = undefined;
        }
      })
      // .addCase(loadOne.pending, (state, action) => {
      //   if (!state.loading) {
      //     state.loading = true;
      //     state.currentRequestId = action.meta.requestId;
      //   }
      // })
      // .addCase(loadOne.fulfilled, (state, action) => {
      //   const { requestId } = action.meta;
      //   if (state.loading && state.currentRequestId === requestId) {
      //     state.loading = false;
      //     // state.measures = action.payload;
      //     state.currentRequestId = undefined;
      //     state.loaded = true;
      //   }
      // })
      // .addCase(loadOne.rejected, (state, action) => {
      //   const { requestId } = action.meta;
      //   if (state.loading && state.currentRequestId === requestId) {
      //     state.loading = false;
      //     state.error = action.error;
      //     state.currentRequestId = undefined;
      //   }
      // })
      .addCase(loadData.pending, (state, action) => {
        if (!state.dataLoading) {
          state.dataLoading = true;
          state.currentRequestId = action.meta.requestId;
        }
      })
      .addCase(loadData.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (state.dataLoading && state.currentRequestId === requestId) {
          state.dataLoading = false;
          state.measures = action.payload;
          state.currentRequestId = undefined;
          state.dataLoaded = true;
        }
      })
      .addCase(loadData.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (state.dataLoading && state.currentRequestId === requestId) {
          state.dataLoading = false;
          // console.log("action.error", JSON.stringify(action.error, null, 2));
          state.error = action.error;
          state.currentRequestId = undefined;
        }
      });
  },
});

export default slice;
