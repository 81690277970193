import { Box, SxProps, Typography } from "@mui/material";
import { alpha, styled, useTheme } from "@mui/material/styles";
import { m } from "framer-motion";
import { ReactNode, useEffect } from "react";

import Iconify from "./Iconify";
import { useLocales } from "src/hooks";

let previousValue = 0;

const AbsoluteDiv = styled("div")(({ theme }) => ({
  right: 0,
  bottom: 0,
  zIndex: 99999,
  width: "100%",
  height: "100%",
  position: "absolute",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "transparent",
}));

const FullBox = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "100%",
}));

function Background() {
  const theme = useTheme();
  return (
    <AbsoluteDiv className="background">
      <Iconify
        icon="gis:compass-alt-o"
        fontSize="60px"
        color={theme.palette.background.neutral}
      />
    </AbsoluteDiv>
  );
}

function Circle() {
  return (
    <AbsoluteDiv className="circle">
      <FullBox
        sx={{
          borderRadius: "50%",
          border: (theme) =>
            `solid 3px ${alpha(theme.palette.primary.light, 0.24)}`,
        }}
      />
    </AbsoluteDiv>
  );
}

function Labels() {
  const theme = useTheme();
  const { translate } = useLocales();
  return (
    <AbsoluteDiv
      className="labels"
      sx={{
        width: "calc(100% - 1rem)",
        height: "calc(100% - 0.8rem)",
        left: "0.5rem",
        top: "0.4rem",
        color: theme.palette.text.secondary,
      }}
    >
      <AbsoluteDiv
        sx={{
          alignItems: "start",
          justifyContent: "center",
        }}
      >
        <Typography>{translate("North").charAt(0)}</Typography>
      </AbsoluteDiv>
      <AbsoluteDiv
        sx={{
          alignItems: "center",
          justifyContent: "end",
        }}
      >
        <Typography>{translate("East").charAt(0)}</Typography>
      </AbsoluteDiv>
      <AbsoluteDiv
        sx={{
          alignItems: "end",
          justifyContent: "center",
        }}
      >
        <Typography>{translate("South").charAt(0)}</Typography>
      </AbsoluteDiv>
      <AbsoluteDiv
        sx={{
          alignItems: "center",
          justifyContent: "start",
        }}
      >
        <Typography>{translate("West").charAt(0)}</Typography>
      </AbsoluteDiv>
    </AbsoluteDiv>
  );
}

function Needle() {
  const theme = useTheme();
  return (
    <AbsoluteDiv className="needle">
      <Iconify
        // icon="gis:compass-needle"
        icon="gis:north-arrow"
        fontSize="30px"
        color={theme.palette.primary.main}
      />
    </AbsoluteDiv>
  );
}

function Rotate({
  value,
  previousValue,
  children,
}: {
  value: number;
  previousValue: number;
  children?: ReactNode;
}) {
  return (
    <Box
      className="rotate"
      component={m.div}
      animate={{
        // rotate: [270, 0, 0, 270, 270],
        rotate: [previousValue, value],
      }}
      transition={{
        ease: "easeInOut",
        duration: 1,
      }}
      sx={{
        width: "100%",
        height: "100%",
      }}
    >
      {children}
    </Box>
  );
}

type Props = {
  value: number;
  rotate?: "needle" | "compass";
  sx?: SxProps;
};

export default function ({ value, rotate = "needle", sx }: Props) {
  const width = 100;
  const height = 100;

  useEffect(() => {
    previousValue = value;
  }, [value]);

  return (
    <Box
      className="compass"
      sx={{ ...sx, width, height, position: "relative" }}
    >
      {rotate === "compass" ? (
        <>
          <Rotate value={value} previousValue={previousValue}>
            <Background />
            <Circle />
            <Labels />
          </Rotate>
          <Needle />
        </>
      ) : (
        <>
          <Background />
          <Circle />
          <Labels />
          <Rotate value={value} previousValue={previousValue}>
            <Needle />
          </Rotate>
        </>
      )}
    </Box>
  );
}
