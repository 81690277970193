import { Box, Card, CardHeader, CardProps, TextField } from "@mui/material";
import merge from "lodash/merge";
import { useState } from "react";
import ReactApexChart from "react-apexcharts";
import ChartBaseOptions from "./ChartBaseOptions";

interface Props extends CardProps {
  title?: string;
  subheader?: string;
  chartData: {
    label: string;
    chartLabels: string[];
    data: {
      name: string;
      data: number[];
    }[];
  }[];
}

export default function MultipleLinesCharts({
  title,
  subheader,
  chartData,
  ...other
}: Props) {
  const [seriesData, setSeriesData] = useState(chartData[0].chartLabels[0]);

  const handleChangeSeriesData = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSeriesData(event.target.value);
  };
  const chart = chartData.find((e: any) => e.label === seriesData);
  const chartOptions = merge(ChartBaseOptions(), {
    xaxis: {
      categories: chart?.chartLabels,
    },
  });

  return (
    <Card {...other}>
      <CardHeader
        title={title}
        subheader={subheader}
        action={
          <TextField
            select
            fullWidth
            value={seriesData}
            SelectProps={{ native: true }}
            onChange={handleChangeSeriesData}
            sx={{
              "& fieldset": { border: "0 !important" },
              "& select": {
                pl: 1,
                py: 0.5,
                pr: "24px !important",
                typography: "subtitle2",
              },
              "& .MuiOutlinedInput-root": {
                borderRadius: 0.75,
                bgcolor: "background.neutral",
              },
              "& .MuiNativeSelect-icon": {
                top: 4,
                right: 0,
                width: 20,
                height: 20,
              },
            }}
          >
            {chartData.map((option: any) => (
              <option key={option.label} value={option.label}>
                {option.label}
              </option>
            ))}
          </TextField>
        }
      />

      {chartData.map((item) => (
        <Box key={item.label} sx={{ mt: 3, mx: 3 }} dir="ltr">
          {item.label === seriesData && (
            <ReactApexChart
              type="line"
              series={item.data}
              chartLabels={item.chartLabels}
              options={chartOptions}
              height={364}
            />
          )}
        </Box>
      ))}
    </Card>
  );
}
