import { forwardRef } from "react";
import RoleBasedGuard from "src/guards/RoleBasedGuard";
import { ListItemStyle, ListItemStyleProps } from "./style";

const ListItem = forwardRef<
  HTMLDivElement & HTMLAnchorElement,
  ListItemStyleProps
>((props, ref) => (
  <RoleBasedGuard roles={props.roles}>
    <ListItemStyle {...props} ref={ref}>
      {props.children}
    </ListItemStyle>
  </RoleBasedGuard>
));

export default ListItem;
