import {
  SerializedError,
  createAsyncThunk,
  createSlice,
} from "@reduxjs/toolkit";
import { Organization } from "src/@types";
import { organizationService } from "src/services";

interface OrganizationState {
  currentRequestId?: string;
  loading: boolean;
  loaded: boolean;
  organization?: Organization;
  error?: SerializedError;
}

const initialState: OrganizationState = {
  loading: false,
  loaded: false,
};

export const load = createAsyncThunk<
  Organization | undefined,
  undefined,
  { state: { organization: OrganizationState } }
>("organization", async (params, { getState, requestId }) => {
  const { currentRequestId, loading } = getState().organization;
  if (!loading || requestId !== currentRequestId) {
    return initialState.organization;
  }
  const response = await organizationService.get();
  return response.data;
});

const slice = createSlice({
  name: "organization",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(load.pending, (state, action) => {
        if (!state.loading) {
          state.loading = true;
          state.currentRequestId = action.meta.requestId;
        }
      })
      .addCase(load.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (state.loading && state.currentRequestId === requestId) {
          state.loading = false;
          state.organization = action.payload;
          state.currentRequestId = undefined;
          state.loaded = true;
        }
      })
      .addCase(load.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (state.loading && state.currentRequestId === requestId) {
          state.loading = false;
          // console.log("action.error", JSON.stringify(action.error, null, 2));
          state.error = action.error;
          state.currentRequestId = undefined;
        }
      });
  },
});

export default slice;
