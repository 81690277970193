// routes
import { addCollection } from "@iconify/react";
import { useEffect } from "react";
import faProDuotoneJson from "./assets/fontawesome-json/fa-pro-duotone.json";
import faProRegularJson from "./assets/fontawesome-json/fa-pro-regular.json";
import faProSolidJson from "./assets/fontawesome-json/fa-pro-solid.json";
import MotionLazyContainer from "./components/generic/animate/MotionLazyContainer";
import { ChartStyle } from "./components/generic/chart";
import NotistackProvider from "./components/generic/NotistackProvider";
import { ProgressBarStyle } from "./components/generic/ProgressBar";
import ScrollToTop from "./components/generic/ScrollToTop";
// components
import ThemeSettings from "./components/generic/settings";
import Router from "./routes";
// theme
import ThemeProvider from "./theme";
import MetaThemeColor from "./MetaThemeColor";

// ----------------------------------------------------------------------

export default function App() {
  useEffect(() => {
    const myFunct = async () => {
      addCollection(faProDuotoneJson);
      addCollection(faProRegularJson);
      addCollection(faProSolidJson);
    };
    myFunct();
  });

  return (
    <MotionLazyContainer>
      <ThemeProvider>
        <ThemeSettings>
          <MetaThemeColor />
          <NotistackProvider>
            <ProgressBarStyle />
            <ChartStyle />
            <ScrollToTop />
            <Router />
          </NotistackProvider>
        </ThemeSettings>
      </ThemeProvider>
    </MotionLazyContainer>
  );
}
