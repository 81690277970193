import { Box, useTheme } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Uppy, UppyFile } from "@uppy/core";
import UppyLocaleEN from "@uppy/locales/lib/en_US";
import UppyLocaleFR from "@uppy/locales/lib/fr_FR";
import { Dashboard } from "@uppy/react";
// import Webcam, { WebcamOptions } from "@uppy/webcam";
import { useState } from "react";
import { useImage, useLocales } from "src/hooks";
import LoadingAnimation from "./LoadingAnimation";

// import "@uppy/core/dist/style.css";
import "@uppy/dashboard/dist/style.css";

const StyledDashboard = styled(Dashboard)(({ theme }) => ({
  ".uppy-Dashboard-inner": {
    height: "auto",
    backgroundColor: "transparent",
    borderWidth: 0,
    // borderWidth: 1,
    // borderStyle: "dashed",
    // borderColor: theme.palette.text.secondary,
    ".uppy-Dashboard-AddFiles": {
      border: "none",
      ".uppy-Dashboard-AddFiles-title": {
        fontSize: "1rem",
        color: theme.palette.text.primary,
        ".uppy-Dashboard-browse": {
          fontSize: "inherit",
          backgroundColor: "transparent",
          border: "none",
        },
      },
      ".uppy-Dashboard-AddFiles-list": {
        display: "block",
        // flexDirection: "row",
        // justifyContent: "center",
        // "> *": { flex: 1 },
        "> span": {
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-around",
        },
        ".uppy-DashboardTab": {
          width: "auto",
          border: "none",
          ".uppy-DashboardTab-btn": {
            width: "auto",
            border: "none",
            backgroundColor: "transparent",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            "&:hover": {
              backgroundColor: "transparent",
            },
            "> svg": {
              margin: 0,
            },
            ".uppy-DashboardTab-inner": {
              margin: 0,
            },
          },
          ".uppy-DashboardTab-name": {
            marginTop: "8px",
            color: theme.palette.text.secondary,
          },
        },
      },
    },
    ".uppy-DashboardContent-panel": {
      backgroundColor: theme.palette.background.default,
      ".uppy-DashboardContent-bar": {
        backgroundColor: "transparent",
        borderBottomColor: theme.palette.divider,
        ".uppy-DashboardContent-back:focus": {
          backgroundColor: "initial",
        },
      },
      ".uppy-DashboardContent-panelBody": {
        padding: "16px",
        ".uppy-Webcam-permissons": {
          ".uppy-Webcam-permissonsIcon": {
            display: "inline-block",
            padding: "16px",
            paddingLeft: 0,
            ">svg": {
              width: "24px",
              height: "24px",
            },
          },
          ".uppy-Webcam-title": {
            display: "inline-block",
            fontSize: "24px",
          },
        },
      },
    },
  },
}));

type Props = {
  locale: "fr" | "en";
  onProcessingFile?: (file: UppyFile) => void;
  onFileAdded: (file: UppyFile) => void;
};

export default function UppyDashboard({
  locale,
  onProcessingFile,
  onFileAdded,
}: Props) {
  const theme = useTheme();
  const image = useImage();
  const { translate } = useLocales();
  const [processing, setProcessing] = useState(false);

  const uppyLocales = {
    en: {
      ...UppyLocaleEN,
      strings: {
        ...UppyLocaleEN.strings,
        dropPasteImportBoth: "Add attachment",
        dropPasteImportFiles: "Add attachment",
      },
    },
    fr: {
      ...UppyLocaleFR,
      strings: {
        ...UppyLocaleFR.strings,
        dropPasteImportBoth: translate("Add attachment"),
        dropPasteImportFiles: translate("Add attachment"),
      },
    },
  };

  const fileAdded = async (uppyFile: UppyFile) => {
    console.log("file-added", uppyFile);
    setProcessing(true);
    onProcessingFile && onProcessingFile(uppyFile);
    // Remove file from uppy state
    delete uppy.getState().files[uppyFile.id];
    uppy.setState({ files: uppy.getState().files });
    // Update Uppy file id as if adding the same file several times,
    if (!uppyFile.type) return;
    // Uppy does not change the id
    uppyFile.id = `${uppyFile.id}-${Math.round(Math.random() * 1e6)}`;
    // Resize images (jpeg and png)
    // if (["image/jpeg", "image/png"].includes(uppyFile.type)) {
    //   console.log("Original image file", uppyFile);
    //   const resizedFile = await image.resize(uppyFile.data, {
    //     minLength: 1024,
    //     mimeType: "image/jpeg",
    //     quality: 0.9,
    //   });
    //   uppyFile = {
    //     ...uppyFile,
    //     data: resizedFile,
    //     meta: {
    //       ...uppyFile.meta,
    //       type: resizedFile.type,
    //     },
    //     size: resizedFile.size,
    //     type: resizedFile.type,
    //   };
    //   console.log("Resized image file", uppyFile);
    // }
    const serializedFile = {
      ...uppyFile,
      content: Buffer.from(await uppyFile.data.arrayBuffer()).toString(
        "base64"
      ),
    };
    console.log("serializedFile", serializedFile);
    setProcessing(false);
    onFileAdded(serializedFile);
  };

  // const webcamOptions: WebcamOptions = {
  //   modes: ["picture"],
  //   mirror: false,
  //   preferredImageMimeType: "image/jpeg",
  //   // mobileNativeCamera: true,
  //   videoConstraints: {
  //     // facingMode: 'environment' # back camera
  //     facingMode: {
  //       exact: "environment", // back camera
  //     },
  //     // Followong constraints are only for video stream...
  //     // width: {
  //     //   min: 1080,
  //     //   max: 1920,
  //     //   ideal: 1920,
  //     // },
  //     // height: {
  //     //   min: 1080,
  //     //   max: 1920,
  //     //   ideal: 1920,
  //     // },
  //   },
  // };

  // const uppy = new Uppy().use(Webcam, webcamOptions).on("file-added", fileAdded);

  const uppy = new Uppy().on("file-added", fileAdded);

  return (
    <Box sx={{ position: "relative", height: "10em" }}>
      <StyledDashboard
        uppy={uppy}
        hideUploadButton={true}
        showProgressDetails={true}
        disableThumbnailGenerator={true}
        proudlyDisplayPoweredByUppy={false}
        locale={uppyLocales[locale]}
        width={"100%"}
        height={undefined}
        sx={{
          position: "absolute",
          left: 0,
          top: 0,
          right: 0,
          bottom: 0,
          opacity: processing ? 0.05 : 1,
        }}
      />
      {processing && <LoadingAnimation />}
    </Box>
  );
}
