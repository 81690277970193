import { Button, Box, Stack, SxProps, ButtonProps } from "@mui/material";
import { useMemo } from "react";
import { Patrol, PatrolMeasure } from "src/@types";
import HelpButton from "src/components/generic/HelpButton";
import Iconify from "src/components/generic/Iconify";
import { useLocales } from "src/hooks";

type PatrolButtonProps = ButtonProps & {
  iconName: string;
  iconPosition?: "left" | "right";
  label: string;
};

const PatrolButton = ({
  label,
  iconName,
  iconPosition = "left",
  ...buttonProps
}: PatrolButtonProps) => {
  return (
    <Button {...buttonProps}>
      {iconPosition === "left" ? (
        <Iconify icon={iconName} fontSize={20} />
      ) : null}
      {label}
      {iconPosition === "right" ? (
        <Iconify icon={iconName} fontSize={20} />
      ) : null}
    </Button>
  );
};

type Props = {
  patrol: Patrol;
  measure: PatrolMeasure;
  mode: "view" | "edit";
  handleMeasureSelection: (measure: PatrolMeasure) => void;
  handleResetMeasure?: (measure: PatrolMeasure) => void;
  handleValidateMeasure?: (measure: PatrolMeasure) => void;
  sx?: SxProps;
};

export default function PatrolMeasureButtons({
  patrol,
  measure,
  mode,
  handleMeasureSelection,
  handleResetMeasure,
  handleValidateMeasure,
  sx,
}: Props) {
  const { translate } = useLocales();

  const measureModel = useMemo(
    () =>
      patrol.model.measures.find((m) => m.identifier === measure.identifier),
    [patrol, measure]
  );

  const activeMeasures = useMemo(
    () => patrol.measures.filter((m) => m.active),
    [patrol.measures]
  );

  const canGoPrevious = useMemo(() => {
    const measureIndex = activeMeasures.findIndex(
      (m) => m.identifier === measure.identifier
    );
    return measureIndex >= 1;
  }, [activeMeasures, measure.identifier]);

  const handleGoPrevious = () => {
    const measureIndex = activeMeasures.findIndex(
      (m) => m.identifier === measure.identifier
    );
    const previousMeasure = activeMeasures[measureIndex - 1];
    handleMeasureSelection(previousMeasure);
  };

  const canGoNext = useMemo(() => {
    const measureIndex = activeMeasures.findIndex(
      (m) => m.identifier === measure.identifier
    );
    return measureIndex < activeMeasures.length - 1;
  }, [activeMeasures, measure.identifier]);

  const handleGoNext = () => {
    const measureIndex = activeMeasures.findIndex(
      (m) => m.identifier === measure.identifier
    );
    const nextMeasure = activeMeasures[measureIndex + 1];
    handleMeasureSelection(nextMeasure);
  };

  if (!measureModel) return null;

  if (mode === "edit") {
    return (
      <Stack direction="row" justifyContent="space-between" sx={{ ...sx }}>
        <Box>
          <PatrolButton
            label={translate("Previous")}
            iconName="eva:arrow-left-outline"
            onClick={() => handleGoPrevious()}
            disabled={!canGoPrevious}
          />
        </Box>
        <Box>
          {handleResetMeasure ? (
            <PatrolButton
              label={translate("Reset")}
              iconName="eva:close-square-outline"
              onClick={() => handleResetMeasure(measure)}
            />
          ) : null}
        </Box>
        <Box>
          {!handleValidateMeasure || measure.done ? (
            <PatrolButton
              label={translate("Next")}
              iconName="eva:arrow-right-outline"
              iconPosition="right"
              onClick={() => handleGoNext()}
              disabled={!canGoNext}
            />
          ) : (
            <Stack direction="row" alignItems="baseline">
              <PatrolButton
                label={
                  canGoNext
                    ? translate("Validate and next")
                    : translate("Validate")
                }
                iconName="eva:checkmark-square-2-outline"
                onClick={() => {
                  handleValidateMeasure(measure);
                  if (canGoNext) {
                    setTimeout(() => handleGoNext(), 100);
                  }
                }}
                disabled={measureModel.required && measure.value === undefined}
              />
              {/*measureModel.required && measure.value === undefined ? (
                <HelpButton
                  content={"Some required fields are empty!"}
                  sx={{ fontSize: 20 }}
                  popoverProps={{
                    anchorOrigin: { vertical: "top", horizontal: "left" },
                    transformOrigin: { vertical: "bottom", horizontal: "right" },
                  }}
                />
                ) : null*/}
            </Stack>
          )}
        </Box>
      </Stack>
    );
  }

  return (
    <Stack direction="row" justifyContent="space-between" sx={{ ...sx }}>
      <Box>
        <PatrolButton
          label={translate("Previous")}
          iconName="eva:arrow-left-outline"
          onClick={() => handleGoPrevious()}
          disabled={!canGoPrevious}
        />
      </Box>
      <Box />
      <Box>
        <PatrolButton
          label={translate("Next")}
          iconName="eva:arrow-right-outline"
          iconPosition="right"
          onClick={() => handleGoNext()}
          disabled={!canGoNext}
        />
      </Box>
    </Stack>
  );
}
