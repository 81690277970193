import { Box, Button, Card, Link, Stack, TablePagination } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Equipment } from "src/@types";
import { LogbookFromServer } from "src/@types/Logbook";
import LogbookTableToolbar from "src/components/app/logbook/LogbookTableToolbar";
import Iconify from "src/components/generic/Iconify";
import LoadingAnimation from "src/components/generic/LoadingAnimation";
import PlusButton from "src/components/generic/PlusButton";
import Scrollbar from "src/components/generic/Scrollbar";
import { TableHeadCell } from "src/components/generic/table";
import { useLocales, useResponsiveShortcut, useTable } from "src/hooks";
import { deleteLogbookEntry, loadLogbook } from "src/redux/slices/logbook";
import { dispatch, useSelector } from "src/redux/store";
import { PATH_DASHBOARD } from "src/routes/paths";
import LogbookTable from "./LogbookTable";
import { toStartEndTimeControl } from "src/@types/TimeControl";
import TableFooter from "src/components/generic/TableFooter";
import MoreButton from "src/components/generic/MoreButton";
import LogbookListDrawer from "./drawer/LogbookListDrawer";

type Props = {
  searchString?: string;
};

export default function LogbookListCmp({ searchString = "" }: Props) {
  const { translate } = useLocales();
  const navigate = useNavigate();
  const table = useTable({ orderBy: "time", order: "desc" });
  const logbookState = useSelector((state) => state.logbook);
  const timeControlState = useSelector((state) => state.logbookTimeControl);

  const [searchFilter, setSearchFilter] = useState(searchString);
  const [searchFilterWatch, setSearchFilterWatch] = useState("");
  const [equipmentsFilter, setEquipmentsFilter] = useState<Equipment[]>([]);
  const [tableData, setTableData] = useState<LogbookFromServer[]>([]);
  const [loading, setLoading] = useState(false);
  const [sizeMax, setSizeMax] = useState(0);
  const [filterTimer, setFilterTimer] = useState<NodeJS.Timeout | undefined>();
  const [drawerOpen, setDrawerOpen] = useState(false);

  const { isLgUp: isDesktop } = useResponsiveShortcut();

  const startEndTimeControl = useMemo(
    () => toStartEndTimeControl(timeControlState.timeControl),
    [timeControlState.timeControl]
  );

  useEffect(() => {
    if (searchFilter === undefined) return;
    if (filterTimer) clearTimeout(filterTimer);
    setFilterTimer(
      setTimeout(() => {
        setSearchFilterWatch(searchFilter);
        setFilterTimer(undefined);
      }, 1000)
    );
  }, [searchFilter]);

  useEffect(() => {
    setTableData(logbookState.logbook);
    setSizeMax(logbookState.logbookTotalCount);
  }, [logbookState.logbook]);

  useEffect(() => {
    setLoading(logbookState.loadingLogbook);
  }, [logbookState.loadingLogbook]);

  const handleSearchFilter = (search: string) => {
    setSearchFilter(search);
    table.setPage(0);
  };

  const handleEquipmentsFilter = (filter: Equipment[]) => {
    setEquipmentsFilter(filter);
    table.setPage(0);
  };

  const isNotFound =
    (!tableData.length && !!searchFilter) ||
    (!tableData.length && !!equipmentsFilter);

  const loadData = async () => {
    dispatch(
      loadLogbook({
        pagesize: table.rowsPerPage,
        page: table.page + 1,
        startDate: startEndTimeControl.start,
        endDate: startEndTimeControl.end,
        sort: table.orderBy,
        order: table.order,
        filterEquipments: equipmentsFilter.map((e) => e.identifier),
        filterSearch: searchFilterWatch,
      })
    );
  };

  useEffect(() => {
    loadData();
  }, [
    timeControlState,
    table.page,
    table.rowsPerPage,
    table.orderBy,
    table.order,
    equipmentsFilter,
    searchFilterWatch,
  ]);

  const handleDelete = async (logbookItem: LogbookFromServer) => {
    dispatch(deleteLogbookEntry(logbookItem._id));
  };

  return (
    <>
      <Card>
        <LogbookTableToolbar
          searchFilter={searchFilter}
          onSearchFilterChange={handleSearchFilter}
          equipmentsFilter={equipmentsFilter}
          onEquipmentsFilterChange={handleEquipmentsFilter}
        >
          {isDesktop ? (
            <Stack
              direction="row"
              gap={2}
              alignItems="center"
              justifyContent="flex-end"
              sx={{
                height: 1,
                // display: { xs: "none", sm: "flex" }
              }}
            >
              <Button
                variant="contained"
                color="primary"
                startIcon={<Iconify icon="eva:plus-fill" />}
                onClick={() => navigate(PATH_DASHBOARD.logbook.create)}
                sx={{ height: 1, textTransform: "none" }}
              >
                {translate("Create entry")}
              </Button>
              {/* <MoreButton onClick={() => setDrawerOpen(true)} /> */}
            </Stack>
          ) : null}
        </LogbookTableToolbar>
        {loading ? (
          <Box sx={{ height: "20vh", position: "relative" }}>
            <LoadingAnimation
              sx={{ backgroundColor: "#ffffff00", transform: "scale(0.7)" }}
            />
          </Box>
        ) : (
          <>
            <LogbookTable
              items={tableData}
              loading={loading}
              table={table}
              mode="full"
              handleDelete={handleDelete}
              sx={{ minHeight: "50vh" }}
            />
            <TableFooter
              rowsPerPageOptions={[10, 20, 50]}
              component="div"
              count={sizeMax}
              rowsPerPage={table.rowsPerPage}
              page={table.page}
              onPageChange={(evt, page) => table.setPage(page)}
              onRowsPerPageChange={(evt) =>
                table.setRowsPerPage(parseInt(evt.target.value, 10))
              }
              labelRowsPerPage={
                <Box
                  component="span"
                  sx={{ display: { xs: "none", sm: "block" } }}
                >
                  {translate("Items per page:")}
                </Box>
              }
            >
              <Link onClick={() => setDrawerOpen(true)}>
                {translate("Export")}
              </Link>
            </TableFooter>
            {/*<TablePagination
              rowsPerPageOptions={[10, 20, 50]}
              component="div"
              count={sizeMax}
              rowsPerPage={table.rowsPerPage}
              page={table.page}
              onPageChange={(evt, page) => table.setPage(page)}
              onRowsPerPageChange={(evt) =>
                table.setRowsPerPage(parseInt(evt.target.value, 10))
              }
              labelRowsPerPage={
                <Box
                  component="span"
                  sx={{ display: { xs: "none", sm: "block" } }}
                >
                  {translate("Items per page:")}
                </Box>
              }
            />*/}
          </>
        )}
      </Card>
      <LogbookListDrawer
        timeControl={startEndTimeControl}
        equipments={equipmentsFilter}
        search={searchFilter}
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
      />
      {!isDesktop ? (
        <PlusButton
          onClick={() => navigate(PATH_DASHBOARD.logbook.create)}
          title={translate("Create logbook entry")}
        />
      ) : null}
    </>
  );
}
