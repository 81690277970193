import { Box, Breakpoint, Drawer, Stack, Typography } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import { useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { sprintf } from "sprintf-js";
import Iconify from "src/components/generic/Iconify";
import Logo from "src/components/generic/Logo";
import LogoFull from "src/components/generic/LogoFull";
import NavSectionVertical from "src/components/generic/nav-section/vertical/NavSectionVertical";
import Scrollbar from "src/components/generic/Scrollbar";
import { NAVBAR } from "src/config";
import { useCollapseDrawer, useLocales, useResponsive } from "src/hooks";
import { useSelector } from "src/redux/store";
import cssStyles from "src/utils/cssStyles";
import CollapseButton from "./CollapseButton";
import SwipeableDrawer from "src/components/generic/SwipeableDrawer";

// const RootStyle = styled(AppBar, {
//   shouldForwardProp: (prop) =>
//     prop !== "isCollapse" && prop !== "isOffset" && prop !== "verticalLayout",
// })<RootStyleProps>(({ isCollapse, isOffset, verticalLayout, theme }) => ({}))

type RootStyleProps = {
  breakpoint: Breakpoint;
};

// const RootStyle = styled("div")(({ theme }) => ({
const RootStyle = styled("div", {
  shouldForwardProp: (prop) => prop !== "breakpoint",
})<RootStyleProps>(({ breakpoint, theme }) => ({
  // [theme.breakpoints.up("lg")]: {
  [theme.breakpoints.up(breakpoint)]: {
    flexShrink: 0,
    transition: theme.transitions.create("width", {
      duration: theme.transitions.duration.shorter,
    }),
  },
}));

type Props = {
  isOpenSidebar: boolean;
  onCloseSidebar: VoidFunction;
  breakpoint: Breakpoint;
};

export default function NavbarVertical({
  isOpenSidebar,
  onCloseSidebar,
  breakpoint,
}: Props) {
  const theme = useTheme();
  const { translate } = useLocales();
  const { pathname } = useLocation();
  // const { isSmUp } = useResponsiveShortcut();
  const isDesktop = useResponsive("up", breakpoint);
  const menuState = useSelector((state) => state.menu);
  const socketIoState = useSelector((state) => state.socketIo);
  const navConfig = useMemo(() => menuState.parsedMenu, [menuState.menu]);
  const {
    isCollapse,
    collapseClick,
    collapseHover,
    onToggleCollapse,
    onHoverEnter,
    onHoverLeave,
  } = useCollapseDrawer();

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const content = useMemo(
    () => (
      <Stack sx={{ height: 1 }}>
        <Stack
          spacing={3}
          sx={{
            pt: 3,
            pb: 2,
            px: 2.5,
            flexShrink: 0,
            ...(isCollapse && { alignItems: "center" }),
          }}
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            {/* TODO : FIXED PIXEL ?_? */}
            {isDesktop && !isCollapse && (
              <>
                <LogoFull />
                {/*<CollapseButton
                  onToggleCollapse={onToggleCollapse}
                  collapseClick={collapseClick}
                />*/}
              </>
            )}
            {isDesktop && isCollapse && <Logo />}
            {!isDesktop && <LogoFull sx={{ scale: "0.8" }} />}
          </Stack>
        </Stack>
        <NavSectionVertical navConfig={navConfig} isCollapse={isCollapse} />
        {!isCollapse && (
          <Stack direction="row" sx={{ mt: 8, ml: 3, pb: 3 }}>
            <Box
              component="span"
              title={
                socketIoState.connecting
                  ? translate("Connecting...")
                  : socketIoState.connected
                  ? translate("Online")
                  : translate("Offline")
              }
            >
              <Iconify
                color={
                  socketIoState.connecting
                    ? theme.palette.warning.main
                    : socketIoState.connected
                    ? theme.palette.success.main
                    : theme.palette.error.main
                }
                icon="fa-pro-solid:circle"
                sx={{ my: "auto", mr: 1, width: 12, height: 12 }}
              />
            </Box>

            <Typography sx={{ my: "auto", fontSize: "0.7rem" }}>
              {window.env.REACT_APP_EYE_VERSION
                ? sprintf(
                    translate("Version: %s"),
                    window.env.REACT_APP_EYE_VERSION
                  )
                : translate("Undefined version")}
            </Typography>
          </Stack>
        )}
      </Stack>
    ),
    [
      navConfig,
      isCollapse,
      isDesktop,
      socketIoState.connecting,
      socketIoState.connected,
    ]
  );

  return (
    <RootStyle
      breakpoint={breakpoint}
      sx={{
        width: {
          // lg: isCollapse
          [breakpoint]: isCollapse
            ? NAVBAR.DASHBOARD_COLLAPSE_WIDTH
            : NAVBAR.DASHBOARD_WIDTH,
        },
        ...(collapseClick && {
          position: "absolute",
        }),
      }}
    >
      {!isDesktop ? (
        <SwipeableDrawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{ sx: { width: NAVBAR.DASHBOARD_WIDTH } }}
        >
          <Box sx={{ height: "calc(100% - env(safe-area-inset-bottom))" }}>
            {content}
          </Box>
        </SwipeableDrawer>
      ) : (
        <Drawer
          open
          variant="persistent"
          onMouseEnter={onHoverEnter}
          onMouseLeave={onHoverLeave}
          PaperProps={{
            sx: {
              width: NAVBAR.DASHBOARD_WIDTH,
              borderRightStyle: "dashed",
              bgcolor: "background.default",
              transition: (theme) =>
                theme.transitions.create("width", {
                  duration: theme.transitions.duration.standard,
                }),
              ...(isCollapse && {
                width: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
              }),
              ...(collapseHover && {
                ...cssStyles(theme).bgBlur(),
                boxShadow: (theme) => theme.customShadows.z24,
              }),
            },
          }}
        >
          <Scrollbar
            sx={{
              height: 1,
              "& .simplebar-content": {
                height: 1,
                display: "flex",
                flexDirection: "column",
              },
            }}
          >
            {content}
          </Scrollbar>
        </Drawer>
      )}
    </RootStyle>
  );
}
