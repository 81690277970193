import { Box, CardProps } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useEffect, useState } from "react";
import { Measure, TimeControl } from "../../../@types";
import useLocales from "../../../hooks/useLocales";
import useChart from "../../../hooks/usePieChart";
import { dataService } from "../../../services";
import { ColorSchema } from "../../../theme/palette";
import MeasureSummary from "./MeasureSummary";

interface Props extends CardProps {
  measure: Measure;
  linkTo?: string;
  onMenuClick: () => void;
  color?: ColorSchema;
  showYOrigin?: boolean;
  timeControl: TimeControl;
}

type LabelsSeriesConfig = {
  series: number[];
  labels: string[];
};

export default function StringBooleanSummaryChart({
  measure,
  linkTo,
  onMenuClick,
  color = "primary",
  sx,
  timeControl,
  ...other
}: Props) {
  const theme = useTheme();
  const { translate } = useLocales();
  const [labelsSeriesConfig, setLabelsSeriesConfig] = useState<
    LabelsSeriesConfig | undefined
  >(undefined);

  // useEffect(() => {
  //   if (
  //     measure.identifier &&
  //     (!labelsSeriesConfig?.labels || !labelsSeriesConfig.series)
  //   ) {
  //     dataService
  //       .getMeasureHistory(measure.identifier, timeControl)
  //       .then((response: any) => {
  //         const labelsTmp = response.data
  //           .map((m: any) => m.value.toString())
  //           .reduce(
  //             (acc: string[], l: string) =>
  //               acc.includes(l) ? acc : [...acc, l],
  //             []
  //           );
  //         const seriesTmp = labelsTmp.map((l: string) =>
  //           response.data.reduce(
  //             (acc: number, el: any) =>
  //               el.value.toString() === l ? acc + 1 : acc,
  //             0
  //           )
  //         );
  //         setLabelsSeriesConfig({ series: seriesTmp, labels: labelsTmp });
  //       })
  //       .catch((e) => console.log(e));
  //   }
  // }, [measure]);

  // const config = useChart({
  //   labels: labelsSeriesConfig?.labels.map((l) =>
  //     l === "true" ? translate("True") : l === "false" ? translate("False") : l
  //   ),
  //   legend: {
  //     position: "right",
  //     offsetX: -20,
  //     offsetY: 64,
  //     itemMargin: {
  //       vertical: 8,
  //     },
  //   },
  //   stroke: {
  //     show: false,
  //   },
  //   dataLabels: {
  //     enabled: true,
  //     dropShadow: {
  //       enabled: false,
  //     },
  //   },
  //   plotOptions: {
  //     pie: {
  //       donut: {
  //         labels: {
  //           show: false,
  //         },
  //       },
  //     },
  //   },
  // });

  return (
    <MeasureSummary
      measure={measure}
      linkTo={linkTo}
      onMenuClick={onMenuClick}
      color={color}
      sx={sx}
      {...other}
    >
      <Box />
      {/*labelsSeriesConfig && (
        <div></div>
        <ReactApexChart
          options={config as ApexOptions}
          series={labelsSeriesConfig.series}
          type="donut"
          width={"100%"}
          height={"100%"}
        />
      )*/}
    </MeasureSummary>
  );
}
