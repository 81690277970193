// Phone number
import { MuiTelInput, MuiTelInputProps } from "mui-tel-input";
// form
import { Controller, useFormContext } from "react-hook-form";

// ----------------------------------------------------------------------

type IProps = {
  name: string;
  label: string;
  defaultCountry?: string;
};

type Props = IProps & Omit<MuiTelInputProps, "value">;
// type Props = IProps;

export default function RHFPhoneNumberField({
  name,
  defaultCountry,
  ...other
}: Props) {
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <MuiTelInput
          defaultCountry={defaultCountry}
          {...field}
          error={!!error}
          helperText={error?.message}
          {...other}
        />
      )}
    />
  );
}
