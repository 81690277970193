import {
  PayloadAction,
  SerializedError,
  createAsyncThunk,
  createSlice,
} from "@reduxjs/toolkit";
import { AuthUser } from "src/@types";
import { userService } from "src/services";
import { get as getStore } from "src/store";
import { setToken as setAxiosToken } from "src/utils/axios";
import { dispatch, setup } from "../store";
import { clear } from "./baseTimeControl";
import { clear as clearFavorites } from "./favorites";
import { load as loadMenu } from "./menu";
import { SettingsState } from "./settings";

export interface AuthState {
  initializing: boolean;
  authenticating: boolean;
  user?: AuthUser;
  error?: SerializedError;
}

const initialState: AuthState = {
  initializing: false,
  authenticating: false,
};

export const login = createAsyncThunk<
  AuthUser,
  { username: string; password: string },
  { state: { settings: SettingsState } }
>("auth-login", async (credentials, { getState }) => {
  const response = await userService.login(
    credentials.username,
    credentials.password
  );
  const user = response.data;
  setAxiosToken(user.token);
  const store = await getStore();
  await store.Users.findAndRemove({ _id: user._id });
  await store.Users.save(user);
  setup(user);
  return user;
});

export const refreshUser = createAsyncThunk<AuthUser, AuthUser>(
  "auth-refresh-user",
  async (user) => {
    const response = await userService.save(user);
    const newUser = { ...response.data, token: user.token };
    const store = await getStore();
    store.Users.save(newUser);
    dispatch(loadMenu());
    return newUser;
  }
);

export const logout = createAsyncThunk("auth-logout", async () => {
  try {
    userService.logout();
  } catch (e) {}
  setAxiosToken(null);
  const store = await getStore();
  await store.Users.clear();
  // Clear associated data
  dispatch(clearFavorites());
  dispatch(clear("alarmsLog"));
  dispatch(clear("logbook"));
  dispatch(clear("measures"));
  dispatch(clear("patrols"));
  return undefined;
});

const slice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    // setUser(state, action: PayloadAction<AuthUser>) {
    //   state.user = action.payload;
    // },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state, action) => {
        state.authenticating = true;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.authenticating = false;
        state.user = action.payload;
      })
      .addCase(login.rejected, (state, action) => {
        state.authenticating = false;
        state.error = action.error;
      })
      // .addCase(logout.pending, (state, action) => {})
      .addCase(logout.fulfilled, (state, action) => {
        state.user = action.payload;
      })
      .addCase(logout.rejected, (state, action) => {
        state.error = action.error;
      })
      // .addCase(refreshUser.pending, (state, action) => {})
      .addCase(refreshUser.fulfilled, (state, action) => {
        state.user = action.payload;
      })
      .addCase(refreshUser.rejected, (state, action) => {
        state.error = action.error;
      });
  },
});

export default slice;
