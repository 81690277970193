import {
  Box,
  Container,
  IconButton,
  Stack,
  Step,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Step as StepType } from "src/@types";
import { LogbookFromServer } from "src/@types/Logbook";
import LoadingAnimation from "src/components/generic/LoadingAnimation";
import LogbookShowCmp from "src/components/app/logbook/LogbookShow";
import HeaderBreadcrumbs from "src/components/generic/HeaderBreadcrumbs";
import Iconify from "src/components/generic/Iconify";
import Page from "src/components/generic/Page";
import StepButton from "src/components/generic/stepper/StepButton";
import StyledStepper from "src/components/generic/stepper/StyledStepper";
import { useFormat, useLocales, useResponsiveShortcut } from "src/hooks";
import { logbookService } from "src/services";
import { useSelector } from "src/redux/store";
import { PATH_DASHBOARD } from "src/routes/paths";
import { sprintf } from "sprintf-js";

export default function LogbookShow() {
  const { id = "" } = useParams();
  const navigate = useNavigate();
  const { isMdUp } = useResponsiveShortcut();
  const settingsState = useSelector((state) => state.settings);
  const theme = useTheme();
  const { translate } = useLocales();
  const { formatDate } = useFormat();
  const [activeStep] = useState(5);
  const [loading, setLoading] = useState(false);
  const [logbook, setLogbook] = useState<LogbookFromServer>();

  useEffect(() => {
    const fn = async () => {
      if (!id) return;
      try {
        setLoading(true);
        const response = await logbookService.get(id);
        setLogbook(response.data);
        setLoading(false);
      } catch (e) {
        console.error(e);
        navigate(PATH_DASHBOARD.logbook.list);
        // setLoading(false);
      }
    };
    fn();
  }, []);

  const steps: StepType[] = [
    {
      label: translate("Location"),
      component: null,
      value: logbook?.location.label,
    },
    {
      label: translate("Equipment type"),
      component: null,
      value: logbook?.equipmentType.label,
    },
    {
      label: translate("Equipement"),
      component: null,
      value: logbook?.equipment.label,
    },
    {
      label: translate("Category"),
      component: null,
      value: logbook?.category.label,
    },
    {
      label: translate("Type"),
      component: null,
      value: logbook?.type.label,
    },
    {
      label: translate("Content"),
      component: null,
      value: "",
    },
  ];

  if (loading) {
    return (
      <Box sx={{ position: "relative", mt: 10 }}>
        <LoadingAnimation sx={{ transform: "scale(0.7)" }} />
      </Box>
    );
  }

  if (!logbook) {
    return null;
  }

  return (
    <Page title={translate("Logbook")}>
      <Container maxWidth={settingsState.themeStretch ? false : "xl"}>
        <HeaderBreadcrumbs
          heading={sprintf(
            "%s - %s - %s",
            logbook.type.label,
            logbook.equipment.label,
            formatDate(logbook.createdOn || "")
          )}
          links={[
            {
              name: translate("Home"),
              href: PATH_DASHBOARD.root,
            },
            {
              name: translate("Tools"),
            },
            {
              name: translate("Logbook"),
              href: PATH_DASHBOARD.logbook.list,
            },
            {
              name: sprintf(
                "%s - %s - %s",
                logbook.type.label,
                logbook.equipment.label,
                formatDate(logbook.createdOn || "")
              ),
            },
          ]}
          action={
            logbook?.updatable ? (
              <Box>
                <IconButton
                  onClick={() =>
                    navigate(PATH_DASHBOARD.logbook.update(logbook._id))
                  }
                  title={translate("Edit")}
                >
                  <Iconify icon={"eva:edit-outline"} />
                </IconButton>
              </Box>
            ) : null
          }
        />
        <Stack gap={4}>
          <StyledStepper
            // nonLinear
            // alternativeLabel
            alternativeLabel={isMdUp}
            orientation={isMdUp ? "horizontal" : "vertical"}
            activeStep={activeStep}
          >
            {steps.map((step, index) => (
              <Step key={index} completed={true}>
                <StepButton
                  step={step}
                  index={index}
                  active={activeStep === index}
                  completed={step.value !== undefined}
                  onClick={undefined}
                />
                {!isMdUp ? (
                  activeStep === index ? (
                    loading ? (
                      <Box sx={{ position: "relative", mt: 10 }}>
                        <LoadingAnimation sx={{ transform: "scale(0.7)" }} />
                      </Box>
                    ) : logbook ? (
                      <LogbookShowCmp logbook={logbook} />
                    ) : null
                  ) : null
                ) : null}
              </Step>
            ))}
          </StyledStepper>
          {isMdUp ? (
            loading ? (
              <Box sx={{ position: "relative", mt: 10 }}>
                <LoadingAnimation sx={{ transform: "scale(0.7)" }} />
              </Box>
            ) : (
              <LogbookShowCmp logbook={logbook} />
            )
          ) : null}
        </Stack>
      </Container>
    </Page>
  );
}
