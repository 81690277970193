import {
  Box,
  Card,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
} from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { Measure, TimeControl } from "src/@types";
import Error500 from "src/components/generic/Error500";
import LoadingAnimation from "src/components/generic/LoadingAnimation";
import Scrollbar from "src/components/generic/Scrollbar";
import {
  TableHeadCell,
  TableHeadCustom,
  TableNoData,
} from "src/components/generic/table";
import { useFormat, useLocales, useTable } from "src/hooks";
import dataService from "src/services/dataService";
import displayLastRefresh from "src/utils/date";
import MeasureAggregate from "../MeasureAggregate";
import MeasureMenuButton from "../MeasureMenuButton";
import { toStartEndTimeControl } from "src/@types/TimeControl";
import TableFooter from "src/components/generic/TableFooter";

type Props = {
  measure: Measure;
  onMenuClick: () => void;
  timeControl: TimeControl;
};

export default function StringDetails({
  measure,
  onMenuClick,
  timeControl,
}: Props) {
  const { formatDate } = useFormat();
  const { translate } = useLocales();

  const [seriesData, setSeriesData] = useState<any[]>([]);
  const [seriesDataLoading, setSeriesDataLoading] = useState<boolean>(false);
  const [seriesDataError, setSeriesDataError] = useState<boolean>(false);
  const [sizeMax, setSizeMax] = useState(0);

  const table = useTable();

  const startEndTimeControl = useMemo(() => {
    return toStartEndTimeControl(timeControl);
  }, [timeControl]);

  useEffect(() => {
    if (!timeControl) return;

    const loadDataSeries = async (measureId: string) => {
      try {
        setSeriesDataError(false);
        setSeriesDataLoading(true);
        const response = await dataService.getMeasureHistory(
          measureId,
          // timeControl,
          startEndTimeControl.start,
          startEndTimeControl.end,
          table.rowsPerPage,
          table.page + 1
        );
        setSeriesData(response.data);
        setSizeMax(Number(response.headers["total-results-count"]));
        setSeriesDataLoading(false);
      } catch (e) {
        setSeriesDataError(true);
        setSeriesDataLoading(false);
      }
    };

    if (measure?.identifier) {
      loadDataSeries(measure?.identifier);
    }
  }, [
    measure?.identifier,
    timeControl,
    table.page,
    table.rowsPerPage,
    table.orderBy,
    table.order,
  ]);

  const TABLE_HEAD: TableHeadCell[] = [
    {
      id: "time",
      label: translate("Date"),
      align: "left",
    },
    {
      id: "value",
      label: translate("Value"),
      align: "left",
    },
  ];

  return (
    <Stack gap={2}>
      <Stack
        sx={{
          flexDirection: { xs: "column-reverse", sm: "row" },
          gap: 2,
          justifyContent: "flex-end",
        }}
      >
        <Card
          sx={{
            display: "flex",
            justifyContent: "center",
            px: 4,
            height: "100px",
          }}
        >
          <MeasureAggregate
            title={translate("Current value")}
            subtitle={
              measure?.data?.last &&
              displayLastRefresh(measure.data.last.time, translate, false)
            }
            measure={measure}
            data={measure.data?.last?.value}
            loading={false}
            loaded={true}
            color="primary"
            sx={{ px: 1 }}
          />
        </Card>
      </Stack>

      <Card>
        {seriesDataError ? (
          <Error500 />
        ) : seriesDataLoading ? (
          <Box sx={{ height: "20vh", position: "relative" }}>
            <LoadingAnimation sx={{ transform: "scale(0.7)" }} />
          </Box>
        ) : (
          <Scrollbar>
            <TableContainer>
              <Stack direction="row" justifyContent="flex-end" sx={{ p: 2 }}>
                <MeasureMenuButton
                  measure={measure}
                  onClick={() => onMenuClick()}
                />
              </Stack>
              <Table size={table.dense ? "small" : "medium"}>
                <TableHeadCustom headCells={TABLE_HEAD} />
                <TableBody>
                  {seriesData.map((d, index) => (
                    <TableRow key={index}>
                      <TableCell>{formatDate(d.time)}</TableCell>
                      <TableCell>
                        {d.value !== undefined
                          ? d.value
                          : translate("Undefined")}
                      </TableCell>
                    </TableRow>
                  ))}
                  <TableNoData isNotFound={!seriesData.length} />
                </TableBody>
              </Table>
            </TableContainer>
            <TableFooter
              rowsPerPageOptions={[10, 20, 50]}
              component="div"
              count={sizeMax}
              rowsPerPage={table.rowsPerPage}
              page={table.page}
              onPageChange={(evt, page) => table.setPage(page)}
              onRowsPerPageChange={(evt) =>
                table.setRowsPerPage(parseInt(evt.target.value, 10))
              }
              labelRowsPerPage={
                <Box
                  component="span"
                  sx={{ display: { xs: "none", sm: "block" } }}
                >
                  {translate("Items per page:")}
                </Box>
              }
            />
            {/* <TablePagination
              rowsPerPageOptions={[10, 20, 50]}
              component="div"
              count={sizeMax}
              rowsPerPage={table.rowsPerPage}
              page={table.page}
              onPageChange={(evt, page) => table.setPage(page)}
              onRowsPerPageChange={(evt) =>
                table.setRowsPerPage(parseInt(evt.target.value, 10))
              }
              labelRowsPerPage={
                <Box
                  component="span"
                  sx={{ display: { xs: "none", sm: "block" } }}
                >
                  {translate("Items per page:")}
                </Box>
              }
            /> */}
          </Scrollbar>
        )}
      </Card>
    </Stack>
  );
}
