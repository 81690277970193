import { Drawer } from "@mui/material";
import { Box } from "@mui/material";
import {
  Button,
  Card,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import diff from "deep-diff";
import { DateTime } from "luxon";
import { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Patrol, PatrolDisplayMode, PatrolMeasure } from "src/@types";
import {
  PatrolHeader,
  PatrolMeasureButtons,
  PatrolMeasuresOutline,
  PatrolValidation,
} from "src/components/app/patrols";
import PatrolMeasureHistoryDrawer from "src/components/app/patrols/drawer/PatrolMeasureHistoryDrawer";
import MeasureForm from "src/components/app/patrols/form/MeasureForm";
import MeasureView from "src/components/app/patrols/view/MeasureView";
import Iconify from "src/components/generic/Iconify";
import Label from "src/components/generic/Label";
import Scrollbar from "src/components/generic/Scrollbar";
import SwipeableDrawer from "src/components/generic/SwipeableDrawer";
import ConfirmDialog from "src/components/generic/dialog/ConfirmDialog";
import {
  useLocales,
  useResponsiveShortcut,
  useStore,
  useTabs,
} from "src/hooks";
import { PatrolsList } from "src/pages/patrols";
import { useSelector } from "src/redux/store";
import { PATH_DASHBOARD } from "src/routes/paths";

type Props = {
  patrol: Patrol;
  measure: PatrolMeasure;
  onMeasureChanged: (measure: PatrolMeasure) => void;
  displayMode: PatrolDisplayMode;
};

export default function PatrolCmp({
  patrol,
  measure,
  onMeasureChanged,
  displayMode,
}: Props) {
  const theme = useTheme();
  const navigate = useNavigate();
  const { translate } = useLocales();
  const { isMdUp } = useResponsiveShortcut();
  const { currentTab, onChangeTab } = useTabs("all");
  const { getStore } = useStore();

  const authState = useSelector((state) => state.auth);
  const [patrolState, setPatrolState] = useState<Patrol>(patrol);
  const [filteredMeasures, setFilteredMeasures] = useState<PatrolMeasure[]>([]);
  const [measures, setMeasures] = useState<PatrolMeasure[]>(patrol.measures);
  const [measureState, setMeasureState] = useState<PatrolMeasure>(measure);
  const [saving, setSaving] = useState(false);
  const [measuresMenuOpen, setMeasuresMenuOpen] = useState(false);
  const [measureHistoryOpen, setMeasureHistoryOpen] = useState(false);
  const [patrolValidationOpen, setPatrolValidationOpen] = useState(false);
  const [previousPatrol, setPreviousPatrol] = useState<Patrol>();
  const [previousMeasure, setPreviousMeasure] = useState<PatrolMeasure>();
  const [resetMeasureDialogOpen, setResetMeasureDialogOpen] =
    useState<PatrolMeasure>();

  const measureScrollbarRef = useRef<HTMLDivElement>();

  useEffect(() => setPatrolState(patrol), [patrol]);
  useEffect(() => setMeasureState(measure), [measure]);
  useEffect(() => setMeasures(patrolState.measures), [patrolState]);

  useEffect(() => {
    const fn = async () => {
      // Previous patrol
      const store = await getStore();
      const previousPatrols = await store.Patrols.find(
        {
          "model.identifier": patrolState.model.identifier,
          done: true,
          startTime: { $lt: patrolState.startTime },
        },
        ["-startTime"],
        { skip: 0, limit: 1 }
      );
      const previousPatrol = previousPatrols.length
        ? previousPatrols[0]
        : undefined;
      setPreviousPatrol(previousPatrol);
      // Previous measure
      const previousMeasure = previousPatrol?.measures.find(
        (measure) => measure.identifier === measureState.identifier
      );
      setPreviousMeasure(previousMeasure);
    };
    fn();
  }, [patrolState, measureState]);

  const measureModel = useMemo(
    () =>
      patrolState.model.measures.find(
        (m) => m.identifier === measureState.identifier
      ),
    [patrolState, measureState]
  );

  // Disable reload by vertical swipe
  useEffect(() => {
    const body = document.getElementsByTagName("body")[0];
    // body.style.overscrollBehaviorY = "contain";
    body.style.setProperty("overscroll-behavior-y", "contain");
    return () => {
      body.style.removeProperty("overscroll-behavior-y");
    };
  }, []);

  // Save patrol if needed
  useEffect(() => {
    const save = async () => {
      setSaving(true);
      console.log("Saving patrol", patrolState);
      const store = await getStore();
      // Search for potential duplicate
      // (ie. the patrol is new and was already saved by the web worker)
      const syncedPatrol = await store.Patrols.findOne({
        remoteId: patrolState._id,
      });
      if (!syncedPatrol) {
        // Duplicate not found: saving this patrol
        patrolState.synced = false;
        await store.Patrols.save(patrolState);
        console.log("Saved patrol", patrolState);
      } else {
        // Duplicate found: mixing properties, saving and cleaning
        const updatedPatrol = {
          ...patrolState,
          isNew: false,
          _id: syncedPatrol._id,
          remoteId: syncedPatrol.remoteId,
          $loki: syncedPatrol.$loki,
          meta: syncedPatrol.meta,
          synced: false,
        };
        await store.Patrols.update(updatedPatrol);
        await store.Patrols.findAndRemove({ _id: patrolState._id });
        console.log("Saved patrol", updatedPatrol);
      }
      setSaving(false);
      if (patrolState.done) {
        if (authState.user?.role !== "ADMIN")
          navigate(PATH_DASHBOARD.patrols.list);
      }
    };

    if (displayMode !== "edit") return;
    if (saving) return;

    const diffs = (diff(patrol, patrolState) || []).filter(
      (d: any) =>
        !(d.kind === "N" && d.lhs === undefined && d.rhs === undefined)
    );
    console.log(
      "Patrol diffs",
      diffs.map((d: any) => ({ ...d, path: d.path?.join("/") }))
    );
    if (!diffs.length) return;
    save();
  }, [patrolState, displayMode]);

  // Update patrol state on measures change
  useEffect(() => {
    if (displayMode !== "edit") return;
    const diffs = diff(patrolState.measures, measures) || [];
    console.log(
      "Patrol measures diffs",
      diffs.map((d: any) => ({ ...d, path: d.path?.join("/") }))
    );
    if (!diffs.length) return;
    console.log("Patrol measures updated");
    // Compute active measures from activeFn
    measures.forEach((measure) => {
      const measureModel = patrol.model.measures.find(
        (m) => m.identifier === measure.identifier
      );
      if (!measureModel) return;
      if (!measureModel.activeFn) return;
      measure.active = eval(measureModel.activeFn)({ measures: measures });
    });
    // Compute patrol progress
    const activeMeasures = measures.filter((m) => m.active);
    const doneMeasures = activeMeasures.filter((m) => m.done);
    // Update patrol
    setPatrolState({
      ...patrol,
      progressPercent: Math.round(
        (100 * doneMeasures.length) / activeMeasures.length
      ),
      measures: measures,
      // hasFiles: measures.reduce(
      //   (acc, measure) => acc || (measure.files || []).length > 0,
      //   false
      // ),
      // hasAttachments: measures.reduce(
      //   (acc, measure) => acc || (measure.attachments || []).length > 0,
      //   false
      // ),
      done: false,
      endTime: undefined,
      signatureFile: undefined,
      signatureAttachment: undefined,
    });
  }, [measures]);

  useEffect(
    () => onMeasureChanged(measureState),
    [onMeasureChanged, measureState]
  );

  useEffect(() => {
    if (currentTab === "all") setFilteredMeasures(measures);
    else if (currentTab === "todo")
      setFilteredMeasures(measures.filter((m) => m.active && !m.done));
    else if (currentTab === "done")
      setFilteredMeasures(measures.filter((m) => m.active && m.done));
  }, [currentTab, measures]);

  const onMeasureUpdated = (measure: PatrolMeasure) => {
    console.log("onMeasureUpdated", measure);
    // Update measure
    const measureIndex = patrolState.measures.findIndex(
      (m) => m.identifier === measure.identifier
    );
    if (measureIndex === -1) return;
    const newMeasures = [...measures];
    newMeasures.splice(measureIndex, 1, measure);
    setMeasures(newMeasures);
    setMeasureState(measure);
  };

  const handleSelectMeasure = (selectedMeasure: PatrolMeasure) => {
    console.log("Selected measure", selectedMeasure.identifier);
    // Close measures menu
    setMeasuresMenuOpen(false);
    // Change current measure
    setMeasureState(selectedMeasure);
    // Scroll to measure top on measure change
    measureScrollbarRef.current?.scrollTo({ top: 0, behavior: "smooth" });
  };

  // const handleResetMeasure = (measure: PatrolMeasure) => {
  //   const resetMeasure = { ...measure };
  //   resetMeasure.value = undefined;
  //   resetMeasure.note = undefined;
  //   resetMeasure.files = [];
  //   resetMeasure.attachments = [];
  //   resetMeasure.done = false;
  //   resetMeasure.doneTime = undefined;
  //   onMeasureUpdated(resetMeasure);
  // };

  const handleResetMeasure = (measure: PatrolMeasure) => {
    setResetMeasureDialogOpen(measure);
  };

  const resetMeasure = (measure: PatrolMeasure) => {
    const resetMeasure = { ...measure };
    resetMeasure.value = undefined;
    resetMeasure.note = undefined;
    resetMeasure.files = [];
    resetMeasure.attachments = [];
    resetMeasure.done = false;
    resetMeasure.doneTime = undefined;
    onMeasureUpdated(resetMeasure);
  };

  const handleValidateMeasure = (measure: PatrolMeasure) => {
    onMeasureUpdated({
      ...measure,
      done: true,
      doneTime: DateTime.now().toISO(),
    });
  };

  const drawerWidth = 240;

  const measuresDrawerContent = useMemo(() => {
    return (
      <PatrolMeasuresOutline
        patrol={patrolState}
        displayedMeasuresIdentifiers={filteredMeasures.map((m) => m.identifier)}
        selectedMeasure={measureState}
        onMeasureSelected={handleSelectMeasure}
      />
    );
  }, [patrolState, measureState, filteredMeasures]);

  if (!measureModel) return null;

  const FILTER_TABS = [
    {
      value: "all",
      label: translate("All"),
      color: "info",
      count: measures.filter((m) => m.active).length,
    },
    {
      value: "todo",
      label: translate("To do"),
      color: "warning",
      count: measures.filter((m) => m.active && !m.done).length || 0,
    },
    {
      value: "done",
      label: translate("Done"),
      color: "success",
      count: measures.filter((m) => m.active && m.done).length || 0,
    },
  ] as const;
  return (
    <Stack
      gap={2}
      sx={{
        height: { xs: "auto", md: "100%" },
        overflowY: "auto",
        flex: 1,
        // display: "flex",
      }}
    >
      {/* Measure history drawer */}
      <PatrolMeasureHistoryDrawer
        patrol={patrolState}
        measure={measureState}
        open={measureHistoryOpen}
        onOpen={() => setMeasureHistoryOpen(true)}
        onClose={() => setMeasureHistoryOpen(false)}
      />
      {/* /Measure history drawer */}
      {/* Patrol header */}
      <Card sx={{ p: 2 }}>
        <PatrolHeader
          patrol={patrolState}
          validatePatrol={
            displayMode === "edit"
              ? (patrol: Patrol) => setPatrolValidationOpen(true)
              : undefined
          }
        />
      </Card>
      {/* /Patrol header */}
      <Card
        sx={{
          height: { xs: "auto", md: "100%" },
          overflowY: "auto",
          flex: 1,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            backgroundColor: theme.palette.background.neutral,
            px: 2,
          }}
        >
          {!isMdUp ? (
            <IconButton
              color="inherit"
              // aria-label="open drawer"
              edge="start"
              onClick={() => setMeasuresMenuOpen(!measuresMenuOpen)}
              sx={{
                mr: 2,
                // display: { sm: "none" }
              }}
            >
              <Iconify icon="eva:menu-fill" />
            </IconButton>
          ) : null}
          {isMdUp ? (
            <Tabs
              allowScrollButtonsMobile
              variant="scrollable"
              scrollButtons="auto"
              value={currentTab}
              onChange={onChangeTab}
              // sx={{ px: 2, bgcolor: "background.neutral" }}
            >
              {FILTER_TABS.map((tab) => (
                <Tab
                  disableRipple
                  key={tab.value}
                  value={tab.value}
                  icon={<Label color={tab.color}>{tab.count}</Label>}
                  label={tab.label}
                />
              ))}
            </Tabs>
          ) : null}
          <Stack>
            {displayMode === "view" ? (
              <Button
                startIcon={<Iconify icon="eva:edit-outline" />}
                onClick={() =>
                  navigate(
                    PATH_DASHBOARD.patrols.edit(
                      patrolState._id,
                      measureState.identifier
                    )
                  )
                }
                title={translate("Edit patrol")}
              >
                {translate("Edit")}
              </Button>
            ) : (
              <Button
                startIcon={<Iconify icon="eva:eye-outline" />}
                onClick={() =>
                  navigate(
                    PATH_DASHBOARD.patrols.overview(patrolState._id)
                    // PATH_DASHBOARD.patrols.view(
                    //   patrolState._id,
                    //   measureState.identifier
                    // )
                  )
                }
                title={translate("View patrol")}
              >
                {translate("View")}
              </Button>
            )}
          </Stack>
        </Stack>

        <Divider />

        <Stack
          direction="row"
          sx={{ height: { xs: "auto", md: "100%" }, overflowY: "auto" }}
        >
          {/* Measures selection mobile drawer */}
          {!isMdUp ? (
            <SwipeableDrawer
              // container={
              //   window !== undefined ? () => window.document.body : undefined
              // }
              open={measuresMenuOpen}
              onOpen={() => setMeasuresMenuOpen(true)}
              onClose={() => setMeasuresMenuOpen(false)}
              ModalProps={{ keepMounted: true }}
              // PaperProps={{ sx: { width: { xs: 1, sm: 480 } } }}
            >
              <Stack>
                <Stack
                  direction="row"
                  gap={1}
                  justifyContent="normal"
                  alignItems="center"
                  sx={{ p: 2 }}
                >
                  <IconButton
                    onClick={() => setMeasuresMenuOpen(false)}
                    sx={{
                      p: 0,
                      ml: "-6px",
                    }}
                  >
                    <Iconify icon="eva:close-fill" width={24} height={24} />
                  </IconButton>
                  <Stack direction="row" gap={1} alignItems="center">
                    <Typography
                      title={translate("Steps")}
                      variant="h6"
                      sx={{ fontSize: 20 }}
                    >
                      {translate("Steps")}
                    </Typography>
                  </Stack>
                </Stack>
                <Tabs
                  allowScrollButtonsMobile
                  variant="scrollable"
                  scrollButtons="auto"
                  value={currentTab}
                  onChange={onChangeTab}
                  // sx={{ px: 2, bgcolor: "background.neutral" }}
                >
                  {FILTER_TABS.map((tab) => (
                    <Tab
                      disableRipple
                      key={tab.value}
                      value={tab.value}
                      icon={<Label color={tab.color}>{tab.count}</Label>}
                      label={tab.label}
                    />
                  ))}
                </Tabs>
                {measuresDrawerContent}
              </Stack>
            </SwipeableDrawer>
          ) : null}
          {/* /Measures selection mobile drawer */}
          {/* Measures selection desktop drawer */}
          {isMdUp ? (
            <Box style={{ flex: 0.4, maxWidth: drawerWidth }}>
              <Drawer
                variant="permanent"
                open
                sx={{
                  height: 1,
                  "& .MuiDrawer-paper": { position: "relative" },
                }}
              >
                <Box component="span" sx={{ px: 2, py: 1 }}>
                  {translate("Steps")}
                </Box>
                {measuresDrawerContent}
              </Drawer>
            </Box>
          ) : null}
          {/* /Measures selection desktop drawer */}
          <Stack
            sx={{ flex: 1, width: "100%", height: { xs: "auto", md: "100%" } }}
            divider={<Divider />}
          >
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ p: 2 }}
            >
              <Box
                sx={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                <Typography variant="widgetTitle" noWrap>
                  {measureModel.label}
                </Typography>
              </Box>
              <Box>
                <Button
                  startIcon={<Iconify icon="fa-solid:history" />}
                  onClick={() => setMeasureHistoryOpen(true)}
                  title={translate("Measure history")}
                  size="small"
                >
                  {translate("History")}
                </Button>
              </Box>
            </Stack>

            {isMdUp ? (
              <Scrollbar ref={measureScrollbarRef}>
                <Box sx={{ p: 2 }}>
                  {displayMode === "view" ? (
                    <MeasureView
                      patrol={patrolState}
                      measure={measureState}
                      showLabel={true}
                      showEmpty={false}
                    />
                  ) : displayMode === "edit" ? (
                    <MeasureForm
                      patrol={patrolState}
                      measure={measureState}
                      onMeasureUpdated={onMeasureUpdated}
                      previous={
                        previousPatrol && previousMeasure
                          ? { patrol: previousPatrol, measure: previousMeasure }
                          : undefined
                      }
                      sx={{ flex: 1 }}
                    />
                  ) : null}
                </Box>
              </Scrollbar>
            ) : (
              <Box sx={{ p: 2 }}>
                {displayMode === "view" ? (
                  <MeasureView
                    patrol={patrolState}
                    measure={measureState}
                    showLabel={true}
                    showEmpty={false}
                  />
                ) : displayMode === "edit" ? (
                  <MeasureForm
                    patrol={patrolState}
                    measure={measureState}
                    onMeasureUpdated={onMeasureUpdated}
                    previous={
                      previousPatrol && previousMeasure
                        ? { patrol: previousPatrol, measure: previousMeasure }
                        : undefined
                    }
                    sx={{ flex: 1 }}
                  />
                ) : null}
              </Box>
            )}

            <PatrolMeasureButtons
              patrol={patrolState}
              measure={measureState}
              mode={displayMode === "edit" ? "edit" : "view"}
              handleMeasureSelection={handleSelectMeasure}
              handleResetMeasure={
                displayMode === "edit" ? handleResetMeasure : undefined
              }
              handleValidateMeasure={
                displayMode === "edit" ? handleValidateMeasure : undefined
              }
              sx={{ px: 2, py: 1 }}
            />
          </Stack>
        </Stack>
      </Card>
      <Dialog
        // fullScreen
        open={patrolValidationOpen}
        onClose={() => setPatrolValidationOpen(false)}
        maxWidth="md"
        // TransitionComponent={Transition}
      >
        <DialogTitle>{translate("Patrol validation")}</DialogTitle>
        <DialogContent>
          <PatrolValidation
            patrol={patrol}
            onCancel={() => setPatrolValidationOpen(false)}
            onValidated={(signatureFile) => {
              setPatrolState({
                ...patrolState,
                done: true,
                endTime:
                  patrolState.startTimeMode === "auto"
                    ? DateTime.utc().toISO()
                    : patrolState.startTime,
                signatureFile: signatureFile,
              });
              setPatrolValidationOpen(false);
            }}
          />
        </DialogContent>
      </Dialog>
      {resetMeasureDialogOpen ? (
        <ConfirmDialog
          open={resetMeasureDialogOpen !== undefined}
          onClose={() => setResetMeasureDialogOpen(undefined)}
          title={
            <Typography variant="subtitle2">{translate("Warning")}</Typography>
          }
          content={
            <>
              <Typography>
                {translate("You are about to reset the current measure.")}
              </Typography>
              <Typography>
                {translate("This will overwrite the current measure.")}
              </Typography>
              <Typography>
                {translate("Are you sure you want to continue?")}
              </Typography>
              <Typography>
                <Iconify
                  icon={"fa-pro-duotone:triangle-exclamation"}
                  sx={{ mr: 1 }}
                />
                {translate("Please be careful, this operation is irreversible")}
              </Typography>
            </>
          }
          actions={
            <>
              <Button
                variant="outlined"
                color="inherit"
                onClick={() => setResetMeasureDialogOpen(undefined)}
              >
                {translate("Cancel")}
              </Button>
              <Button
                variant="contained"
                color="info"
                onClick={async () => {
                  resetMeasure(resetMeasureDialogOpen);
                  setResetMeasureDialogOpen(undefined);
                }}
              >
                {translate("Reset")}
              </Button>
            </>
          }
        />
      ) : null}
    </Stack>
  );
}
