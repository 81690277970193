import {
  Box,
  SxProps,
  TableCell,
  TableCellProps,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";

const visuallyHidden = {
  border: 0,
  margin: -1,
  padding: 0,
  width: "1px",
  height: "1px",
  overflow: "hidden",
  position: "absolute",
  whiteSpace: "nowrap",
  clip: "rect(0 0 0 0)",
} as const;

export type TableHeadCell = {
  id: string;
  label?: string;
  align?: TableCellProps["align"];
  sx?: SxProps;
  // width:
  // minWidth:
};

type Props = {
  headCells: TableHeadCell[];
  order?: "asc" | "desc";
  orderBy?: string;
  rowCount?: number;
  numSelected?: number;
  onSort?: (id: string) => void;
  onSelectAllRows?: (checked: boolean) => void;
  sx?: SxProps;
};

export default function TableHeadCustom({
  headCells,
  order,
  orderBy,
  onSort,
  sx,
}: Props) {
  return (
    <TableHead sx={sx}>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.align || "left"}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{ bgcolor: "background.neutral", ...headCell.sx }}
            // sx={{
            //   width: headCell.width,
            //   minWidth: headCell.minWidth,
            //   bgcolor: "background.neutral",
            // }}
          >
            {onSort ? (
              <TableSortLabel
                hideSortIcon
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={() => onSort(headCell.id)}
                // sx={{ textTransform: "capitalize" }}
              >
                {headCell.label}

                {orderBy === headCell.id ? (
                  <Box sx={{ ...visuallyHidden }}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            ) : (
              headCell.label
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
