import {
  Box,
  List,
  ListItemButton,
  ListItemText,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { SxProps } from "@mui/material/styles";
import { useEffect, useMemo, useRef } from "react";
import numeral from "numeral";
import { sprintf } from "sprintf-js";
import { Patrol, PatrolMeasure, PatrolModelMeasure } from "src/@types";
import Label from "src/components/generic/Label";
import Scrollbar from "src/components/generic/Scrollbar";
import { useLocales } from "src/hooks";
import { useSelector } from "src/redux/store";

function OutlinePrimary({
  patrol,
  measure,
  measureModel,
}: {
  patrol: Patrol;
  measure: PatrolMeasure;
  measureModel: PatrolModelMeasure;
}) {
  const theme = useTheme();
  return (
    <Typography noWrap={true} variant="subtitle2">
      <span style={{ color: theme.palette.primary.main, marginRight: "2px" }}>
        {measureModel.required ? "*" : ""}
      </span>
      {measureModel.label}
    </Typography>
  );
}

function OutlineSecondary({
  patrol,
  measure,
  measureModel,
}: {
  patrol: Patrol;
  measure: PatrolMeasure;
  measureModel: PatrolModelMeasure;
}) {
  const { translate } = useLocales();
  if (!measure.done) {
    return <Label color="warning">{translate("To do")}</Label>;
  }
  const getLabel = () => {
    if (!measureModel) return "";
    if (measure.value === undefined) return translate("Not set");
    if (measureModel.type === "boolean") {
      return measure.value ? translate("Yes") : translate("No");
    }
    if (measureModel.type === "number") {
      return sprintf(
        "%s %s",
        numeral(measure.value).format("0.[0000]"),
        measureModel.unit || ""
      );
    }
    return measure.value;
  };
  return (
    <Label
      color="success"
      sx={{
        // Trick to make ellipsis work
        maxWidth: "100%",
        height: "auto",
        lineHeight: "2em",
        justifyContent: "start",
        "> span": {
          display: "block",
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
      }}
    >
      <span>{getLabel()}</span>
    </Label>
  );
}

type Props = {
  patrol: Patrol;
  displayedMeasuresIdentifiers: string[];
  selectedMeasure: PatrolMeasure;
  onMeasureSelected: (measure: PatrolMeasure) => void;
  sx?: SxProps;
};

export default function PatrolMeasuresOutline({
  patrol,
  displayedMeasuresIdentifiers,
  selectedMeasure,
  onMeasureSelected,
  sx,
}: Props) {
  const { translate } = useLocales();
  const scrollbarRef = useRef<HTMLDivElement>();

  const { groupMeasures } = useSelector((state) => state.patrols);

  const measuresItemsOffsets: { [key: string]: number } = {};
  const measuresItemsHeights: { [key: string]: number } = {};

  const selectedMeasureModel = useMemo(
    () =>
      patrol.model.measures.find(
        (m) => m.identifier === selectedMeasure.identifier
      ),
    [patrol, selectedMeasure]
  );

  useEffect(() => {
    const itemOffset = measuresItemsOffsets[selectedMeasure.identifier];
    const itemHeight = measuresItemsHeights[selectedMeasure.identifier];
    if (itemOffset === undefined || itemHeight === undefined) return;
    if (scrollbarRef.current === undefined) return;
    // Scroll outline if selected item is not visible
    if (
      itemOffset < scrollbarRef.current.scrollTop ||
      itemOffset + itemHeight >
        scrollbarRef.current.scrollTop + scrollbarRef.current.clientHeight
    ) {
      scrollbarRef.current.scrollTo({
        top: itemOffset,
        behavior: "smooth",
      });
    }
    // }, [measuresItemsOffsets, selectedMeasure]);
  }, [selectedMeasure]);

  const groupedMeasures = useMemo(
    () =>
      groupMeasures(
        patrol.measures
          .filter((m) => displayedMeasuresIdentifiers.includes(m.identifier))
          .filter((m) => m.active),
        patrol.model
      ),
    [displayedMeasuresIdentifiers, patrol.measures, patrol.model]
  );

  if (!selectedMeasureModel) return null;

  return (
    <Scrollbar ref={scrollbarRef}>
      <Stack sx={sx}>
        {/*<Box component="span" sx={{ px: 2, py: 1 }}>
          {translate("Steps")}
        </Box>*/}
        {groupedMeasures.map((groupedMeasures, index) =>
          groupedMeasures.measuresAndModels.length ? (
            <Stack key={index}>
              {groupedMeasures.group?.label && (
                <Box sx={{ px: 2 }}>
                  <Typography variant="subtitle2" sx={{ fontSize: "1rem" }}>
                    {groupedMeasures.group?.label}
                  </Typography>
                </Box>
              )}
              <List sx={{ p: 0 }}>
                {groupedMeasures.measuresAndModels.map(
                  (measureAndModel, index) => (
                    <ListItemButton
                      key={index}
                      onClick={() => onMeasureSelected(measureAndModel.measure)}
                      sx={{
                        ...(measureAndModel.measure.identifier ===
                          selectedMeasure.identifier && {
                          bgcolor: "action.selected",
                        }),
                      }}
                      ref={(elt) => {
                        if (!elt) return;
                        const offsetTop =
                          (elt.offsetTop || 0) +
                          (elt.parentElement?.offsetTop || 0);
                        if (!offsetTop) return;
                        measuresItemsOffsets[
                          measureAndModel.measure.identifier
                        ] = offsetTop;
                        measuresItemsHeights[
                          measureAndModel.measure.identifier
                        ] = elt.clientHeight;
                      }}
                    >
                      <ListItemText
                        // primary={measureAndModel.model.label}
                        primary={
                          <OutlinePrimary
                            patrol={patrol}
                            measure={measureAndModel.measure}
                            measureModel={measureAndModel.model}
                          />
                        }
                        // primaryTypographyProps={{
                        //   noWrap: true,
                        //   variant: "subtitle2",
                        // }}
                        secondary={
                          <OutlineSecondary
                            patrol={patrol}
                            measure={measureAndModel.measure}
                            measureModel={measureAndModel.model}
                          />
                        }
                        // secondaryTypographyProps={{
                        //   // noWrap: true,
                        //   variant: "body2",
                        //   color: "textSecondary",
                        // }}
                      />
                    </ListItemButton>
                  )
                )}
              </List>
            </Stack>
          ) : null
        )}
      </Stack>
    </Scrollbar>
  );
}
