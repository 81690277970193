import {
  SerializedError,
  createAsyncThunk,
  createSlice,
} from "@reduxjs/toolkit";
import { Equipment, EquipmentType } from "src/@types";
import { equipmentTypeService } from "src/services";

interface EquipmentsTypesState {
  currentRequestId?: string;
  loading: boolean;
  loaded: boolean;
  equipmentsTypes: EquipmentType[];
  error?: SerializedError;
}

const initialState: EquipmentsTypesState = {
  loading: false,
  loaded: false,
  equipmentsTypes: [],
};

export const load = createAsyncThunk<
  EquipmentType[],
  undefined,
  { state: { equipmentsTypes: EquipmentsTypesState } }
>("equipmentsTypes", async (params, { getState, requestId }) => {
  const { currentRequestId, loading } = getState().equipmentsTypes;
  if (!loading || requestId !== currentRequestId) {
    return [];
  }
  const response = await equipmentTypeService.get();
  return response.data;
});

export const filterEquipmentTypes = (
  locationId: string,
  equipments: Equipment[],
  equipmentTypes: EquipmentType[]
) => {
  if (!equipments.length) {
    return [];
  }
  return equipments
    .filter((e) => e.locationId === locationId)
    .map((e) => equipmentTypes.find((t) => t.identifier === e.type))
    .filter((t) => t)
    .reduce((acc, equipmentType) => {
      if (!equipmentType) return acc;
      return acc.find((t) => t.identifier === equipmentType.identifier)
        ? acc
        : [...acc, equipmentType];
    }, [] as EquipmentType[]);
};

const slice = createSlice({
  name: "equipmentsTypes",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(load.pending, (state, action) => {
        if (!state.loading) {
          state.loading = true;
          state.currentRequestId = action.meta.requestId;
        }
      })
      .addCase(load.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (state.loading && state.currentRequestId === requestId) {
          state.loading = false;
          state.equipmentsTypes = action.payload;
          state.currentRequestId = undefined;
          state.loaded = true;
        }
      })
      .addCase(load.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (state.loading && state.currentRequestId === requestId) {
          state.loading = false;
          // console.log("action.error", JSON.stringify(action.error, null, 2));
          state.error = action.error;
          state.currentRequestId = undefined;
        }
      });
  },
});

export default slice;
