import { Box, Grid, Stack, Typography } from "@mui/material";
import { DateTime, Duration } from "luxon";
import { memo, useMemo } from "react";
import { Equipment, Measure } from "src/@types";
import { timeControlFromDuration } from "src/@types/TimeControl";
import { useSelector } from "src/redux/store";
import { PATH_DASHBOARD } from "src/routes/paths";
import NumericSummaryChart from "./NumericSummaryChart";
import StringBooleanSummaryChart from "./StringBooleanSummaryChart";

function MeasureDisplay({
  measure,
  setDrawerMeasure,
}: {
  measure: Measure;
  setDrawerMeasure: (open: Measure) => void;
}) {
  const timeControlState = useSelector((state) => state.measuresTimeControl);
  if (measure.type === "number") {
    return (
      <NumericSummaryChart
        measure={measure}
        linkTo={PATH_DASHBOARD.measures.details(measure.identifier)}
        onMenuClick={() => setDrawerMeasure(measure)}
        timeControl={timeControlState.timeControl}
      />
    );
  } else if (measure.type === "boolean" || measure.type === "string") {
    return (
      <StringBooleanSummaryChart
        measure={measure}
        linkTo={PATH_DASHBOARD.measures.details(measure.identifier)}
        onMenuClick={() => setDrawerMeasure(measure)}
        timeControl={timeControlState.timeControl}
      />
    );
  }
  throw new Error(`Unsupported measure type "${measure.type}"`);
}

type Group = {
  equipment: Equipment;
  measures: Measure[];
  lastUpdated?: DateTime;
  widths: number[];
};

type Props = {
  measures: Measure[];
  setDrawerMeasure: (open: Measure) => void;
  shouldHideTitleIfAlone?: boolean;
  shouldDisplayLocation?: boolean;
};

function MeasuresBlock({
  measures,
  setDrawerMeasure,
  shouldHideTitleIfAlone = true,
  shouldDisplayLocation = false,
}: Props) {
  const equipments: Equipment[] = measures.reduce(
    (acc: Equipment[], measure: Measure) => {
      if (
        !acc.find((e: Equipment) => e.identifier === measure.equipmentId) &&
        measure.equipment
      )
        acc = [...acc, measure.equipment];
      return acc;
    },
    [] as Equipment[]
  );
  // .sort((e1, e2) => {
  //   return e1.label.localeCompare(e2.label);
  // });

  const equipmentsLocationsState = useSelector(
    (state) => state.equipmentsLocations
  );

  const buildWidth = (measures: Measure[]): number[] => {
    const widthsArray = Array(measures.length).fill(undefined);

    const lineMaxElements = 3;
    const fixedWidth = 4;

    const rows = Math.ceil(measures.length / lineMaxElements);

    const nonNumberMeasuresIndices = measures.reduce(
      (acc: number[], measure: Measure, index: number) =>
        measure.type === "number" ? acc : [...acc, index],
      [] as number[]
    );

    nonNumberMeasuresIndices.forEach((nonNumberMeasuresIndex) => {
      widthsArray[nonNumberMeasuresIndex] = fixedWidth;
    });

    for (let row = 0; row < rows; row++) {
      const rowWidths = widthsArray.slice(
        row * lineMaxElements,
        (row + 1) * lineMaxElements
      );
      const fixedWidths = rowWidths.filter((w) => w !== undefined).length;
      rowWidths.forEach((width, index) => {
        if (width !== undefined) return;
        widthsArray[row * lineMaxElements + index] =
          (12 - fixedWidths * fixedWidth) / (rowWidths.length - fixedWidths);
      });
    }
    return widthsArray;
  };

  const groups: Group[] = useMemo(() => {
    return equipments.map((equipment: Equipment) => {
      const groupMeasures = measures.filter(
        (measure: Measure) => measure.equipmentId === equipment.identifier
      );
      const lastUpdated = groupMeasures
        .filter((measure: Measure) => measure.data)
        .map((measure: Measure) => measure.data?.last?.time)
        .find((u) => u);
      return {
        equipment,
        measures: groupMeasures,
        lastUpdated: lastUpdated ? DateTime.fromISO(lastUpdated) : undefined,
        widths: buildWidth(groupMeasures),
      };
    });
  }, [equipments, measures]);

  if (groups.length === 1 && shouldHideTitleIfAlone) {
    return (
      <Grid container spacing={3}>
        {groups[0].measures.map((measure: Measure, index) => (
          <Grid
            item
            xs={12}
            md={groups[0].widths[index]}
            key={measure.identifier}
          >
            <MeasureDisplay
              measure={measure}
              setDrawerMeasure={setDrawerMeasure}
            />
          </Grid>
        ))}
      </Grid>
    );
  }

  return (
    <Stack spacing={3}>
      {groups.map((group) => (
        <Box key={group.equipment.identifier}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Stack
                direction="row"
                alignItems="baseline"
                justifyContent="space-between"
              >
                <Typography
                  variant="h3"
                  color="text.white"
                  sx={{ marginTop: 3 }}
                >
                  {shouldDisplayLocation
                    ? `${
                        equipmentsLocationsState.equipmentsLocations.find(
                          (l) => l.identifier === group.equipment.locationId
                        )?.label
                      } - ${group.equipment.label}`
                    : group.equipment.label}
                </Typography>
              </Stack>
            </Grid>
            {group.measures.map((measure: Measure, index) => (
              <Grid
                item
                key={measure.identifier}
                xs={12}
                md={group.widths[index]}
              >
                <MeasureDisplay
                  measure={measure}
                  setDrawerMeasure={setDrawerMeasure}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      ))}
    </Stack>
  );
}

export default memo(MeasuresBlock);
