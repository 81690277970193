import { DateTime } from "luxon";
import axios from "../utils/axios";

class PatrolService {
  getModels() {
    return axios.get("/patrols-models");
  }

  getExportFile(
    format: "ods" | "xlsx",
    optionalFields: string[],
    filter: {
      start: DateTime;
      end: DateTime;
      operators?: string[];
      models?: string[];
    }
  ) {
    const queryFilter = {
      $and: [
        { startTime: { $gte: filter.start.toUTC().toISO() } },
        { startTime: { $lte: filter.end.toUTC().toISO() } },
      ],
      "operator._id": filter.operators?.length
        ? { $in: filter.operators }
        : undefined,
      "model.identifier": filter.models?.length
        ? { $in: filter.models }
        : undefined,
      // "done":
    };
    return axios.get("/patrols/export", {
      params: {
        format,
        filter: queryFilter,
        optionalFields,
      },
      responseType: "blob",
    });
  }

  getAttachment(patrolId: string, measureId: string, attachmentKey: string) {
    const url = [
      "/patrols",
      patrolId,
      "measures",
      measureId,
      "attachments",
      encodeURIComponent(attachmentKey),
    ].join("/");
    return axios.get(url, { responseType: "blob" });
  }
}

export default new PatrolService();
