import { Checkbox } from "@mui/material";
import { ColorSchema } from "src/theme/palette";
import Iconify from "./Iconify";

type Props = {
  isFavorite: boolean;
  toggleFavorite?: () => void;
  color?: ColorSchema;
  sx?: any;
};

function FavoriteIcon({
  isFavorite,
  toggleFavorite,
  color,
  sx,
}: Props) {
  return (
    <Checkbox
      color={color || "warning"}
      icon={<Iconify icon="eva:star-outline" />}
      checkedIcon={<Iconify icon="eva:star-fill" />}
      checked={isFavorite}
      onChange={toggleFavorite}
      sx={{ p: 0, ...sx }}
    />
  );
}

export default FavoriteIcon;
