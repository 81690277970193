import { Threshold } from "../@types";

class Range {
  from: number;
  to: number;
  priority: number;
  static sort(ranges: Range[], prop: "from" | "to" | "priority") {
    return ranges.sort((r1, r2) =>
      r1[prop] < r2[prop] ? -1 : r1[prop] > r2[prop] ? 1 : 0
    );
  }
  constructor(from: number, to: number, priority: number) {
    this.from = from;
    this.to = to;
    this.priority = priority;
  }
  intersect(range: Range): boolean;
  intersect(value: number): boolean;
  intersect(arg: number | Range): boolean {
    if (typeof arg === "number") return this.from < arg && arg < this.to;
    return this.to > arg.from && this.from < arg.to;
  }
}

class NonOverlappingRanges extends Array<Range> {
  constructor(initialRange?: Range) {
    super();
    if (initialRange) super.push(initialRange);
  }
  sortByProp(prop: "from" | "to" | "priority") {
    return super.sort((r1, r2) =>
      r1[prop] < r2[prop] ? -1 : r1[prop] > r2[prop] ? 1 : 0
    );
  }
  intersect(range: Range): NonOverlappingRanges;
  intersect(value: number): NonOverlappingRanges;
  intersect(arg: number | Range): NonOverlappingRanges {
    if (typeof arg === "number")
      return this.filter((r) => r.intersect(arg)) as NonOverlappingRanges;
    return this.filter((r) => r.intersect(arg)) as NonOverlappingRanges;
  }
  push(range: Range) {
    const intersectingRanges = this.intersect(range);
    if (!intersectingRanges.length) {
      return super.push(range);
    } else {
      intersectingRanges.forEach((intersectingRange) => {
        const [lpr, hpr] = Range.sort([range, intersectingRange], "priority");
        if (lpr.from < hpr.from) {
          if (lpr === range) {
            this.push(new Range(lpr.from, hpr.from, lpr.priority));
          } else {
            lpr.to = hpr.from;
            this.push(hpr);
          }
        }
        if (lpr.to > hpr.to) {
          if (lpr === range) {
            this.push(new Range(hpr.to, lpr.to, lpr.priority));
          } else {
            lpr.from = hpr.to;
            this.push(hpr);
          }
        }
        if (lpr.from === hpr.from && lpr.to === hpr.to && hpr === range) {
          lpr.priority = hpr.priority;
        }
      });
    }
    // this._ranges = Range.sort(this._ranges, "from");
    return this.length;
  }
}

export default function thresholdsToRanges(thresholds: Threshold[]) {
  // Build ranges from thresholds
  const ranges = new NonOverlappingRanges(
    new Range(-Infinity, +Infinity, -Infinity)
  );
  if (!thresholds.length) {
    return ranges;
  }

  thresholds.forEach((threshold) => {
    if (typeof threshold.value === "number") {
      const priority =
        threshold.priority === "warning"
          ? 2
          : threshold.priority === "alert"
          ? 3
          : -Infinity;
      if (["<", "<="].includes(threshold.operator || "")) {
        ranges.push(new Range(-Infinity, threshold.value, priority));
      } else if ([">", ">="].includes(threshold.operator || "")) {
        ranges.push(new Range(threshold.value, +Infinity, priority));
      } else if (["!=="].includes(threshold.operator || "")) {
        ranges.push(new Range(-Infinity, threshold.value, priority));
        ranges.push(new Range(threshold.value, +Infinity, priority));
      } else if (["==="].includes(threshold.operator || "")) {
        ranges.push(new Range(threshold.value, threshold.value, priority));
      }
    }
  });

  return ranges.sortByProp("from");
}

export { Range };
