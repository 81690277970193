import { MenuItemConf, MenuItem } from "../../../@types";
import Iconify from "../../../components/generic/Iconify";

const createSubItemObjectFromMenu = (menuItems: MenuItemConf[]) => {
  const items = menuItems.map((menuItem) => {
    const parsedMenuItem: MenuItem = {
      label: menuItem.label,
      icon:
        menuItem.icon !== undefined ? (
          <Iconify icon={menuItem.icon} width={24} height={24} />
        ) : undefined,
      path: menuItem.path,
      children: menuItem.children.length
        ? createSubItemObjectFromMenu(menuItem.children)
        : undefined,
    };
    return parsedMenuItem;
  });
  return items;
};

export const createItemObjectFromMenu = (menu: MenuItemConf[]) => {
  const appMenu = menu.map((item) => {
    const p: MenuItem = {
      label: item.label,
      children: createSubItemObjectFromMenu(item.children),
    };
    return p;
  });
  return appMenu;
};
