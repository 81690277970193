import { ReactNode } from "react";
import { Navigate } from "react-router-dom";
import { stop as stopSocketIo } from "src/redux/slices/socketIo";
import { dispatch, useSelector } from "src/redux/store";
import { PATH_DASHBOARD } from "src/routes/paths";

type GuestGuardProps = {
  children: ReactNode;
};

export default function GuestGuard({ children }: GuestGuardProps) {
  const authState = useSelector((state) => state.auth);

  if (!!authState.user) {
    return <Navigate to={PATH_DASHBOARD.root} />;
  }

  return <>{children}</>;
}
