import { TableCell, TableRow } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { AlarmLogItemRow } from "src/@types/Alarm";
import { useFormat, useLocales } from "src/hooks";
import AlarmStatusLabel from "./AlarmStatusLabel";

type Props = {
  row: AlarmLogItemRow;
};

export default function AlarmDrawerTableRow({ row }: Props) {
  const {
    measureid,
    lastChangeOn,
    equipment,
    equipmentid,
    measureLabel,
    threshold,
    value,
    status,
    priority,
  } = row;
  const { formatDate } = useFormat();
  const { translate } = useLocales();
  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <TableRow hover>
      <TableCell>{formatDate(lastChangeOn)}</TableCell>
      <TableCell>{value}</TableCell>
      <TableCell>
        <AlarmStatusLabel priority={priority} active={status} />
      </TableCell>
    </TableRow>
  );
}
