import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Duration } from "luxon";
import {
  TimeControlState,
  reducers,
  set as baseSet,
  load,
  TimeControlName,
  clear,
} from "./baseTimeControl";
import { TimeControl } from "src/@types";
import { dispatch } from "../store";

const timeControlName: TimeControlName = "patrols";

const initialState: TimeControlState = {
  timeControl: {
    interval: Duration.fromISO("P7D"),
  },
};

export const set = createAsyncThunk<void, TimeControl>(
  "patrols-timecontrol-set",
  (value) => {
    dispatch(baseSet({ name: timeControlName, value }));
  }
);

const slice = createSlice({
  name: "patrolsTimeControl",
  initialState,
  reducers,
  extraReducers: (builder) => {
    builder
      .addCase(load.pending, (state, action) => {
        if (action.meta.arg.name !== timeControlName) return;
        console.log("Fetching patrols time control...");
      })
      .addCase(load.fulfilled, (state, action) => {
        if (action.meta.arg.name !== timeControlName) return;
        console.log(
          "Fetched patrols time control.",
          action.payload?.timeControl
        );
        if (action.payload) {
          state.timeControl = action.payload.timeControl;
        }
      })
      .addCase(load.rejected, (state, action) => {
        if (action.meta.arg.name !== timeControlName) return;
        console.error("Error while fetching patrols time control.", action);
      })
      .addCase(clear.pending, (state, action) => {
        if (action.meta.arg !== timeControlName) return;
        console.log("Clearing patrols time control...");
      })
      .addCase(clear.fulfilled, (state, action) => {
        if (action.meta.arg !== timeControlName) return;
        state.timeControl = initialState.timeControl;
        console.log("Cleared patrols time control.", state.timeControl);
      })
      .addCase(clear.rejected, (state, action) => {
        if (action.meta.arg !== timeControlName) return;
        console.error("Error while clearing patrols time control.", action);
      })
      // .addCase(baseSet.pending, (state, action) => {})
      .addCase(baseSet.fulfilled, (state, action) => {
        if (action.meta.arg.name !== timeControlName) return;
        state.timeControl = action.payload.timeControl;
        console.log("Set patrols time control.", state.timeControl);
      })
      .addCase(baseSet.rejected, (state, action) => {
        if (action.meta.arg.name !== timeControlName) return;
        console.error("Error while setting patrols time control.", action);
      });
  },
});

export default slice;
