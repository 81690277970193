import {
  Box,
  Container,
  IconButton,
  Stack,
  Step,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Step as StepType } from "src/@types";
import { LogbookFromServer } from "src/@types/Logbook";
import LogbookForm from "src/components/app/logbook/LogbookForm";
import LoadingAnimation from "src/components/generic/LoadingAnimation";
import HeaderBreadcrumbs from "src/components/generic/HeaderBreadcrumbs";
import Iconify from "src/components/generic/Iconify";
import Page from "src/components/generic/Page";
import StepButton from "src/components/generic/stepper/StepButton";
import StyledStepper from "src/components/generic/stepper/StyledStepper";
import { useFormat, useLocales, useResponsiveShortcut } from "src/hooks";
import { PATH_DASHBOARD } from "src/routes/paths";
import logbookService from "src/services/logbookService";
import { useSelector } from "src/redux/store";

export default function LogbookUpdate() {
  const { id = "" } = useParams();
  const navigate = useNavigate();
  const settingsState = useSelector((state) => state.settings);
  const [activeStep] = useState(5);
  const theme = useTheme();
  const { isLgUp } = useResponsiveShortcut();
  const { translate } = useLocales();
  const { formatDate } = useFormat();
  const [loading, setLoading] = useState(false);
  const [logbook, setLogbook] = useState<LogbookFromServer>();

  useEffect(() => {
    const fn = async () => {
      if (!id) return;
      try {
        setLoading(true);
        const { data: logbook } = await logbookService.get(id);
        if (!logbook.updatable) {
          navigate(PATH_DASHBOARD.logbook.show(logbook._id));
          return;
        }
        setLogbook(logbook);
        setLoading(false);
      } catch (e) {
        console.error(e);
        navigate(PATH_DASHBOARD.logbook.list);
        // setLoading(false);
      }
    };
    fn();
  }, []);

  const steps: StepType[] = [
    {
      label: translate("Location"),
      component: null,
      value: logbook?.location.label,
    },
    {
      label: translate("Equipment Type"),
      component: null,
      value: logbook?.equipmentType.label,
    },
    {
      label: translate("Equipement"),
      component: null,
      value: logbook?.equipment.label,
    },
    {
      label: translate("Category"),
      component: null,
      value: logbook?.category.label,
    },
    {
      label: translate("Type"),
      component: null,
      value: logbook?.type.label,
    },
    {
      label: translate("Content"),
      component: null,
      value: "",
    },
  ];

  return (
    <Page title={translate("Logbook")}>
      <Container maxWidth={settingsState.themeStretch ? false : "xl"}>
        <HeaderBreadcrumbs
          heading={
            logbook
              ? `${logbook.type.label} - ${
                  logbook.equipment.label
                } - ${formatDate(logbook.createdOn || "")}`
              : translate("Loading...")
          }
          links={[
            {
              name: translate("Home"),
              href: PATH_DASHBOARD.root,
            },
            {
              name: translate("Logbook"),
              href: PATH_DASHBOARD.logbook.list,
            },
            {
              name: translate("Details"),
            },
          ]}
          action={
            <Box>
              <IconButton
                onClick={() => navigate(PATH_DASHBOARD.logbook.show(id))}
                title={translate("View")}
              >
                <Iconify icon={"eva:eye-outline"} />
              </IconButton>
            </Box>
          }
        />
        <Stack gap={4}>
          <StyledStepper
            nonLinear
            alternativeLabel={isLgUp}
            orientation={isLgUp ? "horizontal" : "vertical"}
            activeStep={activeStep}
          >
            {steps.map((step, index) => (
              <Step key={index} completed={true}>
                <StepButton
                  step={step}
                  index={index}
                  active={activeStep === index}
                  completed={step.value !== undefined}
                  onClick={undefined}
                />
                {!isLgUp && activeStep === index ? (
                  loading ? (
                    <Box sx={{ position: "relative", mt: 10 }}>
                      <LoadingAnimation sx={{ transform: "scale(0.7)" }} />
                    </Box>
                  ) : logbook ? (
                    // <LogbookUpdateForm logbookInput={logbook} />
                    <LogbookForm logbook={{ ...logbook, files: [] }} />
                  ) : null
                ) : null}
              </Step>
            ))}
          </StyledStepper>
          {isLgUp ? (
            loading ? (
              <Box sx={{ position: "relative", mt: 10 }}>
                <LoadingAnimation sx={{ transform: "scale(0.7)" }} />
              </Box>
            ) : !logbook ? null : (
              // <LogbookUpdateForm logbookInput={logbook} />
              <LogbookForm logbook={{ ...logbook, files: [] }} />
            )
          ) : null}
        </Stack>
      </Container>
    </Page>
  );
}
