export function isExternalLink(path: string) {
  return path.includes("http");
}

export function isActive(path: string, pathname: string) {
  if (!path) return false;
  let result = false;
  if (!path.includes(";")) return pathname.includes(path);
  else {
    path.split(";").forEach((elem: string) => {
      if (pathname.includes(elem)) result = true;
    });
    return result;
  }
}
