import { TimeControl, User } from "src/@types";
import {
  AuthUserFromServer,
  UserConnection,
  UserSettings,
} from "src/@types/User";
import axios from "src/utils/axios";

class UserService {
  private _post(user: User) {
    return axios.post<User>("/users", user);
  }

  private _put(user: User, id: string) {
    return axios.put<User>(`/users/${id}`, user);
  }

  get() {
    return axios.get<User[]>(`/users`);
  }

  getOne(id: string) {
    return axios.get<User>(`/users/${id}`);
  }

  getConnections(id: string) {
    return axios.get<UserConnection[]>(`/users/${id}/connections`);
  }

  save(user: User) {
    if (!user._id) {
      return this._post(user);
    } else {
      return this._put(user, user._id);
    }
  }

  delete(id: string) {
    return axios.delete(`/users/${id}`);
  }

  login(username: string, password: string) {
    return axios.post<AuthUserFromServer>("/users/signin", {
      username,
      password,
    });
  }

  logout() {
    return axios.post("/users/signout");
  }

  getMenu() {
    return axios.get("/menu");
  }

  getById(id: string) {
    return axios.get(`/users/${id}`);
  }

  changePassword(
    data: { oldPassword: string; newPassword: string },
    userId: string
  ) {
    return axios.patch(`/users/${userId}/updatePassword`, data);
  }

  getSettings(userId: string) {
    return axios.get<UserSettings>(`/users/${userId}/settings`);
  }

  saveSettings(settings: UserSettings, userId: string) {
    return axios.patch(`/users/${userId}/settings`, settings);
  }

  getTimeControl(userId: string, timeControlName: string) {
    return axios.get(`/users/${userId}/timeControl/${timeControlName}`);
  }

  saveTimeControl(
    timeControlName: string,
    timeControl: TimeControl,
    userId: string
  ) {
    return axios.patch(
      `/users/${userId}/timeControl/${timeControlName}`,
      timeControl
    );
  }

  getFavorites(userId: string) {
    return axios.get<string[]>(`/users/${userId}/favorites`);
  }

  saveFavorites(favorites: string[], userId: string) {
    return axios.patch(`/users/${userId}/favorites`, favorites);
  }
}

const userService = new UserService();
export default userService;
