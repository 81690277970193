import { m } from "framer-motion";
import { Container, Typography } from "@mui/material";
import { ForbiddenIllustration } from "src/assets/illustrations-components";
import { MotionContainer, varBounce } from "src/components/generic/animate";
import { useSelector } from "src/redux/store";

type RoleBasedGuardProp = {
  hasContent?: boolean;
  roles?: string[];
  children: React.ReactNode;
};

export default function RoleBasedGuard({
  hasContent,
  roles,
  children,
}: RoleBasedGuardProp) {
  const authState = useSelector((state) => state.auth);

  if (
    !authState.user?.role ||
    (roles && !roles.includes(authState.user?.role))
  ) {
    return hasContent ? (
      <Container component={MotionContainer} sx={{ textAlign: "center" }}>
        <m.div variants={varBounce().in}>
          <Typography variant="h3" paragraph>
            Permission Denied
          </Typography>
        </m.div>

        <m.div variants={varBounce().in}>
          <Typography sx={{ color: "text.secondary" }}>
            You do not have permission to access this page
          </Typography>
        </m.div>

        <m.div variants={varBounce().in}>
          <ForbiddenIllustration sx={{ height: 260, my: { xs: 5, sm: 10 } }} />
        </m.div>
      </Container>
    ) : null;
  }

  return <>{children}</>;
}
