import { createContext, ReactNode, useEffect, useState } from "react";
import { get as getStore } from "../store";
import LokiStore from "../store/LokiStore";

type StoreContextType = {
  getStore: () => Promise<LokiStore>;
  // store?: LokiStore;
};

const defaultValue = {
  getStore: getStore,
  // store: undefined,
};

const StoreContext = createContext<StoreContextType>(defaultValue);

type StoreProviderProps = {
  children: ReactNode;
};

function StoreProvider({ children }: StoreProviderProps) {
  // const [store, setStore] = useState<LokiStore>();
  // useEffect(() => {
  //   const fn = async () => {
  //     const store = await getStore();
  //     setStore(store);
  //   };
  //   fn();
  // }, []);
  return (
    /*<StoreContext.Provider value={{ store }}>{children}</StoreContext.Provider>*/
    <StoreContext.Provider value={defaultValue}>
      {children}
    </StoreContext.Provider>
  );
}

export { StoreContext, StoreProvider };
