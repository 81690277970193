import { Badge, Box, SxProps } from "@mui/material";
import { Measure } from "src/@types";
import MoreButton from "src/components/generic/MoreButton";
import { useSelector } from "src/redux/store";
import { isMeasureOld } from "src/utils/date";

type Props = {
  measure: Measure;
  onClick: () => void;
  sx?: SxProps;
};

export default function MeasureMenuButton({ measure, onClick, sx }: Props) {
  const measuresState = useSelector((state) => state.measures);

  return (
    <Box sx={sx}>
      <Badge
        color={"warning"}
        variant="dot"
        overlap="circular"
        invisible={
          !measuresState.loaded ||
          !measuresState.dataLoaded ||
          !isMeasureOld(measure)
        }
      >
        <MoreButton onClick={onClick} />
      </Badge>
    </Box>
  );
}
