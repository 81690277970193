import { Box, SxProps } from "@mui/material";
import { forwardRef, useEffect, useRef, useState } from "react";
import uPlot from "uplot";
import { useRefDimensions } from "src/hooks/useRefDimensions";

require("uplot/dist/uPlot.min.css");

export type UPlotChartOptions = Omit<uPlot.Options, "width" | "height">;

type Props = {
  width?: number | string;
  height?: number | string;
  options: UPlotChartOptions;
  data: uPlot.AlignedData;
  style?: any;
  sx?: SxProps;
};

export default forwardRef(function UplotChart(
  {
    width = "100%",
    height = "200px",
    options,
    data,
    style,
    sx,
    ...other
  }: Props,
  ref
) {
  const [uplotOptions, setUplotOptions] = useState<uPlot.Options>();
  const [plot, setPlot] = useState<uPlot>();
  const plotContainerRef = useRef(null);
  const plotContainerDimensions = useRefDimensions(plotContainerRef);

  // useEffect(() => {
  //   console.log("plotContainerDimensions", plotContainerDimensions);
  // }, [plotContainerDimensions]);

  useEffect(() => {
    const effectiveLength = (propName: "width" | "height") => {
      const prop = propName === "width" ? width : height;
      if (typeof prop === "string") {
        let m = prop.match(/([0-9]*)(?:px)$/);
        if (m && m.length > 1) {
          return parseInt(m[1]);
        }
        m = prop.match(/([0-9]*)(?:%)$/);
        if (m && m.length > 1) {
          const plotContainerLength = plotContainerDimensions[propName];
          if (plotContainerLength === undefined) return 0;
          return (parseInt(m[1]) * plotContainerLength) / 100;
        }
        return parseInt(prop);
      }
      return prop;
    };
    setUplotOptions({
      ...options,
      width: effectiveLength("width"),
      height: effectiveLength("height"),
    });
  }, [options, width, height, plotContainerDimensions]);

  useEffect(() => {
    if (!plotContainerRef.current) return;
    plot?.destroy();
    if (!uplotOptions) return;
    const thePlot = new uPlot(uplotOptions, data, plotContainerRef.current);
    // thePlot.ctx.canvas.onresize = (event) => {
    //   console.log("resize", event);
    // };
    setPlot(thePlot);
  }, [uplotOptions, data, plotContainerRef]);

  return (
    <Box
      ref={plotContainerRef}
      className="uplot-container"
      style={{ ...style, width: width, height: height }}
      sx={sx}
    />
  );
});
