import {
  Box,
  Button,
  ButtonGroup,
  CardProps,
  Divider,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import { alpha, useTheme } from "@mui/material/styles";
import { saveAs } from "file-saver";
import { toJpeg, toPng } from "html-to-image";
import { DateTime, Duration } from "luxon";
import numeral from "numeral";
import { useMemo, useRef, useState } from "react";
import { sprintf } from "sprintf-js";
import uPlot, { AlignedData } from "uplot";
import { Measure, TimeControl } from "src/@types";
import { useLocales, useResponsiveShortcut } from "src/hooks";
import { ColorSchema } from "src/theme/palette";
// import thresholdsToRanges from "src/utils/thresholdsToRanges";
import { computeMinMax } from "src/components/generic/chart/uPlot/functions";
import {
  cursorTransitionPlugin,
  tooltipPlugin,
} from "src/components/generic/chart/uPlot/plugins";
import UplotChart, {
  UPlotChartOptions,
} from "src/components/generic/chart/uPlot/UplotChart";
import FavoriteIcon from "src/components/generic/FavoriteIcon";
import LabelBadge from "src/components/generic/LabelBadge";
// import scaleGradient from "src/components/generic/chart/uPlot/functions/scaleGradient";
import Iconify from "src/components/generic/Iconify";
import MenuPopover from "src/components/generic/MenuPopover";
import { fillGradient, thresholdsGradient } from "../chart/uPlot/functions";
import { thresholdsLines } from "../chart/uPlot/plugins";
import MeasureMenuButton from "./MeasureMenuButton";
import MeasureTooltip from "./MeasureTooltip";
import { dispatch, useSelector } from "src/redux/store";
import { toggle as toggleMeasure } from "src/redux/slices/favorites";
import { toStartEndTimeControl } from "src/@types/TimeControl";

type InputData = {
  time: string; // iso8601
  value?: number;
};

interface Props extends CardProps {
  measure: Measure;
  data: InputData[];
  granularity?: Duration;
  onMenuClick: () => void;
  timeControl: TimeControl;
  setTimeControl: (timeControl: TimeControl) => void;
  color?: ColorSchema;
}

export default function MeasureChart({
  measure,
  data,
  granularity,
  timeControl,
  setTimeControl,
  onMenuClick,
  color = "primary",
  sx,
  ...other
}: Props) {
  const theme = useTheme();
  const { isMdUp } = useResponsiveShortcut();
  const showYOrigin = true;
  const favoritesState = useSelector((state) => state.favorites);
  const { translate } = useLocales();
  const uplotChartRef = useRef<HTMLDivElement>(null);
  const [openImageExport, setOpenImageExport] = useState<HTMLElement | null>(
    null
  );

  // const defaultTimeControl = useMemo(() => timeControl, []);
  const defaultTimeControl = useMemo(
    () => toStartEndTimeControl(timeControl),
    []
  );
  const startEndTimeControl = useMemo(() => {
    return toStartEndTimeControl(timeControl);
  }, [timeControl]);

  // const thresholdsRanges = thresholdsToRanges(measure.thresholds);

  const uplotData: AlignedData = [
    // X
    Uint32Array.from(
      (data || []).map((d) => DateTime.fromISO(d.time).toUnixInteger())
    ),
    // Y
    // Float32Array.from((data || []).map((d) => d.value)),
    // undefined -> null to interrupt drawing when value is undefined (with spanGaps = false)
    (data || []).map((d) => (d.value !== undefined ? d.value : null)),
  ];

  const [min, max] = computeMinMax(uplotData)[1];

  const uplotOptions: UPlotChartOptions = {
    padding: isMdUp ? [0, 10, 0, 0] : [0, 0, 0, 0],
    // padding: [0, 0, 0, 0],
    series: [
      {},
      {
        points: { show: false },
        paths: uPlot.paths.spline && uPlot.paths.spline(),
        // stroke: theme.palette.primary.main,
        stroke: (u, seriesIdx) =>
          thresholdsGradient({ thresholds: measure.thresholds, theme })(u),
        // fill: theme.palette.primary.main,
        fill:
          min !== undefined && max !== undefined
            ? (u, seriesIdx) =>
                fillGradient({ color: theme.palette.primary.main, min, max })(u)
            : undefined,
        width: 2,
        spanGaps: false,
      },
    ],
    legend: { show: false },
    scales: {
      x: {
        range: [
          startEndTimeControl.start.toUnixInteger(),
          startEndTimeControl.end.toUnixInteger(),
        ],
      },
      y: {
        range:
          min !== undefined && max !== undefined
            ? [
                // Min
                Math.min.apply(
                  null,
                  [showYOrigin ? 0 : min, min]
                    .concat(
                      measure.thresholds.map((threshold) =>
                        threshold.value !== undefined
                          ? (threshold.value as number)
                          : min
                      )
                    )
                    .filter((e) => e !== undefined)
                ),
                // Max
                Math.max.apply(
                  null,
                  [showYOrigin ? 0 : min, max]
                    .concat(
                      measure.thresholds.map((threshold) =>
                        threshold.value !== undefined
                          ? (threshold.value as number)
                          : min
                      )
                    )
                    .filter((e) => e !== undefined)
                ),
              ]
            : undefined,
      },
      // y: {
      //   ...(showYOrigin && {
      //     range: {
      //       min: {
      //         soft: 0,
      //         mode: 1,
      //         hard: 2
      //       },
      //       max: {
      //         soft: 0,
      //         mode: 1,
      //         hard: 200
      //       },
      //     },
      //   }),
      // },
    },
    axes: [
      // X axis
      {
        show: true,
        stroke: theme.palette.grey[600],
        grid: {
          show: true,
          width: 0.5,
          dash: [3],
          stroke: theme.palette.grey[600],
        },
        border: {
          show: true,
          width: 0.5,
          dash: [3],
          stroke: theme.palette.grey[600],
        },
        values: (
          u: uPlot,
          splits: number[],
          axisIdx: number,
          foundSpace: number,
          foundIncr: number
        ) => {
          return splits.reduce((acc, split, index) => {
            const time = DateTime.fromSeconds(split);
            const previousTime = index
              ? DateTime.fromSeconds(splits[index - 1])
              : undefined;
            if (foundIncr < 86400) {
              // 1 day = 86400 seconds
              let label = time.toLocaleString(DateTime.TIME_SIMPLE);
              if (time.day !== previousTime?.day) {
                const lower = time.toLocaleString({
                  day: "2-digit",
                  month: "2-digit",
                });
                label = `${label}\n${lower}`;
              }
              acc = [...acc, label];
            } else {
              let label = time.toLocaleString({
                day: "2-digit",
                month: "2-digit",
              });
              if (time.year !== previousTime?.year) {
                const lower = time.toLocaleString({
                  year: "numeric",
                });
                label = `${label}\n${lower}`;
              }
              acc = [...acc, label];
            }
            return acc;
          }, [] as string[]);
        },
      },
      // Y axis
      {
        show: true,
        stroke: theme.palette.grey[600],
        grid: {
          show: true,
          width: 0.5,
          dash: [3],
          stroke: theme.palette.grey[600],
        },
        border: {
          show: true,
          width: 0.5,
          dash: [3],
          stroke: theme.palette.grey[600],
        },
        values: (
          u: uPlot,
          splits: number[],
          axisIdx: number,
          foundSpace: number,
          foundIncr: number
        ) =>
          splits.map((split) => {
            const formattedValue = numeral(split).format("0.[00]");
            return formattedValue;
            // return measure.unit
            //   ? `${formattedValue} ${measure.unit}`
            //   : formattedValue;
          }),
        size: (
          self: uPlot,
          values: string[],
          axisIdx: number,
          cycleNum: number
        ) => {
          if (!values) return 0;
          const maxLength = Math.max(...values.map((value) => value.length));
          return (maxLength + 1) * 14; // 14 px per character
        },
      },
    ],
    cursor: {
      points: {
        size: 14,
        width: 2,
        fill: theme.palette.primary.main,
        // fill: (u: uPlot, seriesIdx: number) => {
        //   const yValues = u.data[seriesIdx];
        //   const dataIdx = u.cursor.idx;
        //   if (dataIdx === undefined || dataIdx === null) return "";
        //   const value = yValues[dataIdx];
        //   if (value === undefined || value === null) return "";
        //   const ranges = thresholdsRanges
        //     .intersect(value)
        //     .sortByProp("priority");
        //   const range = ranges.length ? ranges[0] : undefined;
        //   return range?.priority === 2
        //     ? theme.palette.warning.main
        //     : range?.priority === 3
        //     ? theme.palette.error.main
        //     : theme.palette.primary.main;
        // },
        stroke: theme.palette.background.paper,
      },
      x: true,
      y: true,
      focus: { prox: 500 },
      drag: { x: true, y: false, setScale: false },
    },
    hooks: {
      setSelect: [
        (u: uPlot) => {
          if (!u.select.width) return;
          const start = u.posToVal(u.select.left, "x");
          const end = u.posToVal(u.select.left + u.select.width, "x");
          setTimeControl({
            start: DateTime.fromSeconds(start),
            end: DateTime.fromSeconds(end),
          });
        },
      ],
    },
    plugins: [
      thresholdsLines({
        thresholds: measure.thresholds,
        translate,
        theme,
        showLabels: false,
      }),
      cursorTransitionPlugin({ duration: "100ms" }),
      tooltipPlugin({
        transition: { duration: "100ms" },
        content: (time, values) => {
          return (
            <MeasureTooltip
              measure={measure}
              time={time}
              value={values[0]}
              translate={translate}
              theme={theme}
            />
          );
        },
      }),
    ],
  };

  const zoom = (factor: number) => {
    // Start, center and end timestamps
    // const startTs = timeControl.start.toSeconds();
    const startTs = startEndTimeControl.start.toSeconds();
    // const endTs = timeControl.end.toSeconds();
    const endTs = startEndTimeControl.end.toSeconds();
    const centerTs = startTs + (endTs - startTs) / 2;
    // Compute new start and end timestamps
    const startTsNew = centerTs - (centerTs - startTs) / factor;
    const endTsNew = centerTs + (endTs - centerTs) / factor;
    // Convert start and end timestamps to DateTime
    let start = DateTime.fromSeconds(startTsNew);
    let end = DateTime.fromSeconds(endTsNew);
    // If end is in the future, offset the whole range towards past
    const diffNow = end.diffNow();
    if (diffNow.toMillis() > 0) {
      start = start.minus(diffNow);
      end = end.minus(diffNow);
    }
    // Update time control
    setTimeControl({ start, end });
  };

  const resetZoom = () => {
    setTimeControl(defaultTimeControl);
  };

  const exportImage = (imageType: "image/png" | "image/jpeg") => {
    if (!uplotChartRef.current) return;
    const [toImage, extension] =
      imageType === "image/png"
        ? [toPng, "png"]
        : imageType === "image/jpeg"
        ? [toJpeg, "jpg"]
        : [];
    if (!toImage || !extension) return;
    toImage(uplotChartRef.current, {
      cacheBust: true,
      backgroundColor: theme.palette.background.paper,
    }).then((dataUrl) => {
      const filename = `${measure.equipment?.label}-${measure.label}.${extension}`;
      saveAs(dataUrl, filename);
      setOpenImageExport(null);
    });
  };

  const handleOpenImageExport = (event: React.MouseEvent<HTMLElement>) => {
    setOpenImageExport(event.currentTarget);
  };

  const handleCloseImageExport = () => {
    setOpenImageExport(null);
  };

  const cardHeader = () => {
    return (
      <Stack direction="row" gap={1} sx={{ p: 2 }}>
        <Stack
          direction="row"
          gap={1}
          alignItems="center"
          sx={{ minWidth: "calc(100% - 44px - 8px)" }}
        >
          <FavoriteIcon
            isFavorite={
              favoritesState.measuresIds.findIndex(
                (measureId) => measureId === measure.identifier
              ) !== -1
            }
            toggleFavorite={() => dispatch(toggleMeasure(measure.identifier))}
          />
          <Typography noWrap sx={{ typography: "subtitle2" }}>
            {measure.label}
          </Typography>
          {measure.machineName && <LabelBadge text={measure.machineName} />}
        </Stack>
        <MeasureMenuButton measure={measure} onClick={() => onMenuClick()} />
      </Stack>
    );
  };

  const chartButtons = () => {
    return (
      <Stack
        sx={{
          flexDirection: "row",
          justifyContent: "flex-start",
          px: 2,
          gap: 1,
        }}
      >
        <ButtonGroup>
          <Button onClick={() => zoom(2)} title={translate("Zoom in")}>
            <Iconify icon={"bytesize:zoom-in"} sx={{ fontSize: 18 }} />
          </Button>
          <Button onClick={() => zoom(0.5)} title={translate("Zoom out")}>
            <Iconify icon={"bytesize:zoom-out"} sx={{ fontSize: 18 }} />
          </Button>
          <Button onClick={() => resetZoom()} title={translate("Reset zoom")}>
            <Iconify icon={"bytesize:zoom-reset"} sx={{ fontSize: 18 }} />
          </Button>
        </ButtonGroup>
        <ButtonGroup>
          <Button
            onClick={handleOpenImageExport}
            title={translate("Export chart as image")}
          >
            <Iconify icon={"bi:card-image"} sx={{ fontSize: 18 }} />
          </Button>
        </ButtonGroup>
        <MenuPopover
          open={Boolean(openImageExport)}
          anchorEl={openImageExport}
          onClose={handleCloseImageExport}
          sx={{
            p: 0,
            mt: 1.5,
            ml: 0.75,
            "& .MuiMenuItem-root": {
              typography: "body2",
              borderRadius: 0.75,
            },
          }}
        >
          <Box sx={{ my: 1, px: 2 }}>
            <Typography
              variant="body2"
              color={theme.palette.text.secondary}
              noWrap
            >
              {translate("Export")}
            </Typography>
          </Box>
          <Divider sx={{ borderStyle: "dashed" }} />
          <Stack sx={{ my: 0, px: 0 }}>
            <MenuItem key={"png"} onClick={() => exportImage("image/png")}>
              {translate("PNG image")}
            </MenuItem>
            <MenuItem key={"jpeg"} onClick={() => exportImage("image/jpeg")}>
              {translate("JPEG image")}
            </MenuItem>
          </Stack>
        </MenuPopover>
      </Stack>
    );
  };

  return (
    <Stack sx={sx}>
      {cardHeader()}

      <Stack gap={1} sx={{ flex: 1 }}>
        {chartButtons()}

        <Stack sx={{ flex: 1, mt: 1, position: "relative" }}>
          <Box
            sx={{ position: "absolute", left: 0, right: 0, top: 0, bottom: 0 }}
          >
            <UplotChart
              ref={uplotChartRef}
              data={uplotData}
              options={uplotOptions}
              height="100%"
              sx={{
                cursor: "crosshair",
                ".uplot-container": {
                  ".uplot": {
                    ".u-select": {
                      background: alpha(theme.palette.background.default, 0.5),
                    },
                  },
                },
              }}
            />
          </Box>
        </Stack>
        {granularity && (
          <Typography sx={{ typography: "body2", textAlign: "center", pb: 1 }}>
            {sprintf(translate("Time step: %s"), granularity.toHuman())}
          </Typography>
        )}
      </Stack>
    </Stack>
  );
}
