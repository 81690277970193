import { Equipment, EquipmentType, Location, Measure } from "src/@types";

export type SearchItem = { group: string; title: string; path: string };

const searchBarConfig = (
  locations: Location[],
  equipmentTypes: EquipmentType[],
  equipments: Equipment[],
  measures: Measure[],
  translate: (arg0: string) => string
): SearchItem[] => {
  const locationsMapped: SearchItem[] = locations.map((loc: Location) => ({
    group: translate("Location"),
    title: `${loc.label} (${loc.identifier})`,
    path: `/measures/location/${loc.identifier}`,
  }));
  const equipmentTypesMapped: SearchItem[] = equipmentTypes.map(
    (equipmentType: EquipmentType) => ({
      group: translate("Equipment type"),
      title: `${equipmentType.label} (${equipmentType.identifier})`,
      path: `/measures/equipment_type/${equipmentType.identifier}`,
    })
  );
  const equipmentsMapped: SearchItem[] = equipments.map(
    (equipment: Equipment) => ({
      group: translate("Equipment"),
      title: `${equipment.label} (${equipment.identifier})`,
      path: `/measures/equipment/${equipment.identifier}/overview`,
    })
  );
  const measuresMapped: SearchItem[] = measures.map((measure: Measure) => ({
    group: translate("Measure"),
    title: `${measure.label} (${measure.identifier})`,
    path: `/measures/${measure.identifier}`,
  }));
  return ([] as SearchItem[]).concat(
    locationsMapped,
    equipmentTypesMapped,
    equipmentsMapped,
    measuresMapped
  );
};

export default searchBarConfig;
