// @mui
import { Box, BoxProps } from "@mui/material";
import { forwardRef, ReactNode } from "react";
import { Helmet } from "react-helmet-async";

// ----------------------------------------------------------------------

interface Props extends BoxProps {
  children: ReactNode;
  meta?: ReactNode;
  title: string;
}
const Page = forwardRef<HTMLDivElement, Props>(
  ({ children, title = "", meta, ...other }, ref) => (
    <>
      <Helmet>
        <title>{`${title} | Mobapi.EYE`}</title>
        {meta}
      </Helmet>

      <Box className="Page-root" ref={ref} {...other}>
        {children}
      </Box>
    </>
  )
);

export default Page;
