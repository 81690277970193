import AnimatedDot from "./AnimatedDot";

export default function () {
  return (
    <>
      <AnimatedDot duration={2} delay={0} />
      <AnimatedDot duration={2} delay={0.5} />
      <AnimatedDot duration={2} delay={1} />
    </>
  );
}
