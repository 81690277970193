import { EquipmentType } from "src/@types";
import axios from "src/utils/axios";

class EquipmentTypeService {
  get() {
    return axios.get<EquipmentType[]>("/equipmentstypes");
  }

  getWithLocation(location: string) {
    return axios.get<EquipmentType[]>(
      `/equipmentstypes?q={locationId:${location}}`
    );
  }
}

const equipmentTypeService = new EquipmentTypeService();
export default equipmentTypeService;
