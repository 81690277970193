import { Container } from "@mui/material";
import { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { sprintf } from "sprintf-js";
import { Equipment, EquipmentType, Measure } from "src/@types";
import {
  isIntervalTimeControl,
  toStartEndTimeControl,
} from "src/@types/TimeControl";
import MeasureDrawer from "src/components/app/measure/drawer/MeasureDrawer";
import MeasuresBlock from "src/components/app/measure/MeasuresBlock";
import DateRangeSelect from "src/components/generic/DateRangeSelect";
import HeaderBreadcrumbs from "src/components/generic/HeaderBreadcrumbs";
import Page from "src/components/generic/Page";
import { useLocales } from "src/hooks";
import { set as setTimeControl } from "src/redux/slices/measuresTimeControl";
import { dispatch, useSelector } from "src/redux/store";
import { PATH_DASHBOARD } from "src/routes/paths";

function EquipmentTypeOverview() {
  const organizationState = useSelector((state) => state.organization);
  const equipmentsState = useSelector((state) => state.equipments);
  const equipmentsTypesState = useSelector((state) => state.equipmentsTypes);
  const measuresState = useSelector((state) => state.measures);
  const timeControlState = useSelector((state) => state.measuresTimeControl);
  const settingsState = useSelector((state) => state.settings);
  const { translate } = useLocales();
  const { typeId = "" } = useParams();
  const [drawerMeasure, setDrawerMeasure] = useState<Measure>();
  const [drawerOpen, setDrawerOpen] = useState(false);

  const startEndTimeControl = useMemo(() => {
    return toStartEndTimeControl(timeControlState.timeControl);
  }, [timeControlState.timeControl]);

  const equipmentType = useMemo(() => {
    return equipmentsTypesState.equipmentsTypes?.find(
      (et: EquipmentType) => et.identifier === typeId
    );
  }, [typeId, equipmentsTypesState.equipmentsTypes]);

  const equipments = useMemo(() => {
    return equipmentsState.equipments.filter(
      (equipment: Equipment) => equipment.type === equipmentType?.identifier
    );
  }, [equipmentsState.equipments, equipmentType]);

  const measures = useMemo(() => {
    return measuresState.measures.filter(
      (measure: Measure) =>
        equipments
          .map((equipment: Equipment) => equipment.identifier)
          .includes(measure.equipmentId) &&
        measure.domains.includes("equipment-type-overview")
    );
  }, [measuresState.measures, equipments]);

  const handleCloseDrawer = () => setDrawerOpen(false);

  return (
    <Page title={sprintf(translate("%s: overview"), equipmentType?.label)}>
      <Container maxWidth={settingsState.themeStretch ? false : "xl"}>
        <HeaderBreadcrumbs
          heading={sprintf(translate("%s: overview"), equipmentType?.label)}
          // links={[
          //   {
          //     name: translate("Home"),
          //     href: PATH_DASHBOARD.root,
          //   },
          //   {
          //     name: equipmentType?.label || "",
          //     href: PATH_DASHBOARD.measures.equipment_type(
          //       equipmentType?.identifier || ""
          //     ),
          //   },
          // ]}
          action={
            <DateRangeSelect
              label={translate("Display time range")}
              intervals={
                organizationState.organization?.timeSelectorIntervals
                  .overview || []
              }
              value={
                isIntervalTimeControl(timeControlState.timeControl)
                  ? timeControlState.timeControl.interval
                  : null
              }
              onChange={(value) =>
                value && dispatch(setTimeControl({ interval: value }))
              }
              sx={{ width: { xs: "100%", sm: "24rem" } }}
            />
          }
        />

        {drawerMeasure && (
          <MeasureDrawer
            measure={drawerMeasure}
            timeControl={startEndTimeControl}
            displayLinkToMeasure={true}
            open={drawerOpen}
            onClose={handleCloseDrawer}
          />
        )}
        <MeasuresBlock
          measures={measures}
          setDrawerMeasure={(measure: Measure) => {
            setDrawerOpen(true);
            setDrawerMeasure(measure);
          }}
          shouldHideTitleIfAlone={false}
        />
      </Container>
    </Page>
  );
}

export default EquipmentTypeOverview;
