import {
  SerializedError,
  createAsyncThunk,
  createSlice,
} from "@reduxjs/toolkit";
import { Location } from "src/@types";
import { locationService } from "src/services";

interface EquipmentsLocationsState {
  currentRequestId?: string;
  loading: boolean;
  loaded: boolean;
  equipmentsLocations: Location[];
  error?: SerializedError;
}

const initialState: EquipmentsLocationsState = {
  loading: false,
  loaded: false,
  equipmentsLocations: [],
};

export const load = createAsyncThunk<
  Location[],
  undefined,
  { state: { equipmentsLocations: EquipmentsLocationsState } }
>("equipmentsLocations", async (params, { getState, requestId }) => {
  const { currentRequestId, loading } = getState().equipmentsLocations;
  if (!loading || requestId !== currentRequestId) {
    return initialState.equipmentsLocations;
  }
  const response = await locationService.getEquipmentsLocations();
  return response.data;
});

const slice = createSlice({
  name: "equipmentsLocations",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(load.pending, (state, action) => {
        if (!state.loading) {
          state.loading = true;
          state.currentRequestId = action.meta.requestId;
        }
      })
      .addCase(load.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (state.loading && state.currentRequestId === requestId) {
          state.loading = false;
          state.equipmentsLocations = action.payload;
          state.currentRequestId = undefined;
          state.loaded = true;
        }
      })
      .addCase(load.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (state.loading && state.currentRequestId === requestId) {
          state.loading = false;
          // console.log("action.error", JSON.stringify(action.error, null, 2));
          state.error = action.error;
          state.currentRequestId = undefined;
        }
      });
  },
});

export default slice;
