import {
  Autocomplete,
  FormControlLabel,
  FormGroup,
  InputAdornment,
  Stack,
  Switch,
  SxProps,
  TextField,
  useTheme,
} from "@mui/material";
import { useMemo } from "react";
import { Equipment, ListDisplayMode } from "src/@types";
import Iconify from "src/components/generic/Iconify";
import { useLocales } from "src/hooks";
import { useSelector } from "src/redux/store";

type AutocompleteEquipment = {
  label: string;
  value: string;
  group: string;
};

type Props = {
  searchFilter: string;
  onSearchFilterChange: (value: string) => void;
  equipmentsFilter: Equipment[];
  onEquipmentsFilterChange: (value: Equipment[]) => void;
  onlyFavoritesFilter: boolean;
  onOnlyFavoritesFilterChange: (value: boolean) => void;
  displayMode: ListDisplayMode;
  onDisplayModeChange: (mode: ListDisplayMode) => void;
  sx?: SxProps;
};

export default function AlarmsTableToolbar({
  searchFilter,
  onSearchFilterChange,
  equipmentsFilter,
  onEquipmentsFilterChange,
  onlyFavoritesFilter,
  onOnlyFavoritesFilterChange,
  displayMode,
  onDisplayModeChange,
  sx,
}: Props) {
  const { translate } = useLocales();
  const equipmentsState = useSelector((state) => state.equipments);
  const theme = useTheme();

  const equipmentsToOptions = (equipments: Equipment[]) =>
    equipments.map((equipment) => ({
      label: equipment.label,
      // label: `${equipment.location.label} ${equipment.label}`,
      value: equipment.identifier,
      group: equipment.location.label,
    })) as AutocompleteEquipment[];

  const optionsToEquipments = (equipments: AutocompleteEquipment[]) =>
    equipmentsState.equipments.filter((e) =>
      equipments.map((e) => e.value).includes(e.identifier)
    );

  const equipmentsOptions = useMemo(
    () =>
      equipmentsToOptions(equipmentsState.equipments).sort((a, b) =>
        a.group > b.group ? 1 : b.group > a.group ? -1 : 0
      ),
    [equipmentsState.equipments]
  );

  return (
    <Stack gap={2} sx={sx}>
      <Stack direction={{ xs: "column", sm: "row" }} gap={2}>
        <TextField
          fullWidth
          value={searchFilter}
          onChange={(event) => onSearchFilterChange(event.target.value)}
          placeholder={translate(
            "Search for equipments, measure label or machine name"
          )}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Iconify
                  icon={"eva:search-fill"}
                  sx={{ color: "text.disabled", width: 20, height: 20 }}
                />
              </InputAdornment>
            ),
          }}
        />

        <Autocomplete
          multiple
          sx={{ minWidth: "50%" }}
          value={equipmentsToOptions(equipmentsFilter)}
          onChange={(event, values) =>
            onEquipmentsFilterChange(optionsToEquipments(values))
          }
          options={equipmentsOptions}
          groupBy={(option) => option.group}
          isOptionEqualToValue={(option, value) => option.value === value.value}
          renderInput={(params) => (
            <TextField {...params} label={translate("Equipements")} />
          )}
        />
      </Stack>

      <FormGroup>
        <Stack gap={2} direction={{ xs: "column", sm: "row" }}>
          <FormControlLabel
            control={
              <Switch
                checked={displayMode === "card"}
                onChange={(event) =>
                  onDisplayModeChange(event.target.checked ? "card" : "table")
                }
              />
            }
            label={translate("Display mode card")}
          />
          <FormControlLabel
            control={
              <Switch
                checked={onlyFavoritesFilter}
                onChange={(event) =>
                  onOnlyFavoritesFilterChange(event.target.checked)
                }
              />
            }
            label={translate("Display favorites only")}
          />
        </Stack>
      </FormGroup>
    </Stack>
  );
}
