import {
  SerializedError,
  createAsyncThunk,
  createSlice,
} from "@reduxjs/toolkit";
import { AccessRight, AccessRights } from "src/@types";
import { accessRightsService } from "src/services";

interface AccessRightsState {
  currentRequestId?: string;
  loading: boolean;
  loaded: boolean;
  accessRights: AccessRights;
  error?: SerializedError;
}

const initialState: AccessRightsState = {
  loading: false,
  loaded: false,
  accessRights: new AccessRights(),
};

export const load = createAsyncThunk<
  AccessRights,
  undefined,
  { state: { accessRights: AccessRightsState } }
>("accessRights", async (params, { getState, requestId }) => {
  const { currentRequestId, loading } = getState().accessRights;
  if (!loading || requestId !== currentRequestId) {
    return initialState.accessRights;
  }
  const response = await accessRightsService.get();
  return AccessRights.from(response.data);
});

const slice = createSlice({
  name: "accessRights",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(load.pending, (state, action) => {
        if (!state.loading) {
          state.loading = true;
          state.currentRequestId = action.meta.requestId;
        }
      })
      .addCase(load.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (state.loading && state.currentRequestId === requestId) {
          state.loading = false;
          state.accessRights = action.payload;
          state.currentRequestId = undefined;
          state.loaded = true;
        }
      })
      .addCase(load.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (state.loading && state.currentRequestId === requestId) {
          state.loading = false;
          // console.log("action.error", JSON.stringify(action.error, null, 2));
          state.error = action.error;
          state.currentRequestId = undefined;
        }
      });
  },
});

export default slice;
