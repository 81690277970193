import { Theme } from "@mui/material/styles";

// ----------------------------------------------------------------------

export default function Link(theme: Theme) {
  return {
    MuiLink: {
      defaultProps: {
        underline: "hover",
      },
      styleOverrides: {
        root: {
          fontSize: 14,
          fontWeight: 600,
          cursor: "pointer",
          "&:hover": {
            textDecorationStyle: "dotted",
            textDecorationLine: "underline",
            textUnderlineOffset: "0.4em",
          },
        },
      },
    },
  };
}
