import { Stack, Typography } from "@mui/material";
import { ReactNode, useEffect, useState } from "react";
import Breadcrumbs, { Props as BreadcrumbsProps } from "./Breadcrumbs";
import { useSelector } from "src/redux/store";
// import { MenuConfig } from "src/@types";
import { useLocation } from "react-router";
import { MenuItem } from "src/@types";
import uuidv4 from "src/utils/uuidv4";
import { useLocales } from "src/hooks";

const findPathInMenuRecursive = (
  searchedPath: string,
  items: MenuItem[],
  menuPaths: MenuItem[] = [],
  found: boolean = false
): { found: boolean; menuPaths: MenuItem[] } => {
  if (found) {
    return { found, menuPaths };
  }
  for (let i in items) {
    if (found) {
      return { found, menuPaths };
    }
    const item = items[i];
    // console.log("> item", item);
    if (item.children?.length) {
      menuPaths.push(item);
      const res = findPathInMenuRecursive(
        searchedPath,
        item.children,
        menuPaths,
        found
      );
      // return res
      found = res.found;
      menuPaths = res.menuPaths;
    } else if (item.path === searchedPath) {
      // console.log(
      //   "!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! FOUND !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!"
      // );
      menuPaths.push(item);
      // console.log(
      //   "menuPaths",
      //   menuPaths.map((p) => [p.label, p.path])
      // );
      return { found: true, menuPaths };
    }
    // console.log("< item", item);
  }
  if (!found) {
    menuPaths = menuPaths.slice(0, menuPaths.length - 1);
  }
  // console.log(
  //   "menuPaths",
  //   menuPaths.map((p) => [p.label, p.path])
  // );
  return { found, menuPaths };
};

const findPathInMenu = (searchedPath: string, menuItems: MenuItem[]) => {
  const { found, menuPaths } = findPathInMenuRecursive(searchedPath, menuItems);
  return found ? menuPaths : undefined;
};

interface Props extends Omit<BreadcrumbsProps, "links"> {
  links?: BreadcrumbsProps["links"];
  action?: ReactNode;
  heading: string;
  moreLink?: string | string[];
  flexGrow?: number;
}

export default function HeaderBreadcrumbs({
  links,
  action,
  heading,
  moreLink = "" || [],
  flexGrow = 1,
  sx,
  ...other
}: Props) {
  const { pathname } = useLocation();
  const { translate } = useLocales();
  const { parsedMenu } = useSelector((state) => state.menu);

  const [linksState, setLinksState] = useState<BreadcrumbsProps["links"]>(
    links || []
  );

  useEffect(() => {
    if (!links) {
      const menuPathArray = findPathInMenu(pathname, parsedMenu);
      if (!menuPathArray) {
        return;
      }

      let menuPathes = menuPathArray.map((p) => {
        return p.path !== undefined
          ? p
          : p.children?.length
          ? { ...p, path: p.children[0].path }
          : p;
      });

      // Remove paths without path
      // pathArray = pathArray.filter((p) => p.path !== undefined);

      // Add home as first path
      menuPathes = [{ label: translate("Home"), path: "/" }, ...menuPathes];

      const links = menuPathes.map((menuItem, index) => {
        return {
          name: uuidv4(),
          href: index < menuPathes.length - 1 ? menuItem.path : undefined,
          // icon?: ReactElement,
          label: menuItem.label,
        };
      });
      setLinksState(links);
    }
  }, [links, pathname, parsedMenu]);

  return (
    <Stack
      direction={{ xs: "column", sm: "row" }}
      // gap={2}
      gap={{ xs: 1, sm: 2 }}
      justifyContent="space-between"
      sx={{ mb: { xs: 2, sm: 5 }, ...sx }}
    >
      <Stack direction="column">
        <Typography variant="h4" gutterBottom>
          {heading}
        </Typography>
        <Breadcrumbs links={linksState} {...other} />
      </Stack>
      {action}
    </Stack>
  );
}
