import { Box, SxProps, Typography, useTheme } from "@mui/material";
import { Attachment, PatrolMeasure } from "src/@types";
import { useLocales } from "src/hooks";
import BaseBlock, { NotSet } from "./BaseBlock";
import AttachmentsList from "src/components/generic/AttachmentsList";
import { forwardRef } from "react";

type Props = {
  measure: PatrolMeasure;
  getAttachmentBaseUrl: (attachment: Attachment) => string;
  sx?: SxProps;
};

export default forwardRef<HTMLDivElement, Props>(function AttachmentsBlock(
  { measure, getAttachmentBaseUrl, sx }: Props,
  ref
) {
  const theme = useTheme();
  const { translate } = useLocales();
  return (
    <BaseBlock
      ref={ref}
      label={translate("Attachments")}
      component={
        measure.files?.length || measure.attachments?.length ? (
          <Box>
            <AttachmentsList attachments={measure.files || []} />
            <AttachmentsList
              attachments={measure.attachments || []}
              getBaseUrl={getAttachmentBaseUrl}
            />
          </Box>
        ) : (
          <NotSet />
        )
      }
      sx={sx}
    />
  );
});
