import { IconButton, Stack, StackProps, Typography } from "@mui/material";
import Iconify from "./Iconify";

interface PanelProps extends StackProps {
  label: string;
  toggle: boolean;
  onToggle: VoidFunction;
}

function Panel({ label, toggle, onToggle, ...other }: PanelProps) {
  return (
    <Stack direction="row" alignItems="center" onClick={onToggle} {...other}>
      <Typography variant="subtitle2"> {label} </Typography>
      <IconButton size="small" sx={{ ml: 1 }}>
        <Iconify
          icon={toggle ? "eva:chevron-down-fill" : "eva:chevron-right-fill"}
        />
      </IconButton>
    </Stack>
  );
}
export default Panel;
