import { AlignedData } from "uplot";

export default function computeMinMax(data: AlignedData) {
  return data.map((series) => {
    const { min, max } = (series as Float64Array).reduce(
      (acc, value) => {
        acc = {
          min:
            value !== null && value !== undefined && value < acc.min
              ? value
              : acc.min,
          max:
            value !== null && value !== undefined && value > acc.max
              ? value
              : acc.max,
        };
        return acc;
      },
      { min: +Infinity, max: -Infinity }
    );
    const realMin = min !== Infinity ? min : undefined;
    const realMax = max !== -Infinity ? max : undefined;
    return [realMin, realMax];
  });
}
