import { Box, BoxProps } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

import logoFile from "src/assets/logo/mobapi.EYE-512.png";

interface Props extends BoxProps {
  disabledLink?: boolean;
}

export default function Logo({ disabledLink = false, sx }: Props) {
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <img src={logoFile} alt="logo" />
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="/">{logo}</RouterLink>;
}
