import { SxProps, Typography, useTheme } from "@mui/material";
import { PatrolMeasure } from "src/@types";
import { useLocales } from "src/hooks";
import BaseBlock, { NotSet } from "./BaseBlock";
import { forwardRef } from "react";

type Props = {
  measure: PatrolMeasure;
  sx?: SxProps;
};

export default forwardRef<HTMLDivElement, Props>(function NoteBlock(
  { measure, sx }: Props,
  ref
) {
  const theme = useTheme();
  const { translate } = useLocales();
  return (
    <BaseBlock
      ref={ref}
      label={translate("Note")}
      component={
        measure.note ? (
          <Typography
            color={theme.palette.text.primary}
            style={{ whiteSpace: "pre-line" }}
          >
            {measure.note}
          </Typography>
        ) : (
          <NotSet />
        )
      }
      sx={sx}
    />
  );
});
