import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import { Alert, IconButton, InputAdornment, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { useIsMountedRef, useLocales } from "src/hooks";
import { FormProvider, RHFTextField } from "src/components/generic/hook-form";
import Iconify from "src/components/generic/Iconify";
import { useDispatch, useSelector } from "src/redux/store";
import { login } from "src/redux/slices/auth";

// ----------------------------------------------------------------------

type FormValuesProps = {
  username: string;
  password: string;
  remember: boolean;
  afterSubmit?: string;
};

export default function LoginForm() {
  const dispatch = useDispatch();
  const authState = useSelector((state) => state.auth);

  const isMountedRef = useIsMountedRef();
  const { translate } = useLocales();

  const [showPassword, setShowPassword] = useState(false);

  const LoginSchema = Yup.object().shape({
    username: Yup.string().required(translate("Username is required")),
    password: Yup.string().required(translate("Password is required")),
  });

  const defaultValues = {
    username: "",
    password: "",
    remember: true,
  };

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    reset,
    setError,
    setFocus,
    handleSubmit,
    formState: { errors },
  } = methods;

  useEffect(() => {
    const error = authState.error;
    if (!error) return;
    methods.getValues();
    reset({
      username: methods.getValues().username,
    });
    setFocus("password");
    if (isMountedRef.current && error.message) {
      if (error.message.includes("Network Error"))
        setError("afterSubmit", {
          ...error,
          message: translate("Network error"),
        });
      else if (error.message.includes("Authentication failed"))
        setError("afterSubmit", {
          ...error,
          message: translate("Authentication failed"),
        });
    } else
      setError("afterSubmit", {
        ...error,
        message: translate("Something went wrong"),
      });
  }, [authState.error]);

  const onSubmit = async (data: FormValuesProps) => {
    dispatch(login(data));
  };

  // Autofocus on username
  useEffect(() => {
    setFocus("username");
  }, [setFocus]);

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3} p="2">
        {!!errors.afterSubmit && (
          <Alert severity="error">{errors.afterSubmit.message}</Alert>
        )}

        <RHFTextField
          name="username"
          label={translate("Username")}
          InputLabelProps={{ shrink: true }}
          inputProps={{ autoCapitalize: "none" }}
        />

        <RHFTextField
          name="password"
          label={translate("Password")}
          type={showPassword ? "text" : "password"}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setShowPassword(!showPassword)}
                  edge="end"
                >
                  <Iconify
                    icon={showPassword ? "eva:eye-fill" : "eva:eye-off-fill"}
                  />
                </IconButton>
              </InputAdornment>
            ),
          }}
          InputLabelProps={{ shrink: true }}
        />

        {/* <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ my: 2 }}
        >
          <RHFCheckbox name="remember" label="Remember me" />
          <Link
            component={RouterLink}
            variant="subtitle2"
            to={PATH_AUTH.resetPassword}
          >
            Forgot password?
          </Link>
        </Stack> */}

        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={authState.authenticating}
        >
          {translate("Connection")}
        </LoadingButton>
      </Stack>
    </FormProvider>
  );
}
