import { Theme } from '@mui/material/styles';
import { SECONDARY_FONT } from "../fonts";

export default function PickerDays(theme: Theme) {
  return {
    MuiPickersDay: {
      styleOverrides: {
        root: {
          // fontFamily: SECONDARY_FONT,
          fontWeight: 500,
        },
      },
    },
  };
}
