import {
  Box,
  Link,
  Stack,
  styled,
  Table,
  TableBody,
  TableContainer,
  Typography,
  useTheme,
} from "@mui/material";
import { DateTime, Duration } from "luxon";
import { useEffect, useState } from "react";
import { PATH_DASHBOARD } from "src/routes/paths";
import { Measure } from "src/@types";
import { AlarmLog, AlarmLogItemRow } from "src/@types/Alarm";
import AlarmDrawerTableRow from "src/components/app/alarms/AlarmDrawerTableRow";
import Error500 from "src/components/generic/Error500";
import LoadingAnimation from "src/components/generic/LoadingAnimation";
import {
  TableHeadCell,
  TableHeadCustom,
  TableNoData,
} from "src/components/generic/table";
import { useLocales } from "src/hooks";
import { alarmService } from "src/services";
import { fShortenNumber } from "src/utils/formatNumber";
import { convertOperators } from "src/utils/str";
import { sprintf } from "sprintf-js";

type Props = {
  measure: Measure;
  load: boolean;
};

export default function AlarmLogsSection({ measure, load }: Props) {
  const { translate } = useLocales();
  const [tableData, setTableData] = useState([] as Array<AlarmLogItemRow>);
  const isNotFound = !tableData.length;
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const theme = useTheme();

  useEffect(() => {
    const fn = async () => {
      try {
        setError(false);
        setLoading(true);
        const response = await alarmService.getAlarmLogs(
          {
            start: DateTime.now().minus(Duration.fromISO("PT12H")),
            end: DateTime.now(),
            search: measure.identifier || "",
          },
          10,
          1,
          "alarm.status.lastChangeOn",
          "desc"
        );
        const alarmLogs = response.data;
        setTableData(
          alarmLogs.map(buildRow).filter(function (x: any) {
            return x !== undefined;
          }) as AlarmLogItemRow[]
        );
        setLoading(false);
      } catch (e) {
        setLoading(false);
        setError(true);
      }
    };
    load && fn();
  }, [measure, load]);

  const LinkStyle = styled(Link)(({ theme }) => ({
    color: theme.palette.primary.main,
    fontSize: 14,
    fontWeight: 600,
    cursor: "pointer",
    "&:hover": {
      textDecorationStyle: "dotted",
      textDecorationLine: "underline",
      textDecorationColor: theme.palette.primary.main,
      textUnderlineOffset: "0.4em",
    },
  }));

  const TABLE_HEAD: TableHeadCell[] = [
    {
      id: "alarm.status.lastChangeOn",
      label: translate("Date"),
      align: "left",
    },
    {
      id: "alarm.status.extractionValue.measureValue",
      label: translate("Value"),
      align: "left",
    },
    { id: "alarm.status.active", label: translate("Status"), align: "left" },
  ];

  return loading ? (
    <Box marginTop={"20vh"}>
      <LoadingAnimation
        sx={{
          position: "relative",
          mb: "5%",
          backgroundColor: "#ffffff00",
          transform: "scale(0.7)",
        }}
      />
    </Box>
  ) : error ? (
    <Error500 />
  ) : !tableData.length ? (
    <Stack gap={1} sx={{ px: 2, py: 2 }}>
      <Typography variant="body2" sx={{ color: theme.palette.text.secondary }}>
        {translate("No data found during last 12 hours.")}
      </Typography>
      <LinkStyle href={PATH_DASHBOARD.alarms.filter(measure.identifier)}>
        {sprintf("> %s", translate("Access to alarms log details"))}
      </LinkStyle>
    </Stack>
  ) : (
    <Stack gap={1} sx={{ py: 2 }}>
      <TableContainer>
        <Table
          size={"medium"}
          sx={{
            "& > thead.MuiTableHead-root, & > tbody.MuiTableBody-root": {
              "& > tr.MuiTableRow-root > .MuiTableCell-root:first-of-type": {
                pl: 2,
              },
              "& > tr.MuiTableRow-root > .MuiTableCell-root:last-of-type": {
                pr: 2,
              },
            },
          }}
        >
          <TableHeadCustom
            headCells={TABLE_HEAD}
            rowCount={tableData.length - 1}
          />
          <TableBody>
            {tableData.map((alarmLogItemRow, index) => (
              <AlarmDrawerTableRow key={index} row={alarmLogItemRow} />
            ))}
            <TableNoData isNotFound={isNotFound} />
          </TableBody>
        </Table>
      </TableContainer>
      <LinkStyle
        href={PATH_DASHBOARD.alarms.filter(measure.identifier)}
        sx={{ px: 2 }}
      >
        {sprintf("> %s", translate("Access to alarms log details"))}
      </LinkStyle>
    </Stack>
  );
}

function buildRow(alarmLog: AlarmLog): AlarmLogItemRow | undefined {
  return {
    measureid: alarmLog.alarm.metadata.measureId,
    lastChangeOn: alarmLog.alarm.status.lastChangeOn,
    equipment: alarmLog.alarm.metadata.equipmentLabel,
    equipmentid: alarmLog.alarm.metadata.equipmentId,
    locationid: alarmLog.alarm.metadata.locationId,
    measureLabel: alarmLog.alarm.metadata.measureLabel,
    machineName: alarmLog.alarm.metadata.measureMachineName,
    threshold: {
      label: alarmLog.alarm.metadata.measureMachineName,
      trigger: convertOperators(alarmLog.alarm.metadata.measureTrigger),
    },
    value:
      typeof alarmLog.alarm.status.extractionsValues.measureValue === "number"
        ? `${
            fShortenNumber(
              alarmLog.alarm.status.extractionsValues.measureValue
            ) || 0
          } ${alarmLog.alarm.metadata.measureUnit || ""}`
        : typeof alarmLog.alarm.status.extractionsValues.measureValue ===
          "boolean"
        ? alarmLog.alarm.status.extractionsValues.measureValue.toString()
        : (alarmLog.alarm.status.extractionsValues.measureValue as string),
    status: alarmLog.alarm.status.active,
    priority: alarmLog.alarm.priority,
  };
}
