import {
  Divider,
  FormControl,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  Stack,
  styled,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { useSnackbar } from "notistack";
import numeral from "numeral";
import { useEffect, useState } from "react";
import { Measure, Threshold } from "src/@types";
import Iconify from "src/components/generic/Iconify";
import LabelBadge from "src/components/generic/LabelBadge";
import LoadingPanel from "src/components/generic/LoadingPanel";
import { useLocales } from "src/hooks";
import { load as loadAlarmOperators } from "src/redux/slices/alarmsOperators";
import {
  load as loadMeasures,
  loadData as loadMeasuresData,
  update as updateMeasure,
} from "src/redux/slices/measures";
import { dispatch, useSelector } from "src/redux/store";
import { convertOperators } from "src/utils/str";

function AlarmThreshold({
  measure,
  thresholdIndex,
  onChange,
  color,
}: {
  measure: Measure;
  thresholdIndex: number;
  onChange: (measure: Measure) => void;
  color: string;
}) {
  const { translate } = useLocales();
  const [mode, setMode] = useState<"read" | "write">("read");
  const [initialThreshold, setInitialThreshold] = useState(
    measure.thresholds[thresholdIndex]
  );
  const [editedThreshold, setEditedThreshold] = useState<Threshold>({
    ...initialThreshold,
  });

  const emptyThreshold: Threshold = {
    priority: initialThreshold.priority,
    operator: "",
    value: "",
  };

  const alarmsOperatorsState = useSelector((state) => state.alarmsOperators);

  const availableOperators =
    measure.type === "string"
      ? alarmsOperatorsState.operators.string
      : measure.type === "boolean"
      ? alarmsOperatorsState.operators.boolean
      : alarmsOperatorsState.operators.number;

  useEffect(
    () => setInitialThreshold(measure.thresholds[thresholdIndex]),
    [measure, thresholdIndex]
  );

  useEffect(
    () => setEditedThreshold({ ...initialThreshold }),
    [initialThreshold]
  );

  const isValid = () =>
    editedThreshold.operator !== undefined &&
    editedThreshold.operator !== "" &&
    editedThreshold.value !== undefined &&
    (editedThreshold.value as string) !== "";

  const cancelEditing = () => {
    setEditedThreshold(initialThreshold);
    setMode("read");
  };

  const removeThreshold = () => {
    setEditedThreshold(emptyThreshold);
    measure.thresholds[thresholdIndex] = emptyThreshold;
    onChange(measure);
    setMode("read");
  };

  const saveThreshold = () => {
    if (!isValid()) return;
    let updatedThreshold: Threshold = { ...editedThreshold };
    // Compute potential error
    if (
      measure.type === "number" &&
      (editedThreshold?.value as string).length
    ) {
      const val = parseFloat(editedThreshold?.value as string);
      if (!isNaN(val)) {
        updatedThreshold = { ...editedThreshold, value: val };
      }
    }
    setEditedThreshold(updatedThreshold);
    if (updatedThreshold) {
      measure.thresholds[thresholdIndex] = updatedThreshold;
    } else {
      delete measure.thresholds[thresholdIndex];
    }
    onChange(measure);
    setMode("read");
  };

  const LabelStyle = styled(Typography)(({ theme }) => ({
    ...theme.typography.body2,
    width: 140,
    fontSize: 14,
    flexShrink: 0,
    color: theme.palette.text.secondary,
  }));

  return (
    <Stack direction="column" sx={{ flex: 1 }}>
      <Stack direction="row" gap={1} alignItems="center" sx={{ flex: 1 }}>
        <Iconify icon="fa-pro-solid:circle" sx={{ color: color }} />
        <LabelStyle variant="body2" sx={{ flex: 1 }}>
          {editedThreshold.priority === "warning"
            ? translate("Warning")
            : editedThreshold.priority === "alert"
            ? translate("Alert")
            : translate("Unknown")}
        </LabelStyle>
        {mode === "read" ? (
          <IconButton
            onClick={() => setMode("write")}
            title={translate("Edit threshold")}
          >
            <Iconify width={"1rem"} height={"1rem"} icon="fa-pro-duotone:pen" />
          </IconButton>
        ) : (
          <>
            <IconButton
              onClick={() => removeThreshold()}
              title={translate("Remove threshold")}
              color="error"
              disabled={!isValid()}
            >
              <Iconify
                width={"1rem"}
                height={"1rem"}
                icon="fa-pro-duotone:trash"
              />
            </IconButton>
            <IconButton
              onClick={() => saveThreshold()}
              title={translate("Save threshold")}
              color="primary"
              disabled={!isValid()}
            >
              <Iconify
                width={"1rem"}
                height={"1rem"}
                icon="fa-pro-duotone:floppy-disk"
              />
            </IconButton>
            <IconButton
              onClick={() => cancelEditing()}
              title={"Cancel editing"}
            >
              <Iconify
                width={"1rem"}
                height={"1rem"}
                icon="fa-pro-duotone:xmark"
              />
            </IconButton>
          </>
        )}
      </Stack>
      <Stack
        direction="row"
        gap={1}
        alignItems="center"
        sx={{ flex: 1, ml: 3 }}
      >
        {mode === "read" ? (
          isValid() ? (
            <>
              {measure.machineName ? (
                <LabelBadge sx={{ mr: 1 }} text={measure.machineName} />
              ) : (
                <Typography maxWidth="33%" variant="body2" noWrap>
                  {measure.label}
                </Typography>
              )}

              <Typography variant="body2" sx={{ flex: 1 }}>
                {`${convertOperators(editedThreshold.operator)} ${
                  measure.type === "number"
                    ? numeral(editedThreshold.value).format()
                    : measure.type === "boolean"
                    ? editedThreshold.value === "true"
                      ? translate("True")
                      : translate("False")
                    : editedThreshold.value
                } ${measure.unit ? measure.unit : ""}`}
              </Typography>
            </>
          ) : (
            <Typography variant="body2" sx={{ flex: 1 }}>
              {translate("No threshold saved")}
            </Typography>
          )
        ) : null}

        {mode === "write" ? (
          <>
            {measure.machineName ? (
              <LabelBadge sx={{ mr: 1 }} text={measure.machineName} />
            ) : (
              <Typography maxWidth="33%" variant="body2" noWrap>
                {measure.label}
              </Typography>
            )}
            <TextField
              select
              label={translate("Operator")}
              value={editedThreshold.operator}
              onChange={(e) =>
                setEditedThreshold({
                  ...editedThreshold,
                  operator: e.target.value,
                })
              }
              sx={{ flex: 1 }}
              size="small"
            >
              <MenuItem value="">{translate("None")}</MenuItem>
              {availableOperators.map((operator) => (
                <MenuItem value={operator.value} key={operator.label}>
                  {operator.label}
                </MenuItem>
              ))}
            </TextField>
            <Stack sx={{ flex: 2 }}>
              {measure.type === "boolean" ? (
                <FormControl sx={{ minWidth: "3em" }}>
                  <Select
                    value={editedThreshold.value}
                    onChange={(e) =>
                      setEditedThreshold({
                        ...editedThreshold,
                        value: e.target.value,
                      })
                    }
                    size="small"
                  >
                    <MenuItem value="">{translate("None")}</MenuItem>
                    <MenuItem value="true">{translate("True")}</MenuItem>
                    <MenuItem value="false">{translate("False")}</MenuItem>
                  </Select>
                </FormControl>
              ) : measure.type === "number" ? (
                <TextField
                  type={"number"}
                  value={editedThreshold.value}
                  size="small"
                  onChange={(e) =>
                    setEditedThreshold({
                      ...editedThreshold,
                      value: e.target.value,
                    })
                  }
                  onKeyPress={(e) => e.key === "Enter" && saveThreshold()}
                  InputProps={{
                    endAdornment: measure.unit && (
                      <InputAdornment position="end">
                        {measure.unit}
                      </InputAdornment>
                    ),
                  }}
                />
              ) : (
                <TextField
                  type={"text"}
                  value={editedThreshold.value}
                  onChange={(e) =>
                    setEditedThreshold({
                      ...editedThreshold,
                      value: e.target.value,
                    })
                  }
                  size="small"
                  onKeyPress={(e) => e.key === "Enter" && saveThreshold()}
                  InputProps={{
                    endAdornment: measure.unit && (
                      <InputAdornment position="end">
                        {measure.unit}
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            </Stack>
          </>
        ) : null}
      </Stack>
    </Stack>
  );
}

export default function AlarmsThresholdsSection({
  measure,
  onUpdated,
}: {
  measure: Measure;
  onUpdated?: (thresholds: Threshold[]) => void;
}) {
  const { translate } = useLocales();
  const theme = useTheme();
  const measuresState = useSelector((state) => state.measures);
  const { enqueueSnackbar } = useSnackbar();
  const [measureState, setMeasureState] = useState<Measure>(measure);
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    dispatch(loadAlarmOperators());
  }, []);

  useEffect(() => {
    const targetedMeasure = measuresState.measures.find(
      (measure) => measure.identifier === measureState.identifier
    );
    if (!targetedMeasure) return;
    const warningThreshold = targetedMeasure.thresholds.find(
      (threshold) => threshold.priority === "warning"
    );
    const alertThreshold = targetedMeasure.thresholds.find(
      (threshold) => threshold.priority === "alert"
    );
    const thresholds = [
      warningThreshold || { priority: "warning", operator: "", value: "" },
      alertThreshold || { priority: "alert", operator: "", value: "" },
    ];
    setMeasureState({
      ...targetedMeasure,
      thresholds,
    });
  }, [measuresState.measures, measure]);

  const save = async () => {
    try {
      setSaving(true);
      measure = {
        ...measure,
        thresholds: measureState.thresholds.filter(
          (threshold) =>
            threshold.priority &&
            threshold.operator &&
            threshold.value !== undefined
        ),
      };
      await dispatch(updateMeasure(measure));
      setSaving(false);
      setMeasureState(measure);
      enqueueSnackbar(translate("Thresholds updated"));
      await dispatch(loadMeasuresData());
      onUpdated && onUpdated(measure.thresholds);
    } catch (e) {
      console.error(e);
      enqueueSnackbar(e.message ? e.message : e, { variant: "error" });
      setSaving(false);
    }
  };

  return (
    <>
      <Stack sx={{ px: 2, py: 2, position: "relative" }}>
        {saving ? (
          <LoadingPanel scale={0.5}>Saving alarm thresholds...</LoadingPanel>
        ) : null}
        {measureState.thresholds.map((threshold, i) => (
          <Stack key={i}>
            <AlarmThreshold
              measure={measureState}
              thresholdIndex={i}
              onChange={(updatedThreshold) => save()}
              color={
                threshold.priority === "warning"
                  ? theme.palette.warning.dark
                  : threshold.priority === "alert"
                  ? theme.palette.error.dark
                  : theme.palette.primary.dark
              }
            />
            {i < measureState.thresholds.length - 1 && (
              <Divider sx={{ borderStyle: "dashed", mt: 2, mb: 1 }} />
            )}
          </Stack>
        ))}
      </Stack>
    </>
  );
}
