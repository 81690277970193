import { SxProps, useTheme } from "@mui/material";
import { useMemo } from "react";
import CircularGauge from "src/components/generic/chart/CircularGauge";
import { ColorSchema } from "src/theme/palette";

type SpeedometerRange = {
  from: number;
  to: number;
  colorSchema: ColorSchema;
  label?: string;
};

type Props = {
  ranges: SpeedometerRange[];
  currentValue: number | undefined;
  bgColor: string;
  // unit?: string;
  sx?: SxProps;
};

export default function Speedometer({
  ranges,
  currentValue,
  bgColor,
  // unit,
  sx,
}: Props) {
  const theme = useTheme();

  const scaledRanges = useMemo(
    () =>
      currentValue !== undefined
        ? ranges.map((range) => {
            let scaledRange = { ...range };
            if (range.from === -Infinity && range.to === Infinity)
              scaledRange = {
                ...range,
                from: currentValue - 1,
                to: currentValue + 1,
              };
            else if (range.from === -Infinity)
              scaledRange = {
                ...range,
                from: Math.min(
                  currentValue * 0.9,
                  range.to - Math.abs(currentValue - range.to) / 10
                ),
                to: range.to,
              };
            else if (range.to === Infinity)
              scaledRange = {
                ...range,
                from: range.from,
                to: Math.max(
                  currentValue * 1.1,
                  range.from + Math.abs(currentValue - range.from) / 10
                ),
              };
            return scaledRange;
          })
        : [],
    [currentValue, ranges]
  );

  const currentRange = useMemo(
    () =>
      currentValue !== undefined
        ? ranges.find(
            (range) => range.from < currentValue && currentValue < range.to
          )
        : undefined,
    [currentValue, ranges]
  );

  const currentColor = useMemo(
    () =>
      currentRange ? theme.palette[currentRange.colorSchema].main : "#ccc",
    [currentRange, theme.palette]
  );

  return (
    <CircularGauge
      donutStops={scaledRanges.reduce((acc, r) => {
        if (!acc.includes(r.from)) acc = [...acc, r.from];
        if (!acc.includes(r.to)) acc = [...acc, r.to];
        return acc;
      }, [] as number[])}
      donutColors={scaledRanges.map((r) => theme.palette[r.colorSchema].light)}
      currentValue={currentValue}
      currentColor={currentColor}
      bgColor={bgColor}
      sx={{
        "& .labels .label": {
          fontSize: 12,
          stroke: theme.palette.text.primary,
          fill: theme.palette.text.primary,
        },
      }}
    />
  );
}
