import { Breakpoint } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

type Query = "up" | "down" | "between" | "only";
type Key = Breakpoint | number;
type Start = Breakpoint | number;
type End = Breakpoint | number;

export function useResponsive(
  query: Query,
  key?: Key,
  start?: Start,
  end?: End
) {
  const theme = useTheme();

  const mediaUp = useMediaQuery(theme.breakpoints.up(key as Key));
  const mediaDown = useMediaQuery(theme.breakpoints.down(key as Key));
  const mediaBetween = useMediaQuery(
    theme.breakpoints.between(start as Start, end as End)
  );
  const mediaOnly = useMediaQuery(theme.breakpoints.only(key as Breakpoint));

  return query === "up"
    ? mediaUp
    : query === "down"
    ? mediaDown
    : query === "between"
    ? mediaBetween
    : query === "only"
    ? mediaOnly
    : undefined;
}

export function useResponsiveShortcut() {
  const isSmUp = useResponsive("up", "sm");
  const isMdUp = useResponsive("up", "md");
  const isLgUp = useResponsive("up", "lg");
  return {
    isLgUp,
    isSmUp,
    isMdUp,
  };
}

// export default function useResponsive(
//   query: Query,
//   key?: Key,
//   start?: Start,
//   end?: End
// ) {
//   const theme = useTheme();

//   const mediaUp = useMediaQuery(theme.breakpoints.up(key as Key));
//   const mediaDown = useMediaQuery(theme.breakpoints.down(key as Key));
//   const mediaBetween = useMediaQuery(
//     theme.breakpoints.between(start as Start, end as End)
//   );
//   const mediaOnly = useMediaQuery(theme.breakpoints.only(key as Breakpoint));

//   if (query === "up") {
//     return mediaUp;
//   }

//   if (query === "down") {
//     return mediaDown;
//   }

//   if (query === "between") {
//     return mediaBetween;
//   }

//   if (query === "only") {
//     return mediaOnly;
//   }
// }
