// @mui
import { createTheme, ThemeProvider, useTheme } from "@mui/material/styles";
import { ReactNode } from "react";
// hooks
import useLocales from "../../../hooks/useLocales";

// ----------------------------------------------------------------------

type Props = {
  children: ReactNode;
};

export default function ThemeLocalization({ children }: Props) {
  const defaultTheme = useTheme();

  const { currentLanguage } = useLocales();

  const theme = createTheme(defaultTheme, currentLanguage.systemValue);

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}
