import { Stack } from "@mui/material";
import { SxProps } from "@mui/material/styles";
import { useMemo } from "react";
import { Attachment, Patrol, PatrolMeasure } from "src/@types";
import AttachmentsBlock from "./AttachmentsBlock";
import NoteBlock from "./NoteBlock";
import ValueBlock from "./ValueBlock";

type Props = {
  patrol: Patrol;
  measure: PatrolMeasure;
  showLabel: boolean;
  showEmpty: boolean;
  sx?: SxProps;
};

export default function MeasureView({
  patrol,
  measure,
  showLabel,
  showEmpty,
  sx,
}: Props) {
  const measureModel = useMemo(
    () =>
      patrol.model.measures.find((m) => m.identifier === measure.identifier),
    [patrol, measure]
  );

  if (!measureModel) return null;

  return (
    <Stack gap={1} sx={sx}>
      <ValueBlock
        measureModel={measureModel}
        measure={measure}
        showLabel={showLabel}
      />

      {showEmpty || measure.note ? <NoteBlock measure={measure} /> : null}

      {showEmpty || measure.files?.length || measure.attachments?.length ? (
        <AttachmentsBlock
          measure={measure}
          getAttachmentBaseUrl={(attachment: Attachment) =>
            [
              window.env.REACT_APP_EYE_BACKEND_URL,
              "patrols",
              patrol._id,
              "measures",
              measure.identifier,
              "attachments",
              encodeURIComponent(attachment["aws-s3"].Key),
            ].join("/")
          }
        />
      ) : null}
    </Stack>
  );
}
