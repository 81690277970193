import { Stack, styled, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { sprintf } from "sprintf-js";
import { Measure } from "src/@types";
import LabelBadge from "src/components/generic/LabelBadge";
import { useLocales } from "src/hooks";
import { useSelector } from "src/redux/store";
import { PATH_DASHBOARD } from "src/routes/paths";
import { displayCompleteDateFromLocale, isMeasureOld } from "src/utils/date";

type Props = {
  measure: Measure;
  displayLinkToMeasure: boolean;
};

export default function GeneralSection({
  measure,
  displayLinkToMeasure,
}: Props) {
  const { translate } = useLocales();
  const authState = useSelector((state) => state.auth);
  const theme = useTheme();

  const LabelStyle = styled(Typography)(({ theme }) => ({
    ...theme.typography.body2,
    width: 140,
    fontSize: 13,
    flexShrink: 0,
    color: theme.palette.text.secondary,
  }));

  const RouterLinkStyle = styled(RouterLink)(({ theme }) => ({
    color: theme.palette.primary.main,
    textDecoration: "none",
    fontSize: 14,
    fontWeight: 600,
    cursor: "pointer",
    "&:hover": {
      textDecorationStyle: "dotted",
      textDecorationLine: "underline",
      textDecorationColor: theme.palette.primary.main,
      textUnderlineOffset: "0.4em",
    },
  }));

  const navigate = useNavigate();

  return (
    <Stack
      gap={1}
      sx={{
        px: 2,
        py: 2,
        backgroundColor: theme.palette.background.neutral,
      }}
    >
      <Typography variant="subtitle2">{translate("General")}</Typography>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <LabelStyle>{translate("Equipment")}</LabelStyle>
        <Typography variant="body2" sx={{ color: "white" }} noWrap>
          {measure.equipment?.label}
        </Typography>
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <LabelStyle>{translate("Label")}</LabelStyle>
        <Typography variant="body2" sx={{ color: "white" }} noWrap>
          {measure.label}
        </Typography>
      </Stack>

      {measure.machineName && (
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <LabelStyle>{translate("Machine name")}</LabelStyle>
          <LabelBadge
            onClick={() =>
              navigate(PATH_DASHBOARD.measures.details(measure.identifier))
            }
            text={measure.machineName}
          />
        </Stack>
      )}

      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <LabelStyle>{translate("Last update")}</LabelStyle>
        <Typography
          variant="body2"
          sx={{ color: isMeasureOld(measure) ? "orange" : "white" }}
          noWrap
        >
          {displayCompleteDateFromLocale(
            authState.user?.language,
            measure?.data && measure.data.last ? measure?.data?.last.time : "",
            translate
          )}
        </Typography>
      </Stack>

      {displayLinkToMeasure && (
        <Stack sx={{ mt: 1 }}>
          <RouterLinkStyle
            to={PATH_DASHBOARD.measures.details(measure.identifier)}
          >
            {sprintf("> %s", translate("Access to measure details"))}
          </RouterLinkStyle>
        </Stack>
      )}
    </Stack>
  );
}
