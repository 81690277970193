import { Box, BoxProps } from "@mui/system";
import React, { useEffect, useRef, useState } from "react";

function SquareBox({ sx, children }: BoxProps) {
  const [height, setHeight] = useState<number | undefined>();
  const componentRootRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const observer = new ResizeObserver((entries) =>
      entries.forEach((entry) => setHeight(entry.contentRect.width))
    );
    componentRootRef.current && observer.observe(componentRootRef.current);
    return () => observer.disconnect();
  }, []);

  return (
    <Box ref={componentRootRef} sx={{ ...sx, height: height }}>
      {children}
    </Box>
  );
}

export default SquareBox;
