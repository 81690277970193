// @mui
import { TableCell, TableRow } from "@mui/material";
import useLocales from "src/hooks/useLocales";
//
import EmptyContent from "../EmptyContent";

// ----------------------------------------------------------------------

type Props = {
  isNotFound: boolean;
  label?: string;
};

export default function TableNoData({ isNotFound, label }: Props) {
  const { translate } = useLocales();
  const finalLabel = label ? label : translate("No data");
  return (
    <TableRow>
      {isNotFound ? (
        <TableCell colSpan={12}>
          <EmptyContent title={finalLabel} />
        </TableCell>
      ) : (
        <TableCell colSpan={12} sx={{ p: 0 }} />
      )}
    </TableRow>
  );
}
