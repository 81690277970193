// Source
// https://github.com/mui/mui-x/issues/4563#issuecomment-1214792498
// https://codesandbox.io/s/textfieldhiddenlabel-material-demo-forked-bpm46e?file=/RangePicker.tsx:0-9887

import {
  Box,
  Button,
  ClickAwayListener,
  Divider,
  Fade,
  Grid,
  IconButton,
  InputAdornment,
  List,
  ListItemButton,
  ListItemText,
  Paper,
  Popper,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import { PopperPlacementType } from "@mui/material/Popper";
import { styled, useTheme } from "@mui/material/styles";
import { SxProps } from "@mui/system";
import { ClockPicker } from "@mui/x-date-pickers";
import type { SlideDirection } from "@mui/x-date-pickers/CalendarPicker/PickersSlideTransition";
import {
  DayPicker,
  PickersToolbarButton,
  useDefaultDates,
} from "@mui/x-date-pickers/internals";
import { capitalize } from "lodash";
import { DateTime, Duration } from "luxon";
import { useEffect, useRef, useState } from "react";
import { TimeSelectorInterval } from "src/@types/Organization";
import { useLocales, useResponsiveShortcut } from "src/hooks";
import Iconify from "./Iconify";
import { TimeControl } from "src/@types";
import {
  IntervalTimeControl,
  isIntervalTimeControl,
  isStartEndTimeControl,
  toStartEndTimeControl,
} from "src/@types/TimeControl";
import SwipeableDrawer from "./SwipeableDrawer";

const defaultIntervals: TimeSelectorInterval[] = [
  {
    identifier: "PT12H",
    label: "Last 12 hours",
    durationUntilNow: "PT12H",
  },
  {
    identifier: "P1D",
    label: "Yesterday",
    durationUntilNow: "P1D",
  },
  {
    identifier: "P7D",
    label: "Last 7 days",
    durationUntilNow: "P7D",
  },
  {
    identifier: "P1M",
    label: "Past month",
    durationUntilNow: "P1M",
  },
];

enum Mode {
  DATE,
  TIME,
}

const MyClockPicker = styled(ClockPicker<DateTime>)(({ theme }) => ({
  // width: "auto",
  width: "280px",
}));

type DateRangePickerProps = {
  minDate?: DateTime;
  maxDate?: DateTime;
  intervals?: TimeSelectorInterval[];
  label: string;
  format?: string;
  value: TimeControl;
  onChange: (timeControl: TimeControl) => void;
  open: boolean;
  setOpen: (arg0: boolean) => void;
  sx?: SxProps;
};

type NullableStartEndTimeControl = {
  start?: DateTime;
  end?: DateTime;
};

const DateRangePicker = ({
  minDate,
  maxDate,
  intervals = defaultIntervals,
  label = "",
  format = "DD",
  value,
  onChange,
  open,
  setOpen,
  sx,
}: DateRangePickerProps) => {
  const defaultDates = useDefaultDates<DateTime>();
  const { translate, currentLanguage } = useLocales();
  const theme = useTheme();

  const { isMdUp, isSmUp } = useResponsiveShortcut();

  const [valueState, setValueState] = useState<
    IntervalTimeControl | NullableStartEndTimeControl
  >(value);

  const [startDateDisplay, setStartDateDisplay] = useState(
    isStartEndTimeControl(value) ? value.start : undefined
  );
  const [endDateDisplay, setEndDateDisplay] = useState(
    isStartEndTimeControl(value) ? value.end : undefined
  );
  const [selectedDays, setSelectedDays] = useState<DateTime[]>([]);
  const [month, setMonth] = useState(DateTime.now());
  const [direction, setDirection] = useState<SlideDirection>("left");
  const [anchorEl, setAnchorEl] = useState<
    HTMLButtonElement | HTMLInputElement | HTMLTextAreaElement | null
  >(null);
  const [placement, setPlacement] = useState<PopperPlacementType>();
  const [textValue, setTextValue] = useState<string>("");
  // enum Mode {
  //   DATE,
  //   TIME,
  // }
  const [mode, setMode] = useState<Mode>(Mode.DATE);
  const [tabValue, setTabValue] = useState(0);
  const textfieldElRef = useRef(null);

  // Update initial text
  useEffect(() => {
    const startDate = isStartEndTimeControl(value) ? value.start : undefined;
    const endDate = isStartEndTimeControl(value) ? value.end : undefined;
    const durationUntilNow = isIntervalTimeControl(value)
      ? value.interval
      : undefined;
    const text = computeTextValue({ startDate, endDate, durationUntilNow });
    setTextValue(text);
  }, [value]);

  // Props -> state
  // useEffect(() => {
  //   setDurationUntilNowState(
  //     isIntervalTimeControl(value) ? value.interval : undefined
  //   );
  // }, [value]);

  // useEffect(() => {
  //   if (isStartEndTimeControl(value)) {
  //     setStartDateState(value.start);
  //     setEndDateState(value.end);
  //   } else if (isIntervalTimeControl(value)) {
  //     setDurationUntilNowState(value.interval);
  //   }
  // }, [value]);

  // State -> display
  useEffect(() => {
    const startEndValue = toStartEndTimeControl(valueState as TimeControl);
    setStartDateDisplay(startEndValue.start);
    setEndDateDisplay(startEndValue.end);
  }, [valueState]);

  //
  useEffect(() => {
    if (startDateDisplay && !endDateDisplay) {
      setSelectedDays([startDateDisplay]);
      return;
    }
    if (startDateDisplay && endDateDisplay) {
      const daysCount = endDateDisplay.diff(startDateDisplay).as("days");
      const days: Array<DateTime> = [];
      for (let i = 0; i <= daysCount; i++) {
        days.push(startDateDisplay.plus({ days: i }));
      }
      setSelectedDays(days);
    }
  }, [startDateDisplay, endDateDisplay]);

  const computeTextValue = ({
    startDate,
    endDate,
    durationUntilNow,
  }: {
    startDate?: DateTime;
    endDate?: DateTime;
    durationUntilNow?: Duration;
  }) => {
    let text: string | undefined;
    if (durationUntilNow) {
      const interval = intervals.find(
        (i) => i.identifier === durationUntilNow.toISO()
      );
      if (interval) text = interval.label;
    }
    if (!text && startDate && endDate) {
      text = `${startDate.toFormat(format)} - ${endDate.toFormat(format)}`;
    }
    return text || translate("Select date");
  };

  const selectInterval = (selectedInterval: string) => {
    const interval = intervals.find((i) => i.identifier === selectedInterval);
    if (!interval) return;
    setValueState({ interval: Duration.fromISO(interval.durationUntilNow) });
  };

  const selectDate = (d: DateTime | null) => {
    if (!d) return;
    const value = {
      start: (valueState as NullableStartEndTimeControl).start,
      end: (valueState as NullableStartEndTimeControl).end,
    };
    if (!value.start && !value.end) {
      value.start = d;
      setValueState(value);
      return;
    }
    if (!value.end) {
      if (value.start && d < value.start) {
        value.start = d.set({
          hour: value.start.hour,
          minute: value.start.minute,
          second: value.start.second,
        });
        value.end = value.start;
      } else {
        value.end = d.set({
          hour: 23,
          minute: 59,
          second: 59,
        });
      }
      setValueState(value);
      return;
    }
    if (value.start) {
      if (d.toISODate() === value.start.toISODate()) {
        value.start = d.set({
          hour: 0,
          minute: 0,
          second: 0,
        });
      } else {
        value.start = d.set({
          hour: value.start.hour,
          minute: value.start.minute,
          second: value.start.second,
        });
      }
    }
    value.end = undefined;
    setValueState(value);
  };

  const handleClose = () => {
    setOpen(false);
    setValueState(value);
  };

  const apply = () => {
    const lol = valueState as TimeControl;
    if (isStartEndTimeControl(lol) && (!lol.start || !lol.end)) {
      return;
    }
    if (isIntervalTimeControl(lol) && !lol.interval) {
      return;
    }
    setOpen(false);
    setMode(Mode.DATE);
    if (isIntervalTimeControl(lol)) {
      onChange({ interval: lol.interval });
    } else {
      onChange({ start: lol.start, end: lol.end });
    }
    setTextValue(
      computeTextValue(
        isIntervalTimeControl(lol)
          ? {
              durationUntilNow: lol.interval,
            }
          : { startDate: lol.start, endDate: lol.end }
      )
    );
  };

  const IntervalsList = () => {
    const value = valueState as TimeControl;
    return (
      <List sx={{ p: 0 }}>
        {intervals.map((interval) => (
          <ListItemButton
            key={interval.identifier}
            onClick={() => selectInterval(interval.identifier)}
            selected={
              isIntervalTimeControl(value) &&
              interval.identifier === value.interval.toISO()
            }
          >
            <ListItemText primary={interval.label} />
          </ListItemButton>
        ))}
      </List>
    );
  };

  const StartDate = () => {
    const value = valueState as TimeControl;
    return (
      <Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            gap: 1,
          }}
        >
          <PickersToolbarButton
            variant="h6"
            onClick={() => {
              setMode(Mode.DATE);
            }}
            value={startDateDisplay?.toFormat("D") || "--/--/----"}
            selected={false}
          />
          <PickersToolbarButton
            variant="h6"
            onClick={() => {
              setMode(Mode.TIME);
            }}
            value={startDateDisplay?.toFormat("T") || "--:--"}
            selected={false}
          />
        </Box>
        {mode === Mode.DATE && (
          <>
            <Grid
              container
              justifyContent="space-between"
              alignItems="baseline"
            >
              <Grid item sx={{ width: "40px" }}>
                <IconButton
                  onClick={() => {
                    setDirection("right");
                    setMonth(month.minus({ month: 1 }));
                  }}
                >
                  <Iconify icon={"material-symbols:arrow-left"} />
                </IconButton>
              </Grid>
              <Grid item sx={{ textAlign: "center" }}>
                <Typography variant="h6">
                  {capitalize(month.toLocaleString({ month: "long" }))}
                </Typography>
              </Grid>
              <Grid item sx={{ width: "40px" }} />
            </Grid>
            <DayPicker
              currentMonth={month}
              selectedDays={selectedDays}
              onSelectedDaysChange={selectDate}
              focusedDay={DateTime.now()}
              isMonthSwitchingAnimating
              onFocusedDayChange={() => null}
              onMonthSwitchingAnimationEnd={() => null}
              reduceAnimations={false}
              slideDirection={direction}
              loading={false}
              disablePast={false}
              disableFuture={false}
              minDate={minDate || defaultDates.minDate}
              maxDate={maxDate || defaultDates.maxDate}
            />
          </>
        )}
        {mode === Mode.TIME && (
          <Box>
            <MyClockPicker
              date={isStartEndTimeControl(value) ? value.start : null}
              onChange={(newValue) => {
                if (newValue === null) return;
                setValueState({ ...value, start: newValue });
              }}
            />
          </Box>
        )}
      </Box>
    );
  };

  const EndDate = () => {
    const value = valueState as TimeControl;
    return (
      <Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            gap: 1,
          }}
        >
          <PickersToolbarButton
            variant="h6"
            onClick={() => {
              setMode(Mode.DATE);
            }}
            value={endDateDisplay?.toFormat("D") || "--/--/----"}
            selected={false}
          />
          <PickersToolbarButton
            variant="h6"
            onClick={() => {
              setMode(Mode.TIME);
            }}
            value={endDateDisplay?.toFormat("T") || "--:--"}
            selected={false}
          />
        </Box>
        {mode === Mode.DATE && (
          <>
            <Grid
              container
              justifyContent="space-between"
              alignItems="baseline"
            >
              <Grid item sx={{ width: "40px" }} />
              <Grid item sx={{ textAlign: "center" }}>
                <Typography variant="h6">
                  {capitalize(
                    month.plus({ month: 1 }).toLocaleString({ month: "long" })
                  )}
                </Typography>
              </Grid>
              <Grid item sx={{ width: "40px" }}>
                <IconButton
                  onClick={() => {
                    setDirection("left");
                    setMonth(month.plus({ month: 1 }));
                  }}
                >
                  <Iconify icon={"material-symbols:arrow-right"} />
                </IconButton>
              </Grid>
            </Grid>
            <DayPicker
              currentMonth={month.plus({ month: 1 })}
              selectedDays={selectedDays}
              onSelectedDaysChange={selectDate}
              focusedDay={DateTime.now()}
              isMonthSwitchingAnimating
              onFocusedDayChange={() => null}
              onMonthSwitchingAnimationEnd={() => null}
              reduceAnimations={false}
              slideDirection={direction}
              loading={false}
              disablePast={false}
              disableFuture={false}
              minDate={minDate || defaultDates.minDate}
              maxDate={maxDate || defaultDates.maxDate}
            />
          </>
        )}
        {mode === Mode.TIME && (
          <Box>
            <MyClockPicker
              date={isStartEndTimeControl(value) ? value.end : null}
              onChange={(newValue) => {
                if (newValue === null) return;
                setValueState({ ...value, end: newValue });
              }}
            />
          </Box>
        )}
      </Box>
    );
  };

  const DateRangePickerDesktop = () => {
    return (
      <Stack>
        <Stack direction="row">
          <Box sx={{ minWidth: "200px" }}>
            <IntervalsList />
          </Box>
          <Divider orientation="vertical" flexItem />
          <Stack direction="row" gap={4} sx={{ pt: 2 }}>
            <StartDate />
            <EndDate />
          </Stack>
        </Stack>
        <Divider />
        <Stack direction="row" gap={1} justifyContent="end" sx={{ p: 2 }}>
          <Button
            variant="contained"
            sx={{ backgroundColor: theme.palette.action.disabled }}
            onClick={() => handleClose()}
          >
            {translate("Cancel")}
          </Button>
          <Button variant="contained" color="primary" onClick={() => apply()}>
            {translate("Apply")}
          </Button>
        </Stack>
      </Stack>
    );
  };

  const DateRangePickerMobile = () => {
    return (
      <Stack flex={1} sx={{ minWidth: 300 }}>
        <Stack
          direction="row"
          gap={1}
          justifyContent="normal"
          alignItems="center"
          sx={{ p: 2 }}
        >
          <IconButton
            onClick={() => handleClose()}
            sx={{
              p: 0,
              ml: "-6px",
            }}
          >
            <Iconify icon="eva:close-fill" width={24} height={24} />
          </IconButton>

          <Stack direction="row" gap={1} alignItems="center">
            <Typography title={label} variant="h6" sx={{ fontSize: 20 }}>
              {label}
            </Typography>
          </Stack>
        </Stack>
        <Tabs
          value={tabValue}
          onChange={(event: React.SyntheticEvent, newValue: number) =>
            setTabValue(newValue)
          }
          variant="fullWidth"
        >
          <Tab label={translate("Predefined interval")} />
          <Tab label={translate("Custom dates")} />
        </Tabs>
        <Box hidden={tabValue !== 0} sx={{ flex: 1 }}>
          <IntervalsList />
        </Box>
        <Box hidden={tabValue !== 1} sx={{ flex: 1 }}>
          <Stack direction="column" gap={0} sx={{ pt: 2 }}>
            <StartDate />
            <EndDate />
          </Stack>
        </Box>
        <Divider />
        <Stack
          direction="row"
          gap={1}
          justifyContent="end"
          sx={{ p: 2 }}
          flex={0}
        >
          {/*<Button
            variant="contained"
            sx={{ backgroundColor: theme.palette.action.disabled }}
            onClick={() => handleClose()}
          >
            {translate("Cancel")}
          </Button>*/}
          <Button variant="contained" color="primary" onClick={() => apply()}>
            {translate("Apply")}
          </Button>
        </Stack>
      </Stack>
    );
  };

  return (
    <ClickAwayListener onClickAway={() => handleClose()}>
      <Box className="DateRangePicker-root" sx={{ width: "100%", ...sx }}>
        <TextField
          label={label}
          ref={textfieldElRef}
          // variant="outlined"
          onClick={(e) => {
            setAnchorEl(textfieldElRef.current);
            setOpen(true);
            setPlacement("bottom-end");
          }}
          // onFocus={(e) => {
          //   setAnchorEl(textfieldElRef.current);
          //   setOpen(true);
          //   setPlacement("bottom-end");
          // }}
          value={textValue}
          InputProps={{
            readOnly: true,
            endAdornment: (
              <InputAdornment position="end" sx={{ mr: 2 }}>
                <IconButton
                  aria-label="open range picker"
                  onClick={(e) => {
                    // setAnchorEl(e.currentTarget);
                    setAnchorEl(textfieldElRef.current);
                    setOpen(true);
                    setPlacement("bottom-end");
                  }}
                  edge="end"
                >
                  <Iconify icon={"material-symbols:date-range"} />
                </IconButton>
              </InputAdornment>
            ),
          }}
          sx={{ width: "100%" }}
        />

        {isMdUp ? (
          <Popper
            open={open}
            anchorEl={anchorEl}
            placement={placement}
            transition
          >
            {({ TransitionProps }) => (
              <Fade {...TransitionProps} timeout={250}>
                <Paper elevation={12}>
                  <DateRangePickerDesktop />
                </Paper>
              </Fade>
            )}
          </Popper>
        ) : (
          <SwipeableDrawer open={open} onClose={handleClose} anchor="right">
            <DateRangePickerMobile />
          </SwipeableDrawer>
        )}
      </Box>
    </ClickAwayListener>
  );
};

export default DateRangePicker;
