import { StepConnector, Stepper, StepperProps, styled } from "@mui/material";
import {
  StepConnectorProps,
  stepConnectorClasses,
} from "@mui/material/StepConnector";

function StyledHorizontalStepConnector(props: StepConnectorProps) {
  const MyStyledHorizontalStepConnector = styled(StepConnector)(
    ({ theme }) => ({
      left: "calc(-50% + 40px)",
      right: "calc(50% + 40px)",
      [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 20,
      },
      [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
          backgroundColor: theme.palette.primary.main,
        },
      },
      [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
          backgroundColor: theme.palette.primary.main,
        },
      },
      [`& .${stepConnectorClasses.line}`]: {
        height: 3,
        border: 0,
        backgroundColor: theme.palette.divider,
        transition: theme.transitions.create("background-color", {
          duration: "1s",
        }),
      },
    })
  );
  return <MyStyledHorizontalStepConnector {...props} />;
}

function StyledVerticalStepConnector(props: StepConnectorProps) {
  const MyStyledVerticalStepConnector = styled(StepConnector)(({ theme }) => ({
    marginLeft: 20,
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: theme.palette.primary.main,
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: theme.palette.primary.main,
      },
    },
    // [`& .${stepConnectorClasses.line}`]: {
    //   height: 3,
    //   border: 0,
    //   borderColor: theme.palette.divider,
    //   transition: theme.transitions.create("background-color", {
    //     duration: "1s",
    //   }),
    // },
  }));
  return <MyStyledVerticalStepConnector {...props} />;
}

function StyledStepper(props: StepperProps) {
  const MyStyledStepper = styled(Stepper)(({ theme }) => ({
    overflowX: "auto",
    overflowY: "visible",
    ".MuiStepButton-root": {
      padding: 0,
      margin: 0,
    },
    ".MuiStep-vertical": {
      ".MuiStepContent-root": {
        marginLeft: 20,
      },
      "&.Mui-completed": {
        ".MuiStepContent-root": {
          borderColor: theme.palette.primary.main,
        },
        "& + .MuiStepConnector-root .MuiStepConnector-line": {
          borderColor: theme.palette.primary.main,
        },
      },
    },
  }));
  return (
    <MyStyledStepper
      connector={
        props.orientation == "vertical" ? (
          <StyledVerticalStepConnector />
        ) : (
          <StyledHorizontalStepConnector />
        )
      }
      {...props}
    />
  );
}

export default StyledStepper;
