import { m } from "framer-motion";

export default function ({
  duration,
  delay,
}: {
  duration: number;
  delay: number;
}) {
  return (
    <m.span
      animate={{
        opacity: [0, 1, 0],
      }}
      transition={{
        delay,
        duration,
        ease: "easeInOut",
        repeatDelay: 1,
        repeat: Infinity,
      }}
    >
      .
    </m.span>
  );
}
