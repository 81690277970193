import LokiStore from "./LokiStore";

// abstract class Collection<E extends object> {
class Collection<E extends object> {
  store: LokiStore;
  collectionName: string;

  constructor(store: LokiStore, collectionName: string) {
    this.store = store;
    this.collectionName = collectionName;
  }

  /**
   * Fetch a document in collection
   * @param query
   * @returns First matching document
   */
  findOne(query: any = {}) {
    return this.store.findOne(this.collectionName, query) as Promise<
      (E & LokiObj) | undefined
    >;
  }

  /**
   * Fetch documents in collection.
   * @param query				object	{ createdOn: { "$gte": "2022-04-01" } }
   * @param sort				array		[ "-createdOn" ]
   * @param pagination	object	{ skip: 10, limit: 100 }
   * @returns Array of found documents
   */
  find(
    query: any = {},
    sort?: string | string[],
    pagination?: { skip: number; limit: number }
  ) {
    return this.store.find(
      this.collectionName,
      query,
      sort,
      pagination
    ) as Promise<(E & LokiObj)[]>;
  }

  /**
   * Count documents in collection
   */
  count(query: any = {}) {
    return this.store.count(this.collectionName, query);
  }

  /**
   * Find document in collection and update it
   */
  // findAndUpdate(query: any, updateFn: (obj: any) => any) {
  //   if (!this.lokiCollection) throw new Error("Collection not defined");
  //   this.lokiCollection.findAndUpdate(query, updateFn);
  // }

  /**
   * Insert document in collection
   */
  insert(items: E | E[]) {
    return this.store.insert(this.collectionName, items) as Promise<
      E & LokiObj
    >;
  }

  /**
   * Update document in collection
   */
  update(item: E) {
    return this.store.update(this.collectionName, item) as Promise<E & LokiObj>;
  }

  /**
   * Save document in collection
   */
  save(item: E) {
    return this.store.save(this.collectionName, item) as Promise<E & LokiObj>;
  }

  /**
   * Remove document in collection
   */
  remove(doc: E) {
    return this.store.remove(this.collectionName, doc);
  }

  /**
   * Remove documents in collection
   */
  findAndRemove(query?: any) {
    return this.store.findAndRemove(this.collectionName, query);
  }

  clear() {
    return this.store.clear(this.collectionName);
  }
}

export default Collection;
