import { useContext } from "react";
import { ImageContext } from "src/contexts/ImageContext";

// ----------------------------------------------------------------------

export default function useImage() {
  const context = useContext(ImageContext);

  if (!context)
    throw new Error("ImageContext must be use inside ImageContext Provider");

  return context;
}
