import { alpha, Theme } from "@mui/material";

import scaleGradientBase, {
  ScaleStop,
} from "../../../../generic/chart/uPlot/functions/scaleGradient";

type Props = {
  color: string;
  min: number;
  max: number;
};

export default function fillGradient({ color, min, max }: Props) {
  const scaleStops = (
    [
      (min < 0 && {
        value: min,
        // color: alpha(theme.palette.primary.main, 0.8),
        color: alpha(color, 0.8),
      }) ||
        undefined,
      // { value: 0, color: alpha(theme.palette.primary.main, 0.0) },
      { value: 0, color: alpha(color, 0.0) },
      (max > 0 && {
        value: max,
        // color: alpha(theme.palette.primary.main, 0.8),
        color: alpha(color, 0.8),
      }) ||
        undefined,
    ].filter((e) => e) as ScaleStop[]
  ).sort((s1, s2) => (s1.value < s2.value ? -1 : s1.value > s2.value ? 1 : 0));

  return scaleGradientBase({
    scaleKey: "y",
    orientation: "y",
    scaleStops: scaleStops,
    discrete: false,
  });
}
