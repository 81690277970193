import {
  SerializedError,
  createAsyncThunk,
  createSlice,
} from "@reduxjs/toolkit";
import { DateTime } from "luxon";
import {
  Location,
  Patrol,
  PatrolMeasure,
  PatrolModel,
  PatrolModelMeasure,
  User,
} from "src/@types";
import { locationService, patrolService } from "src/services";
import { get as getStore } from "src/store";
import { AuthState } from "./auth";

type GroupedMeasures = {
  group?: {
    identifier: string;
    label?: string;
  };
  // measures: PatrolMeasure[];
  measuresAndModels: {
    measure: PatrolMeasure;
    model: PatrolModelMeasure;
  }[];
};

export interface PatrolsState {
  // Models
  models: PatrolModel[];
  modelsRequestId?: string;
  modelsLoading: boolean;
  modelsLoaded: boolean;
  // Locations
  locations: Location[];
  locationsRequestId?: string;
  locationsLoading: boolean;
  locationsLoaded: boolean;
  // Patrols
  patrols: Patrol[];
  patrolsTotal: number;
  patrolsRequestId?: string;
  patrolsLoading: boolean;
  patrolsLoaded: boolean;
  // Group measures
  groupMeasures: (
    measures: PatrolMeasure[],
    patrolModel: PatrolModel
  ) => GroupedMeasures[];
  // Patrol utilities
  isPatrolEditable: (patrol: Patrol, user: User) => boolean;
  isPatrolDeletable: (patrol: Patrol, user: User) => boolean;
  // Error
  error?: SerializedError;
}

const initialState: PatrolsState = {
  // Models
  models: [],
  modelsRequestId: undefined,
  modelsLoading: false,
  modelsLoaded: false,
  // Locations
  locations: [],
  locationsRequestId: undefined,
  locationsLoading: false,
  locationsLoaded: false,
  // Patrols
  patrols: [],
  patrolsTotal: 0,
  patrolsRequestId: undefined,
  patrolsLoading: false,
  patrolsLoaded: false,
  // Group measures
  groupMeasures: (measures: PatrolMeasure[], patrolModel: PatrolModel) =>
    measures.reduce((acc, measure) => {
      const measureModel = patrolModel.measures.find(
        (m) => m.identifier === measure.identifier
      );
      if (!measureModel) return acc;
      let measureGroup = acc.find(
        (measureGroup) =>
          measureGroup.group?.identifier === measureModel.groupId
      );
      if (!measureGroup) {
        measureGroup = {
          group: patrolModel.groups.find(
            (g) => g.identifier === measureModel.groupId
          ),
          measuresAndModels: [],
        };
        acc = [...acc, measureGroup];
      }
      measureGroup.measuresAndModels = [
        ...measureGroup.measuresAndModels,
        { measure, model: measureModel },
      ];
      return acc;
    }, [] as GroupedMeasures[]),
  // Patrol utilities
  isPatrolEditable: (patrol: Patrol, user: User) =>
    !patrol.done || user.role === "ADMIN",
  isPatrolDeletable: (patrol: Patrol, user: User) =>
    !patrol.done || user.role === "ADMIN",
  // Error
  error: undefined,
};

export const loadLocations = createAsyncThunk<
  Location[],
  undefined,
  { state: { patrols: PatrolsState } }
>("patrols-locations-load", async (params, { getState, requestId }) => {
  const { locationsRequestId, locationsLoading } = getState().patrols;
  if (!locationsLoading || requestId !== locationsRequestId) {
    return initialState.locations;
  }
  const response = await locationService.getPatrolsModelsLocations();
  return response.data;
});

export const loadModels = createAsyncThunk<
  PatrolModel[],
  undefined,
  { state: { patrols: PatrolsState } }
>("patrols-models-load", async (params, { getState, requestId }) => {
  const { modelsRequestId, modelsLoading } = getState().patrols;
  if (!modelsLoading || requestId !== modelsRequestId) {
    return initialState.models;
  }
  const response = await patrolService.getModels();
  return response.data;
});

export const loadPatrols = createAsyncThunk<
  { totalCount: number; patrols: Patrol[] },
  {
    startDate: DateTime;
    endDate: DateTime;
    operatorsFilter: User[];
    modelsFilter: PatrolModel[];
    validationStatusFilter: any;
    sort: string;
    order: "asc" | "desc";
    pagesize: number;
    page: number;
  },
  { state: { auth: AuthState; patrols: PatrolsState } }
>(
  "patrols-load",
  async (
    {
      startDate,
      endDate,
      operatorsFilter,
      modelsFilter,
      validationStatusFilter,
      sort,
      order,
      pagesize,
      page,
    },
    { getState, requestId }
  ) => {
    const { patrolsRequestId, patrolsLoading } = getState().patrols;
    if (!patrolsLoading || requestId !== patrolsRequestId) {
      return {
        totalCount: 0,
        patrols: initialState.patrols,
      };
    }

    const query: any = {
      deleted: { $or: [{ $eq: false }, { $exists: false }] },
      startTime: {
        $gte: startDate.toISO(),
        $lte: endDate.toISO(),
      },
    };
    if (operatorsFilter.length) {
      query["operator._id"] = {
        $in: operatorsFilter.map((operator) => operator._id),
      };
    }
    if (modelsFilter.length) {
      query["model.identifier"] = {
        $in: modelsFilter.map((model) => model.identifier),
      };
    }
    query["$or"] = [
      { "operator.username": getState().auth.user?.username },
      { done: true },
    ];

    if (validationStatusFilter !== undefined) {
      query["done"] = validationStatusFilter === "InProgress" ? false : true;
    }

    const sorting = `${order === "asc" ? "+" : "-"}${sort}`;
    const pagination = {
      skip: page * pagesize,
      limit: pagesize,
    };
    console.log("Patrols query / sort / pagination", {
      query,
      sort: sorting,
      pagination,
    });

    const store = await getStore();
    return {
      totalCount: await store.Patrols.count(query),
      patrols: await store.Patrols.find(query, sorting, pagination),
    };
  }
);

export const deletePatrol = createAsyncThunk<
  // { totalCount: number; logbook: Patrol[] },
  void,
  Patrol,
  { state: { patrols: PatrolsState } }
>("patrols-delete", async (patrol, { getState, requestId }) => {
  const store = await getStore();
  await store.Patrols.save({ ...patrol, deleted: true });

  // return {
  //   totalCount: await store.Patrols.count(query),
  //   patrols: await store.Patrols.find(query, sorting, pagination),
  // };
});

const slice = createSlice({
  name: "patrols",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Locations
      .addCase(loadLocations.pending, (state, action) => {
        if (!state.locationsLoading) {
          state.locationsLoading = true;
          state.locationsRequestId = action.meta.requestId;
        }
      })
      .addCase(loadLocations.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (state.locationsLoading && state.locationsRequestId === requestId) {
          state.locationsLoading = false;
          state.locations = action.payload;
          // state.logbookTotalCount = action.payload.totalCount;
          state.locationsRequestId = undefined;
          state.locationsLoaded = true;
        }
      })
      .addCase(loadLocations.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (state.locationsLoading && state.locationsRequestId === requestId) {
          state.locationsLoading = false;
          // console.log("action.error", JSON.stringify(action.error, null, 2));
          state.error = action.error;
          state.locationsRequestId = undefined;
        }
      })
      // Models
      .addCase(loadModels.pending, (state, action) => {
        if (!state.modelsLoading) {
          state.modelsLoading = true;
          state.modelsRequestId = action.meta.requestId;
        }
      })
      .addCase(loadModels.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (state.modelsLoading && state.modelsRequestId === requestId) {
          state.modelsLoading = false;
          state.models = action.payload;
          // state.logbookTotalCount = action.payload.totalCount;
          state.modelsRequestId = undefined;
          state.modelsLoaded = true;
        }
      })
      .addCase(loadModels.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (state.modelsLoading && state.modelsRequestId === requestId) {
          state.modelsLoading = false;
          // console.log("action.error", JSON.stringify(action.error, null, 2));
          state.error = action.error;
          state.modelsRequestId = undefined;
        }
      })
      // Patrols
      .addCase(loadPatrols.pending, (state, action) => {
        if (!state.patrolsLoading) {
          state.patrolsLoading = true;
          state.patrolsRequestId = action.meta.requestId;
        }
      })
      .addCase(loadPatrols.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (state.patrolsLoading && state.patrolsRequestId === requestId) {
          state.patrolsLoading = false;
          state.patrols = action.payload.patrols;
          state.patrolsTotal = action.payload.totalCount;
          state.patrolsRequestId = undefined;
          state.patrolsLoaded = true;
        }
      })
      .addCase(loadPatrols.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (state.patrolsLoading && state.patrolsRequestId === requestId) {
          state.patrolsLoading = false;
          // console.log("action.error", JSON.stringify(action.error, null, 2));
          state.error = action.error;
          state.patrolsRequestId = undefined;
        }
      });
  },
});

export default slice;
