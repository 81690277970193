import {
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableCellProps,
  TableContainer,
  TableRow,
} from "@mui/material";
import { useNavigate } from "react-router";
import { AlarmLog } from "src/@types";
// import { AlarmLogTableRow } from "src/components/app/alarmlogs";
import Scrollbar from "src/components/generic/Scrollbar";
import {
  // TableHeadCell,
  TableHeadCustom,
  TableNoData,
} from "src/components/generic/table";
import { useFormat, useLocales, useTable } from "src/hooks";
import { TableColumnDefinition } from "src/hooks/useTable";
import { useSelector } from "src/redux/store";
import { PATH_DASHBOARD } from "src/routes/paths";
import AlarmStatusLabel from "../alarms/AlarmStatusLabel";
import { fShortenNumber } from "src/utils/formatNumber";
import { convertOperators } from "src/utils/str";
import LabelBadge from "src/components/generic/LabelBadge";

const LinkTableCell = (props: TableCellProps) => (
  <TableCell {...props} sx={{ cursor: "pointer" }} />
);

type Props = {
  items: AlarmLog[];
  sx?: SxProps;
};

export default function AlarmLogTable({ items, sx }: Props) {
  const { translate } = useLocales();
  const table = useTable();
  const { formatDate } = useFormat();
  const navigate = useNavigate();

  const { measures: allMeasures } = useSelector((state) => state.measures);
  const equipmentsLocationsState = useSelector(
    (state) => state.equipmentsLocations
  );

  const measures = allMeasures.filter((measure) =>
    items
      .map((item) => item.alarm.metadata.measureId)
      .includes(measure.identifier)
  );

  // const TABLE_HEAD: TableHeadCell[] = [
  //   {
  //     id: "alarm.status.lastChangeOn",
  //     label: translate("Date"),
  //     align: "left",
  //   },
  //   {
  //     id: "alarm.metadata.equipmentLabel",
  //     label: translate("Equipment"),
  //     align: "left",
  //   },
  //   {
  //     id: "alarm.metadata.locationLabel",
  //     label: translate("Location"),
  //     align: "left",
  //   },
  //   {
  //     id: "alarm.metadata.measureLabel",
  //     label: translate("Measure"),
  //     align: "left",
  //   },
  //   { id: "threshold", label: translate("Threshold"), align: "left" },
  //   {
  //     id: "alarm.status.extractionValue.measureValue",
  //     label: translate("Value"),
  //     align: "left",
  //   },
  //   { id: "alarm.status.active", label: translate("Status"), align: "left" },
  // ];

  const tableDefinition: TableColumnDefinition<AlarmLog>[] = [
    {
      head: {
        id: "alarm.status.lastChangeOn",
        label: translate("Date"),
        align: "left",
      },
      Cell: ({ item }: { item: AlarmLog }) => (
        <TableCell>{formatDate(item.alarm.status.lastChangeOn)}</TableCell>
      ),
    },
    {
      head: {
        id: "alarm.metadata.equipmentLabel",
        label: translate("Equipment"),
        align: "left",
      },
      Cell: ({ item }: { item: AlarmLog }) => (
        <LinkTableCell
          onClick={() =>
            navigate(
              PATH_DASHBOARD.measures.equipment(item.alarm.metadata.equipmentId)
            )
          }
        >
          <strong>{item.alarm.metadata.equipmentLabel}</strong>
        </LinkTableCell>
      ),
    },
    {
      head: {
        id: "alarm.metadata.locationLabel",
        label: translate("Location"),
        align: "left",
      },
      Cell: ({ item }: { item: AlarmLog }) => {
        const locationLabel = equipmentsLocationsState.equipmentsLocations.find(
          (e) => e.identifier === item.alarm.metadata.locationId
        )?.label;
        return (
          <LinkTableCell
            onClick={() =>
              navigate(
                PATH_DASHBOARD.measures.location(item.alarm.metadata.locationId)
              )
            }
          >
            <strong>{locationLabel}</strong>
          </LinkTableCell>
        );
      },
    },
    {
      head: {
        id: "alarm.metadata.measureLabel",
        label: translate("Measure"),
        align: "left",
      },
      Cell: ({ item }: { item: AlarmLog }) => {
        const measureLabel = measures.find(
          (m) => m.identifier === item.alarm.metadata.measureId
        )?.label;
        return (
          <LinkTableCell
            onClick={() =>
              navigate(
                PATH_DASHBOARD.measures.details(item.alarm.metadata.measureId)
              )
            }
          >
            {measureLabel ? measureLabel : item.alarm.metadata.measureLabel}
          </LinkTableCell>
        );
      },
    },
    {
      head: { id: "threshold", label: translate("Threshold"), align: "left" },
      Cell: ({ item }: { item: AlarmLog }) => (
        <TableCell sx={{ whiteSpace: "nowrap" }}>
          <LabelBadge
            sx={{ mr: 1 }}
            text={
              item.alarm.metadata.measureMachineName ||
              item.alarm.metadata.measureLabel
            }
          />
          {convertOperators(item.alarm.metadata.measureTrigger)}
        </TableCell>
      ),
    },
    {
      head: {
        id: "alarm.status.extractionValue.measureValue",
        label: translate("Value"),
        align: "left",
      },
      Cell: ({ item }: { item: AlarmLog }) => (
        <TableCell sx={{ whiteSpace: "nowrap" }}>
          {typeof item.alarm.status.extractionsValues.measureValue === "number"
            ? `${
                fShortenNumber(
                  item.alarm.status.extractionsValues.measureValue
                ) || 0
              } ${item.alarm.metadata.measureUnit || ""}`
            : typeof item.alarm.status.extractionsValues.measureValue ===
              "boolean"
            ? item.alarm.status.extractionsValues.measureValue.toString()
            : (item.alarm.status.extractionsValues.measureValue as string)}
        </TableCell>
      ),
    },
    {
      head: {
        id: "alarm.status.active",
        label: translate("Status"),
        align: "left",
      },
      Cell: ({ item }: { item: AlarmLog }) => (
        <TableCell>
          <AlarmStatusLabel
            priority={item.alarm.priority}
            active={item.alarm.status.active}
          />
        </TableCell>
      ),
    },
  ];

  return (
    <Scrollbar>
      <TableContainer
        sx={{
          ...sx,
          // minWidth: 800,
          position: "relative",
        }}
      >
        <Table size={table.dense ? "small" : "medium"}>
          <TableHeadCustom
            // headCells={TABLE_HEAD}
            headCells={tableDefinition.map((d) => d.head)}
            order={table.order}
            orderBy={table.orderBy}
            rowCount={items.length}
            onSort={table.onSort}
          />
          <TableBody>
            {items.map((item, index) => (
              <TableRow key={index} hover>
                {tableDefinition
                  .map((d) => d.Cell)
                  .map((Cell, index) => (
                    <Cell item={item} key={index} />
                  ))}
              </TableRow>
            ))}
            {/*items.map((item, index) => (
              <AlarmLogTableRow key={index} item={item} />
            ))*/}
            <TableNoData
              isNotFound={!items.length}
              label={translate("No active alarm found for this period.")}
            />
          </TableBody>
        </Table>
      </TableContainer>
    </Scrollbar>
  );
}
