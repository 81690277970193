import { Location } from "src/@types";
import axios from "src/utils/axios";

class LocationService {
  getEquipmentsLocations() {
    return axios.get<Location[]>("/equipments/locations");
  }
  getPatrolsModelsLocations() {
    return axios.get<Location[]>("/patrols-models/locations");
  }
}

const locationService = new LocationService();
export default locationService;
