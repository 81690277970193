import { Organization } from "src/@types";
import axios from "src/utils/axios";

class OrganizationService {
  get() {
    return axios.get<Organization>("/organization");
  }
}

const organizationService = new OrganizationService();
export default organizationService;
