import { MutableRefObject, useEffect, useRef, useState } from "react";

export function useRefDimensions(target: MutableRefObject<HTMLElement | null>) {
  const [size, setSize] = useState<{
    width: number | undefined;
    height: number | undefined;
  }>({ width: undefined, height: undefined });

  const observer = useRef(
    new ResizeObserver((entries: ResizeObserverEntry[]) => {
      if (!entries.length) return;
      const entry = entries[0];
      const bcr = entry.target.getBoundingClientRect();
      setSize({
        width: bcr.width,
        height: bcr.height,
      });
    })
  );

  useEffect(() => {
    const currentObserver = observer.current;
    if (target.current) {
      currentObserver.observe(target.current);
    }
    return () => {
      currentObserver.disconnect();
    };
  }, [target, observer]);

  return size;
}
