import { DateTime, Duration } from "luxon";
import { sprintf } from "sprintf-js";
import { Measure } from "../@types/Measure";

const shouldPrintLastRefreshDate = (diffNow: Duration): boolean => {
  return diffNow > Duration.fromISO("PT10M");
};

export const isMeasureOld = (measure: Measure): boolean => {
  const now = DateTime.now();
  if (!measure.data || !measure?.data.last) return true;
  const diffNow = now.diff(DateTime.fromISO(measure?.data.last.time));
  return shouldPrintLastRefreshDate(diffNow);
};

const displayLastRefresh = (
  lastDataDateString: string,
  translate: (arg0: string) => string,
  printOnlyIfOld: boolean = false
) => {
  if (!lastDataDateString) return;
  const lastData = DateTime.fromISO(lastDataDateString);
  const now = DateTime.now();
  const diff = now.diff(lastData);
  if (printOnlyIfOld && !shouldPrintLastRefreshDate(diff)) return;
  if (diff < Duration.fromISO("P1D"))
    return diff.as("hours") < 1
      ? diff.as("minutes") < 1
        ? translate("Just now")
        : diff.as("minutes") < 2
        ? translate("1 minute ago")
        : sprintf(translate("%d minutes ago"), diff.as("minutes"))
      : diff.as("hours") < 2
      ? translate("One hour ago")
      : sprintf(translate("%d hours ago"), diff.as("hours"));
  else
    return diff.as("days") < 1
      ? sprintf(translate("%d hours ago"), diff.as("hours"))
      : diff.as("days") < 2
      ? translate("One day ago")
      : sprintf(translate("%d days ago"), diff.as("days"));
};

const displayDateFromLocale = (locale: string, dateString: string) => {
  const isoDate = DateTime.fromISO(dateString);
  return isoDate
    .setLocale(locale)
    .toLocaleString(DateTime.DATETIME_MED_WITH_SECONDS);
};

export const displayCompleteDateFromLocale = (
  locale: string = "fr",
  dateString: string,
  translate: (arg0: string) => string
): string => {
  if (!dateString) return translate("No data");
  return `${displayDateFromLocale(locale, dateString)} (${displayLastRefresh(
    dateString,
    translate,
    false
  )})`;
};

export default displayLastRefresh;
