import { ElementType, lazy, Suspense } from "react";
import {
  matchRoutes,
  Navigate,
  RouteObject,
  useLocation,
  useRoutes,
} from "react-router-dom";
import LoadingScreen from "src/components/generic/LoadingScreen";
import { AuthGuard, GuestGuard } from "src/guards";
import DashboardLayout from "src/layouts/dashboard";
import LogoOnlyLayout from "src/layouts/LogoOnlyLayout";
import { AlarmLogList, AlarmStatus } from "src/pages/alarm";
import {
  LogbookCreate,
  LogbookList,
  LogbookShow,
  LogbookUpdate,
} from "src/pages/logbook";
import {
  DemoMeasures,
  EquipmentDomainOverview,
  EquipmentOverview,
  EquipmentTypeLocationOverview,
  EquipmentTypeOverview,
  LocationOverview,
  MeasureDetails,
} from "src/pages/measures";
import {
  Patrol,
  PatrolCreate,
  PatrolOverview,
  PatrolsList,
} from "src/pages/patrols";
import { useSelector } from "src/redux/store";

// ----------------------------------------------------------------------

const Loadable = (Component: ElementType) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const authState = useSelector((state) => state.auth);

  const isDashboard = pathname.includes("/dashboard") && !!authState.user;

  return (
    <Suspense fallback={<LoadingScreen isDashboard={isDashboard} />}>
      <Component {...props} />
    </Suspense>
  );
};

let rootRoutes: RouteObject[] | undefined = undefined;

export default function Router() {
  const authState = useSelector((state) => state.auth);
  //
  // If current path matches any of the following paths,
  // the page will be set as "full height"
  //
  const { pathname } = useLocation();
  const fullHeightPaths = [
    // /^\/measures\/(?:[^\/]*)$/,
    /^\/patrols\/(?:.*)\/(?:view|edit)\/(?:.*)$/,
  ];
  const fullHeight = fullHeightPaths.reduce(
    (acc, path) => acc || path.test(pathname),
    false
  );
  //
  // Routes definition
  //
  const routes: RouteObject[] = [
    {
      path: "auth",
      children: [
        {
          path: "login",
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        // { path: "login-unprotected", element: <Login /> },
        // { path: "reset-password", element: <ResetPassword /> },
        // { path: "new-password", element: <NewPassword /> },
        // { path: "verify", element: <VerifyCode /> },
      ],
    },
    // Dashboard routes
    {
      path: "/",
      element: (
        <AuthGuard>
          <DashboardLayout fullHeight={fullHeight} />
        </AuthGuard>
      ),
      children: [
        {
          index: true,
          element: (
            <Navigate
              to={
                authState.user?.accessRights.includes("dashboard")
                  ? "/dashboard"
                  : "/favorites"
              }
              replace
            />
          ),
        },
        // authState.user?.accessRights.includes("dashboard")
        //   ? { path: "dashboard", element: <Dashboard /> }
        //   : undefined,
        { path: "dashboard", element: <Dashboard /> },
        { path: "favorites", element: <Favorites /> },
        {
          path: "user",
          children: [
            { index: true, element: <Navigate to="/user/account" replace /> },
            { path: "account", element: <MyAccount /> },
            { path: "list", element: <UserList /> },
            { path: "create", element: <UserCreate /> },
            { path: ":id/edit", element: <UserEdit /> },
          ],
        },
        {
          path: "measures",
          children: [
            { index: true, element: <Navigate to="/measures/demo" replace /> },
            { path: "location/:locationId", element: <LocationOverview /> },
            {
              path: "equipment_type/:typeId",
              element: <EquipmentTypeOverview />,
            },
            {
              path: "equipment_type/:typeId/location/:locationId",
              element: <EquipmentTypeLocationOverview />,
            },
            {
              path: "equipment",
              children: [
                {
                  path: ":equipmentId/overview",
                  element: <EquipmentOverview />,
                },
                {
                  path: ":equipmentId/domain/:domainId",
                  element: <EquipmentDomainOverview />,
                },
              ],
            },
            { path: ":id", element: <MeasureDetails /> },
            { path: "demo", element: <DemoMeasures /> },
          ],
        },
        {
          path: "alarms",
          children: [
            { index: true, element: <Navigate to="/alarms/log" replace /> },
            { path: "log", element: <AlarmLogList /> },
            { path: "status", element: <AlarmStatus /> },
          ],
        },
        {
          path: "logbook",
          children: [
            {
              index: true,
              element: <Navigate to="/logbook/list" replace />,
            },
            { path: "list", element: <LogbookList /> },
            { path: "create", element: <LogbookCreate /> },
            { path: "update/:id", element: <LogbookUpdate /> },
            { path: "show/:id", element: <LogbookShow /> },
          ],
        },
        {
          path: "patrols",
          children: [
            {
              index: true,
              element: <Navigate to="/patrols/list" replace />,
            },
            { path: "list", element: <PatrolsList /> },
            { path: "create", element: <PatrolCreate /> },
            {
              path: ":patrolId/edit",
              // element: <Patrol displayMode="edit" />,
              Component: () => <Patrol displayMode="edit" />,
              children: [
                {
                  path: ":measureId",
                  // element: <Patrol displayMode="edit" />,
                  Component: () => <Patrol displayMode="edit" />,
                },
              ],
            },
            {
              path: ":patrolId/view",
              // element: <Patrol displayMode="view" />,
              Component: () => <Patrol displayMode="view" />,
              children: [
                {
                  path: ":measureId",
                  // element: <Patrol displayMode="view" />,
                  Component: () => <Patrol displayMode="view" />,
                },
              ],
            } as RouteObject,
            {
              path: ":patrolId/overview",
              element: <PatrolOverview />,
            },
          ],
        },
        { path: "permission-denied", element: <PermissionDenied /> },
      ].filter((e) => e) as RouteObject[],
    },
    // Main Routes
    {
      path: "*",
      element: <LogoOnlyLayout />,
      children: [
        { path: "coming-soon", element: <ComingSoon /> },
        { path: "maintenance", element: <Maintenance /> },
        { path: "404", element: <Page404 /> },
        { path: "403", element: <Page403 /> },
        { path: "*", element: <Navigate to="/404" replace /> },
      ],
    },
    { path: "*", element: <Navigate to="/404" replace /> },
  ];
  rootRoutes = routes;
  return useRoutes(routes);
}

// AUTHENTICATION
const Login = Loadable(lazy(() => import("../pages/auth/Login")));
// const ResetPassword = Loadable(
//   lazy(() => import("../pages/auth/ResetPassword"))
// );
// const NewPassword = Loadable(lazy(() => import("../pages/auth/NewPassword")));
// const VerifyCode = Loadable(lazy(() => import("../pages/auth/VerifyCode")));

// DASHBOARD

// GENERAL
const Dashboard = Loadable(lazy(() => import("../pages/Dashboard")));
const Favorites = Loadable(lazy(() => import("../pages/Favorites")));

// USER
const MyAccount = Loadable(lazy(() => import("../pages/user/MyAccount")));
const UserList = Loadable(lazy(() => import("../pages/user/UserList")));
const UserCreate = Loadable(lazy(() => import("../pages/user/UserCreate")));
const UserEdit = Loadable(lazy(() => import("../pages/user/UserEdit")));

// TEST RENDER PAGE BY ROLE
const PermissionDenied = Loadable(
  lazy(() => import("../pages/user/PermissionDenied"))
);

// MAIN
const ComingSoon = Loadable(lazy(() => import("../pages/ComingSoon")));
const Maintenance = Loadable(lazy(() => import("../pages/Maintenance")));
const Page403 = Loadable(lazy(() => import("../pages/Page403")));
const Page404 = Loadable(lazy(() => import("../pages/Page404")));

export const findRouteFromPath = (path: string) => {
  if (!rootRoutes) {
    throw new Error("Le drame...");
  }
  const matchedRoutes = matchRoutes(rootRoutes, path);
  // console.log("matchedRoutes", matchedRoutes);
  const matchedRoute = matchedRoutes?.length
    ? matchedRoutes[matchedRoutes.length - 1]
    : undefined;
  // console.log("matchedRoute", matchedRoute);
  return matchedRoute?.route;
};
