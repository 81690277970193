import { createAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Socket, io } from "socket.io-client";
import { AuthState } from "./auth";

interface SocketIoState {
  connecting: boolean;
  connected: boolean;
  duration?: string;
  nextLoadIn?: string;
  dataReady: boolean;
  error?: Error;
}

const initialState: SocketIoState = {
  connecting: false,
  connected: false,
  dataReady: false,
};

// Actions
const socketioActions = {
  error: createAction("socketio-error", (payload: { error: any }) => ({
    payload,
  })),
  connecting: createAction("socketio-connecting"),
  connected: createAction("socketio-connected"),
  disconnected: createAction(
    "socketio-disconnected",
    (payload: { reason: string }) => ({
      payload,
    })
  ),
  authenticated: createAction("socketio-authenticated"),
  dataReady: createAction("socketio-dataReady"),
  // dataLoaded: createAction("socketio-dataLoaded"),
  tick: createAction(
    "socketio-tick",
    (payload: { duration: string; nextIn: string }) => ({
      payload,
    })
  ),
};

// Socket
interface ServerToClientEvents {
  "authentication:error": (message: any) => void;
  "authentication:success": () => void;
  "data-load-tick": (data: any) => void;
  "data-loaded": () => void;
}
interface ClientToServerEvents {}
let socket: Socket<ServerToClientEvents, ClientToServerEvents> | undefined;

export const start = createAsyncThunk<
  void,
  undefined,
  {
    state: { socketIo: SocketIoState; auth: AuthState };
  }
>("socketio-start", async (arg, { dispatch, getState }) => {
  if (socket) return;
  const { user } = getState().auth;
  if (!user) {
    return;
  }
  socket = io(window.env.REACT_APP_EYE_BACKEND_URL || "", {
    autoConnect: false,
    // transports: ["websocket"],
    auth: { token: user.token },
  });
  socket.io.on("error", (error) => {
    dispatch(socketioActions.error({ error }));
  });
  socket.on("connect_error", (error) => {
    dispatch(socketioActions.error({ error }));
  });
  socket.on("connect", () => {
    dispatch(socketioActions.connected());
  });
  socket.io.on("reconnect_attempt", () => {
    dispatch(socketioActions.connecting());
  });
  socket.on("disconnect", (reason) => {
    dispatch(socketioActions.disconnected({ reason }));
  });
  socket.on("authentication:error", (message) => {
    dispatch(socketioActions.error({ error: new Error(message) }));
  });
  socket.on("authentication:success", () => {
    dispatch(socketioActions.authenticated());
  });
  socket.on("data-load-tick", (data) => {
    dispatch(
      socketioActions.tick({
        duration: data.duration,
        nextIn: data.nextIn,
      })
    );
  });
  socket.on("data-loaded", () => {
    dispatch(socketioActions.dataReady());
  });
  dispatch(socketioActions.connecting());
  socket.connect();
});

export const stop = createAction("socketio-stop");
// export const stop = createAsyncThunk<void, undefined>(
//   "socketio-stop",
//   async () => {
//     if (!socket) return;
//     // Remove all listeners
//     socket.offAny();
//     // Close connection
//     socket.disconnect();
//   }
// );

export const clearDataReady = createAction("socketio-clear-data-ready");

const slice = createSlice({
  name: "socket.io",
  initialState,
  reducers: {
    dataLoaded: (state) => {
      state.dataReady = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(socketioActions.connecting, (state, action) => {
        console.log("Socket.io", "connecting...");
        state.connecting = true;
      })
      .addCase(socketioActions.connected, (state, action) => {
        console.log("Socket.io", "connected.");
        state.connected = true;
        state.connecting = false;
      })
      .addCase(socketioActions.disconnected, (state, action) => {
        console.log("Socket.io", "disconnected.", action.payload.reason);
        state.connected = false;
        state.connecting = false;
        if (action.payload.reason === "io server disconnect") {
          socket = undefined;
        }
      })
      .addCase(socketioActions.authenticated, (state, action) => {
        console.log("Socket.io", "authenticated!");
      })
      .addCase(socketioActions.tick, (state, action) => {
        // console.log("Socket.io", "tick", action.payload);
        state.duration = action.payload.duration;
        state.nextLoadIn = action.payload.nextIn;
      })
      .addCase(socketioActions.dataReady, (state, action) => {
        console.log("Socket.io", "data ready");
        state.dataReady = true;
      })
      .addCase(socketioActions.error, (state, action) => {
        console.error("Socket.io", "error", action.payload.error);
      })
      .addCase(stop, () => {
        console.error("Socket.io", "stop");
        if (!socket) return;
        // Remove all listeners
        socket.offAny();
        // Close connection
        socket.disconnect();
      })
      .addCase(clearDataReady, (state, action) => {
        console.log("Socket.io", "clear data ready");
        state.dataReady = false;
      });
  },
});

export default slice;
