import { Box, TablePagination, TablePaginationProps } from "@mui/material";
import { styled } from "@mui/material";
import { useTheme } from "@mui/material";
import { Stack } from "@mui/material";
import { ReactNode } from "react";

const RootStyle = styled("div")(({ theme }) => ({
  borderTop: `solid 1px ${theme.palette.divider}`,
}));

type TableFooterProps = TablePaginationProps & {
  children?: ReactNode;
};

export default function TableFooter({ children, ...props }: TableFooterProps) {
  const theme = useTheme();
  return (
    <RootStyle className="TableFooter-root">
      <Stack
        direction="row"
        alignItems="stretch"
        justifyContent="space-between"
        sx={{ px: 1 }}
      >
        <Box sx={{ p: 1, flex: 1, display: "flex", alignItems: "center" }}>
          {children}
        </Box>
        <TablePagination {...props} />
      </Stack>
    </RootStyle>
  );
}
