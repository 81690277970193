import {
  Box,
  Button,
  Container,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Step,
  StepContent,
  TextField,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { SHA256 } from "crypto-js";
import { DateTime } from "luxon";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Location, Patrol, PatrolModel, Step as StepType } from "src/@types";
import { ChoiceStep, Step as StepComponent } from "src/components/generic/step";
import HeaderBreadcrumbs from "src/components/generic/HeaderBreadcrumbs";
import LoadingAnimation from "src/components/generic/LoadingAnimation";
import StepButton from "src/components/generic/stepper/StepButton";
import Page from "src/components/generic/Page";
import StyledStepper from "src/components/generic/stepper/StyledStepper";
import { useLocales, useResponsiveShortcut, useStore } from "src/hooks";
import { useSelector } from "src/redux/store";
import { PATH_DASHBOARD } from "src/routes/paths";

export default function PatrolCreate() {
  const theme = useTheme();
  const navigate = useNavigate();
  const { translate } = useLocales();
  const { isSmUp } = useResponsiveShortcut();
  const settingsState = useSelector((state) => state.settings);
  const { getStore } = useStore();
  const authState = useSelector((state) => state.auth);
  const { locations: patrolsLocations, models: patrolsModels } = useSelector(
    (state) => state.patrols
  );
  const [activeStep, setActiveStep] = useState(0);
  const [locations, setLocations] = useState<Location[]>([]);
  const [location, setLocation] = useState<Location>();
  const [models, setModels] = useState<PatrolModel[]>([]);
  const [model, setModel] = useState<PatrolModel>();
  const [startTimeMode, setStartTimeMode] = useState<"auto" | "manual">("auto");
  const [startTime, setStartTime] = useState<DateTime>(DateTime.now());
  // const [completed, setCompleted] = useState<any>({});
  // const [allStepsCompleted, setAllStepsCompleted] = useState(false);
  // const [patrol, setPatrol] = useState<Patrol | null>(null);
  const [loading, setLoading] = useState(false);

  const steps: StepType[] = useMemo(
    () => [
      {
        label: translate("Location"),
        component: (
          <ChoiceStep
            title={translate("Location")}
            choices={locations}
            emptySentence={translate("No location found")}
            isSelected={(_location) =>
              _location !== undefined &&
              location !== undefined &&
              _location.identifier === location.identifier
            }
            onChange={(location) => {
              setLocation(location);
              setModel(undefined);
              nextStep();
            }}
          />
        ),
        value: location?.label,
      },
      {
        label: translate("Model"),
        component: (
          <ChoiceStep
            title={translate("Model")}
            choices={models}
            emptySentence={translate("No model found")}
            isSelected={(_model) =>
              _model !== undefined &&
              model !== undefined &&
              _model.identifier === model.identifier
            }
            onChange={(model) => {
              setModel(model);
              nextStep();
            }}
            // goToPreviousStep={() => previousStep()}
          />
        ),
        value: model?.label,
      },
      {
        label: translate("Start date"),
        component: (
          <StepComponent
            title={translate("Start date")}
            // goToPreviousStep={() => previousStep()}
            actionComponent={
              <Button variant="outlined" onClick={() => handleCreate()}>
                {translate("Create patrol")}
              </Button>
            }
          >
            <Stack gap={2}>
              <RadioGroup
                // aria-labelledby="demo-radio-buttons-group-label"
                defaultValue={startTimeMode}
                value={startTimeMode}
                // name="startTime"
                // onChange={() => {
                //   setStartTimeMode("auto")
                //   handleComplete();
                // }}
                sx={{ gap: 2 }}
              >
                <FormControlLabel
                  sx={{ alignItems: "flex-start" }}
                  value="auto"
                  control={<Radio />}
                  label={
                    <Box>
                      <Typography>{translate("Now")}</Typography>
                      <Typography variant="body2">
                        {translate("I am starting the patrol")}
                      </Typography>
                    </Box>
                  }
                  onChange={() => setStartTimeMode("auto")}
                />
                <FormControlLabel
                  sx={{ alignItems: "flex-start" }}
                  value="manual"
                  control={<Radio />}
                  label={
                    <Stack gap={2} /*direction={{ xs: "column", sm: "row" }}*/>
                      <Box>
                        <Typography>{translate("Past")}</Typography>
                        <Typography variant="body2">
                          {translate(
                            "I already finished the patrol, I just need to enter it"
                          )}
                        </Typography>
                      </Box>
                      {startTimeMode === "manual" && (
                        <DateTimePicker
                          renderInput={(props) => <TextField {...props} />}
                          label={translate("Patrol start")}
                          disableFuture={true}
                          // maxDateTime={DateTime.now().plus(
                          //   Duration.fromISO("PT1S")
                          // )}
                          value={startTime}
                          onChange={(newValue) => {
                            if (!newValue) return;
                            setStartTime(newValue);
                          }}
                        />
                      )}
                    </Stack>
                  }
                  onChange={() => setStartTimeMode("manual")}
                />
              </RadioGroup>
              {/*!completed[2] && (
                <Button variant="outlined" onClick={() => handleComplete()}>
                  {translate("Apply")}
                </Button>
              )*/}
            </Stack>
          </StepComponent>
        ),
        // value:
        //   startTimeMode === "auto"
        //     ? translate("Now")
        //     : startTime?.toLocaleString(DateTime.DATETIME_SHORT),
      },
    ],
    [location, model, startTime, startTimeMode, translate]
  );

  // const allStepsCompleted = useMemo(
  //   () => steps.filter((s) => s.value !== undefined).length === steps.length,
  //   [steps]
  // );

  useEffect(() => {
    setLoading(!patrolsLocations.length);
    setLocations(patrolsLocations);
  }, [patrolsLocations]);

  useEffect(() => {
    if (location) {
      setModels(
        patrolsModels.filter(
          (model) => model.locationId === location.identifier
        )
      );
    }
  }, [location, patrolsModels]);

  useEffect(() => {
    if (startTimeMode === "manual" && !startTime) setStartTime(DateTime.utc());
  }, [startTime, startTimeMode]);

  const nextStep = () => {
    setActiveStep(activeStep + 1);
  };

  const previousStep = () => {
    if (activeStep >= 1) setActiveStep(activeStep - 1);
  };

  const stepClicked = (index: number) => {
    if (index === 0 || steps[index - 1].value !== undefined) {
      setActiveStep(index);
    }
  };

  const handleCreate = async () => {
    if (!authState.user || !location || !model || !startTime) return;
    const patrol: Patrol = {
      _id: SHA256(DateTime.now().toISO()).toString(),
      operator: {
        _id: authState.user._id,
        firstName: authState.user.firstName,
        lastName: authState.user.lastName,
        username: authState.user.username,
      },
      model: model,
      location: location,
      measures: model.measures.map((measureModel) => ({
        ...measureModel,
        value: undefined,
        done: false,
        inputTime: undefined,
        active: measureModel.active || false,
      })),
      startTimeMode: startTimeMode,
      startTime: (startTimeMode === "auto"
        ? DateTime.utc()
        : startTime
      ).toISO(),
      progressPercent: 0,
      done: false,
      synced: false,
      createdOn: "",
      isNew: true,
    };
    const store = await getStore();
    const savedPatrol = await store.Patrols.save(patrol);
    console.log("savedPatrol", savedPatrol);
    navigate(PATH_DASHBOARD.patrols.edit(savedPatrol._id));
  };

  return (
    <Page title={translate("Patrols: create")}>
      <Container maxWidth={settingsState.themeStretch ? false : "xl"}>
        <HeaderBreadcrumbs
          heading={translate("Patrols: create")}
          links={[
            {
              name: translate("Home"),
              href: PATH_DASHBOARD.root,
            },
            {
              name: translate("Tools"),
            },
            {
              name: translate("Patrols"),
              href: PATH_DASHBOARD.patrols.list,
            },
            {
              name: translate("Create"),
            },
          ]}
        />
        <Stack gap={4}>
          <StyledStepper
            nonLinear
            alternativeLabel={isSmUp}
            orientation={isSmUp ? "horizontal" : "vertical"}
            activeStep={activeStep}
          >
            {steps.map((step, index) => (
              <Step key={index} completed={steps[index].value !== undefined}>
                <StepButton
                  step={step}
                  index={index}
                  active={activeStep === index}
                  completed={steps[index].value !== undefined}
                  onClick={() => stepClicked(index)}
                >
                  <Typography
                    sx={{
                      color:
                        activeStep === index
                          ? theme.palette.primary.main
                          : theme.palette.grey[600],
                      fontWeight: "bold",
                    }}
                  >
                    {step.label}
                  </Typography>
                  <Typography variant="body2" sx={{ minHeight: "2em" }}>
                    {step.value || ""}
                  </Typography>
                </StepButton>
                {!isSmUp ? (
                  <StepContent>
                    {loading ? (
                      <Box sx={{ height: "20vh", position: "relative" }}>
                        <LoadingAnimation
                          sx={{
                            backgroundColor: "#ffffff00",
                            transform: "scale(0.7)",
                          }}
                        />
                      </Box>
                    ) : (
                      steps[activeStep].component
                    )}
                  </StepContent>
                ) : null}
              </Step>
            ))}
          </StyledStepper>
          {isSmUp ? (
            loading ? (
              <Box sx={{ height: "20vh", position: "relative" }}>
                <LoadingAnimation
                  sx={{
                    backgroundColor: "#ffffff00",
                    transform: "scale(0.7)",
                  }}
                />
              </Box>
            ) : (
              steps[activeStep].component
            )
          ) : null}
        </Stack>
      </Container>
    </Page>
  );
}
