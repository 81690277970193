import { MenuItem, SelectProps, TextField } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import { TimeSelectorInterval } from "src/@types/Organization";
import Iconify from "./Iconify";
import { Duration } from "luxon";

const StyledTextField = styled(TextField)(({ theme }) => ({
  ".MuiSelect-iconOpen": {
    transform: "rotate(0deg)",
  },
  [theme.breakpoints.down("sm")]: {
    "&.MuiFormControl-root": {
      width: "100%",
    },
  },
  ".MuiInputBase-root": {
    width: "100%",
  },
}));

type DateRangeSelectProps = {
  intervals: TimeSelectorInterval[];
  value: Duration | null;
  onChange: (interval: Duration | null) => void;
} & Omit<SelectProps, "onChange" | "value">;

const DateRangeSelect = ({
  label,
  intervals,
  value,
  onChange,
  sx,
  ...other
}: DateRangeSelectProps) => {
  const theme = useTheme();
  if (!value) return null;
  if (!intervals.length) return null;
  if (
    !intervals.map((interval) => interval.identifier).includes(value.toISO())
  ) {
    onChange(Duration.fromISO(intervals[0].identifier));
  }
  return (
    <StyledTextField
      className="DateRangeSelect-root"
      select
      label={label}
      value={value}
      onChange={(e) => onChange(Duration.fromISO(e.target.value as string))}
      SelectProps={{
        IconComponent: (props) => (
          <Iconify
            icon={"material-symbols:date-range"}
            sx={{ fontSize: 24, color: theme.palette.grey[500], mr: 2.4 }}
            {...props}
          />
        ),
      }}
      sx={sx}
    >
      {intervals.map((interval) => (
        <MenuItem
          value={interval.identifier}
          key={interval.identifier}
          selected={interval.identifier === value.toISO()}
        >
          {interval.label}
        </MenuItem>
      ))}
    </StyledTextField>
  );
};

export default DateRangeSelect;
