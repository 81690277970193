import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { userService } from "src/services";
import { AuthState } from "./auth";
import { User } from "src/@types";

export interface FavoritesState {
  measuresIds: string[];
}

const initialState: FavoritesState = {
  measuresIds: [],
};

export const load = createAsyncThunk<
  FavoritesState,
  User | undefined,
  {
    state: { auth: AuthState };
  }
>("favorites-load", async (userParam, { getState }) => {
  const user = userParam || getState().auth.user;
  if (!user) {
    throw new Error("User is undefined!");
  }
  const response = await userService.getFavorites(user._id);
  return { measuresIds: response.data };
});

export const clear = createAsyncThunk<FavoritesState>(
  "favorites-clear",
  async () => ({
    measuresIds: [],
  })
);

export const add = createAsyncThunk<
  FavoritesState,
  string,
  {
    state: { auth: AuthState; favorites: FavoritesState };
  }
>("favorites-add", (measureId, { getState }) => {
  const { user } = getState().auth;
  if (!user) {
    throw new Error();
  }
  const newFavorites = [...getState().favorites.measuresIds, measureId];
  userService.saveFavorites(newFavorites, user._id);
  return { measuresIds: newFavorites };
});

export const remove = createAsyncThunk<
  FavoritesState,
  string,
  {
    state: { auth: AuthState; favorites: FavoritesState };
  }
>("favorites-remove", (measureId, { getState }) => {
  const { user } = getState().auth;
  if (!user) {
    throw new Error();
  }
  const newFavorites = getState().favorites.measuresIds.filter(
    (mId) => mId !== measureId
  );
  userService.saveFavorites(newFavorites, user._id);
  return { measuresIds: newFavorites };
});

export const toggle = createAsyncThunk<
  FavoritesState,
  string,
  {
    state: { auth: AuthState; favorites: FavoritesState };
  }
>("favorites-toggle", (measureId, { getState }) => {
  const { user } = getState().auth;
  if (!user) {
    throw new Error();
  }
  const measuresIds = getState().favorites.measuresIds;
  const newFavorites = measuresIds.includes(measureId)
    ? measuresIds.filter((mId) => mId !== measureId)
    : [...measuresIds, measureId];
  userService.saveFavorites(newFavorites, user._id);
  return { measuresIds: newFavorites };
});

const slice = createSlice({
  name: "favorites",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // .addCase(load.pending, (state, action) => {})
      .addCase(load.fulfilled, (state, action) => {
        state.measuresIds = action.payload.measuresIds;
      })
      .addCase(load.rejected, (state, action) => {
        console.error("Error while loading favorites!", action.error);
      })
      // .addCase(clear.pending, (state, action) => {})
      .addCase(clear.fulfilled, (state, action) => {
        state.measuresIds = action.payload.measuresIds;
      })
      .addCase(clear.rejected, (state, action) => {
        console.error("Error while clearing favorites!", action.error);
      })
      // .addCase(add.pending, (state, action) => {})
      .addCase(add.fulfilled, (state, action) => {
        state.measuresIds = action.payload.measuresIds;
      })
      .addCase(add.rejected, (state, action) => {
        console.error("Error while adding favorite!", action.error);
      })
      // .addCase(remove.pending, (state, action) => {})
      .addCase(remove.fulfilled, (state, action) => {
        state.measuresIds = action.payload.measuresIds;
      })
      .addCase(remove.rejected, (state, action) => {
        console.error("Error while removing favorite!", action.error);
      })
      // .addCase(toggle.pending, (state, action) => {})
      .addCase(toggle.fulfilled, (state, action) => {
        state.measuresIds = action.payload.measuresIds;
      })
      .addCase(toggle.rejected, (state, action) => {
        console.error("Error while toggling favorite!", action.error);
      });
  },
});

// export const { addMeasure, removeMeasure, toggleMeasure } = slice.actions;

export default slice;
