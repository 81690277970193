import { Box, Card, Container, Stack, Typography } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import { CSSProperties } from "react";
import { LoginForm } from "src/components/app/auth/login";
import Logo from "src/components/generic/Logo";
import Page from "src/components/generic/Page";
import { useLocales, useResponsiveShortcut } from "src/hooks";
import { useSelector } from "src/redux/store";

import logoWhite from "src/assets/logo/MOBAPI-logo-blanc-1000px.png";
import logoBlue from "src/assets/logo/MOBAPI-logo-bleu-1000px.png";
import bg from "src/assets/illustrations/eye-login-bg.jpg";

const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: "100%",
  maxWidth: 464,
  display: "flex",
  flexDirection: "column",
  justifyContent: "end",
  margin: theme.spacing(2, 0, 2, 2),
  padding: theme.spacing(5, 5, 5, 5),
  // backgroundImage: `url(/assets/illustrations/eye-login-bg.jpg)`,
  backgroundImage: `url(${bg})`,
  backgroundSize: "cover",
}));

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  minHeight: "100vh",
  display: "flex",
  flexDirection: "column",
  // padding: theme.spacing(12, 0),
  justifyContent: "center",
}));

// ----------------------------------------------------------------------

export default function Login() {
  const settingsState = useSelector((state) => state.settings);
  const theme = useTheme();
  const { translate } = useLocales();
  const { isMdUp } = useResponsiveShortcut();

  const fontWeight = { fontWeight: 900 } as CSSProperties;

  return (
    <Page title={translate("Login")}>
      <RootStyle>
        {isMdUp && (
          <SectionStyle>
            <Box
              component="img"
              // src="/logo/MOBAPI-logo-blanc-1000px.png"
              src={logoWhite}
              sx={{ width: "40%", mb: 2 }}
            />
            <Typography variant="h3" color={theme.palette.common.white}>
              “
              {
                // translate("Digital transformation for the energy of the future")
                translate("A 360° view of your industrial assets data")
              }
              ”
            </Typography>
          </SectionStyle>
        )}

        <Container maxWidth="sm">
          <ContentStyle>
            <Box
              sx={{
                height: "33vh",
                display: "flex",
                alignItems: "flex-end",
                py: "60px",
              }}
            >
              {!isMdUp && (
                <Box
                  component="img"
                  // src={
                  //   settingsState.themeMode === "dark"
                  //     ? "/logo/MOBAPI-logo-blanc-1000px.png"
                  //     : "/logo/MOBAPI-logo-bleu-1000px.png"
                  // }
                  src={
                    settingsState.themeMode === "dark" ? logoWhite : logoBlue
                  }
                  sx={{ width: "60%" }}
                />
              )}
            </Box>
            <Box sx={{ height: "33vh" }}>
              <Stack direction="row" alignItems="center" sx={{ mb: 5 }}>
                <Box sx={{ flexGrow: 1 }}>
                  <Typography variant="h4" gutterBottom>
                    {translate("Connection to")}
                    <span>
                      &nbsp;mobapi.
                      <span style={fontWeight}>EYE</span>
                    </span>
                  </Typography>
                </Box>
                <Logo />
              </Stack>
              <LoginForm />
            </Box>
            <Box sx={{ height: "33vh" }}></Box>
          </ContentStyle>
        </Container>
      </RootStyle>
    </Page>
  );
}
