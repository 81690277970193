import { Container } from "@mui/material";
import { DateTime } from "luxon";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { PatrolsList as PatrolsListCmp } from "src/components/app/patrols";
import DateRangePicker from "src/components/generic/DateRangePicker";
import HeaderBreadcrumbs from "src/components/generic/HeaderBreadcrumbs";
import Page from "src/components/generic/Page";
import { useLocales } from "src/hooks";
import { set as setTimeControl } from "src/redux/slices/patrolsTimeControl";
import { dispatch, useSelector } from "src/redux/store";
import { PATH_DASHBOARD } from "src/routes/paths";

export default function PatrolsList() {
  const { translate } = useLocales();
  const organizationState = useSelector((state) => state.organization);
  const settingsState = useSelector((state) => state.settings);
  const timeControlState = useSelector((state) => state.patrolsTimeControl);
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const search = useLocation().search;
  const searchString = new URLSearchParams(search).get("search") || "";

  return (
    <Page title={translate("Patrols")}>
      <Container maxWidth={settingsState.themeStretch ? false : "xl"}>
        <HeaderBreadcrumbs
          heading={translate("Patrols")}
          links={[
            {
              name: translate("Home"),
              href: PATH_DASHBOARD.root,
            },
            {
              name: translate("Tools"),
            },
            {
              name: translate("Patrols"),
            },
            {
              name: translate("List"),
            },
          ]}
          action={
            <DateRangePicker
              label={translate("Display time range")}
              intervals={
                organizationState.organization?.timeSelectorIntervals
                  ?.details || []
              }
              maxDate={DateTime.now()}
              open={openDatePicker}
              setOpen={setOpenDatePicker}
              format={"f"}
              sx={{ width: { xs: "100%", sm: "24rem" } }}
              value={timeControlState.timeControl}
              onChange={(timeControl) => dispatch(setTimeControl(timeControl))}
            />
          }
        />
        <PatrolsListCmp searchString={searchString} />
      </Container>
    </Page>
  );
}
