import {
  Autocomplete,
  Box,
  InputAdornment,
  Stack,
  TextField,
} from "@mui/material";
import { ReactNode, useMemo, useState } from "react";
import { useLocales } from "src/hooks";
import Iconify from "src/components/generic/Iconify";
import { useSelector } from "src/redux/store";
import { Equipment } from "src/@types";

type AutocompleteEquipment = {
  label: string;
  value: string;
  group: string;
};

type Props = {
  filterEquipments: Equipment[];
  onFilterEquipments: (value: Equipment[]) => void;
  filterSearch: string;
  onFilterSearch: (value: string) => void;
  children?: ReactNode;
};

export default function AlarmLogTableToolbar({
  onFilterSearch,
  filterSearch,
  onFilterEquipments,
  children,
}: Props) {
  const { translate } = useLocales();
  const equipmentsState = useSelector((state) => state.equipments);
  const equipmentsLocationsState = useSelector(
    (state) => state.equipmentsLocations
  );
  // const [equipmentsSelected, setEquipmentSelected] = useState<string[]>([]);

  const equipmentsToOptions = (equipments: Equipment[]) =>
    equipments.map((equipment) => ({
      label: equipment.label,
      // label: `${equipment.location.label} ${equipment.label}`,
      value: equipment.identifier,
      group: equipment.location.label,
    })) as AutocompleteEquipment[];

  const optionsToEquipments = (equipments: AutocompleteEquipment[]) =>
    equipmentsState.equipments.filter((e) =>
      equipments.map((e) => e.value).includes(e.identifier)
    );

  const equipmentsOptions = useMemo(
    () =>
      equipmentsToOptions(equipmentsState.equipments).sort((a, b) =>
        a.group > b.group ? 1 : b.group > a.group ? -1 : 0
      ),
    [equipmentsState.equipments]
  );

  return (
    <Stack
      direction={{ xs: "column", sm: "row" }}
      justifyContent="space-between"
      alignItems="stretch"
      gap={2}
      sx={{ p: 3 }}
    >
      <Stack
        direction={{ xs: "column", sm: "row" }}
        width={{ sm: "75%" }}
        gap={2}
      >
        <TextField
          fullWidth
          value={filterSearch}
          onChange={(event) => onFilterSearch(event.target.value)}
          placeholder={translate(
            "Search for equipments, measure label or machine name"
          )}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Iconify
                  icon={"eva:search-fill"}
                  sx={{ color: "text.disabled", width: 20, height: 20 }}
                />
              </InputAdornment>
            ),
          }}
        />
        <Autocomplete
          multiple
          sx={{ minWidth: "50%" }}
          groupBy={(opt) => opt.group}
          onChange={(event, values) => {
            onFilterEquipments(optionsToEquipments(values));
            // const equipmentsIds = values.map((e) => e.value);
            // // setEquipmentSelected(equipmentsIds);
            // onFilterEquipments(equipmentsIds);
          }}
          options={equipmentsOptions}
          // options={equipmentsState.equipments
          //   .map((equipment) => {
          //     return {
          //       label: equipment.label,
          //       value: equipment.identifier,
          //       group:
          //         equipmentsLocationsState.equipmentsLocations.find(
          //           (e) => e.identifier === equipment.locationId
          //         )?.label || "",
          //     };
          //   })
          //   .sort((a, b) =>
          //     a.group > b.group ? 1 : b.group > a.group ? -1 : 0
          //   )}
          renderInput={(params) => (
            <TextField {...params} label={translate("Equipements")} />
          )}
        />
      </Stack>
      <Box sx={{ flex: 1 }}>{children}</Box>
    </Stack>
  );
}
