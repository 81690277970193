import {
  Box,
  ClickAwayListener,
  InputAdornment,
  Popper,
  PopperProps,
  Slide,
  TextField,
} from "@mui/material";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { alpha, styled } from "@mui/material/styles";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { IconButtonAnimate } from "src/components/generic/animate";
import Iconify from "src/components/generic/Iconify";
import SearchNotFound from "src/components/generic/SearchNotFound";
import { useLocales } from "src/hooks";
import cssStyles from "src/utils/cssStyles";
import { useSelector } from "src/redux/store";
import searchBarConfig, { SearchItem } from "./SearchBarConfig";

const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const SearchbarStyle = styled("div")(({ theme }) => ({
  ...cssStyles(theme).bgBlur(),
  top: 0,
  left: 0,
  zIndex: 99,
  width: "100%",
  display: "flex",
  position: "absolute",
  alignItems: "center",
  height: APPBAR_MOBILE,
  padding: theme.spacing(0, 3),
  boxShadow: theme.customShadows.z8,
  [theme.breakpoints.up("md")]: {
    height: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

const autocompleteStyle = {
  width: 1,
  height: 1,
  "& .MuiFormControl-root, .MuiOutlinedInput-root, .MuiAutocomplete-input": {
    height: 1,
    p: "0 !important",
    fontWeight: "fontWeightBold",
    "& fieldset": { display: "none" },
  },
};

const PopperStyle = styled((props: PopperProps) => {
  return <Popper {...props} />;
})(({ theme }) => ({
  left: `8px !important`,
  top: `${APPBAR_MOBILE + 8}px !important`,
  width: "calc(100% - 16px) !important",
  transform: "none !important",
  [theme.breakpoints.up("md")]: {
    top: `${APPBAR_DESKTOP + 8}px !important`,
  },
  "& .MuiAutocomplete-paper": {
    padding: theme.spacing(1, 0),
  },
  "& .MuiListSubheader-root": {
    "&.MuiAutocomplete-groupLabel": {
      ...cssStyles(theme).bgBlur({ color: theme.palette.background.paper }),
      ...theme.typography.overline,
      top: 0,
      margin: 0,
      lineHeight: "48px",
      borderRadius: theme.shape.borderRadius,
    },
  },
  "& .MuiAutocomplete-listbox": {
    "& .MuiAutocomplete-option": {
      padding: theme.spacing(0.5, 2),
      margin: 0,
      display: "block",
      border: `dashed 1px transparent`,
      borderBottom: `dashed 1px ${theme.palette.divider}`,
      "&:hover": {
        border: `dashed 1px ${theme.palette.primary.main}`,
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.hoverOpacity
        ),
      },
    },
  },
}));

// ----------------------------------------------------------------------

export default function Searchbar() {
  const equipmentsState = useSelector((state) => state.equipments);
  const equipmentsLocationsState = useSelector(
    (state) => state.equipmentsLocations
  );
  const equipmentsTypesState = useSelector((state) => state.equipmentsTypes);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { translate } = useLocales();
  const { measures: allMeasures } = useSelector((state) => state.measures);
  const [open, setOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [allItems, setAllItems] = useState<SearchItem[] | undefined>(undefined);

  useEffect(() => {
    setAllItems(
      searchBarConfig(
        equipmentsLocationsState.equipmentsLocations,
        equipmentsTypesState.equipmentsTypes,
        equipmentsState.equipments,
        allMeasures,
        translate
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    equipmentsLocationsState.equipmentsLocations,
    equipmentsTypesState.equipmentsTypes,
    equipmentsState.equipments,
    allMeasures,
  ]);

  useEffect(() => {
    if (open) {
      setOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const handleClick = (path: string) => {
    if (path.includes("http")) {
      window.open(path);
    } else {
      navigate(path);
    }
    setOpen(false);
  };

  const handleKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      handleClick(searchQuery);
    }
  };

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <div>
        {!open && (
          <IconButtonAnimate onClick={() => setOpen((prev) => !prev)}>
            <Iconify icon={"eva:search-fill"} width={20} height={20} />
          </IconButtonAnimate>
        )}

        <Slide direction="down" in={open} mountOnEnter unmountOnExit>
          <SearchbarStyle>
            {allItems && (
              <Autocomplete
                sx={autocompleteStyle}
                autoHighlight
                disablePortal
                disableClearable
                popupIcon={null}
                PopperComponent={PopperStyle}
                onInputChange={(event, value) => setSearchQuery(value)}
                noOptionsText={<SearchNotFound searchQuery={searchQuery} />}
                options={allItems.sort(
                  (a, b) => -b.group.localeCompare(a.group)
                )}
                groupBy={(option) => option.group}
                getOptionLabel={(option) => option.path}
                isOptionEqualToValue={(option, value) =>
                  option.path === value.path
                }
                filterOptions={createFilterOptions({
                  matchFrom: "any",
                  stringify: (option) => option.title || option.path,
                })}
                renderOption={(props, option, { inputValue }) => {
                  const { title, path } = option;

                  const partsTitle = parse(title, match(title, inputValue));

                  const partsPath = parse(path, match(path, inputValue));

                  return (
                    <Box
                      component="li"
                      {...props}
                      onClick={() => handleClick(path)}
                    >
                      <div>
                        {partsTitle.map((part, index) => (
                          <Box
                            key={index}
                            component="span"
                            sx={{
                              typography: "subtitle2",
                              textTransform: "capitalize",
                              color: part.highlight
                                ? "primary.main"
                                : "text,primary",
                            }}
                          >
                            {part.text}
                          </Box>
                        ))}
                      </div>

                      <div>
                        {partsPath.map((part, index) => (
                          <Box
                            key={index}
                            component="span"
                            sx={{
                              typography: "caption",
                              color: part.highlight
                                ? "primary.main"
                                : "text.secondary",
                            }}
                          >
                            {part.text}
                          </Box>
                        ))}
                      </div>
                    </Box>
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    autoFocus
                    placeholder={translate("Search...")}
                    onKeyUp={handleKeyUp}
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <InputAdornment position="start">
                          <Iconify
                            icon="eva:search-fill"
                            sx={{
                              color: "text.disabled",
                              width: 20,
                              height: 20,
                            }}
                          />
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            )}
          </SearchbarStyle>
        </Slide>
      </div>
    </ClickAwayListener>
  );
}
