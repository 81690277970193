import { PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import { DateTime, Duration } from "luxon";
import { TimeControl, User } from "src/@types";
import { AuthState } from "./auth";
import { userService } from "src/services";
import { createTransform } from "redux-persist";

export type TimeControlState = {
  timeControl: TimeControl;
};

export const load = createAsyncThunk<
  TimeControlState | undefined,
  { name: TimeControlName; user?: User },
  { state: { auth: AuthState } }
>("timecontrol-load", async ({ name, user: userParam }, { getState }) => {
  const user = userParam || getState().auth.user;
  if (!user) {
    throw new Error("User is undefined!");
  }
  const response = await userService.getTimeControl(user._id, name);
  const timeControlFromServer = response.data;

  const timeControl = timeControlFromServer?.interval
    ? { interval: Duration.fromISO(timeControlFromServer.interval) }
    : timeControlFromServer?.start && timeControlFromServer?.end
    ? {
        start: DateTime.fromISO(timeControlFromServer.start),
        end: DateTime.fromISO(timeControlFromServer.end),
      }
    : undefined;

  return timeControl ? { timeControl } : undefined;
});

export const clear = createAsyncThunk<void, TimeControlName>(
  "timecontrol-clear",
  (name) => {}
);

export type TimeControlName = "alarmsLog" | "logbook" | "measures" | "patrols";
type State = {
  auth: AuthState;
  alarmsLogTimeControl: TimeControlState;
  logbookTimeControl: TimeControlState;
  measuresTimeControl: TimeControlState;
  patrolsTimeControl: TimeControlState;
};

export const set = createAsyncThunk<
  TimeControlState,
  { name: TimeControlName; value: TimeControl },
  {
    state: State;
  }
>("timecontrol-set", ({ name, value }, { getState }) => {
  const { user } = getState().auth;
  if (!user) {
    throw new Error();
  }
  const newTimeControl = { timeControl: value };
  userService.saveTimeControl(name, value, user._id);
  return newTimeControl;
});

export const reducers = {
  set(state: TimeControlState, action: PayloadAction<TimeControl>) {
    state.timeControl = action.payload;
  },
};

export const transforms = [
  createTransform<any, any, TimeControlState>(
    // IN (serialization)
    (subState, key) => {
      // console.log("IN", subState);
      return subState;
    },
    // OUT (hydration)
    (subState, key) => {
      // console.log("OUT", subState);
      return {
        ...subState,
        interval: subState.interval
          ? Duration.fromISO(subState.interval)
          : undefined,
        start: subState.start ? DateTime.fromISO(subState.start) : undefined,
        end: subState.end ? DateTime.fromISO(subState.start) : undefined,
      };
    }
  ),
];
