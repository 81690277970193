import { FormLabel, Stack, SxProps } from "@mui/material";
import { ReactNode, forwardRef } from "react";

type Props = {
  label: ReactNode;
  component: ReactNode;
  sx?: SxProps;
};

export default forwardRef<HTMLDivElement, Props>(function BaseBlock(
  { label, component, sx }: Props,
  ref
) {
  return (
    <Stack ref={ref} gap={1} sx={sx}>
      <FormLabel>{label}</FormLabel>
      {component}
    </Stack>
  );
});
