import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  ThemeContrast,
  ThemeDirection,
  ThemeLayout,
  ThemeMode,
} from "src/components/generic/settings/type";
import { AuthState } from "./auth";
import { userService } from "src/services";
import { User, UserSettings } from "src/@types/User";
import { dispatch } from "../store";

export interface SettingsState extends UserSettings {
  // colorVariants: ColorVariants;
  // themeColorPresets: ThemeColorPresets;
}

const initialState: SettingsState = {
  themeMode: "light",
  themeDirection: "ltr",
  themeStretch: false,
  themeLayout: "horizontal",
  themeContrast: "default",
  // colorVariants: defaultPreset,
  // themeColorPresets: "default",
};

export const load = createAsyncThunk<
  SettingsState,
  User | undefined,
  {
    state: { auth: AuthState };
  }
>("settings-load", async (userParam, { getState }) => {
  const user = userParam || getState().auth.user;
  if (!user) {
    throw new Error("User is undefined!");
  }
  const response = await userService.getSettings(user._id);
  const settings = response.data;
  return settings as SettingsState;
});

export const setThemeMode = createAsyncThunk<
  SettingsState,
  ThemeMode,
  {
    state: { auth: AuthState; settings: SettingsState };
  }
>("settings-set-theme-mode", (value, { getState }) => {
  const { user } = getState().auth;
  if (!user) {
    throw new Error();
  }
  const newSettings = { ...getState().settings, themeMode: value };
  userService.saveSettings(newSettings, user._id);
  return newSettings;
});

export const setThemeDirection = createAsyncThunk<
  SettingsState,
  ThemeDirection,
  {
    state: { auth: AuthState; settings: SettingsState };
  }
>("settings-set-theme-direction", (value, { getState }) => {
  const { user } = getState().auth;
  if (!user) {
    throw new Error();
  }
  const newSettings = { ...getState().settings, themeDirection: value };
  userService.saveSettings(newSettings, user._id);
  return newSettings;
});

export const toggleThemeStretch = createAsyncThunk<
  SettingsState,
  undefined,
  {
    state: { auth: AuthState; settings: SettingsState };
  }
>("settings-toggle-theme-stretch", (params, { getState }) => {
  const { user } = getState().auth;
  if (!user) {
    throw new Error();
  }
  const newSettings = {
    ...getState().settings,
    themeStretch: !getState().settings.themeStretch,
  };
  userService.saveSettings(newSettings, user._id);
  return newSettings;
});

const slice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    // toggleThemeStretch(state) {
    //   state.themeStretch = !state.themeStretch;
    // },
    setThemeLayout(state, action: PayloadAction<ThemeLayout>) {
      state.themeLayout = action.payload;
    },
    setThemeContrast(state, action: PayloadAction<ThemeContrast>) {
      state.themeContrast = action.payload;
    },
    // setColorVariants(state, action: PayloadAction<ColorVariants>) {
    //   state.colorVariants = action.payload;
    // },
    // setThemeColorPresets(state, action: PayloadAction<ThemeColorPresets>) {
    //   state.themeColorPresets = action.payload;
    // },
    reset(state) {
      state = initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(setThemeMode.fulfilled, (state, action) => {
        state.themeMode = action.payload.themeMode;
      })
      .addCase(setThemeMode.rejected, (state, action) => {
        console.error("Error while setting theme mode setting", action.error);
      })
      .addCase(setThemeDirection.fulfilled, (state, action) => {
        state.themeDirection = action.payload.themeDirection;
      })
      .addCase(setThemeDirection.rejected, (state, action) => {
        console.error("", action.error);
      })
      .addCase(toggleThemeStretch.fulfilled, (state, action) => {
        state.themeStretch = action.payload.themeStretch;
      })
      .addCase(toggleThemeStretch.rejected, (state, action) => {
        console.error(
          "Error while toggling theme stretch setting!",
          action.error
        );
      })
      // .addCase(load.pending, (state, action) => {})
      .addCase(load.fulfilled, (state, action) => {
        state.themeMode = action.payload.themeMode;
        state.themeStretch = action.payload.themeStretch;
      })
      .addCase(load.rejected, (state, action) => {
        console.error("Error while loading settings!", action.error);
      });
  },
});

export const {
  // setThemeStretch,
  // toggleThemeStretch,
  setThemeLayout,
  setThemeContrast,
  // setColorVariants,
  // setThemeColorPresets,
  reset,
} = slice.actions;

export default slice;
