import { Container, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { m } from "framer-motion";
import { SeverErrorIllustration } from "src/assets/illustrations-components";
import { MotionContainer, varBounce } from "src/components/generic/animate";
import useLocales from "src/hooks/useLocales";

const ContentStyle = styled("div")(({ theme }) => ({
  // maxWidth: 480,
  margin: "auto",
  // minHeight: "30vh",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  // padding: theme.spacing(12, 0),
  padding: theme.spacing(2, 0),
}));

export default function Error500() {
  const { translate } = useLocales();
  return (
    <Container component={MotionContainer}>
      <ContentStyle sx={{ textAlign: "center", alignItems: "center" }}>
        <m.div variants={varBounce().in}>
          <Typography variant="h3" paragraph>
            {translate("Server error!")}
          </Typography>
        </m.div>

        <m.div variants={varBounce().in}>
          <Typography sx={{ color: "text.secondary" }}>
            {translate("Sorry, the server could not handle your request.")}
          </Typography>
        </m.div>

        <m.div variants={varBounce().in}>
          <SeverErrorIllustration sx={{ height: 260, mt: 4 }} />
        </m.div>
      </ContentStyle>
    </Container>
  );
}
