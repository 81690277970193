import {
  Alert,
  Box,
  Button,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useEffect, useRef, useState } from "react";
import SignatureCanvas from "react-signature-canvas";
import { sprintf } from "sprintf-js";
import { LocalAttachment, Patrol } from "src/@types";
import Iconify from "src/components/generic/Iconify";
import { useLocales } from "src/hooks";

type Props = {
  patrol: Patrol;
  onCancel: () => void;
  onValidated: (signatureFile: LocalAttachment) => void;
};

export default function PatrolValidation({
  patrol,
  onCancel,
  onValidated,
}: Props) {
  const { translate } = useLocales();
  const theme = useTheme();
  const canvasRef = useRef<SignatureCanvas | null>(null);
  const [signed, setSigned] = useState(false);

  const emptyMeasures = patrol.measures.filter(
    (measure) => measure.active && measure.value === undefined
  );
  // console.log("emptyMeasures", emptyMeasures);

  useEffect(() => {
    if (!canvasRef.current) return;
    const signatureCanvas = canvasRef.current;
    const canvas = signatureCanvas.getCanvas();
    const resizeCanvas = () => {
      canvas.setAttribute("width", `${canvas.parentElement?.clientWidth || 0}`);
      canvas.setAttribute(
        "heigh",
        `${canvas.parentElement?.clientHeight || 0}`
      );
      signatureCanvas.clear();
    };
    window.addEventListener("resize", resizeCanvas);
    setTimeout(resizeCanvas, 50);
    //
    canvasRef.current.getSignaturePad().onBegin = () => setSigned(true);
  }, [canvasRef]);

  const clearCanvas = () => {
    if (!canvasRef.current) return;
    canvasRef.current.clear();
    setSigned(false);
  };

  const validate = () => {
    if (!canvasRef.current) return;
    const mimeType = "image/png";
    const imageDataUrl = canvasRef.current.toDataURL(mimeType);
    const imageDataContent = imageDataUrl.replace(
      `data:${mimeType};base64,`,
      ""
    );
    const imageDataBin = Buffer.from(imageDataContent, "base64");
    onValidated({
      source: "SignaturePad",
      id: "signature",
      name: "signature",
      extension: "png",
      meta: {
        name: "signature",
        type: mimeType,
      },
      type: mimeType,
      data: new Blob([imageDataBin]),
      size: imageDataBin.length,
      isRemote: false,
      content: imageDataContent,
    });
  };

  return (
    <Stack gap={1}>
      <Alert severity="info">
        <Typography>
          {translate("You have to sign in order to validate this patrol.")}
        </Typography>
        <Typography>
          {translate(
            "Once the patrol is validated, you will not be able to modify it."
          )}
        </Typography>
      </Alert>
      {emptyMeasures.length ? (
        <Alert severity="warning">
          {sprintf(
            translate(
              "Warning: you are about to validate a patrol with %d missing measures."
            ),
            emptyMeasures.length
          )}
        </Alert>
      ) : null}
      <Box sx={{ position: "relative" }}>
        <SignatureCanvas
          ref={canvasRef}
          backgroundColor={theme.palette.background.default}
          penColor={theme.palette.text.primary}
          canvasProps={{ width: 600, height: 400 }}
        />
        <IconButton
          sx={{ position: "absolute", right: 20, bottom: 20 }}
          onClick={() => clearCanvas()}
        >
          <Iconify icon="eva:trash-2-outline" />
        </IconButton>
      </Box>
      <Stack direction="row" justifyContent="space-between">
        <Button onClick={() => onCancel()}>{translate("Cancel")}</Button>
        <Button
          onClick={() => validate()}
          variant="contained"
          disabled={!signed}
        >
          {translate("Validate")}
        </Button>
      </Stack>
    </Stack>
  );
}
