import numeral from "numeral";
import { sprintf } from "sprintf-js";
import { Patrol } from "src/@types";
import Label, { LabelColor } from "src/components/generic/Label";
import { useLocales } from "src/hooks";

type LabelProps = {
  color: LabelColor;
  label: string;
};

type Props = {
  patrol: Patrol;
  showProgress?: boolean;
};

export default function PatrolStatusLabel({
  patrol,
  showProgress = false,
}: Props) {
  const { translate } = useLocales();

  const status: LabelProps = patrol.done
    ? { color: "success", label: translate("Validated") }
    : patrol.progressPercent === 100
    ? { color: "warning", label: translate("To sign") }
    : {
        color: "primary",
        label: showProgress
          ? sprintf(
              translate("In progress: %s %%"),
              numeral(patrol.progressPercent).format("0")
            )
          : translate("In progress"),
      };

  return <Label color={status.color}>{status.label}</Label>;
}
