// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import {
  Box,
  Radio,
  RadioGroup,
  FormHelperText,
  RadioGroupProps,
  FormControlLabel,
  Typography,
} from '@mui/material';

// ----------------------------------------------------------------------

type IProps = {
  name: string;
  options: {
    label: string;
    value: any;
    description?: string;
  }[];
};

type Props = IProps & RadioGroupProps;

export default function RHFRadioGroup({ name, options, ...other }: Props) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <div>
          <RadioGroup {...field} row {...other}>
            {options.map((option) => (
              <FormControlLabel
                sx={{ alignItems: "flex-start" }}
                key={option.value}
                value={option.value}
                control={<Radio />}
                // label={option.label}
                label={
                  <Box sx={{ mb: 2 }}>
                    <Box>
                      {option.label}
                    </Box>
                    <Typography
                      variant="body2"
                      sx={{
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        color: "text.disabled",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {option.description}
                    </Typography>
                  </Box>}
              />
            ))}
          </RadioGroup>

          {!!error && (
            <FormHelperText error sx={{ px: 2 }}>
              {error.message}
            </FormHelperText>
          )}
        </div>
      )}
    />
  );
}
