import { Box, Card, FormLabel, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { Attachment } from "src/@types";
import { LogbookFromServer, LogbookType } from "src/@types/Logbook";
import AttachmentsList from "src/components/generic/AttachmentsList";
import { useFormat, useLocales } from "src/hooks";
import { loadTypes } from "src/redux/slices/logbook";
import { dispatch, useSelector } from "src/redux/store";

type Props = {
  logbook: LogbookFromServer;
};

function LogbookShow({ logbook }: Props) {
  const { translate } = useLocales();
  const { formatDate } = useFormat();
  const logbookState = useSelector((state) => state.logbook);

  const [customPropsModels, setCustomPropsModels] = useState<{
    [key: string]: any;
  }>({});
  const [type, setType] = useState<LogbookType | undefined>(
    logbookState.types.find((e) => e.identifier === logbook.type.identifier)
  );

  useEffect(() => {
    dispatch(loadTypes());
  }, []);

  useEffect(() => {
    setType(
      logbookState.types.find((e) => e.identifier === logbook.type.identifier)
    );
  }, [logbookState.types]);

  useEffect(() => {
    let result: { [key: string]: any } = {};
    if (type?.customProperties) {
      type.customProperties.forEach((p) => {
        result[p.identifier] = logbook[p.identifier as keyof LogbookFromServer];
      });
    }
    setCustomPropsModels(result);
  }, [type]);

  return (
    <Stack direction={{ xs: "column", md: "row" }} gap={2}>
      <Card sx={{ p: 2, width: { xs: 1, md: 2 / 3 } }}>
        <Stack direction="column" gap={2}>
          <Stack>
            <FormLabel>{translate("Date")}</FormLabel>
            <Typography>{formatDate(logbook.time)}</Typography>
          </Stack>

          <Stack>
            <FormLabel>{translate("Note")}</FormLabel>
            <Typography sx={{ whiteSpace: "pre-line" }}>
              {logbook.note}
            </Typography>
          </Stack>

          <Stack direction="column">
            <FormLabel>{translate("Attachments")}</FormLabel>
            <Box>
              {logbook.attachments.length ? (
                <Box>
                  <AttachmentsList
                    attachments={logbook.attachments || []}
                    getBaseUrl={(attachment: Attachment) =>
                      [
                        window.env.REACT_APP_EYE_BACKEND_URL,
                        "logbook",
                        logbook._id,
                        "attachments",
                        encodeURIComponent(attachment["aws-s3"].Key),
                      ].join("/")
                    }
                  />
                </Box>
              ) : (
                <Typography>{translate("No attachment")}</Typography>
              )}
            </Box>
          </Stack>
        </Stack>
      </Card>
      <Stack sx={{ width: { xs: 1, md: 1 / 3 } }}>
        {type && (type.type === "action" || type.customProperties) ? (
          <Card sx={{ p: 2 }}>
            <Stack direction="column" gap={2}>
              {type.customProperties?.map((p, key) => (
                <Stack key={key}>
                  <FormLabel>{p.label}</FormLabel>
                  <Typography>
                    {customPropsModels[p.identifier] === true
                      ? translate("True")
                      : customPropsModels[p.identifier] === false
                      ? translate("False")
                      : customPropsModels[p.identifier]}
                  </Typography>
                </Stack>
              ))}
              {logbook.type.type === "action" && (
                <Stack>
                  <FormLabel>{translate("Action result")}</FormLabel>
                  {logbook.actionResult === false
                    ? translate("Bad")
                    : logbook.actionResult === true
                    ? translate("Good")
                    : translate("Unknown")}
                </Stack>
              )}
            </Stack>
          </Card>
        ) : null}
      </Stack>
    </Stack>
  );
}

export default LogbookShow;
