import { useState } from "react";
import { TableHeadCell } from "src/components/generic/table";

export type TableColumnDefinition<T> = {
  head: TableHeadCell;
  Cell: ({ item }: { item: T }) => JSX.Element;
};

export type TableParams = {
  orderBy: string;
  setOrderBy: (val: string) => void;
  order: Order;
  setOrder: (val: Order) => void;
  page: number;
  setPage: (val: number) => void;
  rowsPerPage: number;
  setRowsPerPage: (val: number) => void;
  //
  selected: string[];
  setSelected: (ids: string[]) => void;
  dense: boolean;
  setDense: (val: boolean) => void;
  //
  onSelectRow: (id: string) => void;
  onSelectAllRows: (checked: boolean, newSelecteds: string[]) => void;
  onSort: (val: string) => void;
};

type Order = "asc" | "desc";

export type Props = {
  dense?: boolean;
  order?: Order;
  orderBy?: string;
  selected?: string[];
  rowsPerPage?: number;
  currentPage?: number;
};

export default function useTable(props?: Props): TableParams {
  const [orderByState, setOrderByState] = useState(props?.orderBy || "name");
  const [orderState, setOrderState] = useState<Order>(props?.order || "asc");
  const [pageState, setPageState] = useState(props?.currentPage || 0);
  const [rowsPerPageState, setRowsPerPageState] = useState(
    props?.rowsPerPage !== undefined ? props?.rowsPerPage : 20
  );
  const [selectedState, setSelectedState] = useState<string[]>(
    props?.selected || []
  );
  const [denseState, setDenseState] = useState(props?.dense || false);

  const onSort = (id: string) => {
    const isAsc = orderByState === id && orderState === "asc";
    if (id !== "") {
      setOrderState(isAsc ? "desc" : "asc");
      setOrderByState(id);
    }
  };

  const onSelectRow = (id: string) => {
    const selectedIndex = selectedState.indexOf(id);

    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedState, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedState.slice(1));
    } else if (selectedIndex === selectedState.length - 1) {
      newSelected = newSelected.concat(selectedState.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedState.slice(0, selectedIndex),
        selectedState.slice(selectedIndex + 1)
      );
    }
    setSelectedState(newSelected);
  };

  const onSelectAllRows = (checked: boolean, newSelecteds: string[]) => {
    if (checked) {
      setSelectedState(newSelecteds);
      return;
    }
    setSelectedState([]);
  };

  const setRowsPerPage = (val: number) => {
    setRowsPerPageState(val);
    setPageState(0);
  };

  return {
    orderBy: orderByState,
    setOrderBy: setOrderByState,
    order: orderState,
    setOrder: setOrderState,
    page: pageState,
    setPage: setPageState,
    rowsPerPage: rowsPerPageState,
    setRowsPerPage,
    //
    selected: selectedState,
    setSelected: setSelectedState,
    onSelectRow,
    onSelectAllRows,
    //
    onSort,
    dense: denseState,
    setDense: setDenseState,
  };
}

// ----------------------------------------------------------------------

export function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  const aValue = a[orderBy] !== undefined ? a[orderBy] : "";
  const bValue = b[orderBy] !== undefined ? b[orderBy] : "";
  return bValue < aValue ? -1 : bValue > aValue ? 1 : 0;
}

// export function getComparator<Key extends keyof any>(
//   order: Order,
//   orderBy: Key
// ): (
//   a: { [key in Key]: number | string },
//   b: { [key in Key]: number | string }
// ) => number {
//   return order === "desc"
//     ? (a, b) => descendingComparator(a, b, orderBy)
//     : (a, b) => -descendingComparator(a, b, orderBy);
// }

export function getComparator<T>(order: Order, orderBy: keyof T) {
  return order === "desc"
    ? (a: T, b: T) => descendingComparator(a, b, orderBy)
    : (a: T, b: T) => -descendingComparator(a, b, orderBy);
}

export function emptyRows(
  page: number,
  rowsPerPage: number,
  arrayLength: number
) {
  return page > 0 ? Math.max(0, (1 + page) * rowsPerPage - arrayLength) : 0;
}
