export type AccessRight = {
  identifier: string;
  label: string;
  typeId: string;
  group: string;
  parent?: string;
  children?: AccessRights;
  equipementsIds?: string[];
};

export class AccessRights extends Array<AccessRight> {
  static from(accessRights: AccessRight[]) {
    const ar = new AccessRights();
    accessRights.forEach((accessRight) => ar.push(accessRight));
    return ar;
  }
  clone() {
    return AccessRights.from(this.map((ar) => Object.assign({}, ar)));
  }
  getAccessRightParents(accessRight: AccessRight) {
    let out: AccessRights = new AccessRights();
    if (accessRight.parent) {
      const parent = this.recursiveFind(
        (ar) => ar.identifier === accessRight.parent
      );
      if (parent) {
        out.push(Object.assign({}, parent));
        out = AccessRights.from(out.concat(this.getAccessRightParents(parent)));
      }
    }
    return out;
  }
  getPath(accessRight: AccessRight, path: AccessRight[] = []) {
    const item = AccessRights.from(this.toTree().recursiveFlat()).recursiveFind(
      (item, index) => item.identifier === accessRight.identifier
    );
    if (item) path.push(item);
    const parent = this.recursiveFind(
      (item, index) => item.identifier === accessRight.parent
    );
    if (parent) {
      this.getPath(parent, path);
    }
    return path;
  }
  getLevelsCount() {
    let levels = 0;
    if (this.length) levels++;
    const childrenMaxLevels = this.reduce((acc, ar) => {
      if (!ar.children) return acc;
      return Math.max(acc, ar.children.getLevelsCount());
    }, 0);
    levels += childrenMaxLevels;
    return levels;
  }
  recursiveFlat(): AccessRight[] {
    let accessRights: AccessRight[] = [];
    for (let i = 0; i < this.length; i++) {
      const ar = this[i];
      accessRights.push(ar);
      if (!ar.children) continue;
      accessRights = accessRights.concat(ar.children.recursiveFlat());
    }
    return accessRights;
  }
  recursiveFind(
    predicate: (item: AccessRight, index: number) => boolean
  ): AccessRight | undefined {
    let out: AccessRight | undefined = this.find(predicate);
    if (out) return out;
    for (let i = 0; i < this.length; i++) {
      const ar = this[i];
      if (!ar.children) continue;
      out = ar.children.recursiveFind(predicate);
      if (out) return out;
    }
  }
  recursiveFilter(
    fn: (item: AccessRight, index: number) => boolean
  ): AccessRights {
    // const out = AccessRights.from([...this]);
    const out = this.clone();
    for (let i = 0; i < out.length; i++) {
      const ar = out[i];
      if (!fn(ar, i)) {
        out.splice(i, 1);
        i--;
        continue;
      }
      if (ar.children) {
        ar.children = ar.children.recursiveFilter(fn);
      }
    }
    return out;
  }
  toTree() {
    const treeAccessRights = AccessRights.from(
      this.clone()
        .filter((ar) => !ar.parent)
        .map((ar) => ({ ...ar, children: new AccessRights() }))
    );
    this.clone()
      .recursiveFilter((ar) => !!ar.parent)
      .forEach((flatAccessRight) => {
        if (!flatAccessRight.parent) return;
        const treeAccessRight = Object.assign({}, flatAccessRight, {
          children: new AccessRights(),
        });
        const parentAccessRight = treeAccessRights.recursiveFind(
          (ar) => ar.identifier === flatAccessRight.parent
        );
        if (parentAccessRight) {
          parentAccessRight.children?.push(treeAccessRight);
        }
      });
    return treeAccessRights;
  }
}
