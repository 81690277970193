import { Container } from "@mui/material";
import { useState } from "react";
import { Measure } from "src/@types";
import AlarmsStatus from "src/components/app/alarms/AlarmsStatus";
import MeasureDrawer from "src/components/app/measure/drawer/MeasureDrawer";
import HeaderBreadcrumbs from "src/components/generic/HeaderBreadcrumbs";
import Page from "src/components/generic/Page";
import { useLocales } from "src/hooks";
import { useSelector } from "src/redux/store";
import { PATH_DASHBOARD } from "src/routes/paths";

export default function AlarmStatus() {
  const { translate } = useLocales();
  const settingsState = useSelector((state) => state.settings);
  const [drawerMeasure, setDrawerMeasure] = useState<Measure>();
  const [drawerOpen, setDrawerOpen] = useState(false);

  return (
    <Page title={translate("Alarms status")}>
      <Container maxWidth={settingsState.themeStretch ? false : "xl"}>
        <HeaderBreadcrumbs
          heading={translate("Alarms status")}
          links={[
            {
              name: translate("Home"),
              href: PATH_DASHBOARD.root,
            },
            {
              name: translate("Tools"),
            },
            {
              name: translate("Alarms"),
              href: PATH_DASHBOARD.alarms.root,
            },
            {
              name: translate("Alarms status"),
            },
          ]}
        />
        {drawerMeasure && (
          <MeasureDrawer
            measure={drawerMeasure}
            open={drawerOpen}
            displayLinkToMeasure={true}
            onClose={() => setDrawerOpen(false)}
            // onThresholdsUpdated={() => {
            //   setProcessing(true);
            //   loadAlarms();
            // }}
          />
        )}

        <AlarmsStatus
          setDrawerOpen={setDrawerOpen}
          setDrawerMeasure={setDrawerMeasure}
        />
      </Container>
    </Page>
  );
}
