import {
  SerializedError,
  createAsyncThunk,
  createSlice,
} from "@reduxjs/toolkit";
import { Dashboard } from "src/@types";
import { dashboardService } from "src/services";
import { TimeControlState } from "./baseTimeControl";
import { getTimeControlDuration } from "src/@types/TimeControl";

interface DashboardState {
  currentRequestId?: string;
  loading: boolean;
  loaded: boolean;
  dashboard?: Dashboard;
  error?: SerializedError;
}

const initialState: DashboardState = {
  loading: false,
  loaded: false,
};

export const load = createAsyncThunk<
  Dashboard | undefined,
  undefined,
  {
    state: { dashboard: DashboardState; measuresTimeControl: TimeControlState };
  }
>("dashboard", async (params, { getState, requestId }) => {
  const { currentRequestId, loading } = getState().dashboard;
  if (!loading || requestId !== currentRequestId) {
    return initialState.dashboard;
  }
  const timeControlState = getState().measuresTimeControl;
  const response = await dashboardService.get(
    getTimeControlDuration(timeControlState.timeControl).toISO()
  );
  return response.data;
});

const slice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(load.pending, (state, action) => {
        if (!state.loading) {
          state.loading = true;
          state.loaded = false;
          state.error = undefined;
          state.currentRequestId = action.meta.requestId;
        }
      })
      .addCase(load.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (state.loading && state.currentRequestId === requestId) {
          state.loading = false;
          state.error = undefined;
          state.dashboard = action.payload;
          // console.log("state.dashboard", state.dashboard);
          state.currentRequestId = undefined;
          state.loaded = true;
        }
      })
      .addCase(load.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (state.loading && state.currentRequestId === requestId) {
          state.loading = false;
          console.log("action.error", JSON.stringify(action.error, null, 2));
          console.log("action.error", action.error);
          state.error = action.error;
          state.currentRequestId = undefined;
        }
      });
  },
});

export default slice;
