import async from "async-es";
import { DateTime } from "luxon";
import {
  Logbook,
  LogbookCategory,
  LogbookFromServer,
  LogbookType,
} from "src/@types/Logbook";
import axios from "src/utils/axios";

class LogbookService {
  get(id: string) {
    return axios.get<LogbookFromServer>(`/logbook/${id}`);
  }

  getAll() {
    return axios.get("/logbook");
  }

  getLogbooks(
    pagesize: number,
    page: number,
    startDate: DateTime,
    endDate: DateTime,
    sort: string,
    order: string,
    filterEquipments: string[],
    filterSearch: string
  ) {
    const pagination = `page=${page}&pagesize=${pagesize}`;
    const equipmentFilter =
      filterEquipments.length > 0
        ? `{"equipment.identifier":{"$in":${JSON.stringify(filterEquipments)}}}`
        : "";

    const searchFilter =
      filterSearch.length > 0
        ? `{"$or" : [{"note":{"$regex":"${filterSearch}","$options":"i"}}, {"equipment.label":{"$regex":"${filterSearch}","$options":"i"}}, {"operator.firstName":{"$regex":"${filterSearch}","$options":"i"}}, {"operator.lastName":{"$regex":"${filterSearch}","$options":"i"}},{"type.label":{"$regex":"${filterSearch}","$options":"i"}}, {"location.label":{"$regex":"${filterSearch}","$options":"i"}}]}`
        : "";

    const timeFilter = `{"time":{"$gte":"${startDate
      .toUTC()
      .toISO()}"}}, {"time":{"$lte":"${endDate.toUTC().toISO()}"}}`;

    const sortFormated = `${order === "desc" ? "-" : "+"}${sort}`;

    const filters = encodeURIComponent(
      `{"$and":[${equipmentFilter !== "" ? `${equipmentFilter},` : ""} ${
        searchFilter !== "" ? `${searchFilter},` : ""
      } ${timeFilter}]}`
    );

    return axios.get<LogbookFromServer[]>(
      `/logbook?filter=${filters}&${pagination}&sort=${sortFormated}`
    );
  }

  getExportFile(
    format: "ods" | "xlsx" | "csv",
    optionalFields: string[],
    filter: {
      start: DateTime;
      end: DateTime;
      equipments?: string[];
      search?: string;
    }
  ) {
    const queryFilter = {
      $and: [
        { time: { $gte: filter.start.toUTC().toISO() } },
        { time: { $lte: filter.end.toUTC().toISO() } },
      ],
      "equipment.identifier": filter.equipments?.length
        ? { $in: filter.equipments }
        : undefined,
      $or: filter.search
        ? [
            { note: { $regex: filter.search, $options: "i" } },
            { "equipment.label": { $regex: filter.search, $options: "i" } },
            { "operator.firstName": { $regex: filter.search, $options: "i" } },
            { "operator.lastName": { $regex: filter.search, $options: "i" } },
            { "type.label": { $regex: filter.search, $options: "i" } },
            { "location.label": { $regex: filter.search, $options: "i" } },
          ]
        : undefined,
    };
    return axios.get("/logbook/export", {
      params: {
        format,
        filter: queryFilter,
        optionalFields,
      },
      responseType: "blob",
    });
  }

  getCategories() {
    return axios.get<LogbookCategory[]>("/logbook_categories");
  }

  getTypes() {
    return axios.get<LogbookType[]>("/logbook_types");
  }

  post(logbookForm: Logbook, customProperties: { [key: string]: any }) {
    let objToSend: any = {
      actionResult: logbookForm.actionResult,
      category: logbookForm.category,
      equipment: logbookForm.equipment,
      equipmentType: logbookForm.equipmentType,
      location: logbookForm.location,
      type: logbookForm.type,
      note: logbookForm.note || "",
      time: logbookForm.time,
    };

    Object.keys(customProperties).forEach((key: string) => {
      objToSend[key] = customProperties[key];
    });

    return axios.post<LogbookFromServer>("/logbook", objToSend);
  }

  put(logbook: LogbookFromServer, customProperties: { [key: string]: any }) {
    let objToSend: any = {
      _id: logbook._id,
      actionResult: logbook.actionResult,
      category: logbook.category,
      equipment: logbook.equipment,
      attachments: logbook.attachments,
      equipmentType: logbook.equipmentType,
      location: logbook.location,
      type: logbook.type,
      note: logbook.note || "",
      time: logbook.time,
    };

    Object.keys(customProperties).forEach((key: string) => {
      objToSend[key] = customProperties[key];
    });

    return axios.put(`/logbook/${logbook._id}`, objToSend);
  }

  delete(logbookEntryId: string) {
    return axios.delete(`/logbook/${logbookEntryId}`);
  }

  async saveAttachments(logbook: Logbook) {
    const tasks = logbook.files.map((file) =>
      async.asyncify(() =>
        axios.post(`/logbook/${logbook._id}/attachments`, file)
      )
    );
    return await async.series(tasks);
  }
}

const logbookService = new LogbookService();
export default logbookService;
