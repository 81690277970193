import { useTheme } from "@mui/material/styles";
import { useEffect } from "react";

export default function MetaThemeColor() {
  const theme = useTheme();

  useEffect(() => {
    const meta = document.querySelector("meta[name='theme-color']");
    if (!meta) return;
    meta.setAttribute("content", theme.palette.background.default);
  }, [theme.palette.background.default]);

  return null;
}
