import { Box, Container } from "@mui/material";
import { DateTime } from "luxon";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { sprintf } from "sprintf-js";
import { Patrol, PatrolDisplayMode, PatrolMeasure } from "src/@types";
import { Patrol as PatrolCmp } from "src/components/app/patrols";
import HeaderBreadcrumbs from "src/components/generic/HeaderBreadcrumbs";
import LoadingAnimation from "src/components/generic/LoadingAnimation";
import Page from "src/components/generic/Page";
import { useFormat, useLocales, useStore } from "src/hooks";
import { useSelector } from "src/redux/store";
import { PATH_DASHBOARD } from "src/routes/paths";

type Props = {
  displayMode: PatrolDisplayMode;
};

export default function PatrolPage({ displayMode }: Props) {
  const { patrolId = "", measureId = "" } = useParams();
  const authState = useSelector((state) => state.auth);
  const { formatDate } = useFormat();
  const navigate = useNavigate();
  const { translate } = useLocales();
  const settingsState = useSelector((state) => state.settings);
  const { getStore } = useStore();
  const { enqueueSnackbar } = useSnackbar();
  const [patrol, setPatrol] = useState<Patrol>();
  const [measure, setMeasure] = useState<PatrolMeasure>();
  const [loading, setLoading] = useState(false);

  // Set state objects from URL params
  useEffect(() => {
    const fn = async () => {
      if (!patrolId) return;
      // Patrol loading
      let loadedPatrol = patrol;
      if (patrol?._id !== patrolId) {
        setLoading(true);
        const store = await getStore();
        loadedPatrol = await store.Patrols.findOne({ _id: patrolId });
        if (!loadedPatrol) {
          navigate(PATH_DASHBOARD.patrols.list);
          enqueueSnackbar(translate("Patrol was not found"), {
            variant: "error",
          });
          return;
        }
        if (
          !loadedPatrol.done &&
          loadedPatrol.operator._id !== authState.user?._id
        ) {
          navigate(PATH_DASHBOARD.patrols.list);
          enqueueSnackbar(translate("Patrol is not editable"), {
            variant: "error",
          });
          return;
        }
        if (
          loadedPatrol.done &&
          displayMode === "edit" &&
          authState.user?.role !== "ADMIN"
        ) {
          navigate(PATH_DASHBOARD.patrols.view(patrolId));
          enqueueSnackbar(translate("Patrol is not editable"), {
            variant: "error",
          });
          return;
        }
        console.log("Loaded patrol", loadedPatrol);
        loadedPatrol.measures = loadedPatrol.measures.map((m) => {
          m.files = m.files || [];
          return m;
        });
        setPatrol(loadedPatrol);
        setLoading(false);
      }
      if (!loadedPatrol) {
        navigate(PATH_DASHBOARD.patrols.list);
        enqueueSnackbar(translate("Patrol was not found"), {
          variant: "error",
        });
        return;
      }
      // Measure
      let loadedMeasure = measure;
      if (measure?.identifier !== measureId) {
        loadedMeasure = loadedPatrol.measures.find(
          (measure) => measure.identifier === measureId
        );
        if (!loadedMeasure && loadedPatrol.measures.length) {
          loadedMeasure = loadedPatrol.measures[0];
        }
        console.log("Loaded measure", loadedMeasure);
        if (!loadedMeasure) {
          navigate(
            displayMode === "edit"
              ? PATH_DASHBOARD.patrols.view(loadedPatrol._id)
              : PATH_DASHBOARD.patrols.edit(
                  loadedPatrol._id,
                  loadedPatrol.measures[0].identifier
                ),
            {
              replace: true,
            }
          );
          return;
        }
        setMeasure(loadedMeasure);
      }
    };
    fn();
  }, [patrolId, measureId, displayMode, navigate]);

  // Change URL on measure change
  useEffect(() => {
    if (!patrol || !measure) return;
    window.history.replaceState(null, "", measure.identifier);
  }, [measure]);

  if (!patrol) {
    return null;
  }

  return (
    <Page
      title={translate("Patrol")}
      sx={{ height: { xs: "auto", md: "100%" }, overflowY: "auto" }}
    >
      <Container
        maxWidth={settingsState.themeStretch ? false : "xl"}
        sx={{
          height: { xs: "auto", md: "100%" },
          overflowY: "auto",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <HeaderBreadcrumbs
          heading={sprintf(
            translate("Patrol %s on %s"),
            patrol.model.label,
            formatDate(patrol.startTime, DateTime.DATETIME_MED)
          )}
          links={[
            {
              name: translate("Home"),
              href: PATH_DASHBOARD.root,
            },
            {
              name: translate("Tools"),
            },
            {
              name: translate("Patrols"),
              href: PATH_DASHBOARD.patrols.list,
            },
            {
              name: sprintf(
                translate("Patrol %s on %s"),
                patrol.model.label,
                formatDate(patrol.startTime, DateTime.DATETIME_MED)
              ),
            },
          ]}
        />
        {loading ? (
          <Box marginTop={"20vh"}>
            <LoadingAnimation
              sx={{ backgroundColor: "#ffffff00", transform: "scale(0.7)" }}
            />
          </Box>
        ) : !measure ? null : (
          <PatrolCmp
            patrol={patrol}
            measure={measure}
            onMeasureChanged={(measure: PatrolMeasure) => {
              // Change URL on measure change
              window.history.replaceState(null, "", measure.identifier);
            }}
            displayMode={displayMode}
          />
        )}
      </Container>
    </Page>
  );
}
