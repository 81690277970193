import axios from "axios";
import { logout } from "src/redux/slices/auth";
import { dispatch } from "src/redux/store";

window.env = Object.assign({}, process?.env || {}, window.env || {});
console.log("process.env", process.env);
console.log("window.env", window.env);

const axiosInstance = axios.create({
  baseURL: window.env.REACT_APP_EYE_BACKEND_URL,
});

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    // Logout on error 401
    if (error.response?.status === 401) {
      // When signin in
      if (error.request?.responseURL?.endsWith("signin")) {
        return Promise.reject(new Error("Authentication failed"));
      }
      // When signin out
      if (!error.request?.responseURL?.endsWith("signout")) {
        dispatch(logout());
      }
      return Promise.reject("Logged out");
    }
    return Promise.reject((error.response && error.response.data) || error);
  }
);

const setToken = (token: string | null) => {
  if (token) {
    axiosInstance.defaults.headers.common.Authorization = `Bearer ${token}`;
  } else {
    delete axiosInstance.defaults.headers.common.Authorization;
  }
};

export { setToken };

export default axiosInstance;
