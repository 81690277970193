import {
  Box,
  Card,
  Divider,
  Grid,
  Tab,
  Table,
  TableBody,
  TableContainer,
  Tabs,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { sortBy } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { Alarm, Equipment, ListDisplayMode, Measure } from "src/@types";
import {
  AlarmCard,
  AlarmTableRow,
  AlarmsTableToolbar,
} from "src/components/app/alarms";
import EmptyContent from "src/components/generic/EmptyContent";
import Error500 from "src/components/generic/Error500";
import Label from "src/components/generic/Label";
import LoadingAnimation from "src/components/generic/LoadingAnimation";
import Scrollbar from "src/components/generic/Scrollbar";
import TableFooter from "src/components/generic/TableFooter";
import { TableHeadCell, TableHeadCustom } from "src/components/generic/table";
import { useLocales, useTable, useTabs } from "src/hooks";
import { getComparator } from "src/hooks/useTable";
import { load as loadAlarms } from "src/redux/slices/alarms";
import { dispatch, useSelector } from "src/redux/store";

type AlarmsProps = {
  measures: Measure[];
  setDrawerOpen: (open: boolean) => void;
  setDrawerMeasure: (measure: Measure) => void;
};

function AlarmsStatusCards({
  measures,
  setDrawerOpen,
  setDrawerMeasure,
}: AlarmsProps) {
  return (
    <>
      <Divider sx={{ mb: 2 }} />
      <Grid container spacing={2} sx={{ pb: 2.5, px: 2 }}>
        {measures.map((measure, index) => (
          <Grid key={index} item xs={12} sm={6} md={4} lg={4} xl={3}>
            <AlarmCard
              measure={measure}
              onMenuClick={() => {
                setDrawerOpen(true);
                setDrawerMeasure(measure);
              }}
            />
          </Grid>
        ))}
      </Grid>
    </>
  );
}

function AlarmsStatusTable({
  measures,
  setDrawerOpen,
  setDrawerMeasure,
}: AlarmsProps) {
  const { translate } = useLocales();
  const table = useTable();
  const TABLE_HEAD: TableHeadCell[] = [
    { id: "isFav", label: "", align: "left" },
    { id: "status", label: translate("Status"), align: "left" },
    { id: "lastChangeOn", label: translate("Date"), align: "left" },
    { id: "location", label: translate("Location"), align: "left" },
    { id: "equipment", label: translate("Equipment"), align: "left" },
    { id: "measure", label: translate("Measure"), align: "left" },
    { id: "threshold", label: translate("Threshold"), align: "left" },
    { id: "value", label: translate("Value"), align: "left" },
    { id: "action", label: translate(""), align: "left" },
  ];
  return (
    <Scrollbar>
      <TableContainer>
        <Table size={table.dense ? "small" : "medium"}>
          <TableHeadCustom
            headCells={TABLE_HEAD}
            order={table.order}
            orderBy={table.orderBy}
            onSort={table.onSort}
          />
          <TableBody>
            {measures.map((measure, index) => (
              <AlarmTableRow
                key={index}
                measure={measure}
                onMenuClick={() => {
                  setDrawerOpen(true);
                  setDrawerMeasure(measure);
                }}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Scrollbar>
  );
}

type Props = {
  setDrawerOpen: (open: boolean) => void;
  setDrawerMeasure: (measure: Measure) => void;
};

export default function AlarmsStatus({
  setDrawerOpen,
  setDrawerMeasure,
}: Props) {
  const { translate } = useLocales();
  const { currentTab: statusFilter, onChangeTab: onStatusFilter } =
    useTabs("all");
  // const { themeStretch } = useSettings();
  const theme = useTheme();
  const table = useTable();
  const favoritesState = useSelector((state) => state.favorites);
  const measuresState = useSelector((state) => state.measures);
  const alarmsState = useSelector((state) => state.alarms);
  const equipmentsLocationsState = useSelector(
    (state) => state.equipmentsLocations
  );
  const [searchFilter, setSearchFilter] = useState("");
  const [equipmentsFilter, setEquipmentsFilter] = useState<Equipment[]>([]);
  const [displayMode, setDisplayMode] = useState<ListDisplayMode>("card");
  const [favoriteFilter, setFavoriteFilter] = useState<boolean>(false);
  const [measuresWithAlarms, setMeasuresWithAlarms] = useState<Measure[]>([]);
  const [measuresWithAlarmsLoaded, setMeasuresWithAlarmsLoaded] =
    useState<boolean>(false);

  useEffect(() => {
    dispatch(loadAlarms());
  }, [measuresState.measures]);

  useEffect(() => {
    if (alarmsState.loading || measuresState.loading) return;
    if (!alarmsState.loaded || !measuresState.loaded) return;
    const measuresWithAlarms = measuresState.measures
      .map((measure) => {
        const measureAlarms =
          alarmsState.groupedAlarms[measure.identifier] || [];
        const strongestAlarm = sortBy(
          measureAlarms,
          (alarm) => alarm.priority
        ).reduce((acc: Alarm | undefined, alarm) => {
          if (!acc) return alarm;
          if (alarm.status.active && alarm.priority > acc.priority)
            return alarm;
          return acc;
        }, undefined);
        return { ...measure, alarms: measureAlarms, strongestAlarm };
      })
      .filter((m) => m.strongestAlarm);
    // console.log("measuresWithAlarms", measuresWithAlarms);
    setMeasuresWithAlarms(measuresWithAlarms);
    setMeasuresWithAlarmsLoaded(true);
  }, [
    alarmsState.groupedAlarms,
    measuresState.measures,
    measuresState.loaded,
    alarmsState.loading,
    alarmsState.loaded,
  ]);

  const filteredMeasures = useMemo(() => {
    function sortAndFilter({
      data,
      comparator,
    }: {
      data: Measure[];
      comparator: (a: any, b: any) => number;
    }) {
      let out = [...data];
      // Sort
      const stabilizedThis = data.map((el, index) => [el, index] as const);
      stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
      });
      // Status filter
      if (statusFilter === "inactive") {
        out = out.filter((e) => !e.strongestAlarm?.status.active);
      } else if (statusFilter === "warning") {
        out = out.filter(
          (e) =>
            e.strongestAlarm?.status.active && e.strongestAlarm?.priority === 2
        );
      } else if (statusFilter === "alert") {
        out = out.filter(
          (e) =>
            e.strongestAlarm?.status.active && e.strongestAlarm?.priority === 3
        );
      }
      // Text filter
      if (searchFilter) {
        out = out.filter(
          (measure) =>
            measure.machineName
              ?.toLowerCase()
              .indexOf(searchFilter.toLowerCase()) !== -1 ||
            measure.equipment?.label
              .toLowerCase()
              .indexOf(searchFilter.toLowerCase()) !== -1 ||
            measure.label.toLowerCase().indexOf(searchFilter.toLowerCase()) !==
              -1
        );
      }
      // Equipments filter
      if (equipmentsFilter.length > 0) {
        out = out.filter((measure) =>
          equipmentsFilter
            .map((e) => e.identifier)
            .includes(measure.equipmentId)
        );
      }
      // Favorite filter
      if (favoriteFilter)
        out = out.filter(
          (measure) =>
            favoritesState.measuresIds.findIndex(
              (measureId) => measureId === measure.identifier
            ) !== -1
        );
      return out;
    }
    const filteredMeasures = sortAndFilter({
      data: measuresWithAlarms,
      comparator: getComparator<Measure>(
        table.order,
        table.orderBy as keyof Measure
      ),
    });
    return filteredMeasures;
  }, [
    measuresWithAlarms,
    searchFilter,
    equipmentsFilter,
    statusFilter,
    favoriteFilter,
    favoritesState.measuresIds,
    table.order,
    table.orderBy,
    table.page,
    table.rowsPerPage,
    equipmentsLocationsState,
  ]);

  const getLengthByStatus = (status: string) => {
    switch (status) {
      case "warning":
        return measuresWithAlarms.filter(
          (e) =>
            e.strongestAlarm?.status.active && e.strongestAlarm?.priority === 2
        ).length;
      case "alert":
        return measuresWithAlarms.filter(
          (e) =>
            e.strongestAlarm?.status.active && e.strongestAlarm?.priority === 3
        ).length;
      case "inactive":
        return measuresWithAlarms.filter(
          (e) => !e.strongestAlarm?.status.active
        ).length;
      default:
        return 0;
    }
  };

  const TABS = [
    {
      value: "all",
      label: translate("All"),
      color: "info",
      count: measuresWithAlarms.length,
    },
    {
      value: "inactive",
      label: translate("Inactive"),
      color: "success",
      count: getLengthByStatus("inactive"),
    },
    {
      value: "warning",
      label: translate("Warning"),
      color: "warning",
      count: getLengthByStatus("warning"),
    },
    {
      value: "alert",
      label: translate("Alert"),
      color: "error",
      count: getLengthByStatus("alert"),
    },
  ] as const;

  const handleSearchFilter = (search: string) => {
    setSearchFilter(search);
    table.setPage(0);
  };

  const handleEquipmentsFilter = (filter: Equipment[]) => {
    setEquipmentsFilter(filter);
    table.setPage(0);
  };

  const toggleFavoritesFilter = () => {
    setFavoriteFilter(!favoriteFilter);
    table.setPage(0);
  };

  return (
    <Card
    // sx={{ backgroundColor: theme.palette.background.default }}
    >
      <Tabs
        allowScrollButtonsMobile
        variant="scrollable"
        scrollButtons="auto"
        value={statusFilter}
        onChange={onStatusFilter}
        sx={{
          px: 2,
          bgcolor: "background.neutral",
        }}
      >
        {TABS.map((tab) => (
          <Tab
            disableRipple
            key={tab.value}
            value={tab.value}
            icon={<Label color={tab.color}> {tab.count} </Label>}
            label={tab.label}
          />
        ))}
      </Tabs>

      <AlarmsTableToolbar
        searchFilter={searchFilter}
        onSearchFilterChange={handleSearchFilter}
        equipmentsFilter={equipmentsFilter}
        onEquipmentsFilterChange={handleEquipmentsFilter}
        onlyFavoritesFilter={favoriteFilter}
        onOnlyFavoritesFilterChange={() => toggleFavoritesFilter()}
        displayMode={displayMode}
        onDisplayModeChange={(mode) => setDisplayMode(mode)}
        sx={{
          // backgroundColor: theme.palette.background.default,
          py: 2.5,
          px: 2,
        }}
      />

      {alarmsState.error ? (
        <Error500 />
      ) : !measuresWithAlarmsLoaded ? (
        <Box sx={{ height: "20vh", position: "relative" }}>
          <LoadingAnimation
            sx={{ backgroundColor: "#ffffff00", transform: "scale(0.7)" }}
          />
        </Box>
      ) : !measuresState.measures.length ? (
        <EmptyContent
          title={translate("No alarm found")}
          description={translate(
            "You should setup thresholds on a measure to see alarms status."
          )}
        />
      ) : !filteredMeasures.length ? (
        <EmptyContent
          title={translate("No alarm found")}
          description={translate(
            "You should change the filters to see alarms status."
          )}
        />
      ) : displayMode === "card" ? (
        <AlarmsStatusCards
          measures={filteredMeasures}
          setDrawerOpen={setDrawerOpen}
          setDrawerMeasure={setDrawerMeasure}
        />
      ) : (
        <AlarmsStatusTable
          measures={filteredMeasures}
          setDrawerOpen={setDrawerOpen}
          setDrawerMeasure={setDrawerMeasure}
        />
      )}

      <TableFooter
        rowsPerPageOptions={[10, 20, 50]}
        component="div"
        count={filteredMeasures.length}
        rowsPerPage={table.rowsPerPage}
        page={table.page}
        onPageChange={(evt, page) => table.setPage(page)}
        onRowsPerPageChange={(evt) =>
          table.setRowsPerPage(parseInt(evt.target.value, 10))
        }
        labelRowsPerPage={
          <Box component="span" sx={{ display: { xs: "none", sm: "block" } }}>
            {translate("Items per page:")}
          </Box>
        }
      />
      {/* <TablePagination
        rowsPerPageOptions={[10, 20, 50]}
        component="div"
        count={filteredMeasures.length}
        rowsPerPage={table.rowsPerPage}
        page={table.page}
        onPageChange={(evt, val) => table.setPage(val)}
        onRowsPerPageChange={(evt) =>
          table.setRowsPerPage(parseInt(evt.target.value, 10))
        }
        labelRowsPerPage={
          <Box component="span" sx={{ display: { xs: "none", sm: "block" } }}>
            {translate("Items per page:")}
          </Box>
        }
      /> */}
    </Card>
  );
}
