import { Dashboard } from "src/@types";
import axios from "src/utils/axios";

class DashboardService {
  get(interval: string) {
    return axios.get<Dashboard | undefined>(`/dashboard/${interval}`);
  }
}

const dashboardService = new DashboardService();
export default dashboardService;
