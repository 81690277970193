import {
  Box,
  Button,
  Card,
  Divider,
  Grid,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { SxProps } from "@mui/material/styles";
import { diff } from "deep-diff";
import { DateTime } from "luxon";
import { useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";

import { Attachment, Patrol, PatrolMeasure } from "src/@types";
import ConfirmDialog from "src/components/generic/dialog/ConfirmDialog";
import ProgressDialog from "src/components/generic/dialog/ProgressDialog";
import Iconify from "src/components/generic/Iconify";
import { useFormat, useLocales, useResponsiveShortcut } from "src/hooks";
import { useSelector } from "src/redux/store";
import { patrolService } from "src/services";

import PreviousAttachmentsBlock from "../view/AttachmentsBlock";
import PreviousNoteBlock from "../view/NoteBlock";
import PreviousValueBlock from "../view/ValueBlock";
import AttachmentsBlock from "./AttachmentsBlock";
import NoteBlock from "./NoteBlock";
import ValueBlock from "./ValueBlock";

const copyMeasure = async (
  from: { patrol: Patrol; measure: PatrolMeasure },
  to: PatrolMeasure
) => {
  const promises = (from.measure.attachments || []).map((attachment) => {
    return new Promise<any>(async (resolve, reject) => {
      try {
        const response = await patrolService.getAttachment(
          from.patrol._id,
          from.measure.identifier,
          attachment["aws-s3"].Key
        );
        const file = {
          id: attachment.id,
          name: attachment.name,
          extension: attachment.extension,
          type: attachment.type,
          content: Buffer.from(await response.data.arrayBuffer()).toString(
            "base64"
          ),
        };
        resolve(file);
      } catch (e) {
        reject(e);
      }
    });
  });
  return {
    ...to,
    value: from.measure.value,
    note: from.measure.note,
    files: await Promise.all(promises),
  };
};

type Props = {
  patrol: Patrol;
  measure: PatrolMeasure;
  onMeasureUpdated: (measure: PatrolMeasure) => void;
  previous?: {
    patrol: Patrol;
    measure: PatrolMeasure;
  };
  sx?: SxProps;
};

export default function MeasureForm({
  patrol,
  measure,
  onMeasureUpdated,
  previous,
  sx,
}: Props) {
  const theme = useTheme();
  const { translate } = useLocales();
  const { formatDate } = useFormat();
  const { isMdUp } = useResponsiveShortcut();

  let timeoutHandle = useRef<NodeJS.Timeout | undefined>();

  const valueBlockRef = useRef<HTMLDivElement>(null);
  const noteBlockRef = useRef<HTMLDivElement>(null);
  const attachmentsBlockRef = useRef<HTMLDivElement>(null);
  const previousValueBlockRef = useRef<HTMLDivElement>(null);
  const previousNoteBlockRef = useRef<HTMLDivElement>(null);
  const previousAttachmentsBlockRef = useRef<HTMLDivElement>(null);

  const socketIoState = useSelector((state) => state.socketIo);
  const [measureState, setMeasureState] = useState(measure);
  const [copyConfirmDialogOpen, setCopyConfirmDialogOpen] = useState(false);
  const [copyProgressDialogOpen, setCopyProgressDialogOpen] = useState(false);

  const [valueMaxHeight, setValueMaxHeight] = useState<number>();
  const [noteMaxHeight, setNoteMaxHeight] = useState<number>();
  const [attachmentsMaxHeight, setAttachmentsMaxHeight] = useState<number>();

  const measureModel = useMemo(
    () =>
      patrol.model.measures.find((m) => m.identifier === measure.identifier),
    [patrol, measure]
  );

  useLayoutEffect(() => {
    if (!valueBlockRef.current || !previousValueBlockRef.current || !isMdUp)
      return;
    setValueMaxHeight(
      Math.max(
        valueBlockRef.current.clientHeight,
        previousValueBlockRef.current.clientHeight
      )
    );
  }, [valueBlockRef.current, previousValueBlockRef.current]);

  useLayoutEffect(() => {
    if (!noteBlockRef.current || !previousNoteBlockRef.current || !isMdUp)
      return;
    setNoteMaxHeight(
      Math.max(
        noteBlockRef.current.clientHeight,
        previousNoteBlockRef.current.clientHeight
      )
    );
  }, [noteBlockRef.current, previousNoteBlockRef.current]);

  useLayoutEffect(() => {
    if (
      !attachmentsBlockRef.current ||
      !previousAttachmentsBlockRef.current ||
      !isMdUp
    )
      return;
    setAttachmentsMaxHeight(
      Math.max(
        attachmentsBlockRef.current.clientHeight,
        previousAttachmentsBlockRef.current.clientHeight
      )
    );
  }, [attachmentsBlockRef.current, previousAttachmentsBlockRef.current]);

  useEffect(() => setMeasureState(measure), [measure]);

  useEffect(() => {
    if (!measureModel) return;
    if (timeoutHandle.current) {
      clearTimeout(timeoutHandle.current);
    }
    timeoutHandle.current = setTimeout(() => {
      let diffs = diff(measure, measureState) || [];
      console.log(
        "Measure raw diffs",
        diffs.map((d: any) => ({ ...d, path: d.path?.join("/") }))
      );
      // diffs = diffs.filter(
      //   (d: any) =>
      //     (d.lhs !== undefined && d.rhs !== undefined) || d.kind === "A"
      // );
      // console.log(
      //   "Measure filtered diffs",
      //   diffs.map((d: any) => ({ ...d, path: d.path?.join("/") }))
      // );
      if (!diffs.length) return;
      const updatedMeasure = {
        ...measureState,
        done: false,
        doneTime: undefined,
      };
      if (measureModel.type === "string" && measure.value === "") {
        updatedMeasure.value = undefined;
      }
      onMeasureUpdated(updatedMeasure);
    }, 300);
  }, [measureState]);

  if (!measureModel) return null;

  return (
    <>
      <Grid container rowSpacing={2} columnSpacing={2}>
        <Grid item xs={12} md={6} order={{ xs: 1, md: 1 }}></Grid>
        <Grid item xs={12} md={6} order={{ xs: 2, md: 3 }}>
          <Stack
            flex={1}
            gap={2}
            // sx={{ py: 2 }}
          >
            <ValueBlock
              ref={valueBlockRef}
              measureModel={measureModel}
              measure={measureState}
              setMeasure={setMeasureState}
              sx={{ minHeight: valueMaxHeight }}
            />
            <NoteBlock
              ref={noteBlockRef}
              measure={measureState}
              setMeasure={setMeasureState}
              sx={{ minHeight: noteMaxHeight }}
            />
            <AttachmentsBlock
              ref={attachmentsBlockRef}
              measure={measureState}
              setMeasure={setMeasureState}
              getAttachmentBaseUrl={(attachment: Attachment) =>
                [
                  window.env.REACT_APP_EYE_BACKEND_URL,
                  "patrols",
                  patrol._id,
                  "measures",
                  measure.identifier,
                  "attachments",
                  encodeURIComponent(attachment["aws-s3"].Key),
                ].join("/")
              }
              sx={{ minHeight: attachmentsMaxHeight }}
            />
          </Stack>
        </Grid>
        <Grid item xs={12} md={6} order={{ xs: 3, md: 2 }}>
          {previous ? (
            <Stack
              direction="row"
              alignItems="start"
              justifyContent="space-between"
              sx={{ ml: 2 }}
            >
              <Stack direction="column">
                <Typography variant="body2" noWrap component="div">
                  {translate("Previous measure")}
                </Typography>
                <Typography variant="subtitle2" noWrap component="div">
                  {formatDate(previous.patrol.startTime, DateTime.DATETIME_MED)}
                </Typography>
              </Stack>
              <Button
                startIcon={<Iconify icon="fa-solid:copy" />}
                onClick={async () => setCopyConfirmDialogOpen(true)}
                size="small"
                disabled={!socketIoState.connected}
              >
                {translate("Copy")}
              </Button>
            </Stack>
          ) : null}
        </Grid>
        <Grid item xs={12} md={6} order={{ xs: 4, md: 4 }}>
          {previous ? (
            <Stack gap={2}>
              <Stack direction="row" gap={2}>
                <Divider orientation="vertical" flexItem />
                <PreviousValueBlock
                  ref={previousValueBlockRef}
                  measureModel={measureModel}
                  measure={previous.measure}
                  showLabel={true}
                  sx={{ flex: 1, minHeight: valueMaxHeight }}
                />
              </Stack>
              <Stack direction="row" gap={2}>
                <Divider orientation="vertical" flexItem />
                <PreviousNoteBlock
                  ref={previousNoteBlockRef}
                  measure={previous.measure}
                  sx={{ flex: 1, minHeight: noteMaxHeight }}
                />
              </Stack>
              <Stack direction="row" gap={2}>
                <Divider orientation="vertical" flexItem />
                <PreviousAttachmentsBlock
                  ref={previousAttachmentsBlockRef}
                  measure={previous.measure}
                  getAttachmentBaseUrl={(attachment: Attachment) =>
                    [
                      window.env.REACT_APP_EYE_BACKEND_URL,
                      "patrols",
                      previous.patrol._id,
                      "measures",
                      previous.measure.identifier,
                      "attachments",
                      encodeURIComponent(attachment["aws-s3"].Key),
                    ].join("/")
                  }
                  sx={{ flex: 1, minHeight: attachmentsMaxHeight }}
                />
              </Stack>
            </Stack>
          ) : null}
        </Grid>
      </Grid>
      <Stack direction={{ xs: "column", md: "row" }} gap={2} sx={sx}></Stack>
      {previous && copyConfirmDialogOpen ? (
        <ConfirmDialog
          open={copyConfirmDialogOpen}
          onClose={() => setCopyConfirmDialogOpen(false)}
          title={
            <Typography variant="subtitle2">{translate("Warning")}</Typography>
          }
          content={
            <>
              <Typography>
                {translate(
                  "You are about to copy previous measure to current measure."
                )}
              </Typography>
              <Typography>
                {translate("This will overwrite the current measure.")}
              </Typography>
              <Typography>
                {translate("Are you sure you want to continue?")}
              </Typography>
              <Typography>
                <Iconify
                  icon={"fa-pro-duotone:triangle-exclamation"}
                  sx={{ mr: 1 }}
                />
                {translate("Please be careful, this operation is irreversible")}
              </Typography>
            </>
          }
          actions={
            <>
              <Button
                variant="outlined"
                color="inherit"
                onClick={() => setCopyConfirmDialogOpen(false)}
              >
                {translate("Cancel")}
              </Button>
              <Button
                variant="contained"
                color="info"
                onClick={async () => {
                  setCopyConfirmDialogOpen(false);
                  setCopyProgressDialogOpen(true);
                  setMeasureState(await copyMeasure(previous, measureState));
                  setCopyProgressDialogOpen(false);
                }}
              >
                {translate("Copy")}
              </Button>
            </>
          }
        />
      ) : null}
      {copyProgressDialogOpen ? (
        <ProgressDialog
          open={copyProgressDialogOpen}
          onClose={() => setCopyConfirmDialogOpen(false)}
          title={
            <Typography variant="subtitle2">
              {translate("Copying...")}
            </Typography>
          }
        />
      ) : null}
    </>
  );
}
