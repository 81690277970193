import Collection from "./Collection";
import { Patrol, User } from "../@types";

export default class LokiStore {
  Patrols: Collection<Patrol>;
  Users: Collection<User>;
  private port: MessagePort | DedicatedWorkerGlobalScope;

  constructor(port: MessagePort | DedicatedWorkerGlobalScope) {
    this.Patrols = new Collection<Patrol>(this, "patrols");
    this.Users = new Collection<User>(this, "users");
    this.port = port;
    if (!this.port) {
      throw new Error("No port set");
    }
  }

  init() {
    const testDatabase = () =>
      new Promise((resolve, reject) => {
        this.port.onmessage = (event: MessageEvent) => {
          if (event.data.error) {
            return reject(event.data.error);
          }
          resolve(event.data.response);
        };
        this.port.postMessage({ command: "test" });
      });
    return new Promise((resolve) => {
      const intervalHandler = setInterval(async () => {
        try {
          await testDatabase();
          clearInterval(intervalHandler);
          resolve(undefined);
        } catch (e) {}
      }, 250);
    });
  }

  private runCommand(command: string, args: any) {
    // console.log (new Error()).stack
    return new Promise<any>((resolve, reject) => {
      // Setup temporary communication port
      const channel = new MessageChannel();
      const port = channel.port1;
      this.port.postMessage(
        {
          addPort: {
            name: Math.round(1000 * Math.random()),
            port: channel.port2,
            temporary: true,
          },
        },
        [channel.port2]
      );
      // Communicate on this temporary port
      const listener = (event: MessageEvent) => {
        port.removeEventListener("message", listener);
        port.close();
        if (event.data.error) {
          return reject(event.data.error);
        }
        // console.log "Command result", command, args, event.data.response
        resolve(event.data.response);
      };
      port.addEventListener("message", listener);
      port.start();
      port.postMessage({ command, ...args });
    });
  }

  // Fetch item in collection
  // collectionName	string		'patrols'
  // query					object		{ createdOn: { "$gte": "2022-04-01" } }
  findOne(collectionName: string, query?: object) {
    return this.runCommand("findOne", { collectionName, query });
  }

  // Fetch items in collection
  // collectionName	string		'patrols'
  // query					object		{ createdOn: { "$gte": "2022-04-01" } }
  // sort						array			[ "-createdOn" ]
  // pagination			object		{ skip: 10, limit: 100 }
  find(
    collectionName: string,
    query?: object,
    sort?: string | string[],
    pagination?: {}
  ) {
    return this.runCommand("find", {
      collectionName,
      query,
      sort,
      pagination,
    }) as Promise<any[]>;
  }

  count(collectionName: string, query?: object) {
    return this.runCommand("count", {
      collectionName,
      query,
    }) as Promise<number>;
  }

  // findAndUpdate(collectionName: string, query?: object, updateFn: Function) {
  //  	this.runCommand("findAndUpdate", { collectionName, query, updateFn })
  // }

  insert(collectionName: string, items: object | object[]) {
    return this.runCommand("insert", { collectionName, items });
  }

  update(collectionName: string, item: object) {
    return this.runCommand("update", { collectionName, item });
  }

  save(collectionName: string, item: object) {
    return this.runCommand("save", { collectionName, item });
  }

  remove(collectionName: string, doc: object) {
    return this.runCommand("remove", { collectionName, doc });
  }

  findAndRemove(collectionName: string, query?: object) {
    return this.runCommand("findAndRemove", { collectionName, query });
  }

  clear(collectionName: string) {
    return this.runCommand("clear", { collectionName });
  }

  // clearAll: ->
  // 	@_runCommand "clearAll", {}
}
