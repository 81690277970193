import {
  Box,
  Button,
  LinearProgress,
  MenuItem,
  MenuList,
  Stack,
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableCellProps,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import { DateTime } from "luxon";
import { useState } from "react";
import { useNavigate } from "react-router";
import { sprintf } from "sprintf-js";

import { Patrol, PatrolDisplayMode } from "src/@types";
import ConfirmDialog from "src/components/generic/dialog/ConfirmDialog";
import Iconify from "src/components/generic/Iconify";
import LoadingAnimation from "src/components/generic/LoadingAnimation";
import Scrollbar from "src/components/generic/Scrollbar";
import {
  TableHeadCell,
  TableHeadCustom,
  TableMoreMenu,
  TableNoData,
} from "src/components/generic/table";
import { useFormat, useLocales, useResponsiveShortcut } from "src/hooks";
import { TableColumnDefinition, TableParams } from "src/hooks/useTable";
import { PATH_DASHBOARD } from "src/routes/paths";
import PatrolStatusLabel from "./PatrolStatusLabel";
import { PatrolWithSyncState } from "./PatrolsList";
import { useSelector } from "src/redux/store";

type Props = {
  patrols: PatrolWithSyncState[];
  loading: boolean;
  table: TableParams;
  mode: "simple" | "full";
  handleDelete?: (patrol: Patrol) => void;
  sx?: SxProps;
};

export default function PatrolsTable({
  patrols,
  loading,
  table,
  mode,
  handleDelete,
  sx,
}: Props) {
  const { translate } = useLocales();
  const navigate = useNavigate();
  const { formatDate } = useFormat();
  const { isMdUp, isSmUp } = useResponsiveShortcut();

  const authState = useSelector((state) => state.auth);
  const { isPatrolDeletable, isPatrolEditable } = useSelector(
    (state) => state.patrols
  );

  const [deleteDialogOpen, setDeleteDialogOpen] = useState<{
    [key: string]: boolean | undefined;
  }>({});

  const handleNavigation = (patrol: Patrol, displayMode: PatrolDisplayMode) => {
    const path =
      displayMode === "overview"
        ? PATH_DASHBOARD.patrols.overview(patrol._id)
        : displayMode === "view"
        ? PATH_DASHBOARD.patrols.view(patrol._id)
        : displayMode === "edit"
        ? PATH_DASHBOARD.patrols.edit(patrol._id)
        : undefined;
    if (!path) {
      throw new Error("");
    }
    navigate(path);
  };
  const handleOpenDeleteDialog = (patrol: Patrol) =>
    setDeleteDialogOpen({ [patrol._id]: true });
  const handleCloseDeleteDialog = (patrol: Patrol) =>
    setDeleteDialogOpen(
      Object.entries(deleteDialogOpen)
        .filter(([k, v]) => k !== patrol._id)
        .filter((item) => item)
        .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {})
    );

  const LinkTableCell = (props: TableCellProps & { item: Patrol }) => {
    return (
      <TableCell
        {...props}
        onClick={() => handleNavigation(props.item, "overview")}
        sx={{ cursor: "pointer" }}
      />
    );
  };

  const tableDefinitionTemplate: (
    | TableColumnDefinition<PatrolWithSyncState>
    | undefined
  )[] = [
    {
      head: {
        id: "startTime",
        label: translate("Start date"),
        align: "left",
      },
      Cell: ({ item }: { item: PatrolWithSyncState }) => (
        <LinkTableCell item={item}>
          {formatDate(item.startTime, DateTime.DATETIME_MED)}
        </LinkTableCell>
      ),
    },
    {
      head: {
        id: "operator.lastName",
        label: translate("Operator"),
        align: "left",
      },
      Cell: ({ item }: { item: PatrolWithSyncState }) => (
        <LinkTableCell item={item}>
          {item.operator.firstName} {item.operator.lastName}
        </LinkTableCell>
      ),
    },
    {
      head: {
        id: "location.label",
        label: translate("Location"),
        align: "left",
      },
      Cell: ({ item }: { item: PatrolWithSyncState }) => (
        <LinkTableCell item={item}>{item.location.label}</LinkTableCell>
      ),
    },
    {
      head: {
        id: "model.label",
        label: translate("Model"),
        align: "left",
      },
      Cell: ({ item }: { item: PatrolWithSyncState }) => (
        <LinkTableCell item={item}>{item.model.label}</LinkTableCell>
      ),
    },
    {
      head: {
        id: "progressPercent",
        label: translate("Progress"),
        align: "left",
      },
      Cell: ({ item }: { item: PatrolWithSyncState }) => (
        <LinkTableCell item={item}>
          <Stack gap={1}>
            <PatrolStatusLabel patrol={item} showProgress={true} />
            {item.progressPercent < 100 ? (
              <LinearProgress
                variant="determinate"
                value={item.progressPercent}
              />
            ) : null}
          </Stack>
        </LinkTableCell>
      ),
    },
    mode === "full"
      ? {
          head: {
            id: "synced",
            label: translate("Sync"),
            align: "left",
          },
          Cell: ({ item }: { item: PatrolWithSyncState }) => (
            <LinkTableCell item={item}>
              {!item.synced ? (
                !item.syncState ? (
                  <Box title={translate("Waiting for next synchronization")}>
                    <Iconify icon="ic:round-access-time" />
                  </Box>
                ) : item.syncState === "inProgress" ? (
                  <Box title={translate("Synchronization in progress")}>
                    <Iconify icon="line-md:loading-twotone-loop" />
                  </Box>
                ) : item.syncState === "error" ? (
                  <Box title={translate("Synchronization error")}>
                    <Iconify icon="line-md:loading-twotone-loop" />
                  </Box>
                ) : null
              ) : (
                <Box title={translate("Synchronized")}>
                  <Iconify icon="eva:checkmark-fill" />
                </Box>
              )}
            </LinkTableCell>
          ),
        }
      : undefined,
    mode === "full"
      ? {
          head: {
            id: "actions",
            // label: "",
            // align: "left",
          },
          Cell: ({ item }: { item: PatrolWithSyncState }) => (
            <TableCell align="right" sx={{ width: 0 }}>
              <TableMoreMenu>
                <MenuList>
                  <MenuItem onClick={() => handleNavigation(item, "overview")}>
                    <Iconify icon={"eva:eye-outline"} />
                    {translate("View")}
                  </MenuItem>
                  {authState.user && isPatrolEditable(item, authState.user) ? (
                    <MenuItem onClick={() => handleNavigation(item, "edit")}>
                      <Iconify icon={"eva:edit-fill"} />
                      {translate("Edit")}
                    </MenuItem>
                  ) : null}
                  {handleDelete &&
                  authState.user &&
                  isPatrolDeletable(item, authState.user) ? (
                    <MenuItem
                      onClick={(event) => handleOpenDeleteDialog(item)}
                      sx={{ color: "error.main" }}
                    >
                      <Iconify icon={"eva:trash-2-outline"} />
                      {translate("Delete")}
                    </MenuItem>
                  ) : null}
                </MenuList>
              </TableMoreMenu>
              {handleDelete ? (
                <ConfirmDialog
                  open={deleteDialogOpen[item._id] || false}
                  onClose={() => handleCloseDeleteDialog(item)}
                  title={
                    <Typography variant="subtitle2">
                      {translate("Patrol deletion")}
                    </Typography>
                  }
                  content={
                    <>
                      <Typography>
                        {translate("This patrol is about to be deleted:")}
                      </Typography>
                      <Typography sx={{ px: 1 }}>
                        {sprintf(translate("Identifier: %s"), item._id)}
                      </Typography>
                      <Typography sx={{ px: 1 }}>
                        {sprintf(
                          translate("Start time: %s"),
                          formatDate(item.startTime)
                        )}
                      </Typography>
                      <Typography>
                        {translate(
                          "Are you sure you want to delete this patrol?"
                        )}
                      </Typography>
                      <Typography>
                        <Iconify
                          icon={"fa-pro-duotone:triangle-exclamation"}
                          sx={{ mr: 1 }}
                        />
                        {translate(
                          "Please be careful, this operation is irreversible"
                        )}
                      </Typography>
                    </>
                  }
                  actions={
                    <>
                      <Button
                        variant="outlined"
                        color="inherit"
                        onClick={() => handleCloseDeleteDialog(item)}
                      >
                        {translate("Cancel")}
                      </Button>
                      <Button
                        variant="contained"
                        color="error"
                        onClick={() => {
                          handleDelete(item);
                          handleCloseDeleteDialog(item);
                        }}
                      >
                        {translate("Delete")}
                      </Button>
                    </>
                  }
                />
              ) : null}
            </TableCell>
          ),
        }
      : undefined,
  ];
  const tableDefinition = tableDefinitionTemplate.filter(
    (e) => e
  ) as TableColumnDefinition<PatrolWithSyncState>[];

  return (
    <Scrollbar>
      {loading ? (
        <Box marginTop={"17vh"}>
          <LoadingAnimation
            sx={{ backgroundColor: "#ffffff00", transform: "scale(0.7)" }}
          />
        </Box>
      ) : (
        <TableContainer sx={sx}>
          <Table size={table.dense ? "small" : "medium"}>
            <TableHeadCustom
              order={table.order}
              orderBy={table.orderBy}
              // headCells={TABLE_HEAD}
              headCells={tableDefinition.map((d) => d.head)}
              onSort={table.onSort}
            />
            <TableBody>
              {patrols.map((patrol, index) => (
                <TableRow key={index} hover>
                  {tableDefinition
                    .map((d) => d.Cell)
                    .map((Cell, index) => (
                      <Cell item={patrol} key={index} />
                    ))}
                </TableRow>
              ))}
              {/*patrols.map((patrol, index) => (
              <PatrolsTableRow
                key={index}
                patrol={patrol}
                syncing={patrolsSyncState[patrol._id]}
                handleDelete={handleDelete}
              />
            ))*/}
              <TableNoData isNotFound={!patrols.length} />
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Scrollbar>
  );
}
