import LokiStore from "./LokiStore";

let store: LokiStore | undefined = undefined;

const getDefaultPort: () => Promise<MessagePort> = () => {
  return new Promise((resolve, reject) => {
    const get = () => window.commPorts?.lokiStore;
    const port = get();
    if (port) return resolve(port);
    const intervalHandler = setInterval(() => {
      console.debug("Getting Lokijs webworker communication port...");
      const port = get();
      if (port) {
        console.debug("Got Lokijs webworker communication port.");
        clearInterval(intervalHandler);
        resolve(port);
      }
    }, 200);
  });
};

const get = async (port?: MessagePort | DedicatedWorkerGlobalScope) => {
  if (store) {
    return store;
  }
  if (typeof window !== "undefined") {
    port = await getDefaultPort();
  }
  if (!port) {
    throw new Error("Found no communication port with Lokijs webworker!");
  }
  store = new LokiStore(port);
  await store.init();
  return store;
};

export { get, LokiStore };
