import { AccessRights } from "src/@types";
import axios from "src/utils/axios";

class AccessRightsService {
  get() {
    return axios.get<AccessRights>("/accessrights");
  };
}

const accessRights = new AccessRightsService();
export default accessRights;
