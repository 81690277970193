import { Box, Card, CardProps, Stack } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { NavLink } from "react-router-dom";
import { Measure } from "src/@types";
import { ColorSchema } from "src/theme/palette";
import MeasureWidgetHeader from "./MeasureWidgetHeader";
import MeasureLastDataDisplay from "./MeasureLastDataDisplay";
import { useSelector } from "src/redux/store";
import { useLocales } from "src/hooks";
import AnimatedDots from "src/components/generic/AnimatedDots";

interface Props extends CardProps {
  measure: Measure;
  linkTo?: string;
  onMenuClick: () => void;
  color?: ColorSchema;
  children?: JSX.Element;
}

export default function ({
  measure,
  linkTo,
  onMenuClick,
  color = "primary",
  children,
  sx,
  ...other
}: Props) {
  const theme = useTheme();
  const { translate } = useLocales();
  const measuresState = useSelector((state) => state.measures);

  const Link = function ({ children }: any) {
    return linkTo ? (
      <NavLink
        to={linkTo || ""}
        style={{ textDecoration: "none", color: theme.palette.text.primary }}
      >
        {children}
      </NavLink>
    ) : (
      <Box>{children}</Box>
    );
  };

  return (
    <Card sx={{ cursor: "pointer", ...sx }} {...other}>
      <Stack>
        <MeasureWidgetHeader
          measure={measure}
          onMenuClick={onMenuClick}
          showLocation={false}
          showEquipment={false}
        />
        <Link>
          <Stack sx={{ height: "260px" }}>
            {!measuresState.loaded || !measuresState.dataLoaded ? (
              <>
                <Box sx={{ px: 2, py: 1 }}>
                  {translate("Loading")}
                  <AnimatedDots />
                </Box>
              </>
            ) : (
              <>
                <MeasureLastDataDisplay
                  measure={measure}
                  sx={{ px: 2, py: 1 }}
                />
                <Box sx={{ height: "200px" }}>{children}</Box>
              </>
            )}
          </Stack>
        </Link>
      </Stack>
    </Card>
  );
}
