import { Collapse, List } from "@mui/material";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { MenuItem } from "src/@types";
import { isActive } from "..";
import NavItemSub from "./NavItemSub";

type Props = {
  item: MenuItem;
};

export default function NavListSub({ item }: Props) {
  const { pathname } = useLocation();

  const active =
    item.path !== undefined ? isActive(item.path, pathname) : false;

  const [open, setOpen] = useState(active);

  const hasChildren = item.children;

  if (hasChildren) {
    return (
      <>
        <NavItemSub
          item={item}
          onOpen={() => setOpen(!open)}
          open={open}
          active={active}
        />

        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding sx={{ pl: 3 }}>
            {(item.children || []).map((item) => (
              <NavItemSub
                key={item.label + item.path}
                // key={item.id}
                item={item}
                active={
                  item.path !== undefined
                    ? isActive(item.path, pathname)
                    : false
                }
              />
            ))}
          </List>
        </Collapse>
      </>
    );
  }

  return <NavItemSub item={item} active={active} />;
}
