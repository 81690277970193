import {
  SerializedError,
  createAsyncThunk,
  createSlice,
} from "@reduxjs/toolkit";
import { User } from "src/@types";
import { userService } from "src/services";

interface UsersState {
  currentRequestId?: string;
  loading: boolean;
  loaded: boolean;
  users: User[];
  totalCount: number;
  // error?: SerializedError;
  error?: any;
}

const initialState: UsersState = {
  loading: false,
  loaded: false,
  users: [],
  totalCount: 0,
};

export const load = createAsyncThunk<
  { totalCount: number; users: User[] },
  undefined,
  { state: { users: UsersState } }
>("users-load", async (params, { getState, requestId }) => {
  const { currentRequestId, loading } = getState().users;
  if (!loading || requestId !== currentRequestId) {
    return {
      totalCount: initialState.totalCount,
      users: initialState.users,
    };
  }
  const response = await userService.get();
  return {
    totalCount: Number(response.headers["elements-total-count"]),
    users: response.data,
  };
});

export const saveUser = createAsyncThunk<
  { totalCount: number; users: User[] },
  User,
  { state: { users: UsersState }; serializedErrorType: any }
>(
  "users-save",
  async (user, { getState, requestId }) => {
    const { currentRequestId, loading } = getState().users;
    if (!loading || requestId !== currentRequestId) {
      return {
        totalCount: initialState.totalCount,
        users: initialState.users,
      };
    }
    const response = await userService.save(user);
    const savedUser = response.data;
    if (user._id) {
      return {
        totalCount: getState().users.totalCount,
        users: [
          ...getState().users.users.filter((entry) => entry._id !== user._id),
          savedUser,
        ],
      };
    } else {
      return {
        totalCount: getState().users.totalCount + 1,
        users: [...getState().users.users, savedUser],
      };
    }
  },
  {
    serializeError: (error) => error,
  }
);

export const deleteUser = createAsyncThunk<
  { totalCount: number; users: User[] },
  string,
  { state: { users: UsersState } }
>("users-delete", async (id, { getState, requestId }) => {
  await userService.delete(id);
  return {
    totalCount: getState().users.totalCount - 1,
    users: getState().users.users.filter((entry) => entry._id !== id),
  };
});

const slice = createSlice({
  name: "users",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Load
      .addCase(load.pending, (state, action) => {
        if (!state.loading) {
          state.loading = true;
          state.currentRequestId = action.meta.requestId;
        }
      })
      .addCase(load.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (state.loading && state.currentRequestId === requestId) {
          state.loading = false;
          state.error = undefined;
          state.users = action.payload.users;
          state.totalCount = action.payload.totalCount;
          state.currentRequestId = undefined;
          state.loaded = true;
        }
      })
      .addCase(load.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (state.loading && state.currentRequestId === requestId) {
          state.loading = false;
          // console.log("action.error", JSON.stringify(action.error, null, 2));
          state.error = action.error;
          state.currentRequestId = undefined;
        }
      })
      // Save
      .addCase(saveUser.pending, (state, action) => {
        if (!state.loading) {
          state.loading = true;
          state.currentRequestId = action.meta.requestId;
        }
      })
      .addCase(saveUser.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (state.loading && state.currentRequestId === requestId) {
          state.loading = false;
          state.error = undefined;
          state.users = action.payload.users;
          state.totalCount = action.payload.totalCount;
          state.currentRequestId = undefined;
          state.loaded = true;
        }
      })
      .addCase(saveUser.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (state.loading && state.currentRequestId === requestId) {
          state.loading = false;
          // console.log("action.error", action.error);
          state.error = action.error;
          state.currentRequestId = undefined;
        }
      })
      // Delete
      .addCase(deleteUser.pending, (state, action) => {
        if (!state.loading) {
          state.loading = true;
          state.currentRequestId = action.meta.requestId;
        }
      })
      .addCase(deleteUser.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (state.loading && state.currentRequestId === requestId) {
          state.loading = false;
          state.users = action.payload.users;
          state.totalCount = action.payload.totalCount;
          state.currentRequestId = undefined;
          state.loaded = true;
        }
      })
      .addCase(deleteUser.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (state.loading && state.currentRequestId === requestId) {
          state.loading = false;
          // console.log("action.error", JSON.stringify(action.error, null, 2));
          state.error = action.error;
          state.currentRequestId = undefined;
        }
      });
  },
});

export default slice;
