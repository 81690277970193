import { blue, green } from "@mui/material/colors";
import { upperCase } from "lodash";
import { User } from "src/@types";
import Avatar from "src/components/generic/Avatar";

type Props = {
  user: User;
};

export default function UserAvatar({ user }: Props) {
  const displayStr = upperCase(
    user.firstName.slice(0, 1) + user.lastName.slice(0, 1)
  );

  let color = undefined;
  if (user.role === "GOD") color = { bgcolor: green[900] };
  else if (user.role === "ADMIN") color = { bgcolor: blue[900] };
  else if (user.role === "USER") color = { bgcolor: blue[100] };

  return <Avatar sx={{ ...color }}>{displayStr}</Avatar>;
}
