import { Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Measure } from "src/@types";
import FavoriteIcon from "src/components/generic/FavoriteIcon";
import LabelBadge from "src/components/generic/LabelBadge";
import { dispatch, useSelector } from "src/redux/store";
import { PATH_DASHBOARD } from "src/routes/paths";
import MeasureMenuButton from "../measure/MeasureMenuButton";
import { toggle as toggleMeasure } from "src/redux/slices/favorites";

type Props = {
  measure: Measure;
  onMenuClick: () => void;
  showLocation: boolean;
  showEquipment: boolean;
};

export default function MeasureWidgetHeader({
  measure,
  onMenuClick,
  showLocation,
  showEquipment,
}: Props) {
  const favoritesState = useSelector((state) => state.favorites);
  const navigate = useNavigate();
  const equipmentsLocationsState = useSelector(
    (state) => state.equipmentsLocations
  );

  const locationLabel = equipmentsLocationsState.equipmentsLocations.find(
    (e) => e.identifier === measure.equipment?.locationId
  )?.label;

  return (
    <Stack direction="row" gap={1} sx={{ p: 2, backgroundColor: "primary" }}>
      <Stack
        direction="column"
        gap={1}
        sx={{ width: "calc(100% - 44px - 8px)" }}
      >
        <Stack direction="row" alignItems="center" gap={1}>
          <FavoriteIcon
            isFavorite={
              favoritesState.measuresIds.findIndex(
                (measureId) => measureId === measure.identifier
              ) !== -1
            }
            toggleFavorite={() => dispatch(toggleMeasure(measure.identifier))}
          />
          <Typography
            variant="widgetTitle"
            sx={{ cursor: "pointer" }}
            noWrap
            onClick={() =>
              navigate(PATH_DASHBOARD.measures.details(measure.identifier))
            }
            title={measure.label}
          >
            {measure.label}
          </Typography>
          {measure.strongestAlarm?.metadata.measureMachineName && (
            <LabelBadge
              sx={{ mr: 1 }}
              text={measure.strongestAlarm?.metadata.measureMachineName}
            />
          )}
        </Stack>
        <Stack direction="row" gap={1}>
          {showLocation && locationLabel && (
            <LabelBadge
              sx={{ cursor: "default" }}
              text={locationLabel}
              color="default"
            />
          )}
          {showEquipment && (
            <LabelBadge
              sx={{ cursor: "default" }}
              text={measure.equipment?.label || ""}
              color="default"
            />
          )}
        </Stack>
      </Stack>
      <MeasureMenuButton measure={measure} onClick={() => onMenuClick()} />
    </Stack>
  );
}
