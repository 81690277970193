const ROOTS_AUTH = "/auth";
const ROOTS = "/";
const ROOTS_DASHBOARD = "/dashboard";
const ROOTS_FAVORITES = "/favorites";
const ROOTS_USER = "/user";
const ROOTS_ALARMS = "/alarms";
const ROOTS_MEASURE = "/measures";
const ROOTS_LOGBOOK = "/logbook";
const ROOTS_PATROLS = "/patrols";

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: `${ROOTS_AUTH}/login`,
  register: `${ROOTS_AUTH}/register`,
  loginUnprotected: `${ROOTS_AUTH}/login-unprotected"`,
  registerUnprotected: `${ROOTS_AUTH}/register-unprotected"`,
  verify: `${ROOTS_AUTH}/verify"`,
  resetPassword: `${ROOTS_AUTH}/reset-password"`,
  newPassword: `${ROOTS_AUTH}/new-password"`,
};

export const PATH_PAGE = {
  comingSoon: "/coming-soon",
  maintenance: "/maintenance",
  page403: "/403",
  page404: "/404",
};

export const PATH_DASHBOARD = {
  root: ROOTS,
  dashboard: ROOTS_DASHBOARD,
  favorites: ROOTS_FAVORITES,
  user: {
    root: ROOTS_USER,
    list: `${ROOTS_USER}/list`,
    create: `${ROOTS_USER}/create`,
    edit: (id: string) => `${ROOTS_USER}/${id}/edit`,
    account: `${ROOTS_USER}/account`,
    rights: `${ROOTS_USER}/access_rights`,
  },
  logbook: {
    list: `${ROOTS_LOGBOOK}/list`,
    create: `${ROOTS_LOGBOOK}/create`,
    show: (id: string) => `${ROOTS_LOGBOOK}/show/${id}`,
    update: (id: string) => `${ROOTS_LOGBOOK}/update/${id}`,
  },
  alarms: {
    root: ROOTS_ALARMS,
    filter: (measureId: string) =>
      `${ROOTS_ALARMS}/log${measureId ? "?search=" + measureId : ""}`,
    status: `${ROOTS_ALARMS}/status`,
  },
  measures: {
    root: ROOTS_MEASURE,
    details: (measureId: string) =>
      `${ROOTS_MEASURE}/${encodeURIComponent(measureId)}`,
    location: (locationId: string) => `${ROOTS_MEASURE}/location/${locationId}`,
    equipment_type: (equipmentTypeId: string) =>
      `${ROOTS_MEASURE}/equipment_type/${equipmentTypeId}`,
    equipment_type_location: (equipmentTypeId: string, locationId: string) =>
      `${ROOTS_MEASURE}/equipment_type/${equipmentTypeId}/location/${locationId}`,
    equipment: (equipmentId: string) =>
      `${ROOTS_MEASURE}/equipment/${equipmentId}/overview`,
    equipment_domain: (equipmentId: string, domainId: string) =>
      `${ROOTS_MEASURE}/${equipmentId}/domain/${domainId}`,
  },
  patrols: {
    root: ROOTS_PATROLS,
    list: `${ROOTS_PATROLS}/list`,
    create: `${ROOTS_PATROLS}/create`,
    edit: (id: string, measureId?: string) =>
      `${ROOTS_PATROLS}/${id}/edit/${measureId || ""}`,
    view: (id: string, measureId?: string) =>
      `${ROOTS_PATROLS}/${id}/view/${measureId || ""}`,
    overview: (id: string) => `${ROOTS_PATROLS}/${id}/overview`,
  },
  permissionDenied: `${ROOTS_DASHBOARD}/permission-denied`,
};

export const PATH_DOCS = "https://docs-minimals.vercel.app/introduction";
