import { Stack, SxProps, Typography, useTheme } from "@mui/material";
import numeral from "numeral";
import { sprintf } from "sprintf-js";
import { PatrolMeasure, PatrolModelMeasure } from "src/@types";
import { useLocales } from "src/hooks";
import BaseBlock, { NotSet } from "./BaseBlock";
import { forwardRef } from "react";

type Props = {
  measureModel: PatrolModelMeasure;
  measure: PatrolMeasure;
  showLabel: boolean;
  sx?: SxProps;
};

export default forwardRef<HTMLDivElement, Props>(function ValueBlock(
  { measureModel, measure, showLabel, sx }: Props,
  ref
) {
  const theme = useTheme();
  const { translate } = useLocales();

  return (
    <Stack ref={ref} gap={3} sx={sx}>
      <BaseBlock
        label={showLabel ? measureModel.label : null}
        component={
          measure.value === undefined ? (
            <NotSet />
          ) : measureModel.type === "boolean" ? (
            <Stack direction="row" gap={2}>
              <Typography
                color={
                  measure.value === true
                    ? theme.palette.text.primary
                    : theme.palette.text.disabled
                }
                fontWeight={measure.value === true ? 700 : undefined}
              >
                {translate("Yes")}
              </Typography>
              <Typography
                color={
                  measure.value === false
                    ? theme.palette.text.primary
                    : theme.palette.text.disabled
                }
                fontWeight={measure.value === false ? 700 : undefined}
              >
                {translate("No")}
              </Typography>
            </Stack>
          ) : measureModel.type === "choice" ? (
            <Stack direction="row" gap={2}>
              {(measureModel.choices || []).map((choice) => (
                <Typography
                  key={choice}
                  color={
                    measure.value === choice
                      ? theme.palette.text.primary
                      : theme.palette.text.disabled
                  }
                  fontWeight={measure.value === choice ? 700 : undefined}
                >
                  {choice}
                </Typography>
              ))}
            </Stack>
          ) : measureModel.type === "string" ? (
            <Typography
              color={theme.palette.text.primary}
              fontWeight={measure.value !== undefined ? 700 : undefined}
              style={{ whiteSpace: "pre-line" }}
            >
              {measure.value}
            </Typography>
          ) : measureModel.type === "number" ? (
            <Typography
              color={theme.palette.text.primary}
              fontWeight={measure.value !== undefined ? 700 : undefined}
            >
              {sprintf(
                "%s %s",
                numeral(measure.value).format("0.[0000]"),
                measureModel.unit || ""
              )}
            </Typography>
          ) : null
        }
      />
    </Stack>
  );
});
