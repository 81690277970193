import { Box, BoxProps, useTheme } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

import logoBlack from "src/assets/logo/Mobapi.EYE_full_eye_black.png";
import logoWhite from "src/assets/logo/Mobapi.EYE_full_eye_white.png";

interface Props extends BoxProps {
  disabledLink?: boolean;
}

export default function LogoFull({ disabledLink = false, sx }: Props) {
  const theme = useTheme();

  const logoFile = theme.palette.mode === "light" ? logoBlack : logoWhite;

  const logo = (
    <Box sx={sx}>
      <img src={logoFile} alt="logo" />
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="/">{logo}</RouterLink>;
}
