import {
  SerializedError,
  createAsyncThunk,
  createSlice,
} from "@reduxjs/toolkit";
import { MenuItemConf, MenuItem } from "src/@types";
import { createItemObjectFromMenu } from "src/layouts/dashboard/navbar/menuParser";
import { userService } from "src/services";

interface MenuState {
  currentRequestId?: string;
  loading: boolean;
  loaded: boolean;
  menu: MenuItemConf[];
  parsedMenu: MenuItem[];
  error?: SerializedError;
}

const initialState: MenuState = {
  loading: false,
  loaded: false,
  menu: [],
  parsedMenu: [],
};

export const load = createAsyncThunk<
  { menu: MenuItemConf[]; parsedMenu: MenuItem[] } | undefined,
  undefined,
  { state: { menu: MenuState } }
>("menu", async (params, { getState, requestId }) => {
  const { currentRequestId, loading } = getState().menu;
  if (!loading || requestId !== currentRequestId) {
    return;
  }
  const response = await userService.getMenu();
  const menu = response.data;
  const parsedMenu = createItemObjectFromMenu(menu);
  return { menu, parsedMenu };
});

const slice = createSlice({
  name: "menu",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(load.pending, (state, action) => {
        if (!state.loading) {
          state.loading = true;
          state.currentRequestId = action.meta.requestId;
        }
      })
      .addCase(load.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (state.loading && state.currentRequestId === requestId) {
          state.loading = false;
          if (action.payload) {
            state.menu = action.payload.menu;
            state.parsedMenu = action.payload.parsedMenu;
          }
          state.currentRequestId = undefined;
          state.loaded = true;
        }
      })
      .addCase(load.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (state.loading && state.currentRequestId === requestId) {
          state.loading = false;
          // console.log("action.error", JSON.stringify(action.error, null, 2));
          state.error = action.error;
          state.currentRequestId = undefined;
        }
      });
  },
});

export default slice;
