import { Paper, PaperProps, Typography } from "@mui/material";
import useLocales from "../../hooks/useLocales";

// ----------------------------------------------------------------------

interface Props extends PaperProps {
  searchQuery?: string;
}

export default function SearchNotFound({ searchQuery = "", ...other }: Props) {
  const { translate } = useLocales();
  return searchQuery ? (
    <Paper {...other}>
      <Typography gutterBottom align="center" variant="subtitle1">
        {translate("Not found")}
      </Typography>
      <Typography variant="body2" align="center">
        {translate("No results found for")} &nbsp;
        <strong>&quot;{searchQuery}&quot;</strong>.
        {translate("Try checking for typos or using complete words.")}
      </Typography>
    </Paper>
  ) : (
    <Typography variant="body2"> Please enter keywords</Typography>
  );
}
