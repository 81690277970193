import {
  Box,
  Card,
  Divider,
  IconButton,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { SxProps } from "@mui/material/styles";
import { DateTime } from "luxon";
import { useEffect, useMemo, useState } from "react";
import { Patrol, PatrolMeasure } from "src/@types";
import Iconify from "src/components/generic/Iconify";
import Scrollbar from "src/components/generic/Scrollbar";
import SwipeableDrawer from "src/components/generic/SwipeableDrawer";
import { useFormat, useLocales, useStore } from "src/hooks";
import MeasureView from "../view/MeasureView";

type PatrolAndMeasure = {
  patrol: Patrol;
  measure: PatrolMeasure;
};

type Props = {
  patrol: Patrol;
  measure: PatrolMeasure;
  open: boolean;
  onOpen: VoidFunction;
  onClose: VoidFunction;
  sx?: SxProps;
};

export default function PatrolMeasureHistoryDrawer({
  patrol,
  measure,
  open,
  onOpen,
  onClose,
  sx,
}: Props) {
  const theme = useTheme();
  const { formatDate } = useFormat();
  const { translate } = useLocales();
  const { getStore } = useStore();
  const [previousPatrolsAndMeasures, setPreviousPatrolsAndMeasures] = useState<
    PatrolAndMeasure[]
  >([]);
  const measureModel = useMemo(
    () =>
      patrol.model.measures.find((m) => m.identifier === measure.identifier),
    [patrol, measure]
  );

  useEffect(() => {
    if (!patrol || !measure) return;
    const fn = async () => {
      const store = await getStore();
      const previousPatrolsAndMeasures = await store.Patrols.find(
        {
          "model.identifier": patrol.model.identifier,
          startTime: { $lt: patrol.startTime },
          done: true,
        },
        "-startTime",
        { skip: 0, limit: 10 }
      );
      // console.log("previousPatrolsAndMeasures", previousPatrolsAndMeasures);
      setPreviousPatrolsAndMeasures(
        previousPatrolsAndMeasures
          .map((patrol) => {
            const previousMeasure = patrol.measures.find(
              (m) => m.identifier === measure.identifier
            );
            if (!previousMeasure) return undefined;
            return {
              patrol,
              measure: previousMeasure,
            };
          })
          .filter((e) => e) as PatrolAndMeasure[]
      );
    };
    fn();
  }, [patrol, measure, getStore]);

  if (!measureModel) return null;

  return (
    <SwipeableDrawer
      anchor="right"
      open={open}
      onOpen={onOpen}
      onClose={onClose}
      PaperProps={{ sx: { width: { xs: 1, sm: 380 } } }}
    >
      <Stack sx={{ overflow: "hidden", ...sx }}>
        <Stack direction="row" gap={2} alignItems="start" sx={{ p: 2 }}>
          <IconButton
            onClick={() => onClose()}
            sx={{
              p: 0,
              ml: "-6px",
            }}
          >
            <Iconify icon="eva:close-fill" width={24} height={24} />
          </IconButton>
          <Stack>
            <Typography variant="h6">{translate("Measure history")}</Typography>
            <Typography variant="subtitle2">{measureModel.label}</Typography>
          </Stack>
        </Stack>
        <Scrollbar>
          <Stack
            // divider={<Divider />}
            gap={2}
            sx={{ p: 2 }}
          >
            {!previousPatrolsAndMeasures.length && (
              <Typography>{translate("No previous measure")}</Typography>
            )}
            {previousPatrolsAndMeasures.map((patrolAndMeasure, index) => (
              <Card
                key={index}
                sx={{ backgroundColor: theme.palette.background.neutral, p: 2 }}
              >
                <Typography variant="subtitle2" noWrap component="div">
                  {formatDate(
                    patrolAndMeasure.patrol.startTime,
                    DateTime.DATETIME_MED
                  )}
                </Typography>
                <MeasureView
                  patrol={patrolAndMeasure.patrol}
                  measure={patrolAndMeasure.measure}
                  showLabel={false}
                  showEmpty={false}
                />
              </Card>
            ))}
          </Stack>
        </Scrollbar>
      </Stack>
    </SwipeableDrawer>
  );
}
