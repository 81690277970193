// import { format, getTime, formatDistanceToNow } from "date-fns";
import { DateTime } from "luxon";
import numeral from "numeral";
import { createContext, ReactNode, useContext } from "react";
import { useSelector } from "src/redux/store";

type FormatContextType = {
  // Date
  formatDate: (
    date: string | Date | DateTime,
    format?: Intl.DateTimeFormatOptions
  ) => string;
  // Number
  formatCurrency: (number: string | number) => string;
  formatPercent: (number: number) => string;
  formatNumber: (number: string | number) => string;
  formatShortenNumber: (number: string | number) => string;
  formatData: (number: string | number) => string;
  // Time
  // formatDate: (date: Date | string | number) => string;
  // formatDateTime: (date: Date | string | number) => string;
  // formatTimestamp: (date: Date | string | number) => string;
  // formatDateTimeSuffix: (date: Date | string | number) => string;
  // formatToNow: (date: Date | string | number) => string;
};

const FormatContext = createContext<FormatContextType | null>(null);

type FormatProviderProps = {
  children: ReactNode;
};

function FormatProvider({ children }: FormatProviderProps) {
  const authState = useSelector((state) => state.auth);
  if (!authState) return null;
  const user = authState.user;
  const formatFunctions = (locale: string) => {
    return {
      // String
      formatDate: (
        date: string | Date | DateTime,
        format?: Intl.DateTimeFormatOptions
      ) => {
        const isoDate =
          date instanceof Date
            ? DateTime.fromJSDate(date)
            : date instanceof DateTime
            ? date
            : DateTime.fromISO(date);
        return isoDate
          .setLocale(locale)
          .toLocaleString(format || DateTime.DATETIME_MED_WITH_SECONDS);
      },
      // Number
      formatCurrency: (number: string | number) =>
        numeral(number).format(Number.isInteger(number) ? "$0,0" : "$0,0.00"),
      formatPercent: (number: number) => numeral(number / 100).format("0.0%"),
      formatNumber: (number: string | number) => numeral(number).format(),
      formatShortenNumber: (number: string | number) =>
        numeral(number).format("0.[00] a"),
      formatData: (number: string | number) => numeral(number).format("0.0 b"),
      // Time
      // formatDate: (date: Date | string | number) =>
      //   format(new Date(date), "dd MMMM yyyy"),
      // formatDateTime: (date: Date | string | number) =>
      //   format(new Date(date), "dd MMM yyyy p"),
      // formatTimestamp: (date: Date | string | number) =>
      //   getTime(new Date(date)),
      // formatDateTimeSuffix: (date: Date | string | number) =>
      //   format(new Date(date), "dd/MM/yyyy hh:mm p"),
      // formatToNow: (date: Date | string | number) =>
      //   formatDistanceToNow(new Date(date), {
      //     addSuffix: true,
      //   }),
    };
  };
  return (
    <FormatContext.Provider value={formatFunctions(user?.language || "en")}>
      {children}
    </FormatContext.Provider>
  );
}

export { FormatContext, FormatProvider };
