import { Box, SxProps } from "@mui/material";
import * as d3 from "d3";
import { PieArcDatum } from "d3-shape";
import numeral from "numeral";
import { useEffect, useLayoutEffect, useRef, useState } from "react";

type Props = {
  donutStops: number[];
  donutColors: string[];
  donutWidth?: number;
  currentValue: number | undefined;
  currentColor: string;
  startAngle?: number;
  endAngle?: number;
  width?: number;
  height?: number;
  bgColor?: string;
  sx?: SxProps;
};

export default function CircularGauge({
  donutStops,
  donutColors,
  donutWidth = 10,
  currentValue,
  currentColor,
  startAngle = (-3 * Math.PI) / 4,
  endAngle = (+3 * Math.PI) / 4,
  width = 200,
  height = 200,
  bgColor,
  sx,
}: Props) {
  const gaugeRef = useRef(null);
  const [svg, setSvg] =
    useState<d3.Selection<SVGSVGElement, unknown, HTMLElement, any>>();

  useLayoutEffect(() => {
    if (svg) return;
    if (!gaugeRef.current) return;
    setSvg(
      d3
        .select(gaugeRef.current)
        .append("svg")
        .attr("class", "circulargauge")
        .attr("viewBox", [-width / 2, -height / 2, width, height])
    );
  }, [gaugeRef, width, height, svg]);

  useEffect(() => {
    if (!svg) return;

    const valueToAngle = (value: number) => {
      const a =
        (endAngle - startAngle) /
        (donutStops[donutStops.length - 1] - donutStops[0]);
      const b = startAngle - a * donutStops[0];
      let valueAngle = a * value + b;
      valueAngle = Math.max(valueAngle, startAngle);
      valueAngle = Math.min(valueAngle, endAngle);
      return valueAngle;
    };

    const radius = height / 2 - 20;

    const pie = d3
      .pie<number>()
      .sort(null)
      .padAngle(0.01)
      .startAngle(startAngle)
      .endAngle(endAngle);
    const arc = d3
      .arc<PieArcDatum<number>>()
      .innerRadius(radius - donutWidth / 2)
      .outerRadius(radius + donutWidth / 2)
      .cornerRadius(10);

    const pieSectionsLengths = donutStops.reduce(
      (acc, stop, i) =>
        i < donutStops.length - 1
          ? [...acc, donutStops[i + 1] - donutStops[i]]
          : acc,
      [] as number[]
    );

    // Donut
    svg.select("g.donut").remove();
    svg
      .append("g")
      .attr("class", "donut")
      .selectAll("path")
      .data(pie(pieSectionsLengths))
      .join("path")
      .attr("fill", (d, i) => donutColors[i])
      .attr("d", arc);
    // Cursor
    if (currentValue !== undefined) {
      svg.select("g.cursor").remove();
      svg.append("g").attr("class", "cursor");
      svg
        .select("g.cursor")
        .append("circle")
        .attr("class", "outerCircle")
        .attr("r", donutWidth)
        .attr("fill", currentColor)
        .attr(
          "transform",
          `translate(0, -${radius}) rotate(${
            (valueToAngle(currentValue) * 180) / Math.PI
          } 0 ${radius})`
        );
      svg
        .select("g.cursor")
        .append("circle")
        .attr("class", "innerCircle")
        .attr("r", donutWidth / 2)
        .attr("fill", bgColor || "#000")
        .attr(
          "transform",
          `translate(0, -${radius}) rotate(${
            (valueToAngle(currentValue) * 180) / Math.PI
          } 0 ${radius})`
        );
    }
    // Stops labels
    svg.select("g.labels").remove();
    svg
      .append("g")
      .attr("class", "labels")
      .selectAll("text")
      .data(
        donutStops.reduce(
          (acc, stop, i) =>
            i !== 0 && i !== donutStops.length - 1 ? [...acc, stop] : acc,
          [] as number[]
        )
      )
      .enter()
      .append("text")
      .text((d) => numeral(d).format("0.[00]"))
      .attr("class", "label")
      .attr("stroke", "#fff")
      .each(function (d) {
        const angle = valueToAngle(d) - Math.PI / 2;
        const textRadius = (radius - donutWidth / 2) * 0.75;
        const x = textRadius * Math.cos(angle);
        const y = textRadius * Math.sin(angle);
        this.setAttribute("x", `${x}`);
        this.setAttribute("y", `${y}`);
      })
      .each(function (d, i) {
        const x = -this.getComputedTextLength() / 2;
        this.setAttribute("transform", `translate(${x} 0)`);
      });
  }, [
    donutStops,
    donutColors,
    donutWidth,
    currentValue,
    currentColor,
    startAngle,
    endAngle,
    bgColor,
    height,
    svg,
  ]);

  return <Box ref={gaugeRef} sx={sx}></Box>;
}
