import { Button, Grid, LinearProgress, Stack, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { DateTime } from "luxon";
import numeral from "numeral";
import { sprintf } from "sprintf-js";
import { Patrol } from "src/@types";
import { useFormat, useLocales } from "src/hooks";
import PatrolStatusLabel from "./PatrolStatusLabel";

const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.body2,
  fontSize: 13,
  flexShrink: 0,
  color: theme.palette.text.secondary,
}));

const ValueStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.body2,
  // fontSize: 14,
  flexShrink: 0,
}));

type Props = {
  patrol: Patrol;
  validatePatrol?: (patrol: Patrol) => void;
};

export default function PatrolHeader({ patrol, validatePatrol }: Props) {
  const { formatDate } = useFormat();
  const { translate } = useLocales();

  return (
    <Stack>
      <Grid container>
        <Grid item xs={4} sm={2} order={{ xs: 1 }}>
          <LabelStyle>{translate("Operator")}</LabelStyle>
        </Grid>
        <Grid item xs={8} sm={4} order={{ xs: 2 }}>
          <ValueStyle>
            {sprintf(
              "%s %s",
              patrol.operator.firstName,
              patrol.operator.lastName
            )}
          </ValueStyle>
        </Grid>
        <Grid item xs={4} sm={2} order={{ xs: 3 }}>
          <LabelStyle>{translate("Start date")}</LabelStyle>
        </Grid>
        <Grid item xs={8} sm={4} order={{ xs: 4 }}>
          <ValueStyle>
            {patrol.startTime
              ? formatDate(patrol.startTime, DateTime.DATETIME_MED)
              : "-"}
          </ValueStyle>
        </Grid>
        <Grid item xs={4} sm={2} order={{ xs: 5, sm: 7 }}>
          <LabelStyle>{translate("End date")}</LabelStyle>
        </Grid>
        <Grid item xs={8} sm={4} order={{ xs: 6, sm: 8 }}>
          <ValueStyle>
            {patrol.endTime
              ? formatDate(patrol.endTime, DateTime.DATETIME_MED)
              : "-"}
          </ValueStyle>
        </Grid>
        <Grid item xs={4} sm={2} order={{ xs: 7, sm: 5 }}>
          <LabelStyle>{translate("Status")}</LabelStyle>
        </Grid>
        <Grid item xs={8} sm={4} order={{ xs: 8, sm: 6 }}>
          <ValueStyle>
            <PatrolStatusLabel patrol={patrol} />
          </ValueStyle>
        </Grid>
        <Grid item xs={4} sm={2} order={{ xs: 9 }}>
          <LabelStyle>{translate("Progress")}</LabelStyle>
        </Grid>
        <Grid item xs={8} sm={10} order={{ xs: 10 }}>
          <Stack direction="row" alignItems="center" gap={2} sx={{ flex: 1 }}>
            <LinearProgress
              variant="determinate"
              value={patrol.progressPercent}
              sx={{ flex: 1 }}
            />
            <Typography>
              {numeral(patrol.progressPercent).format("0")} %
            </Typography>
          </Stack>
        </Grid>
      </Grid>
      {!patrol.done && patrol.progressPercent === 100 && validatePatrol && (
        <Stack direction="row" justifyContent="space-between" gap={4}>
          <LabelStyle>{translate("Validation")}</LabelStyle>
          <Button onClick={() => validatePatrol(patrol)}>
            {translate("Validate patrol")}
          </Button>
        </Stack>
      )}
    </Stack>
  );
}
