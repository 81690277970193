import { createPopper, Instance as PopperInstance } from "@popperjs/core";
import React from "react";
import ReactDOM from "react-dom";
import uPlot, { Hooks, Series } from "uplot";

type Props = {
  // content: (
  //   time: number,
  //   value: number | null | undefined
  // ) => JSX.Element | string | null;
  content: (
    time: number,
    values: (number | null | undefined)[],
    focusedSeriesIdx: number | null
  ) => JSX.Element | string | null;
  transition?: {
    delay?: string;
    duration: string;
    timingFunction?: string;
  };
};

export default function tooltipPlugin({ content, transition }: Props) {
  const tooltipElt = document.createElement("div");
  tooltipElt.className = "u-tooltip";
  tooltipElt.style.display = "block";
  tooltipElt.style.transition = `transform ${transition?.duration || "10ms"} ${
    transition?.timingFunction || "ease"
  } ${transition?.delay || "0s"}`;
  let popper: PopperInstance | undefined;
  let focusedSeriesIdx: number | null = null;

  return {
    hooks: {
      init: [
        (self) => {
          self.over.appendChild(tooltipElt);
          //
          self.over.onmouseenter = () => {
            tooltipElt.style.display = "block";
          };
          self.over.onmouseleave = () => {
            tooltipElt.style.display = "none";
          };
          //
          const cursorElement = self.over.querySelector(".u-cursor-pt");
          if (!cursorElement) return;
          popper = createPopper(cursorElement, tooltipElt, {
            placement: "auto",
            modifiers: [
              {
                name: "computeStyles",
                options: {
                  adaptive: false,
                },
              },
            ],
          });
        },
      ],
      setCursor: [
        (self) => {
          const dataIdx = self.cursor.idx;
          if (!dataIdx) return null;
          const time = self.data[0][dataIdx];
          const values = self.data.slice(1).map((d) => d[dataIdx]);
          const _content = content(time, values, focusedSeriesIdx);
          if (typeof _content === "string") {
            tooltipElt.textContent = _content;
          } else if (React.isValidElement(_content)) {
            try {
              ReactDOM.render(_content, tooltipElt, () => popper?.update());
            } catch (e) {}
          }
        },
      ],
      setSeries: [
        (self, seriesIdx, opts) => {
          focusedSeriesIdx = seriesIdx === null ? seriesIdx : seriesIdx - 1;
        },
      ],
    } as Hooks.ArraysOrFuncs,
  };
}
