// @mui
import { Autocomplete, AutocompleteProps } from '@mui/material';
// form
import { useFormContext, Controller } from 'react-hook-form';

// ----------------------------------------------------------------------

type IProps = {
  name: string;
  options: any[];
  renderInput: (params: object) => JSX.Element;
};

type Props<T> = IProps & AutocompleteProps<T, undefined, undefined, undefined>;

export default function RHFAutocomplete<T>({ name, options, renderInput, ...other }: Props<T>) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <Autocomplete
          {...field}
          fullWidth
          // error={!!error}
          // helperText={error?.message}
          options={options}
          renderInput={renderInput}
          {...other}
        />
      )}
    />
  );
}
