import { alpha, Theme } from "@mui/material";

import { Threshold } from "../../../../../@types";

type Props = {
  thresholds: Threshold[];
  translate: (text: any, options?: any) => string;
  theme: Theme;
  showLabels?: boolean;
};

export default function thresholdsLines({
  thresholds,
  translate,
  theme,
  showLabels = true,
}: Props) {
  return {
    hooks: {
      drawSeries: [
        (u: uPlot, seriesIdx: number) => {
          const horizontalLine = (
            series: uPlot.Series,
            x: number[],
            y: number,
            color: string,
            label?: string
          ) => {
            const [xi, xf] = x;
            if (series.width === undefined) return;
            const ctx = u.ctx;
            ctx.save();
            ctx.beginPath();
            ctx.strokeStyle = color;
            ctx.fillStyle = color;
            ctx.lineWidth = 1;
            ctx.setLineDash([2, 2]);
            ctx.moveTo(xi, y);
            ctx.lineTo(xf, y);
            ctx.stroke();
            if (label) {
              const margin = 0;
              const padding = 4;
              ctx.font = "1rem sans-serif";
              // Text metrics
              const textMetrics = ctx.measureText(label);
              const textWidth = textMetrics.width;
              const textHeight =
                textMetrics.fontBoundingBoxAscent +
                textMetrics.fontBoundingBoxDescent;
              // Label rect
              ctx.fillStyle = alpha(color, 0.8);
              ctx.fillRect(
                // xf - textMetrics.width - 2 * padding - margin,
                xi + margin,
                y - textHeight - 2 * padding - margin,
                textWidth + 2 * padding,
                textHeight + 2 * padding
              );
              // Text
              // ctx.textAlign = "end";
              ctx.textAlign = "start";
              ctx.fillStyle = "#fff";
              ctx.fillText(
                label,
                // xf - margin - padding,
                xi + margin + padding,
                y - textMetrics.fontBoundingBoxDescent - margin - padding
              );
            }
            ctx.restore();
          };

          thresholds.forEach((threshold) => {
            const color =
              threshold.priority === "warning"
                ? theme.palette.warning.main
                : threshold.priority === "alert"
                ? theme.palette.error.main
                : theme.palette.primary.main;
            const label =
              threshold.priority === "warning"
                ? translate("Warning")
                : threshold.priority === "alert"
                ? translate("Alert")
                : translate("Unknown");
            const series = u.series[seriesIdx];
            const x = u.data[0];
            if (!series.idxs) return;
            const [i0, i1] = series.idxs;
            const x0 = u.valToPos(x[i0], "x", true);
            const x1 = u.valToPos(x[i1], "x", true);
            const y = u.valToPos(threshold.value as number, "y", true);
            horizontalLine(
              series,
              [x0, x1],
              y,
              color,
              showLabels ? label : undefined
            );
          });
        },
      ],
    },
  };
}
