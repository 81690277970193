import { Container, Grid } from "@mui/material";
import { DateTime, Duration, Interval } from "luxon";
import { MeasureData } from "src/@types";
// import { NumericWidgetWithIcon } from "src/components/app/general/analytics";
// import {
//   AppCurrentDownload,
//   AppWidgetSummary,
// } from "src/components/app/general/app";
// import { BankingWidgetSummary } from "src/components/app/general/banking";
import MeasureSummaryChart from "src/components/app/measure/NumericSummaryChart";
import LinesCharts from "src/components/generic/chart/LinesCharts";
import LinesChartsTabs from "src/components/generic/chart/LinesChartsTabs";
import HeaderBreadcrumbs from "src/components/generic/HeaderBreadcrumbs";
import Page from "src/components/generic/Page";
import { useSelector } from "src/redux/store";

function DemoMeasures() {
  const settingsState = useSelector((state) => state.settings);

  const chartLabelsYear = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
  ];
  const chartLabelsWeek = ["Mon", "Tue", "Wed", "Thr", "Fri", "Sat", "Sun"];
  const oneLineChartData = {
    label: "last day",
    chartLabels: chartLabelsYear,
    data: [
      {
        name: "Asia",
        data: [10, 41, 35, 51, 49, 62, 69, 91, 148],
      },
    ],
  };
  const oneLineChartData2 = {
    label: "last day",
    chartLabels: chartLabelsYear,
    data: [
      {
        name: "Asia",
        data: [10, 41, 35, 51, 49, 62, 69, 91, 148],
      },
      {
        name: "America",
        data: [10, 34, 13, 56, 77, 88, 99, 77, 45],
      },
    ],
  };

  const multipleLinesChartData = [
    {
      label: "last year",
      chartLabels: chartLabelsYear,
      data: [
        {
          name: "Asia",
          data: [10, 41, 35, 51, 49, 62, 69, 91, 148],
        },
        {
          name: "America",
          data: [10, 34, 13, 56, 77, 88, 99, 77, 45],
        },
      ],
    },
    {
      label: "last week",
      chartLabels: chartLabelsWeek,
      data: [
        {
          name: "Asia",
          data: [148, 91, 69, 62, 49, 51, 35],
        },
        {
          name: "America",
          data: [45, 77, 99, 88, 77, 56, 13],
        },
      ],
    },
  ];

  return (
    <Page title="Measure : TAC">
      <Container maxWidth={settingsState.themeStretch ? false : "xl"}>
        <HeaderBreadcrumbs
          heading={"Vue d'ensemble TAC"}
          links={[
            { name: "Measure" },
            {
              name: "TAC",
            },
          ]}
        />
        <Grid container spacing={3}>
          {/*<Grid item xs={12} md={3}>
            <NumericWidgetWithIcon
              title="Puissance active "
              total={121212}
              unit="J"
              icon={"carbon:wind-gusts"}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <NumericWidgetWithIcon
              title="Puissance réactive "
              total={141411144411441}
              unit="J"
              color="info"
              icon={"mdi:power-plug-outline"}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <NumericWidgetWithIcon
              title="New Users"
              total={1352831}
              color="info"
              icon={"ant-design:apple-filled"}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <NumericWidgetWithIcon
              title="New Users"
              total={1352831}
              color="info"
              icon={"ant-design:apple-filled"}
            />
          </Grid>*/}
          <Grid item xs={12} md={6}>
            <LinesCharts title="Single one line" chartData={oneLineChartData} />
          </Grid>
          <Grid item xs={12} md={6}>
            <LinesCharts title="Single 2 lines" chartData={oneLineChartData2} />
          </Grid>
          <Grid item xs={12} md={6}>
            <LinesChartsTabs
              title="Multiple tabs multiple lines"
              chartData={multipleLinesChartData}
            />
          </Grid>
          {/*<Grid item xs={12} md={2}>
            <BankingWidgetSummary
              title="Offre"
              icon={"eva:diagonal-arrow-left-down-fill"}
              percent={2.6}
              total={18765}
              chartData={[74, 97, 97, 122, 87, 79, 99, 148]}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <BankingWidgetSummary
              title="Demande"
              color="warning"
              icon={"eva:diagonal-arrow-right-up-fill"}
              percent={-0.5}
              total={8938}
              chartData={[147, 122, 110, 108, 110, 127, 135, 138]}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <AppWidgetSummary
              title="Total Active Users"
              percent={2.6}
              total={18765}
              chartColor={theme.palette.primary.main}
              chartData={[5, 18, 12, 51, 68, 11, 39, 37, 27, 20]}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <AppWidgetSummary
              title="Total Installed"
              percent={0.2}
              total={4876}
              chartColor={theme.palette.chart.blue[0]}
              chartData={[20, 41, 63, 33, 28, 35, 50, 46, 11, 26]}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <AppWidgetSummary
              title="Total Downloads"
              percent={-0.1}
              total={678}
              chartColor={theme.palette.chart.red[0]}
              chartData={[8, 9, 31, 8, 16, 37, 8, 33, 46, 31]}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppCurrentDownload
              title="Current Download"
              chartColors={[
                theme.palette.primary.lighter,
                theme.palette.primary.light,
                theme.palette.primary.main,
                theme.palette.primary.dark,
              ]}
              chartData={[
                { label: "Mac", value: 12244 },
                { label: "Window", value: 53345 },
                { label: "iOS", value: 44313 },
                { label: "Android", value: 78343 },
              ]}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={8}></Grid>*/}

          {/*<Grid item xs={12} md={6} lg={6}>
            <MeasureSummaryChart
              measure={{
                identifier: "measure",
                sourceVar: "sourceVar",
                machineName: "machineName",
                label: "Mesure",
                equipmentId: "equipment",
                domains: [],
                type: "type",
                unit: "MW",
                active: true,
                data: {
                  last: {
                    time: DateTime.now().toISO(),
                    value: 15,
                  } as MeasureInstantData,
                  history: Interval.fromDateTimes(
                    DateTime.now().minus({ hours: 12 }),
                    DateTime.now()
                  )
                    .splitBy(Duration.fromISO("PT10M"))
                    .map((t) => {
                      return {
                        time: t.start.toISO(),
                        value: 5 + Math.random() * 12,
                      };
                    }),
                },
              }}
              onMenuClick={() => {}}
            />
          </Grid>*/}
          <Grid item xs={12} md={6} lg={6}>
            <MeasureSummaryChart
              measure={{
                identifier: "measure",
                sourceVar: "sourceVar",
                machineName: "machineName",
                label: "Mesure",
                equipmentId: "equipment",
                domains: [],
                type: "number",
                unit: "",
                thresholds: [],
                // valueConstraints: {
                //   min: -350,
                //   max: 0,
                // },
                active: true,
                data: {
                  last: {
                    time: DateTime.now().toISO(),
                    value: 0,
                  } as MeasureData,
                  history: Interval.fromDateTimes(
                    DateTime.now().minus({ hours: 12 }),
                    DateTime.now()
                  )
                    .splitBy(Duration.fromISO("PT10M"))
                    .map((t) => {
                      return {
                        time: t.start.toISO(),
                        value:
                          -2000 +
                          100 * Math.sin(t.start.toUnixInteger() / 100) +
                          50 * (Math.random() - 0.5),
                      };
                    }),
                },
              }}
              onMenuClick={() => {}}
              // xDuration={Duration.fromISO("PT12H")}
              timeControl={{ interval: Duration.fromISO("PT12H") }}
            />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}

export default DemoMeasures;
