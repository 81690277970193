import {
  Button,
  InputAdornment,
  Stack,
  SxProps,
  TextField,
  useTheme,
} from "@mui/material";
import numeral from "numeral";
import { forwardRef, useEffect, useState } from "react";
import { sprintf } from "sprintf-js";
import { PatrolMeasure, PatrolModelMeasure } from "src/@types";
import { useLocales } from "src/hooks";
import BaseBlock from "./BaseBlock";

type Props = {
  measureModel: PatrolModelMeasure;
  measure: PatrolMeasure;
  setMeasure: (meaasure: PatrolMeasure) => void;
  sx?: SxProps;
};

export default forwardRef<HTMLDivElement, Props>(function ValueBlock(
  { measureModel, measure, setMeasure, sx }: Props,
  ref
) {
  const { translate } = useLocales();
  const theme = useTheme();
  const [inputError, setInputError] = useState<string>();
  const [measureState, setMeasureState] = useState(measure);

  useEffect(() => setMeasureState(measure), [measure]);

  useEffect(() => {
    if (inputError) return;
    setMeasure(measureState);
  }, [measureState]);

  return (
    <BaseBlock
      ref={ref}
      label={
        <>
          {measureModel.label}
          <span
            style={{ color: theme.palette.primary.main, marginLeft: "2px" }}
          >
            {measureModel.required ? "*" : ""}
          </span>
        </>
      }
      component={
        measureModel.type === "boolean" ? (
          <Stack direction="row" gap={2}>
            <Button
              variant={measureState.value === true ? "contained" : "outlined"}
              onClick={() => setMeasureState({ ...measureState, value: true })}
            >
              {translate("Yes")}
            </Button>
            <Button
              variant={measureState.value === false ? "contained" : "outlined"}
              onClick={() => setMeasureState({ ...measureState, value: false })}
            >
              {translate("No")}
            </Button>
          </Stack>
        ) : measureModel.type === "choice" ? (
          <Stack direction="row" gap={2}>
            {(measureModel.choices || []).map((choice) => (
              <Button
                key={choice}
                variant={
                  measureState.value === choice ? "contained" : "outlined"
                }
                onClick={() =>
                  setMeasureState({ ...measureState, value: choice })
                }
              >
                {choice}
              </Button>
            ))}
          </Stack>
        ) : measureModel.type === "string" ? (
          <TextField
            multiline
            rows={measureModel.field === "textarea" ? 4 : 1}
            placeholder={translate("Text value")}
            value={measureState.value || ""}
            onChange={(event) =>
              setMeasureState({
                ...measureState,
                value: event.target.value,
              })
            }
          />
        ) : measureModel.type === "number" ? (
          <TextField
            placeholder={
              !measureModel.valueConstraints
                ? translate("Numeric value")
                : sprintf(
                    translate("Numeric value between %s and %s"),
                    measureModel.valueConstraints.min,
                    measureModel.valueConstraints.max
                  )
            }
            // type="number"
            // inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
            inputProps={{ inputMode: "numeric" }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {measureModel.unit || ""}
                </InputAdornment>
              ),
            }}
            value={
              measureState.value !== undefined
                ? numeral(measureState.value).format("0.[0000]")
                : ""
            }
            onChange={(event) => {
              setInputError(undefined);
              let valueStr = event.target.value;
              // if (authState.user?.language === "fr") {
              //   valueStr = valueStr.replace(".", ",");
              // }
              // console.log("valueStr", valueStr);
              const value = numeral(valueStr).value();
              // console.log("value", value);
              if (value === null) {
                setInputError("This is not a number");
              } else {
                if (measureModel.valueConstraints) {
                  if (value < measureModel.valueConstraints.min) {
                    setInputError(
                      `The value must be greater or equal to ${measureModel.valueConstraints.min}`
                    );
                  }
                  if (value > measureModel.valueConstraints.max) {
                    setInputError(
                      `The value must be less or equal to ${measureModel.valueConstraints.max}`
                    );
                  }
                }
              }
              setMeasureState({ ...measureState, value });
            }}
            error={inputError !== undefined}
            helperText={inputError}
          />
        ) : null
      }
      sx={sx}
    />
  );
});
