import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { ReactNode, useEffect, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import LoadingScreen from "src/components/generic/LoadingScreen";
import { useLocales } from "src/hooks";
import Login from "src/pages/auth/Login";
import { loadData } from "src/redux/slices/measures";
import { clearDataReady } from "src/redux/slices/socketIo";
import { dispatch, useSelector } from "src/redux/store";
// import { setToken as setAxiosToken } from "src/utils/axios";

type AuthGuardProps = {
  children: ReactNode;
};

export default function AuthGuard({ children }: AuthGuardProps) {
  const authState = useSelector((state) => state.auth);
  const menuState = useSelector((state) => state.menu);
  const languagesState = useSelector((state) => state.languages);
  const organizationState = useSelector((state) => state.organization);
  const rolesState = useSelector((state) => state.roles);
  const accessRightsState = useSelector((state) => state.accessRights);
  const equipmentsState = useSelector((state) => state.equipments);
  const equipmentsLocationsState = useSelector(
    (state) => state.equipmentsLocations
  );
  const equipmentsTypesState = useSelector((state) => state.equipmentsTypes);
  const measuresState = useSelector((state) => state.measures);
  const socketIoState = useSelector((state) => state.socketIo);
  const { changeLanguage, currentLanguage } = useLocales();
  const { pathname } = useLocation();
  const [requestedLocation, setRequestedLocation] = useState<string>();

  useEffect(() => {
    if (!authState.user) return;
    console.log(
      `Switching language from "${currentLanguage.value}" to "${authState.user.language}"`
    );
    changeLanguage(authState.user.language);
  }, []);

  if (authState.initializing) {
    return <LoadingScreen />;
  }

  if (!authState.user) {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }
    return <Login />;
  }

  if (
    !languagesState.loaded ||
    !menuState.loaded ||
    !organizationState.loaded ||
    !rolesState.loaded ||
    !accessRightsState.loaded ||
    !equipmentsState.loaded ||
    !equipmentsLocationsState.loaded ||
    !equipmentsTypesState.loaded ||
    !measuresState.loaded
  ) {
    return <LoadingScreen />;
  }

  // Load data on socket.io signal
  if (socketIoState.dataReady) {
    dispatch(loadData()).then(() => {
      dispatch(clearDataReady());
    });
  }

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(undefined);
    return <Navigate to={requestedLocation} />;
  }

  return (
    <LocalizationProvider
      dateAdapter={AdapterLuxon}
      adapterLocale={currentLanguage.value}
      localeText={currentLanguage.muiDateTimePickerLocaleText}
    >
      {children}
    </LocalizationProvider>
  );
}
