import { Avatar, Typography, useTheme } from "@mui/material";
import Iconify from "../Iconify";

type Props = {
  index: number;
  active: boolean;
  completed: boolean;
};

export default function ({ index, active, completed }: Props) {
  const theme = useTheme();
  return (
    <Avatar
      sx={{
        backgroundColor: active
          ? theme.palette.primary.main
          : completed
          ? theme.palette.primary.lighter
          : theme.palette.background.neutral,
        borderWidth: 3,
        borderStyle: "solid",
        borderColor: theme.palette.background.neutral,
      }}
    >
      {active ? (
        <Typography
          sx={{
            color: theme.palette.text.primary,
            fontSize: "1.5rem",
            fontWeight: "bold",
          }}
        >
          {index + 1}
        </Typography>
      ) : !completed ? (
        <Typography
          sx={{
            backgroundColor: theme.palette.background.paper,
            color: theme.palette.text.primary,
            fontSize: "1.5rem",
          }}
        >
          {index + 1}
        </Typography>
      ) : (
        <Iconify
          icon="fa-check"
          sx={{
            color: theme.palette.primary.main,
          }}
        />
      )}
    </Avatar>
  );
}
