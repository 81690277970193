import { Autocomplete, Box, Stack, TextField } from "@mui/material";
import { ReactNode, useEffect, useMemo, useState } from "react";
import { PatrolModel, User } from "src/@types";
import { useLocales } from "src/hooks";
import { loadLocations, loadModels } from "src/redux/slices/patrols";
import { load as loadUsers } from "src/redux/slices/users";
import { dispatch, useSelector } from "src/redux/store";

export type ValidationStatus = "InProgress" | "Validated" | undefined;

type AutocompleteOperator = {
  label: string;
  value: string;
};

type AutocompleteModel = {
  label: string;
  value: string;
  group: string;
};

type Props = {
  // searchFilter: string;
  // onSearchFilterChange: (value: string) => void;
  operatorsFilter: User[];
  onOperatorsFilterChange: (value: User[]) => void;
  modelsFilter: PatrolModel[];
  onModelsFilterChange: (value: PatrolModel[]) => void;
  validationStatusFilter: ValidationStatus;
  onValidationStatusFilterChange: (value: ValidationStatus) => void;
  children?: ReactNode;
};

export default function PatrolsTableToolbar({
  // searchFilter,
  // onSearchFilterChange,
  operatorsFilter,
  onOperatorsFilterChange,
  modelsFilter,
  onModelsFilterChange,
  validationStatusFilter,
  onValidationStatusFilterChange,
  children,
}: Props) {
  const { translate } = useLocales();
  const { locations: patrolsLocations, models: patrolsModels } = useSelector(
    (state) => state.patrols
  );
  const usersState = useSelector((state) => state.users);
  const [operators, setOperators] = useState<User[]>([]);

  useEffect(() => {
    dispatch(loadUsers());
    dispatch(loadLocations());
    dispatch(loadModels());
  }, []);

  useEffect(() => {
    const users = [...usersState.users];
    users.sort((a, b) => {
      const aVal = a.name.toLowerCase();
      const bVal = b.name.toLowerCase();
      return aVal < bVal ? -1 : aVal > bVal ? 1 : 0;
    });
    setOperators(users);
  }, [usersState.users]);

  const operatorsToOptions = (operators: User[]) =>
    operators.map((operator) => {
      return {
        label: operator.name,
        value: operator._id,
        // group: location?.label,
      } as AutocompleteOperator;
    });

  const optionsToOperators = (operators: AutocompleteOperator[]) =>
    usersState.users.filter((u) =>
      operators.map((o) => o.value).includes(u._id)
    );

  const modelsToOptions = (models: PatrolModel[]) =>
    models.map((model) => {
      const location = patrolsLocations.find(
        (l) => l.identifier === model.locationId
      );
      return {
        label: model.label,
        value: model.identifier,
        group: location?.label,
      };
    }) as AutocompleteModel[];

  const optionsToModels = (models: AutocompleteModel[]) =>
    patrolsModels.filter((e) =>
      models.map((e) => e.value).includes(e.identifier)
    );

  const modelsOptions = useMemo(
    () =>
      modelsToOptions(patrolsModels).sort((a, b) =>
        a.group > b.group ? 1 : b.group > a.group ? -1 : 0
      ),
    [patrolsModels]
  );

  return (
    <Stack
      direction={{ xs: "column", sm: "row" }}
      justifyContent="space-between"
      alignItems="stretch"
      gap={2}
      sx={{ p: 3 }}
    >
      <Stack
        direction={{ xs: "column", sm: "row" }}
        width={{ xs: "100%", lg: "75%" }}
        gap={2}
      >
        <Autocomplete
          multiple
          // sx={{ minWidth: "50%" }}
          sx={{ flex: 1 }}
          options={operators.map((operator) => ({
            label: operator.name,
            value: operator._id,
          }))}
          isOptionEqualToValue={(option, value) => option.value === value.value}
          value={operatorsToOptions(operatorsFilter)}
          onChange={(event, values) => {
            onOperatorsFilterChange(optionsToOperators(values));
          }}
          renderInput={(params) => (
            <TextField {...params} label={translate("Operators")} />
          )}
        />

        <Autocomplete
          multiple
          sx={{ minWidth: "50%" }}
          value={modelsToOptions(modelsFilter)}
          onChange={(event, values) =>
            onModelsFilterChange(optionsToModels(values))
          }
          options={modelsOptions}
          isOptionEqualToValue={(option, value) => option.value === value.value}
          groupBy={(opt) => opt.group}
          renderInput={(params) => (
            <TextField {...params} label={translate("Models")} />
          )}
        />

        <Autocomplete
          sx={{ flex: 1 }}
          options={
            [
              { value: "InProgress", label: translate("In progress") },
              { value: "Validated", label: translate("Validated") },
            ] as { value: ValidationStatus; label: string }[]
          }
          isOptionEqualToValue={(option, value) => option.value === value.value}
          onChange={(event, value) => {
            console.log("value", value);
            onValidationStatusFilterChange(
              value === null ? undefined : value.value
            );
          }}
          renderInput={(params) => (
            <TextField {...params} label={translate("Validation status")} />
          )}
        />
      </Stack>
      <Box sx={{ flex: 1 }}>{children}</Box>
    </Stack>
  );
}
