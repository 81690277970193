import Pica from "pica";
import { createContext, ReactNode } from "react";

type Options = {
  mimeType?: "image/jpeg" | "image/png";
  quality?: number;
  minLength?: number;
  maxLength?: number;
  width?: number;
  height?: number;
  enlarge?: boolean;
};

type ImageContextType = {
  resize: (file: File | Blob, options: Options) => Promise<Blob>;
};

const defaultValue = {
  resize: (file: File | Blob, options: Options = {}): Promise<Blob> =>
    new Promise((resolve, reject) => {
      const pica = new Pica();
      const reader = new FileReader();
      reader.addEventListener("error", reject);
      reader.addEventListener("load", () => {
        if (!reader.result || typeof reader.result === "object") return;
        const from = document.createElement("img");
        from.src = reader.result;
        from.onerror = reject;
        from.onload = async () => {
          const to = document.createElement("canvas");
          let fixedDimension: "width" | "height" | undefined = undefined;
          let computedDimension: "width" | "height" | undefined = undefined;
          let fixedSize: number | undefined = undefined;
          if (options.minLength) {
            if (Math.min(from.width, from.height) === from.width) {
              fixedDimension = "width";
              computedDimension = "height";
            } else {
              fixedDimension = "height";
              computedDimension = "width";
            }
            fixedSize = options.minLength;
          } else if (options.maxLength) {
            if (Math.max(from.width, from.height) === from.width) {
              fixedDimension = "width";
              computedDimension = "height";
            } else {
              fixedDimension = "height";
              computedDimension = "width";
            }
            fixedSize = options.maxLength;
          } else if (options.width) {
            fixedDimension = "width";
            fixedSize = options.width;
            computedDimension = "height";
          } else if (options.height) {
            fixedDimension = "height";
            fixedSize = options.height;
            computedDimension = "width";
          } else {
            fixedDimension = "width";
            fixedSize = 100;
            computedDimension = "height";
          }
          const computedSize =
            (from[computedDimension] * fixedSize) / from[fixedDimension];
          if (
            fixedSize < from[fixedDimension] ||
            computedSize < from[computedDimension] ||
            (options.enlarge &&
              (fixedSize > from[fixedDimension] ||
                computedSize > from[computedDimension]))
          ) {
            to[fixedDimension] = fixedSize;
            to[computedDimension] = computedSize;
          } else {
            to[fixedDimension] = from[fixedDimension];
            to[computedDimension] = from[computedDimension];
          }
          const canvas = await pica.resize(from, to);
          const blob = await pica.toBlob(
            canvas,
            options.mimeType || file.type,
            options.quality || 0.9
          );
          resolve(blob);
        };
      });
      reader.readAsDataURL(file);
    }),
};

const ImageContext = createContext<ImageContextType>(defaultValue);

type ImageProviderProps = {
  children: ReactNode;
};

function ImageProvider({ children }: ImageProviderProps) {
  return (
    <ImageContext.Provider value={defaultValue}>
      {children}
    </ImageContext.Provider>
  );
}

export { ImageContext, ImageProvider };
