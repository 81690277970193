import {
  Collapse,
  Divider,
  IconButton,
  Link,
  Stack,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Duration } from "luxon";
import { useState } from "react";
import { Measure } from "src/@types";
import {
  StartEndTimeControl,
  toStartEndTimeControl,
} from "src/@types/TimeControl";
import FavoriteIcon from "src/components/generic/FavoriteIcon";
import Iconify from "src/components/generic/Iconify";
import LabelBadge from "src/components/generic/LabelBadge";
import Panel from "src/components/generic/Panel";
import SwipeableDrawer from "src/components/generic/SwipeableDrawer";
import { useLocales } from "src/hooks";
import { dispatch, useSelector } from "src/redux/store";
import { toggle as toggleMeasure } from "src/redux/slices/favorites";
import { PATH_DASHBOARD } from "src/routes/paths";
import AlarmLogsSection from "./AlarmLogsSection";
import AlarmsTresholdsSection from "./AlarmsTresholdsSection";
import ExportSection from "./ExportSection";
import GeneralSection from "./GeneralSection";

const LinkStyle = styled(Link)(({ theme }) => ({
  color: theme.palette.text.primary,
  // textDecorationStyle: "dotted",
  textDecorationLine: "none",
  // textDecorationColor: theme.palette.text.primary,
  // textUnderlineOffset: "0.4em",
  "&:hover": {
    textDecorationLine: "none",
  },
}));

type MeasureDrawerProps = {
  measure?: Measure;
  open: boolean;
  displayLinkToMeasure: boolean;
  onClose: VoidFunction;
  // onThresholdsUpdated?: () => Promise<void>;
  timeControl?: StartEndTimeControl;
};

export default function MeasureDrawer({
  measure,
  open,
  displayLinkToMeasure,
  onClose,
  // onThresholdsUpdated,
  timeControl,
}: MeasureDrawerProps) {
  const { translate } = useLocales();
  const favoritesState = useSelector((state) => state.favorites);

  if (!timeControl) {
    timeControl = toStartEndTimeControl({
      interval: Duration.fromISO("PT12H"),
    });
  }

  const [expandAlarmThresholds, setExpandAlarmThresholds] = useState(false);
  const [expandAlarmLogs, setExpandAlarmLogs] = useState(false);
  const [expandExport, setExpandExport] = useState(false);

  if (!measure) return null;

  return (
    <SwipeableDrawer
      open={open}
      onClose={onClose}
      anchor="right"
    >
      <Stack>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ px: 2, py: 2 }}
        >
          <Stack
            direction="row"
            gap={1}
            justifyContent="normal"
            alignItems="center"
          >
            <IconButton
              onClick={() => onClose()}
              sx={{
                p: 0,
                ml: "-6px",
              }}
            >
              <Iconify icon="eva:close-fill" width={24} height={24} />
            </IconButton>

            <LinkStyle
              href={PATH_DASHBOARD.measures.details(measure.identifier)}
            >
              <Stack direction="row" gap={1} alignItems="center">
                <Typography
                  title={measure.label}
                  variant="h6"
                  sx={{ fontSize: 20 }}
                >
                  {measure.label}
                </Typography>
                {measure.machineName && (
                  <LabelBadge sx={{ mr: 1 }} text={measure.machineName} />
                )}
              </Stack>
            </LinkStyle>
          </Stack>
          <FavoriteIcon
            isFavorite={
              favoritesState.measuresIds.findIndex(
                (measureId) => measureId === measure.identifier
              ) !== -1
            }
            toggleFavorite={() => dispatch(toggleMeasure(measure.identifier))}
          />
        </Stack>

        <GeneralSection
          measure={measure}
          displayLinkToMeasure={displayLinkToMeasure}
        />

        <Stack
          divider={<Divider sx={{ borderStyle: "dashed" }} />}
          gap={2}
          sx={{ pt: 2 }}
        >
          <Stack>
            <Panel
              label={translate("Export")}
              toggle={expandExport}
              onToggle={() => setExpandExport(!expandExport)}
              sx={{ px: 2, cursor: "pointer" }}
            />
            <Collapse in={expandExport}>
              <ExportSection measure={measure} timeControl={timeControl} />
            </Collapse>
          </Stack>

          <Stack>
            <Panel
              label={translate("Alarm thresholds")}
              toggle={expandAlarmThresholds}
              onToggle={() => setExpandAlarmThresholds(!expandAlarmThresholds)}
              sx={{ px: 2, cursor: "pointer" }}
            />
            <Collapse in={expandAlarmThresholds}>
              <AlarmsTresholdsSection
                measure={measure}
                // onUpdated={() => onThresholdsUpdated && onThresholdsUpdated()}
              />
            </Collapse>
          </Stack>

          <Stack>
            <Panel
              label={translate("Alarm logs")}
              toggle={expandAlarmLogs}
              onToggle={() => setExpandAlarmLogs(!expandAlarmLogs)}
              sx={{ px: 2, cursor: "pointer" }}
            />
            <Collapse in={expandAlarmLogs}>
              <AlarmLogsSection measure={measure} load={expandAlarmLogs} />
            </Collapse>
          </Stack>
        </Stack>
      </Stack>
    </SwipeableDrawer>
  );
}
