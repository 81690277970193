import "src/assets/fonts/index.css";

// import "mapbox-gl/dist/mapbox-gl.css";
import ReactDOM from "react-dom";
import { HelmetProvider } from "react-helmet-async";
// lightbox
import "react-image-lightbox/style.css";
import "react-lazy-load-image-component/src/effects/black-and-white.css";
// lazy image
import "react-lazy-load-image-component/src/effects/blur.css";
import "react-lazy-load-image-component/src/effects/opacity.css";
import { Provider as ReduxProvider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/lib/integration/react";
// editor
// slick-carousel
import "slick-carousel/slick/slick.css";
//
import App from "./App";
import { CollapseDrawerProvider } from "./contexts/CollapseDrawerContext";
// Check our docs
// https://docs-minimals.vercel.app/authentication/ts-version
// import { AuthProvider } from './contexts/Auth0Context';
// import { AuthProvider } from './contexts/FirebaseContext';
// import { AuthProvider } from './contexts/AwsCognitoContext';
// contexts
import { FormatProvider } from "./contexts/FormatContext";
// i18n
import "./locales/i18n";
// redux
import { persistor, store as reduxStore } from "./redux/store";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import * as webWorkersRegistration from "./webworker/registration";
import LoadingScreen from "./components/generic/LoadingScreen";
// highlight
// import "./utils/highlight";
// map
// import "./utils/mapboxgl";

// ----------------------------------------------------------------------

ReactDOM.render(
  <ReduxProvider store={reduxStore}>
    <PersistGate loading={<LoadingScreen />} persistor={persistor}>
      <HelmetProvider>
        <CollapseDrawerProvider>
          <BrowserRouter>
            <FormatProvider>
              <App />
            </FormatProvider>
          </BrowserRouter>
        </CollapseDrawerProvider>
      </HelmetProvider>
    </PersistGate>
  </ReduxProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// Web workers registration
webWorkersRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
