import { Box, Stack, SxProps } from "@mui/material";
import { alpha, useTheme } from "@mui/material/styles";
import LoadingAnimation from "./LoadingAnimation";
import { ReactNode } from "react";

type Props = {
  scale?: number;
  children?: ReactNode;
  sx?: SxProps;
};

export default function LoadingPanel({ scale = 1, children, sx }: Props) {
  const theme = useTheme();
  return (
    <Box
      sx={{
        position: "absolute",
        inset: 0,
        backgroundColor: alpha(theme.palette.background.default, 0.7),
        zIndex: 99,
        ...sx,
      }}
    >
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        height={1}
      >
        <Box sx={{ position: "relative", height: scale * 200 }}>
          <LoadingAnimation
            sx={{
              backgroundColor: "#ffffff00",
              transform: `scale(${scale})`,
            }}
          />
        </Box>
        <Box sx={{ position: "relative" }}>{children}</Box>
      </Stack>
    </Box>
  );
}
