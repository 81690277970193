import { DateTime } from "luxon";
import { Measure } from "../@types";
import axios from "../utils/axios";

class MeasureService {
  get() {
    return axios.get<Measure[]>("/measures");
  }

  getOne(id: string) {
    return axios.get<Measure>(`/measures/${id}`);
  }

  put(measure: Measure) {
    return axios.put<Measure>("/measures", measure);
  }

  getExportFile(
    measureId: string,
    start: DateTime,
    end: DateTime,
    format: "csv" | "ods" | "xlsx"
  ) {
    return axios.get(
      `/data/measure/${encodeURIComponent(
        measureId
      )}/export?start=${start}&end=${end}&format=${format}`,
      {
        responseType: "blob",
      }
    );
  }
}

export default new MeasureService();
