import * as muiLocales from "@mui/material/locale";
import * as muiXdpLocales from "@mui/x-date-pickers/locales";
import { Settings as LuxonSettings } from "luxon";
import numeral from "numeral";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { LanguageCode } from "src/@types";
// import { setThemeDirection } from "src/redux/slices/settings";
import { useSelector } from "src/redux/store";

// Numeral locales and prefixes corrections
require("numeral/locales");
Object.keys(numeral.locales).forEach((locale) => {
  numeral.locales[locale].abbreviations = {
    thousand: "k",
    million: "M",
    billion: "G",
    trillion: "T",
  };
});

type AppLanguage = {
  value: LanguageCode;
  label: string;
  icon: string;
  systemValue: muiLocales.Localization;
  // muiXdpLocale: muiXdpLocales.PickersLocaleText<DateTime>;
  muiDateTimePickerLocaleText: any;
};

export default function useLocales() {
  const languagesState = useSelector((state) => state.languages);
  const { i18n, t: translate } = useTranslation();
  const defaultLanguage: AppLanguage = {
    value: "en",
    label: "English",
    icon: `/assets/icons/flags/ic_flag_en.svg`,
    systemValue: muiLocales.enUS,
    muiDateTimePickerLocaleText:
      muiXdpLocales.enUS.components.MuiLocalizationProvider.defaultProps
        .localeText,
  };

  const appLanguages = useMemo(() => {
    return languagesState.languages.map((lng) => {
      const muiLocaleName = Object.keys(muiLocales).find((localeName) =>
        localeName.startsWith(lng.identifier)
      );
      const language: AppLanguage = {
        label: lng.label, // Should be "English", "Français"...
        value: lng.identifier,
        icon: `/assets/icons/flags/ic_flag_${lng.identifier}.svg`,
        systemValue: muiLocaleName
          ? (muiLocales as any)[muiLocaleName]
          : undefined,
        muiDateTimePickerLocaleText: muiLocaleName
          ? (muiXdpLocales as any)[muiLocaleName].components
              .MuiLocalizationProvider.defaultProps.localeText
          : undefined,
      };
      return language;
    });
  }, [languagesState.languages]);

  const appLanguage = useMemo(() => {
    const language = appLanguages.find((lng) => lng.value === i18n.language);
    return language || defaultLanguage;
  }, [appLanguages, i18n.language]);

  const changeLanguage = (language: LanguageCode) => {
    LuxonSettings.defaultLocale = language;
    numeral.locale(language);
    i18n.changeLanguage(language);
    // dispatch(setThemeDirection(language === "ar" ? "rtl" : "ltr"));
  };

  return {
    changeLanguage,
    translate: (text: any, options?: any) => translate(text, options),
    appLanguages,
    currentLanguage: appLanguage,
  };
}
