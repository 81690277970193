import {
  SerializedError,
  createAsyncThunk,
  createSlice,
} from "@reduxjs/toolkit";
import { DateTime } from "luxon";
import {
  Logbook,
  LogbookCategory,
  LogbookFromServer,
  LogbookType,
} from "src/@types/Logbook";
import { logbookService } from "src/services";

export interface LogbookState {
  // Logbook
  logbookRequestId?: string;
  loadingLogbook: boolean;
  loadedLogbook: boolean;
  logbook: LogbookFromServer[];
  logbookTotalCount: number;
  // Categories
  categoriesRequestId?: string;
  loadingCategories: boolean;
  loadedCategories: boolean;
  categories: LogbookCategory[];
  // Types
  typesRequestId?: string;
  loadingTypes: boolean;
  loadedTypes: boolean;
  types: LogbookType[];
  // Error
  error?: SerializedError;
}

const initialState: LogbookState = {
  // Logbook
  loadingLogbook: false,
  loadedLogbook: false,
  logbook: [],
  logbookTotalCount: 0,
  // Categories
  loadingCategories: false,
  loadedCategories: false,
  categories: [],
  // Types
  loadingTypes: false,
  loadedTypes: false,
  types: [],
};

export const loadLogbook = createAsyncThunk<
  { totalCount: number; logbook: LogbookFromServer[] },
  {
    pagesize: number;
    page: number;
    startDate: DateTime;
    endDate: DateTime;
    sort: string;
    order: string;
    filterEquipments: string[];
    filterSearch: string;
  },
  { state: { logbook: LogbookState } }
>("logbook", async (params, { getState, requestId }) => {
  const { logbookRequestId, loadingLogbook } = getState().logbook;
  if (!loadingLogbook || requestId !== logbookRequestId) {
    return {
      totalCount: initialState.logbookTotalCount,
      logbook: initialState.logbook,
    };
  }
  const response = await logbookService.getLogbooks(
    params.pagesize,
    params.page,
    params.startDate,
    params.endDate,
    params.sort,
    params.order,
    params.filterEquipments,
    params.filterSearch
  );
  return {
    totalCount: Number(response.headers["elements-total-count"]),
    logbook: response.data,
  };
});

export const deleteLogbookEntry = createAsyncThunk<
  { totalCount: number; logbook: LogbookFromServer[] },
  string,
  { state: { logbook: LogbookState } }
>("logbook-delete", async (id, { getState, requestId }) => {
  // const { logbookRequestId, loadingLogbook } = getState().logbook;
  // if (!loadingLogbook || requestId !== logbookRequestId) {
  //   return;
  // }
  await logbookService.delete(id);
  return {
    totalCount: getState().logbook.logbookTotalCount - 1,
    logbook: getState().logbook.logbook.filter((entry) => entry._id !== id),
  };
});

export const loadCategories = createAsyncThunk<
  LogbookCategory[],
  undefined,
  { state: { logbook: LogbookState } }
>("categories", async (params, { getState, requestId }) => {
  const { categoriesRequestId, loadingCategories } = getState().logbook;
  if (!loadingCategories || requestId !== categoriesRequestId) {
    return initialState.categories;
  }
  const response = await logbookService.getCategories();
  return response.data;
});

export const loadTypes = createAsyncThunk<
  LogbookType[],
  undefined,
  { state: { logbook: LogbookState } }
>("types", async (params, { getState, requestId }) => {
  const { typesRequestId, loadingTypes } = getState().logbook;
  if (!loadingTypes || requestId !== typesRequestId) {
    return initialState.types;
  }
  const response = await logbookService.getTypes();
  return response.data;
});

const slice = createSlice({
  name: "logbook",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Logbook
      .addCase(loadLogbook.pending, (state, action) => {
        if (!state.loadingLogbook) {
          state.loadingLogbook = true;
          state.logbookRequestId = action.meta.requestId;
        }
      })
      .addCase(loadLogbook.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (state.loadingLogbook && state.logbookRequestId === requestId) {
          state.loadingLogbook = false;
          state.logbook = action.payload.logbook;
          state.logbookTotalCount = action.payload.totalCount;
          state.logbookRequestId = undefined;
          state.loadedLogbook = true;
        }
      })
      .addCase(loadLogbook.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (state.loadingLogbook && state.logbookRequestId === requestId) {
          state.loadingLogbook = false;
          // console.log("action.error", JSON.stringify(action.error, null, 2));
          state.error = action.error;
          state.logbookRequestId = undefined;
        }
      })
      .addCase(deleteLogbookEntry.pending, (state, action) => {
        if (!state.loadingLogbook) {
          state.loadingLogbook = true;
          state.logbookRequestId = action.meta.requestId;
        }
      })
      .addCase(deleteLogbookEntry.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (state.loadingLogbook && state.logbookRequestId === requestId) {
          state.loadingLogbook = false;
          state.logbook = action.payload.logbook;
          state.logbookTotalCount = action.payload.totalCount;
          state.logbookRequestId = undefined;
          state.loadedLogbook = true;
        }
      })
      .addCase(deleteLogbookEntry.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (state.loadingLogbook && state.logbookRequestId === requestId) {
          state.loadingLogbook = false;
          // console.log("action.error", JSON.stringify(action.error, null, 2));
          state.error = action.error;
          state.logbookRequestId = undefined;
        }
      })
      // Categories
      .addCase(loadCategories.pending, (state, action) => {
        if (!state.loadingCategories) {
          state.loadingCategories = true;
          state.categoriesRequestId = action.meta.requestId;
        }
      })
      .addCase(loadCategories.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (
          state.loadingCategories &&
          state.categoriesRequestId === requestId
        ) {
          state.loadingCategories = false;
          state.categories = action.payload;
          state.categoriesRequestId = undefined;
          state.loadedCategories = true;
        }
      })
      .addCase(loadCategories.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (
          state.loadingCategories &&
          state.categoriesRequestId === requestId
        ) {
          state.loadingCategories = false;
          // console.log("action.error", JSON.stringify(action.error, null, 2));
          state.error = action.error;
          state.categoriesRequestId = undefined;
        }
      })
      // Types
      .addCase(loadTypes.pending, (state, action) => {
        if (!state.loadingTypes) {
          state.loadingTypes = true;
          state.typesRequestId = action.meta.requestId;
        }
      })
      .addCase(loadTypes.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (state.loadingTypes && state.typesRequestId === requestId) {
          state.loadingTypes = false;
          state.types = action.payload;
          state.typesRequestId = undefined;
          state.loadedTypes = true;
        }
      })
      .addCase(loadTypes.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (state.loadingTypes && state.typesRequestId === requestId) {
          state.loadingTypes = false;
          // console.log("action.error", JSON.stringify(action.error, null, 2));
          state.error = action.error;
          state.typesRequestId = undefined;
        }
      });
  },
});

export default slice;
