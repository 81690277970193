import { SxProps, TextField } from "@mui/material";
import { PatrolMeasure } from "src/@types";
import { useLocales } from "src/hooks";
import BaseBlock from "./BaseBlock";
import { forwardRef } from "react";

type Props = {
  measure: PatrolMeasure;
  setMeasure: (meaasure: PatrolMeasure) => void;
  sx?: SxProps;
};

export default forwardRef<HTMLDivElement, Props>(function NoteBlock(
  { measure, setMeasure, sx }: Props,
  ref
) {
  const { translate } = useLocales();
  return (
    <BaseBlock
      ref={ref}
      label={translate("Note")}
      component={
        <TextField
          multiline
          rows={4}
          // placeholder={translate("Your notes")}
          value={measure.note || ""}
          onChange={(event) =>
            setMeasure({ ...measure, note: event.target.value })
          }
        />
      }
      sx={sx}
    />
  );
});
