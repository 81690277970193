import { Box, Button, Card, Link, Stack } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Patrol, PatrolModel, User } from "src/@types";
import { PatrolsSyncMessage } from "src/@types/PatrolSync";
import { toStartEndTimeControl } from "src/@types/TimeControl";
import Iconify from "src/components/generic/Iconify";
import PlusButton from "src/components/generic/PlusButton";
import { useLocales, useResponsiveShortcut, useTable } from "src/hooks";
import { PATH_DASHBOARD } from "src/routes/paths";
import { dispatch, useSelector } from "src/redux/store";
import TableFooter from "src/components/generic/TableFooter";
import MoreButton from "src/components/generic/MoreButton";
import { deletePatrol, loadPatrols } from "src/redux/slices/patrols";
import PatrolsListDrawer from "./drawer/PatrolsListDrawer";
import PatrolsTable from "./PatrolsTable";
import PatrolsTableToolbar, { ValidationStatus } from "./PatrolsTableToolbar";

export type PatrolWithSyncState = Patrol & {
  syncState?: "inProgress" | "error";
};

type Props = {
  searchString?: string;
};

export default function PatrolsListCmp({ searchString = "" }: Props) {
  const { translate } = useLocales();
  const navigate = useNavigate();
  const table = useTable({ orderBy: "startTime", order: "desc" });

  const patrolsState = useSelector((state) => state.patrols);
  const timeControlState = useSelector((state) => state.patrolsTimeControl);

  const [operatorsFilter, setOperatorsFilter] = useState<User[]>([]);
  const [modelsFilter, setModelsFilter] = useState<PatrolModel[]>([]);
  const [validationStatusFilter, setValidationStatusFilter] =
    useState<ValidationStatus>();
  const [loading, setLoading] = useState(true);
  const [sizeMax, setSizeMax] = useState(0);
  const [patrols, setPatrols] = useState<PatrolWithSyncState[]>([]);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [hasAlreadySynced, setHasAlreadySynced] = useState(false);

  const { isLgUp } = useResponsiveShortcut();

  const startEndTimeControl = useMemo(() => {
    return toStartEndTimeControl(timeControlState.timeControl);
  }, [timeControlState.timeControl]);

  useEffect(() => {
    window.commPorts.patrolSync.postMessage({ type: "hasAlreadySynced" });
  }, []);

  useEffect(() => {
    if (!hasAlreadySynced && !patrolsState.patrols.length) return;
    setPatrols(patrolsState.patrols);
    setLoading(false);
  }, [hasAlreadySynced, patrolsState.patrols]);

  useEffect(() => {
    setSizeMax(patrolsState.patrolsTotal);
  }, [patrolsState.patrolsTotal]);

  useEffect(() => {
    setLoading(!hasAlreadySynced);
  }, [hasAlreadySynced]);

  useEffect(() => {
    const onPatrolSyncMessage = async (
      event: MessageEvent<
        PatrolsSyncMessage[keyof PatrolsSyncMessage] & {
          type: keyof PatrolsSyncMessage;
        }
      >
    ) => {
      console.log("> Received worker event:", event.data);
      // const message = event.data as PatrolsSyncMessage[typeof event.data.type] & {
      //   type: keyof PatrolsSyncMessage;
      // };
      // Error
      if (event.data.type === "error") {
      }
      // Start
      else if (event.data.type === "start") {
      }
      // End
      else if (event.data.type === "end") {
        const message =
          event.data as PatrolsSyncMessage[typeof event.data.type];
        if (
          message.createdPatrols.length ||
          message.deletedPatrols.length ||
          message.updatedPatrols.length
        ) {
          load();
        }
      }
      // Patrols started to sync
      else if (event.data.type === "patrols:syncing") {
        const message =
          event.data as PatrolsSyncMessage[typeof event.data.type];
        const updatedPatrols = [...patrols];
        const patrolsIds = message.patrols.map((p) => p._id);
        patrolsIds.forEach((patrolId) => {
          const patrolIndex = patrols.findIndex(
            (patrol) => patrol._id === patrolId
          );
          if (patrolIndex === -1) return;
          updatedPatrols.splice(patrolIndex, 1, {
            ...patrols[patrolIndex],
            syncState: "inProgress",
          });
        });
        setPatrols(updatedPatrols);
      }
      // Patrol started to sync
      // else if (event.data.type === "patrol:syncing") {
      //   const message =
      //     event.data as PatrolsSyncMessage[typeof event.data.type];
      //   const patrolId = message.patrol._id;
      //   const patrolIndex = patrols.findIndex(
      //     (patrol) => patrol._id === patrolId
      //   );
      //   if (patrolIndex === -1) return;
      //   const updatedPatrols = [...patrols];
      //   updatedPatrols.splice(patrolIndex, 1, {
      //     ...patrols[patrolIndex],
      //     syncState: "inProgress",
      //   });
      //   setPatrols(updatedPatrols);
      // }
      // // Patrol created locally
      // else if (event.data.type === "patrol:created") {
      //   // const message = event.data as MessagePayload[typeof event.data.type];
      //   const message =
      //     event.data as PatrolsSyncMessage[typeof event.data.type];
      //   setPatrols([...patrols, message.patrol]);
      // }
      // // Patrol update locally
      // else if (event.data.type === "patrol:updated") {
      //   const message =
      //     event.data as PatrolsSyncMessage[typeof event.data.type];
      //   const patrolId = message.old._id;
      //   const patrolIndex = patrols.findIndex(
      //     (patrol) => patrol._id === patrolId
      //   );
      //   if (patrolIndex === -1) return;
      //   const updatedPatrols = [...patrols];
      //   updatedPatrols.splice(patrolIndex, 1, {
      //     ...message.new,
      //     syncState: undefined,
      //   });
      //   setPatrols(updatedPatrols);
      // }
      // // Patrol deleted locally
      // else if (event.data.type === "patrol:deleted") {
      //   const message =
      //     event.data as PatrolsSyncMessage[typeof event.data.type];
      //   const patrolId = message.patrol._id;
      //   const patrolIndex = patrols.findIndex(
      //     (patrol) => patrol._id === patrolId
      //   );
      //   if (patrolIndex === -1) return;
      //   const updatedPatrols = [...patrols];
      //   updatedPatrols.splice(patrolIndex, 1);
      //   setPatrols(updatedPatrols);
      // }
      // Patrol error
      else if (event.data.type === "patrol:error") {
        const message =
          event.data as PatrolsSyncMessage[typeof event.data.type];
        const patrolId = message.patrol._id;
        const patrolIndex = patrols.findIndex((p) => p._id === patrolId);
        if (patrolIndex === -1) return;
        const updatedPatrols = [...patrols];
        updatedPatrols.splice(patrolIndex, 1, {
          ...patrols[patrolIndex],
          syncState: "error",
        });
        setPatrols(updatedPatrols);
      } else if (event.data.type === "hasAlreadySynced") {
        const message =
          event.data as PatrolsSyncMessage[typeof event.data.type];
        console.log("hasAlreadySynced", message);
        setHasAlreadySynced(message.value);
      }
    };
    window.commPorts.patrolSync?.addEventListener(
      "message",
      onPatrolSyncMessage
    );
    window.commPorts.patrolSync?.start();
    return () => {
      window.commPorts.patrolSync?.removeEventListener(
        "message",
        onPatrolSyncMessage
      );
    };
  }, [patrols]);

  const load = () => {
    dispatch(
      loadPatrols({
        startDate: startEndTimeControl.start,
        endDate: startEndTimeControl.end,
        operatorsFilter,
        modelsFilter,
        validationStatusFilter,
        sort: table.orderBy,
        order: table.order,
        pagesize: table.rowsPerPage,
        page: table.page,
      })
    );
  };

  useEffect(() => {
    load();
  }, [
    timeControlState,
    table.rowsPerPage,
    table.page,
    table.order,
    table.orderBy,
    operatorsFilter,
    modelsFilter,
    validationStatusFilter,
  ]);

  const handleDelete = async (patrol: Patrol) => {
    await dispatch(deletePatrol(patrol));
    load();
  };

  return (
    <>
      <Card>
        <PatrolsTableToolbar
          operatorsFilter={operatorsFilter}
          onOperatorsFilterChange={(filter: User[]) => {
            setOperatorsFilter(filter);
            table.setPage(0);
          }}
          modelsFilter={modelsFilter}
          onModelsFilterChange={(filter: PatrolModel[]) => {
            setModelsFilter(filter);
            table.setPage(0);
          }}
          validationStatusFilter={validationStatusFilter}
          onValidationStatusFilterChange={(filter: ValidationStatus) => {
            setValidationStatusFilter(filter);
            table.setPage(0);
          }}
        >
          {isLgUp ? (
            <Stack
              direction="row"
              gap={2}
              alignItems="center"
              justifyContent="flex-end"
              sx={{ height: 1 }}
            >
              <Button
                variant="contained"
                color="primary"
                startIcon={<Iconify icon="eva:plus-fill" />}
                onClick={() => navigate(PATH_DASHBOARD.patrols.create)}
                sx={{ height: 1 }}
              >
                {translate("Create patrol")}
              </Button>
              {/* <MoreButton onClick={() => setDrawerOpen(true)} /> */}
            </Stack>
          ) : null}
        </PatrolsTableToolbar>
        <PatrolsTable
          patrols={patrols}
          loading={loading}
          table={table}
          mode="full"
          handleDelete={handleDelete}
          sx={{ minHeight: "50vh" }}
        />
        <TableFooter
          rowsPerPageOptions={[10, 20, 50]}
          component="div"
          count={sizeMax}
          rowsPerPage={table.rowsPerPage}
          page={table.page}
          onPageChange={(evt, page) => table.setPage(page)}
          onRowsPerPageChange={(evt) =>
            table.setRowsPerPage(parseInt(evt.target.value, 10))
          }
          labelRowsPerPage={
            <Box component="span" sx={{ display: { xs: "none", sm: "block" } }}>
              {translate("Items per page:")}
            </Box>
          }
        >
          <Link onClick={() => setDrawerOpen(true)}>{translate("Export")}</Link>
        </TableFooter>
        {/*<TablePagination
          rowsPerPageOptions={[10, 20, 50]}
          component="div"
          count={sizeMax}
          rowsPerPage={table.rowsPerPage}
          page={table.page}
          onPageChange={(evt, page) => table.setPage(page)}
          onRowsPerPageChange={(evt) =>
            table.setRowsPerPage(parseInt(evt.target.value, 10))
          }
          labelRowsPerPage={
            <Box component="span" sx={{ display: { xs: "none", sm: "block" } }}>
              {translate("Items per page:")}
            </Box>
          }
        />*/}
      </Card>
      <PatrolsListDrawer
        timeControl={startEndTimeControl}
        operators={operatorsFilter}
        models={modelsFilter}
        validationStatus={validationStatusFilter}
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
      />
      {!isLgUp ? (
        <PlusButton
          onClick={() => navigate(PATH_DASHBOARD.patrols.create)}
          title={translate("Create patrol")}
        />
      ) : null}
    </>
  );
}
