import { ReactElement } from "react";
import { Link as RouterLink } from "react-router-dom";
// @mui
import {
  Box,
  Link,
  Typography,
  BreadcrumbsProps,
  Breadcrumbs as MUIBreadcrumbs,
} from "@mui/material";

// ----------------------------------------------------------------------

type TLink = {
  name: string;
  href?: string;
  icon?: ReactElement;
  label?: string;
};

export interface Props extends BreadcrumbsProps {
  links: TLink[];
  activeLast?: boolean;
}

export default function Breadcrumbs({
  links,
  activeLast = false,
  ...other
}: Props) {
  const currentLinkName = links.length
    ? links[links.length - 1].name
    : undefined;

  const listDefault = links.map((link) => (
    <LinkItem key={link.name} link={link} />
  ));

  const listActiveLast = links.map((link) => (
    <div key={link.name}>
      {link.href !== undefined /*link.name !== currentLinkName*/ ? (
        <LinkItem link={link} />
      ) : (
        <TextItem link={link} />
      )}
    </div>
  ));

  return (
    <MUIBreadcrumbs
      separator={
        <Box
          component="span"
          sx={{
            width: 4,
            height: 4,
            borderRadius: "50%",
            bgcolor: "text.disabled",
          }}
        />
      }
      sx={{
        "& > ol": {
          flexWrap: "nowrap",
          overflow: "auto",
        },
      }}
      {...other}
    >
      {activeLast ? listDefault : listActiveLast}
    </MUIBreadcrumbs>
  );
}

// ----------------------------------------------------------------------

type LinkItemProps = {
  link: TLink;
};

function LinkItem({ link }: LinkItemProps) {
  const { href, name, icon, label } = link;
  return (
    <Link
      key={name}
      variant="body2"
      component={RouterLink}
      to={href || "#"}
      sx={{
        lineHeight: 2,
        display: "flex",
        alignItems: "center",
        color: "text.primary",
        whiteSpace: "nowrap",
        "& > div": { display: "inherit" },
      }}
    >
      {icon && (
        <Box sx={{ mr: 1, "& svg": { width: 20, height: 20 } }}>{icon}</Box>
      )}
      {label ? label : name}
    </Link>
  );
}

function TextItem({ link }: LinkItemProps) {
  return (
    <Typography
      variant="body2"
      sx={{
        maxWidth: 260,
        overflow: "hidden",
        whiteSpace: "nowrap",
        color: "text.disabled",
        textOverflow: "ellipsis",
      }}
    >
      {link.label ? link.label : link.name}
    </Typography>
  );
}
