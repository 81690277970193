import { Box } from "@mui/material";
import { Stack, SxProps, Typography } from "@mui/material";
import { sprintf } from "sprintf-js";

import { Measure } from "src/@types";
import AnimatedDots from "src/components/generic/AnimatedDots";
import Compass from "src/components/generic/Compass";
import { useLocales } from "src/hooks";
import { useSelector } from "src/redux/store";
import { fShortenNumber } from "src/utils/formatNumber";

type Props = {
  measure: Measure;
  sx?: SxProps;
};

export default function ({ measure, sx }: Props) {
  const { translate } = useLocales();
  const measuresState = useSelector((state) => state.measures);
  const lastValueString =
    measure.data?.last?.value === undefined
      ? undefined
      : measure.type === "number"
      ? sprintf(
          "%s%s",
          fShortenNumber(measure.data.last.value),
          measure.unit ? ` ${measure.unit}` : ""
        )
      : measure.type === "boolean"
      ? measure.data.last.value === "true"
        ? translate("True")
        : translate("False")
      : measure.type === "string"
      ? measure.data.last.value
      : "";
  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      sx={sx}
    >
      {lastValueString !== undefined ? (
        <Typography variant="h3">
          {lastValueString}
          {measuresState.dataLoading ? <AnimatedDots /> : null}
        </Typography>
      ) : (
        <Typography variant="subtitle2">
          {translate("No data")}
          {measuresState.dataLoading ? <AnimatedDots /> : null}
        </Typography>
      )}
      {measure.type === "number" &&
      measure.display === "compass" &&
      measure.data?.last?.value !== undefined ? (
        <Box sx={{ position: "relative", width: 100 }}>
          <Box
            sx={{ position: "absolute", left: 0, top: 0, marginTop: "-50%" }}
          >
            <Compass value={measure.data.last.value as number} />
          </Box>
        </Box>
      ) : null}
    </Stack>
  );
}
