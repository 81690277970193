import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const locales = {
  // en: require("./messages.pot"),
  fr: require("./fr.po"),
};

i18n.use(initReactI18next).init({
  resources: Object.entries(locales).reduce(
    (acc, [localeName, localeTranslations]) => ({
      ...acc,
      [localeName]: { translations: localeTranslations },
    }),
    {}
  ),
  lng: window.navigator.language.slice(0, 2), // Take the first 2 characters from browser language (fr_FR -> fr)
  fallbackLng: "en",
  supportedLngs: Object.keys(locales),
  debug: false,
  saveMissing: false,
  ns: ["translations"],
  defaultNS: "translations",
  returnEmptyString: false,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
