//
import ThemeContrast from "./ThemeContrast";
// import ThemeColorPresets from "./ThemeColorPresets";
import ThemeLocalization from "./ThemeLocalization";

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
};

export default function ThemeSettings({ children }: Props) {
  // return (
  //   <ThemeColorPresets>
  //     <ThemeContrast>
  //       <ThemeLocalization>
  //         {children}
  //         {/* <SettingsDrawer /> */}
  //       </ThemeLocalization>
  //     </ThemeContrast>
  //   </ThemeColorPresets>
  // );
  return (
      <ThemeContrast>
        <ThemeLocalization>
          {children}
          {/* <SettingsDrawer /> */}
        </ThemeLocalization>
      </ThemeContrast>
  );
}
