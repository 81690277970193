import { Box, Card, Link, Stack, TablePagination } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { AlarmLog, Equipment } from "src/@types";
import AlarmLogTableToolbar from "src/components/app/alarmlogs/AlarmLogTableToolbar";
import Error500 from "src/components/generic/Error500";
import LoadingAnimation from "src/components/generic/LoadingAnimation";
import { TableHeadCell } from "src/components/generic/table";
import { useLocales, useTable } from "src/hooks";
import { useSelector } from "src/redux/store";
import { alarmService } from "src/services";
import AlarmLogTable from "./AlarmLogTable";
import { toStartEndTimeControl } from "src/@types/TimeControl";
import TableFooter from "src/components/generic/TableFooter";
import MoreButton from "src/components/generic/MoreButton";
import AlarmLogListDrawer from "./drawer/AlarmLogListDrawer";

export default function AlarmLogList() {
  const { translate } = useLocales();
  const timeControlState = useSelector((state) => state.alarmsLogTimeControl);
  const table = useTable({
    orderBy: "alarm.status.lastChangeOn",
    order: "desc",
  });
  const search = useLocation().search;
  const initSearch = new URLSearchParams(search).get("search") || "";

  const [searchFilter, setSearchFilter] = useState(initSearch);
  const [searchFilterWatch, setSearchFilterWatch] = useState("");
  const [equipmentsFilter, setEquipmentsFilter] = useState<Equipment[]>([]);
  const [items, setItems] = useState<AlarmLog[]>([]);
  const [itemsTotalCount, setItemsTotalCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [filterTimer, setFilterTimer] = useState<NodeJS.Timeout | undefined>(
    undefined
  );
  const [drawerOpen, setDrawerOpen] = useState(false);

  const startEndTimeControl = useMemo(
    () => toStartEndTimeControl(timeControlState.timeControl),
    [timeControlState.timeControl]
  );

  useEffect(() => {
    const fn = async () => {
      try {
        const startEndTimeControl = toStartEndTimeControl(
          timeControlState.timeControl
        );
        setLoading(true);
        setError(false);
        const response = await alarmService.getAlarmLogs(
          {
            start: startEndTimeControl.start,
            end: startEndTimeControl.end,
            equipments: equipmentsFilter.map(
              (equipment) => equipment.identifier
            ),
            search: searchFilterWatch,
          },
          table.rowsPerPage,
          table.page + 1,
          table.orderBy,
          table.order
        );
        const alarmLogs = response.data;
        setItemsTotalCount(Number(response.headers["elements-total-count"]));
        setItems(alarmLogs);
        setLoading(false);
      } catch (e) {
        console.log(e);
        setLoading(false);
        setError(true);
      }
    };
    fn();
  }, [
    timeControlState,
    table.page,
    table.rowsPerPage,
    table.orderBy,
    table.order,
    equipmentsFilter,
    searchFilterWatch,
  ]);

  useEffect(() => {
    if (searchFilter === undefined) return;
    if (filterTimer) clearTimeout(filterTimer);

    setFilterTimer(
      setTimeout(() => {
        setSearchFilterWatch(searchFilter);
        setFilterTimer(undefined);
      }, 1000)
    );
  }, [searchFilter]);

  const handleFilterSearch = (search: string) => {
    setSearchFilter(search);
    table.setPage(0);
  };

  const handleFilterEquipments = (filter: Equipment[]) => {
    setEquipmentsFilter(filter);
    table.setPage(0);
  };

  return (
    <>
      <Card>
        <AlarmLogTableToolbar
          filterSearch={searchFilter}
          onFilterSearch={handleFilterSearch}
          filterEquipments={equipmentsFilter}
          onFilterEquipments={handleFilterEquipments}
        >
          <Stack
            direction="row"
            gap={2}
            alignItems="center"
            justifyContent="flex-end"
            sx={{ height: 1, display: { xs: "none", sm: "flex" } }}
          >
            {/* <MoreButton onClick={() => setDrawerOpen(true)} /> */}
          </Stack>
        </AlarmLogTableToolbar>
        {loading ? (
          <Box sx={{ height: "20vh", position: "relative" }}>
            <LoadingAnimation
              sx={{ backgroundColor: "#ffffff00", transform: "scale(0.7)" }}
            />
          </Box>
        ) : error ? (
          <Error500 />
        ) : (
          <>
            <AlarmLogTable items={items} sx={{ minHeight: "50vh" }} />
            <TableFooter
              rowsPerPageOptions={[10, 20, 50]}
              component="div"
              count={itemsTotalCount}
              rowsPerPage={table.rowsPerPage}
              page={table.page}
              onPageChange={(evt, page) => table.setPage(page)}
              onRowsPerPageChange={(evt) =>
                table.setRowsPerPage(parseInt(evt.target.value, 10))
              }
              labelRowsPerPage={
                <Box
                  component="span"
                  sx={{ display: { xs: "none", sm: "block" } }}
                >
                  {translate("Items per page:")}
                </Box>
              }
            >
              <Link onClick={() => setDrawerOpen(true)}>
                {translate("Export")}
              </Link>
            </TableFooter>
            {/*<TablePagination
            rowsPerPageOptions={[10, 20, 50]}
            component="div"
            count={itemsTotalCount}
            rowsPerPage={table.rowsPerPage}
            page={table.page}
            onPageChange={(evt, page) => table.setPage(page)}
            onRowsPerPageChange={(evt) =>
              table.setRowsPerPage(parseInt(evt.target.value, 10))
            }
            labelRowsPerPage={
              <Box
                component="span"
                sx={{ display: { xs: "none", sm: "block" } }}
              >
                {translate("Items per page:")}
              </Box>
            }
          />*/}
          </>
        )}
      </Card>
      <AlarmLogListDrawer
        timeControl={startEndTimeControl}
        equipments={equipmentsFilter}
        search={searchFilter}
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
      />
    </>
  );
}
