import {
  Box,
  Dialog,
  DialogContent,
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  ImageListProps,
  Link,
  Slide,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { TransitionProps } from "@mui/material/transitions";
import { forwardRef, useEffect, useState } from "react";
import { sprintf } from "sprintf-js";
import { Attachment, LocalAttachment } from "src/@types";
import LoadingAnimation from "src/components/generic/LoadingAnimation";
import { useLocales, useResponsiveShortcut } from "src/hooks";
import { useSelector } from "src/redux/store";
import Iconify from "./Iconify";
import Scrollbar from "./Scrollbar";
import Error404 from "./Error404";
import Error500 from "./Error500";

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

type WithUrls<T> = T & { thumbnailUrl: string; fullSizeUrl: string };

type Props<T> = {
  attachments: T[];
  getBaseUrl?: (attachment: T) => string;
  onFileRemoved?: (attachment: T) => void;
} & Omit<ImageListProps, "children">;

export default function AttachmentsList<
  T extends Attachment | LocalAttachment
>({ attachments, getBaseUrl, onFileRemoved, ...otherProps }: Props<T>) {
  const { translate } = useLocales();
  const authState = useSelector((state) => state.auth);
  const { isSmUp } = useResponsiveShortcut();
  const [attachmentsWithUrl, setAttachmentsWithUrlState] = useState<
    WithUrls<T>[]
  >([]);
  const [loadedImages, setLoadedImages] = useState<{ [key: string]: boolean }>(
    {}
  );
  const [erroredImages, setErroredImages] = useState<{
    [key: string]: boolean;
  }>({});
  const [loadedFullSize, setLoadedFullSize] = useState(false);
  const [erroredFullSize, setErroredFullSize] = useState(false);
  const [openModal, setOpenModal] = useState<WithUrls<T> | undefined>(
    undefined
  );

  const StyleImageListItemBar = styled(ImageListItemBar)(({ theme }) => ({
    ".MuiImageListItemBar-titleWrap": {
      padding: "0 8px",
      ".MuiImageListItemBar-title": {
        fontSize: 12,
      },
    },
  }));

  useEffect(() => {
    if (!authState.user) return;
    const getRemoteBaseUrl = (attachment: T) =>
      typeof getBaseUrl === "function" ? getBaseUrl(attachment) : "";
    setAttachmentsWithUrlState(
      attachments.map((attachment) => ({
        ...attachment,
        thumbnailUrl: Object.keys(attachment).includes("content")
          ? sprintf(
              "data:%s;base64,%s",
              attachment.type,
              (attachment as LocalAttachment).content
            )
          : sprintf(
              "%s?token=%s",
              [getRemoteBaseUrl(attachment), "thumbnails", "small"].join("/"),
              authState.user?.token
            ),
        fullSizeUrl: Object.keys(attachment).includes("content")
          ? sprintf(
              "data:%s;base64,%s",
              attachment.type,
              (attachment as LocalAttachment).content
            )
          : sprintf(
              "%s?token=%s",
              getRemoteBaseUrl(attachment),
              authState.user?.token
            ),
      }))
    );
  }, [attachments, getBaseUrl, authState.user]);

  return (
    <>
      <ImageList cols={isSmUp ? 4 : 2} {...otherProps}>
        {attachmentsWithUrl
          .filter((attachment) => attachment.type !== undefined)
          .map((attachment) => (
            <ImageListItem key={attachment.id}>
              {["image/jpeg", "image/png"].includes(attachment.type || "") ? (
                <Box
                  sx={{ cursor: "pointer", minHeight: 64 }}
                  onClick={() => setOpenModal(attachment)}
                >
                  <img
                    src={attachment.thumbnailUrl}
                    alt=""
                    loading="lazy"
                    onError={() =>
                      setErroredImages({
                        ...erroredImages,
                        [attachment.id]: true,
                      })
                    }
                    onLoad={() =>
                      setLoadedImages({
                        ...loadedImages,
                        [attachment.id]: true,
                      })
                    }
                    style={{ width: "100%" }}
                  />
                  {erroredImages[attachment.id] ? (
                    <Iconify
                      icon="icon-park-outline:error-picture"
                      sx={{ fontSize: 48 }}
                    />
                  ) : !loadedImages[attachment.id] ? (
                    <LoadingAnimation sx={{ transform: "scale(0.3)" }} />
                  ) : null}
                </Box>
              ) : attachment.type === "application/pdf" ? (
                <Box sx={{ cursor: "pointer", p: 3, textAlign: "center" }}>
                  <Link href={attachment.fullSizeUrl} target="_blank">
                    <Iconify
                      icon="fa6-regular:file-pdf"
                      sx={{ fontSize: 64 }}
                    />
                  </Link>
                </Box>
              ) : (
                <Box sx={{ cursor: "pointer", p: 3, textAlign: "center" }}>
                  <Link href={attachment.fullSizeUrl} target="_blank">
                    <Iconify icon="eva:file-outline" sx={{ fontSize: 64 }} />
                  </Link>
                </Box>
              )}
              <StyleImageListItemBar
                title={attachment.name}
                actionIcon={
                  onFileRemoved ? (
                    <IconButton
                      sx={{ color: "rgba(255, 255, 255, 0.54)" }}
                      aria-label={`Info about ${attachment.name}`}
                      title={translate("Remove attachment")}
                      onClick={() => onFileRemoved(attachment)}
                    >
                      <Iconify icon="eva:trash-2-outline" />
                    </IconButton>
                  ) : null
                }
              />
            </ImageListItem>
          ))}
      </ImageList>
      {openModal ? (
        <Dialog
          // fullScreen
          open={openModal !== undefined}
          onClose={() => {
            setLoadedFullSize(false);
            setErroredFullSize(false);
            setOpenModal(undefined);
          }}
          maxWidth="md"
          fullWidth={true}
          TransitionComponent={Transition}
        >
          <DialogContent sx={{ minHeight: 200, minWidth: 400 }}>
            <Scrollbar>
              <Link href={openModal.fullSizeUrl} target="_blank">
                <img
                  src={openModal.fullSizeUrl}
                  alt=""
                  loading="lazy"
                  onError={() => setErroredFullSize(true)}
                  onLoad={() => setLoadedFullSize(true)}
                />
              </Link>
            </Scrollbar>
            {!loadedFullSize ? (
              <LoadingAnimation sx={{ transform: "scale(0.7)" }} />
            ) : erroredFullSize ? (
              <Error500
              // title={translate("Error")}
              // text={translate(
              //   "An error occured while loading the file."
              // )}
              />
            ) : null}
          </DialogContent>
        </Dialog>
      ) : null}
    </>
  );
}
