import {
  Box,
  Button,
  MenuItem,
  MenuList,
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableCellProps,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import { DateTime } from "luxon";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { sprintf } from "sprintf-js";
import { LogbookFromServer } from "src/@types/Logbook";
// import LogbookTableRow from "src/components/app/logbook/LogbookTableRow";
import ConfirmDialog from "src/components/generic/dialog/ConfirmDialog";
import Iconify from "src/components/generic/Iconify";
import LoadingAnimation from "src/components/generic/LoadingAnimation";
import {
  // TableHeadCell,
  TableHeadCustom,
  TableMoreMenu,
  TableNoData,
} from "src/components/generic/table";
import {
  useFormat,
  useLocales,
  useResponsiveShortcut,
  useTable,
} from "src/hooks";
import { TableColumnDefinition, TableParams } from "src/hooks/useTable";
import { PATH_DASHBOARD } from "src/routes/paths";

const LinkTableCell = (props: TableCellProps & { item: LogbookFromServer }) => {
  const navigate = useNavigate();
  return (
    <TableCell
      {...props}
      onClick={() => navigate(PATH_DASHBOARD.logbook.show(props.item._id))}
      sx={{ cursor: "pointer" }}
    />
  );
};

type Props = {
  items: LogbookFromServer[];
  loading: boolean;
  table: TableParams;
  mode: "simple" | "full";
  handleDelete?: (item: LogbookFromServer) => void;
  sx?: SxProps;
};

export default function LogbookTable({
  items,
  loading,
  table,
  mode,
  handleDelete,
  sx,
}: Props) {
  const { translate } = useLocales();
  const { formatDate } = useFormat();
  const navigate = useNavigate();
  const { isMdUp, isSmUp } = useResponsiveShortcut();

  const [deleteDialogOpen, setDeleteDialogOpen] = useState<{
    [key: string]: boolean;
  }>({});

  const handleOpenDeleteDialog = (item: LogbookFromServer) =>
    setDeleteDialogOpen({ [item._id]: true });
  const handleCloseDeleteDialog = (item: LogbookFromServer) =>
    setDeleteDialogOpen(
      Object.entries(deleteDialogOpen)
        .filter(([k, v]) => k !== item._id)
        .filter((item) => item)
        .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {})
    );

  const tableDefinitionTemplate: (
    | TableColumnDefinition<LogbookFromServer>
    | undefined
  )[] = [
    {
      head: {
        id: "time",
        label: translate("Date"),
        align: "left",
      },
      Cell: ({ item }: { item: LogbookFromServer }) => (
        <LinkTableCell item={item}>
          {formatDate(item.time, DateTime.DATETIME_MED)}
        </LinkTableCell>
      ),
    },
    {
      head: {
        id: "type.label",
        label: translate("Type"),
        align: "left",
      },
      Cell: ({ item }: { item: LogbookFromServer }) => (
        <LinkTableCell item={item}>{item.type.label}</LinkTableCell>
      ),
    },
    {
      head: {
        id: "equipment.label",
        label: translate("Equipment"),
        align: "left",
      },
      Cell: ({ item }: { item: LogbookFromServer }) => (
        <LinkTableCell item={item}>{item.equipment.label}</LinkTableCell>
      ),
    },
    {
      head: {
        id: "location.label",
        label: translate("Location"),
        align: "left",
      },
      Cell: ({ item }: { item: LogbookFromServer }) => (
        <LinkTableCell item={item}>{item.location.label}</LinkTableCell>
      ),
    },
    {
      head: {
        id: "operator.lastName",
        label: translate("Operator"),
        align: "left",
      },
      Cell: ({ item }: { item: LogbookFromServer }) => (
        <LinkTableCell item={item}>
          {`${item.operator.firstName} ${item.operator.lastName}`}
        </LinkTableCell>
      ),
    },
    mode === "full"
      ? {
          head: { id: "actions", sx: { width: 0 } },
          Cell: ({ item }: { item: LogbookFromServer }) => (
            <TableCell align="right" sx={{ width: 0 }}>
              <TableMoreMenu>
                <MenuList>
                  <MenuItem
                    onClick={() =>
                      navigate(PATH_DASHBOARD.logbook.show(item._id))
                    }
                  >
                    <Iconify icon={"eva:eye-outline"} />
                    {translate("Show")}
                  </MenuItem>
                  {item.updatable && (
                    <MenuItem
                      onClick={() =>
                        navigate(PATH_DASHBOARD.logbook.update(item._id))
                      }
                    >
                      <Iconify icon={"eva:edit-fill"} />
                      {translate("Edit")}
                    </MenuItem>
                  )}
                  {item.updatable && (
                    <MenuItem
                      onClick={(event) => handleOpenDeleteDialog(item)}
                      sx={{ color: "error.main" }}
                    >
                      <Iconify icon={"eva:trash-2-outline"} />
                      {translate("Delete")}
                    </MenuItem>
                  )}
                </MenuList>
              </TableMoreMenu>
              {handleDelete ? (
                <ConfirmDialog
                  open={deleteDialogOpen[item._id] ? true : false}
                  onClose={() => handleCloseDeleteDialog(item)}
                  title={
                    <Typography variant="subtitle2">
                      {translate("Logbook entry deletion")}
                    </Typography>
                  }
                  content={
                    <>
                      <Typography>
                        {translate(
                          "This logbook entry is about to be deleted:"
                        )}
                      </Typography>
                      <Typography sx={{ px: 1 }}>
                        {sprintf(translate("Identifier: %s"), item._id)}
                      </Typography>
                      <Typography sx={{ px: 1 }}>
                        {sprintf(
                          translate("Date: %s"),
                          formatDate(item.time, DateTime.DATETIME_MED)
                        )}
                      </Typography>
                      <Typography>
                        {translate(
                          "Are you sure you want to delete this logbook entry?"
                        )}
                      </Typography>
                      <Typography>
                        <Iconify
                          icon={"fa-pro-duotone:triangle-exclamation"}
                          sx={{ mr: 1 }}
                        />
                        {translate(
                          "Please be careful, this operation is irreversible"
                        )}
                      </Typography>
                    </>
                  }
                  actions={
                    <>
                      <Button
                        variant="outlined"
                        color="inherit"
                        onClick={() => handleCloseDeleteDialog(item)}
                      >
                        {translate("Cancel")}
                      </Button>
                      <Button
                        variant="contained"
                        color="error"
                        onClick={() => {
                          handleDelete(item);
                          handleCloseDeleteDialog(item);
                        }}
                      >
                        {translate("Delete")}
                      </Button>
                    </>
                  }
                />
              ) : null}
            </TableCell>
          ),
        }
      : undefined,
  ];
  const tableDefinition = tableDefinitionTemplate.filter(
    (e) => e
  ) as TableColumnDefinition<LogbookFromServer>[];

  // const TABLE_HEAD: TableHeadCell[] = [
  //   {
  //     id: "time",
  //     label: translate("Date"),
  //     align: "left",
  //   },
  //   {
  //     id: "type.label",
  //     label: translate("Type"),
  //     align: "left",
  //   },
  //   {
  //     id: "equipment.label",
  //     label: translate("Equipment"),
  //     align: "left",
  //   },
  //   {
  //     id: "location.label",
  //     label: translate("Location"),
  //     align: "left",
  //   },
  //   {
  //     id: "operator.lastName",
  //     label: translate("Operator"),
  //     align: "left",
  //   },
  //   { id: "actions", label: "", align: "right" },
  // ];

  return (
    <>
      {loading ? (
        <Box marginTop={"17vh"}>
          <LoadingAnimation
            sx={{ backgroundColor: "#ffffff00", transform: "scale(0.7)" }}
          />
        </Box>
      ) : (
        <TableContainer sx={sx}>
          <Table size={table.dense ? "small" : "medium"}>
            <TableHeadCustom
              // headCells={TABLE_HEAD}
              headCells={tableDefinition.map((d) => d.head)}
              order={table.order}
              orderBy={table.orderBy}
              rowCount={items.length - 1}
              onSort={table.onSort}
            />
            <TableBody>
              {items.map((item, index) => (
                <TableRow key={index} hover>
                  {tableDefinition
                    .map((d) => d.Cell)
                    .map((Cell, index) => (
                      <Cell item={item} key={index} />
                    ))}
                </TableRow>
              ))}
              {/*items.map((e, index) => (
                <LogbookTableRow
                  key={index}
                  logbookEntry={e}
                  handleDelete={() => handleDelete(e)}
                />
              ))*/}
              <TableNoData isNotFound={!items.length} />
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
}
