import { IconButton, IconButtonProps } from "@mui/material";
import Iconify from "./Iconify";
import { forwardRef } from "react";
import { Box } from "@mui/material";

type MoreButtonProps = IconButtonProps;

const MoreButton = forwardRef(function MoreButton(props: MoreButtonProps, ref) {
  // const theme = useTheme();
  return (
    <Box ref={ref}>
      <IconButton
        size="large"
        // sx={{
        //   color: theme.palette.text.primary,
        //   backgroundColor: theme.palette.grey[500_12],
        // }}
        {...props}
      >
        <Iconify icon={"eva:more-vertical-fill"} width={20} height={20} />
      </IconButton>
    </Box>
  );
});

export default MoreButton;
