import { Box, Card, CardHeader, CardProps } from "@mui/material";
import merge from "lodash/merge";
import ReactApexChart from "react-apexcharts";
import ChartBaseOptions from "./ChartBaseOptions";

interface Props extends CardProps {
  title?: string;
  subheader?: string;
  chartData: {
    label: string;
    chartLabels: string[];
    data: {
      name: string;
      data: number[];
    }[];
  };
}

export default function SingleLineCharts({
  title,
  subheader,
  chartData,
  ...other
}: Props) {
  const chartOptions = merge(ChartBaseOptions(), {
    xaxis: {
      categories: chartData.chartLabels,
    },
  });

  return (
    <Card {...other}>
      <CardHeader title={title} subheader={subheader} />

      <Box key={chartData.label} sx={{ mt: 3, mx: 3 }} dir="ltr">
        <ReactApexChart
          type="line"
          series={chartData.data}
          options={chartOptions}
          height={364}
        />
      </Box>
    </Card>
  );
}
