import {
  StepButton,
  StepButtonProps,
  Typography,
  useTheme,
} from "@mui/material";
import { Step } from "src/@types";
import StepIcon from "./StepIcon";

type Props = StepButtonProps & {
  step: Step;
  index: number;
  active: boolean;
  completed: boolean;
};

export default function ({
  step,
  index,
  active,
  completed,
  onClick,
  ...props
}: Props) {
  const theme = useTheme();
  return (
    <StepButton
      onClick={onClick}
      sx={{ cursor: !onClick ? "default" : undefined }}
      icon={<StepIcon index={index} active={active} completed={completed} />}
      {...props}
    >
      <Typography
        noWrap
        sx={{
          color: active ? theme.palette.primary.main : theme.palette.grey[600],
          fontWeight: "bold",
        }}
      >
        {step.label}
      </Typography>
      <Typography variant="body2" noWrap>
        {step.value || ""}
      </Typography>
    </StepButton>
  );
}
