import { DateTime } from "luxon";
import { Alarm, AlarmLog, Operator } from "src/@types/Alarm";
import axios from "src/utils/axios";

class AlarmService {
  getAlarms(pagesize: number = 200, page: number = 1) {
    return axios.get<Alarm[]>("/alarms", {
      params: { pagination: { page, pagesize } },
    });
  }

  getAlarmLogs(
    filter: {
      start: DateTime;
      end: DateTime;
      equipments?: string[];
      search?: string;
    },
    pagesize: number,
    page: number,
    sort: string,
    order: string
  ) {
    const pagination = `{"page":${page}, "pagesize":${pagesize}}`;
    const start = `alarm.status.lastChangeOn > ${filter.start.toUTC().toISO()}`;
    const end = `alarm.status.lastChangeOn < ${filter.end.toUTC().toISO()}`;
    const equipmentFilter =
      filter.equipments && filter.equipments.length > 0
        ? `alarm.metadata.equipmentId in ${JSON.stringify(filter.equipments)}`
        : "";
    const queryFilterSearch = filter.search
      ? `(alarm.metadata.equipmentLabel|alarm.metadata.measureMachineName|alarm.metadata.measureLabel|alarm.metadata.measureId)==/${filter.search}/i`
      : "";
    const formattedFilter = [queryFilterSearch, equipmentFilter, start, end]
      .filter((e) => e)
      .join(";");
    const sortFormated = `${order === "desc" ? "-" : "+"}${sort}`;
    return axios.get<AlarmLog[]>("/alarmlogs", {
      params: {
        filter: formattedFilter,
        pagination,
        sort: sortFormated,
      },
    });
  }

  getAlarmLogsExportFile(
    filter: {
      start: DateTime;
      end: DateTime;
      equipments?: string[];
      search?: string;
    },
    format: "ods" | "xlsx"
  ) {
    const start = `alarm.status.lastChangeOn > ${filter.start.toUTC().toISO()}`;
    const end = `alarm.status.lastChangeOn < ${filter.end.toUTC().toISO()}`;
    const equipmentFilter =
      filter.equipments && filter.equipments.length > 0
        ? `alarm.metadata.equipmentId in ${JSON.stringify(filter.equipments)}`
        : "";
    const queryFilterSearch = filter.search
      ? `(alarm.metadata.equipmentLabel|alarm.metadata.measureMachineName|alarm.metadata.measureLabel|alarm.metadata.measureId)==/${filter.search}/i`
      : "";

    const formattedFilter = [queryFilterSearch, equipmentFilter, start, end]
      .filter((e) => e)
      .join(";");
    return axios.get("/alarmlogs/export", {
      params: {
        format,
        filter: formattedFilter,
        // optionalFields,
      },
      responseType: "blob",
    });
  }

  getOperators(type?: string) {
    return axios.get<Operator[]>(
      type !== undefined ? `/alarms/operators/${type}` : `/alarms/operators`
    );
  }
}

const alarmService = new AlarmService();
export default alarmService;
