import { Container } from "@mui/material";
import { upperCase } from "lodash";
import { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { sprintf } from "sprintf-js";
import { Equipment, Location, Measure } from "src/@types";
import {
  isIntervalTimeControl,
  toStartEndTimeControl,
} from "src/@types/TimeControl";
import MeasureDrawer from "src/components/app/measure/drawer/MeasureDrawer";
import MeasuresBlock from "src/components/app/measure/MeasuresBlock";
import DateRangeSelect from "src/components/generic/DateRangeSelect";
import HeaderBreadcrumbs from "src/components/generic/HeaderBreadcrumbs";
import Page from "src/components/generic/Page";
import { useLocales } from "src/hooks";
import { set as setTimeControl } from "src/redux/slices/measuresTimeControl";
import { dispatch, useSelector } from "src/redux/store";
import { PATH_DASHBOARD } from "src/routes/paths";

function LocationOverview() {
  const organizationState = useSelector((state) => state.organization);
  const equipmentsState = useSelector((state) => state.equipments);
  const equipmentsLocationsState = useSelector(
    (state) => state.equipmentsLocations
  );
  const { translate } = useLocales();
  const { locationId = "" } = useParams();
  const settingsState = useSelector((state) => state.settings);
  const timeControlState = useSelector((state) => state.measuresTimeControl);
  const { measures: allMeasures } = useSelector((state) => state.measures);
  const [drawerMeasure, setDrawerMeasure] = useState<Measure>();
  const [drawerOpen, setDrawerOpen] = useState(false);

  const startEndTimeControl = useMemo(() => {
    return toStartEndTimeControl(timeControlState.timeControl);
  }, [timeControlState.timeControl]);

  const location = useMemo(() => {
    return equipmentsLocationsState.equipmentsLocations?.find(
      (l: Location) => l.identifier === locationId
    );
  }, [locationId, equipmentsLocationsState.equipmentsLocations]);

  const equipments = useMemo(() => {
    return equipmentsState.equipments.filter(
      (equipment: Equipment) => equipment.locationId === location?.identifier
    );
  }, [location, equipmentsState.equipments]);

  const measures = useMemo(() => {
    return allMeasures.filter(
      (measure: Measure) =>
        equipments
          .map((equipment: Equipment) => equipment.identifier)
          .includes(measure.equipmentId) &&
        measure.domains.includes("equipment-type-overview")
    );
  }, [equipments, allMeasures]);

  const handleCloseDrawer = () => setDrawerOpen(false);

  return (
    <Page title={location?.label || ""}>
      <Container maxWidth={settingsState.themeStretch ? false : "xl"}>
        <HeaderBreadcrumbs
          heading={sprintf(
            translate("Overview of %s"),
            upperCase(location?.label)
          )}
          // links={[
          //   {
          //     name: translate("Home"),
          //     href: PATH_DASHBOARD.root,
          //   },
          //   {
          //     name: location?.label || "",
          //     href: PATH_DASHBOARD.measures.location(
          //       location?.identifier || ""
          //     ),
          //   },
          // ]}
          action={
            <DateRangeSelect
              label={translate("Display time range")}
              intervals={
                organizationState.organization?.timeSelectorIntervals
                  .overview || []
              }
              value={
                isIntervalTimeControl(timeControlState.timeControl)
                  ? timeControlState.timeControl.interval
                  : null
              }
              onChange={(value) =>
                value && dispatch(setTimeControl({ interval: value }))
              }
              sx={{ width: { xs: "100%", sm: "24rem" } }}
            />
          }
        />
        {drawerMeasure && (
          <MeasureDrawer
            measure={drawerMeasure}
            timeControl={startEndTimeControl}
            displayLinkToMeasure={true}
            open={drawerOpen}
            onClose={handleCloseDrawer}
          />
        )}
        <MeasuresBlock
          measures={measures}
          setDrawerMeasure={(measure: Measure) => {
            setDrawerOpen(true);
            setDrawerMeasure(measure);
          }}
        />
      </Container>
    </Page>
  );
}

export default LocationOverview;
