import { Stack, Typography } from "@mui/material";
import { SxProps, Theme, useTheme } from "@mui/material/styles";
import { Measure } from "src/@types";
import { useLocales } from "src/hooks";
import { ColorSchema } from "src/theme/palette";
import { fShortenNumber } from "src/utils/formatNumber";

export interface MeasureAggregateProps {
  title: string;
  subtitle?: string;
  measure: Measure;
  data: any;
  loading: boolean;
  loaded: boolean;
  color?: ColorSchema;
  sx?: SxProps<Theme>;
}

export default function MeasureAggregate({
  title,
  subtitle,
  measure,
  data,
  loading,
  loaded,
  color,
  sx,
}: MeasureAggregateProps) {
  const { translate } = useLocales();
  const theme = useTheme();

  return (
    <Stack justifyContent="center" sx={{ py: { sm: 1 }, ...sx }}>
      {/* {loading && (
          <LoadingAnimation
            sx={{
              backgroundColor: "#ffffff00",
              transform: "scale(0.5)",
            }}
          />
        )} */}

      {!loading && loaded && (
        <>
          <Typography
            variant="subtitle2"
            color={
              color ? theme.palette[color].light : theme.palette.text.secondary
            }
            noWrap
          >
            {title}
          </Typography>

          {data !== undefined ? (
            <Typography
              variant="h4"
              color={color || theme.palette.text.primary}
              noWrap
            >
              {measure.type === "number"
                ? `${fShortenNumber(data)} ${measure.unit || ""}`
                : measure.type === "boolean"
                ? data === true
                  ? translate("True")
                  : data === false
                  ? translate("False")
                  : translate("Undefined")
                : measure.type === "string"
                ? data
                : null}
            </Typography>
          ) : (
            <div>{translate("No data")}</div>
          )}

          <Typography
            variant="caption"
            color={
              color ? theme.palette[color].light : theme.palette.text.secondary
            }
            noWrap
          >
            {subtitle}
          </Typography>
        </>
      )}
    </Stack>
  );
}
