import { Fab, FabProps, useTheme } from "@mui/material";
import Iconify from "./Iconify";

function PlusButton(props: FabProps) {
  const theme = useTheme();

  return (
    <Fab
      sx={{
        position: "fixed",
        bottom: 0,
        right: 0,
        mb: 2,
        mr: 2,
      }}
      {...props}
    >
      <Iconify
        icon={"eva:plus-fill"}
        sx={{
          height: "36px",
          width: "36px",
          color: theme.palette.text.primary,
        }}
      />
    </Fab>
  );
}

export default PlusButton;
