import {
  Box,
  Button,
  Card,
  Divider,
  Grid,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Patrol, PatrolMeasure } from "src/@types";
import Iconify from "src/components/generic/Iconify";
import Label from "src/components/generic/Label";
import Scrollbar from "src/components/generic/Scrollbar";
import { useLocales, useResponsiveShortcut, useTabs } from "src/hooks";
import { useSelector } from "src/redux/store";
import { PATH_DASHBOARD } from "src/routes/paths";
import MeasureView from "./view/MeasureView";
import PatrolHeader from "./PatrolHeader";

type Props = {
  patrol: Patrol;
};

export default function PatrolOverviewCmp({ patrol }: Props) {
  const theme = useTheme();
  const { isLgUp } = useResponsiveShortcut();
  const navigate = useNavigate();
  const { translate } = useLocales();
  const { currentTab, onChangeTab } = useTabs("all");
  // const scrollbarRef = useRef<HTMLDivElement>();
  const authState = useSelector((state) => state.auth);
  const { groupMeasures, isPatrolEditable } = useSelector(
    (state) => state.patrols
  );
  const [filteredMeasures, setFilteredMeasures] = useState<PatrolMeasure[]>([]);
  // const [measuresAndModels, setMeasuresAndModels] = useState<MeasureAndModel[]>(
  //   []
  // );

  const isEditable = useMemo(
    () => (authState.user ? isPatrolEditable(patrol, authState.user) : false),
    [patrol, authState.user]
  );

  useEffect(() => {
    if (currentTab === "all")
      setFilteredMeasures(patrol.measures.filter((m) => m.active));
    else if (currentTab === "todo")
      setFilteredMeasures(patrol.measures.filter((m) => m.active && !m.done));
    else if (currentTab === "done")
      setFilteredMeasures(patrol.measures.filter((m) => m.active && m.done));
  }, [currentTab, patrol.measures]);

  // useEffect(() => {
  //   setMeasuresAndModels(
  //     // patrol.measures
  //     filteredMeasures
  //       .map((measure) => {
  //         const model = patrol.model.measures.find(
  //           (m) => m.identifier === measure.identifier
  //         );
  //         if (!model) return undefined;
  //         return { measure, model };
  //       })
  //       .filter((e) => e) as MeasureAndModel[]
  //   );
  // }, [patrol, filteredMeasures]);

  const groupedMeasures = useMemo(
    () => groupMeasures(filteredMeasures, patrol.model),
    [filteredMeasures, patrol.model]
  );

  const FILTER_TABS = [
    {
      value: "all",
      label: translate("All"),
      color: "info",
      count: patrol.measures.filter((m) => m.active).length,
    },
    {
      value: "todo",
      label: translate("To do"),
      color: "warning",
      count: patrol.measures.filter((m) => m.active && !m.done).length || 0,
    },
    {
      value: "done",
      label: translate("Done"),
      color: "success",
      count: patrol.measures.filter((m) => m.active && m.done).length || 0,
    },
  ] as const;

  return (
    <Stack
      gap={2}
      sx={{
        height: "100%",
        overflowY: "auto",
        flex: 1,
        // display: "flex",
        // flexDirection: "column",
      }}
    >
      <Card sx={{ p: 2 }}>
        <PatrolHeader patrol={patrol} />
      </Card>
      <Card
        sx={{
          height: "100%",
          overflowY: "auto",
          flex: 1,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            backgroundColor: theme.palette.background.neutral,
            px: 2,
          }}
        >
          <Box>
            {isLgUp ? (
              <Tabs
                allowScrollButtonsMobile
                variant="scrollable"
                scrollButtons="auto"
                value={currentTab}
                onChange={onChangeTab}
              >
                {FILTER_TABS.map((tab) => (
                  <Tab
                    disableRipple
                    key={tab.value}
                    value={tab.value}
                    icon={<Label color={tab.color}>{tab.count}</Label>}
                    label={tab.label}
                  />
                ))}
              </Tabs>
            ) : null}
          </Box>
          {isEditable ? (
            <Stack>
              <Button
                startIcon={<Iconify icon="eva:edit-outline" />}
                onClick={() =>
                  navigate(PATH_DASHBOARD.patrols.edit(patrol._id))
                }
                title={translate("Edit patrol")}
              >
                {translate("Edit")}
              </Button>
            </Stack>
          ) : null}
        </Stack>

        <Divider />

        <Stack gap={2} sx={{ p: 2 }} divider={<Divider />}>
          {groupedMeasures.map((groupedMeasures, index) =>
            groupedMeasures.measuresAndModels.length ? (
              <Stack
                key={groupedMeasures.group?.identifier || index}
                gap={1}
                divider={<Divider />}
              >
                <Box>
                  {groupedMeasures.group?.label ? (
                    <Box sx={{ mb: 2 }}>
                      <Typography variant="h6">
                        {groupedMeasures.group.label}
                      </Typography>
                    </Box>
                  ) : null}
                  <Grid container spacing={2}>
                    {groupedMeasures.measuresAndModels.map(
                      (measureAndModel) => (
                        <Grid
                          item
                          key={measureAndModel.model.identifier}
                          xs={12}
                        >
                          <Card
                            sx={{
                              bgcolor: theme.palette.background.neutral,
                            }}
                          >
                            <Stack direction="row">
                              {measureAndModel.measure.attachments?.length ||
                              measureAndModel.measure.note?.length ? (
                                <Box
                                  sx={{
                                    position: "absolute",
                                    left: 0,
                                    top: 0,
                                    bottom: 0,
                                    width: theme.spacing(0.5),
                                    bgcolor: theme.palette.primary.main,
                                  }}
                                />
                              ) : null}
                              <Box
                                key={measureAndModel.model.identifier}
                                gap={1}
                                sx={{ p: 2 }}
                              >
                                <Typography variant="subtitle2">
                                  {measureAndModel.model.label}
                                </Typography>
                                <MeasureView
                                  patrol={patrol}
                                  measure={measureAndModel.measure}
                                  showLabel={false}
                                  showEmpty={false}
                                />
                              </Box>
                            </Stack>
                          </Card>
                        </Grid>
                      )
                    )}
                  </Grid>
                </Box>
              </Stack>
            ) : null
          )}
        </Stack>
      </Card>
    </Stack>
  );
}
