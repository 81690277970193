import { Box, Card, Stack, SxProps } from "@mui/material";
import { Duration } from "luxon";
import { useEffect, useMemo, useState } from "react";
import { Measure, MeasureAggregatesData, TimeControl } from "src/@types";
import { toStartEndTimeControl } from "src/@types/TimeControl";
import Error500 from "src/components/generic/Error500";
import LoadingAnimation from "src/components/generic/LoadingAnimation";
import { useLocales, useResponsiveShortcut } from "src/hooks";
import { dataService } from "src/services";
import displayLastRefresh from "src/utils/date";
import MeasureAggregate from "../MeasureAggregate";
import MeasureChart from "../MeasureChart";
import EmblaCarousel from "../../../generic/EmblaCarousel";
import LoadingPanel from "src/components/generic/LoadingPanel";

function CurrentValueContent({ measure }: { measure: Measure }) {
  const { translate } = useLocales();
  return (
    <MeasureAggregate
      title={translate("Current value")}
      subtitle={
        measure?.data?.last &&
        displayLastRefresh(measure.data.last.time, translate, false)
      }
      measure={measure}
      data={measure.data?.last?.value}
      loading={false}
      loaded={true}
      color="primary"
      sx={{
        display: "flex",
        alignItems: "center",
      }}
    />
  );
}

type FirstRowProps = {
  measure: Measure;
  timeControl: TimeControl;
};

function Aggregates({ measure, timeControl }: FirstRowProps) {
  const { translate } = useLocales();
  const { isSmUp } = useResponsiveShortcut();
  const [aggregatesData, setAggregatesData] = useState<MeasureAggregatesData>();
  const [aggregatesDataLoading, setAggregatesDataLoading] =
    useState<boolean>(false);
  const [aggregatesDataLoaded, setAggregatesDataLoaded] =
    useState<boolean>(false);

  const startEndTimeControl = useMemo(() => {
    return toStartEndTimeControl(timeControl);
  }, [timeControl]);

  useEffect(() => {
    if (!timeControl) return;
    const loadDataAggregate = async (measureId: string) => {
      setAggregatesDataLoaded(false);
      setAggregatesDataLoading(true);
      const response = await dataService.getMeasureAggregates(
        measureId,
        startEndTimeControl.start,
        startEndTimeControl.end,
        ["mean", "stdev", "min", "max"]
      );
      setAggregatesData(response.data);
      setAggregatesDataLoading(false);
      setAggregatesDataLoaded(true);
    };
    if (measure?.identifier) {
      loadDataAggregate(measure?.identifier);
    }
  }, [measure?.identifier, timeControl]);

  return (
    <>
      {!isSmUp ? (
        <Card>
          <EmblaCarousel>
            <Box>
              <CurrentValueContent measure={measure} />
            </Box>
            {aggregatesDataLoading ? (
              <Box sx={{ height: "100%", position: "relative" }}>
                <LoadingAnimation sx={{ transform: "scale(0.35)" }} />
              </Box>
            ) : (
              <Stack direction="row" justifyContent="space-around">
                <MeasureAggregate
                  title={translate("Mean")}
                  measure={measure}
                  data={aggregatesData?.mean}
                  loading={aggregatesDataLoading}
                  loaded={aggregatesDataLoaded}
                />
                <MeasureAggregate
                  title={translate("Standard deviation")}
                  measure={measure}
                  data={aggregatesData?.stdev}
                  loading={aggregatesDataLoading}
                  loaded={aggregatesDataLoaded}
                />
              </Stack>
            )}
            {aggregatesDataLoading ? (
              <Box sx={{ height: "100%", position: "relative" }}>
                <LoadingAnimation sx={{ transform: "scale(0.35)" }} />
              </Box>
            ) : (
              <Stack direction="row" justifyContent="space-around">
                <MeasureAggregate
                  title={translate("Min")}
                  measure={measure}
                  data={aggregatesData?.min}
                  loading={aggregatesDataLoading}
                  loaded={aggregatesDataLoaded}
                />
                <MeasureAggregate
                  title={translate("Max")}
                  measure={measure}
                  data={aggregatesData?.max}
                  loading={aggregatesDataLoading}
                  loaded={aggregatesDataLoaded}
                />
              </Stack>
            )}
          </EmblaCarousel>
        </Card>
      ) : (
        <Stack gap={2} sx={{ flexDirection: "row" }}>
          <Card sx={{ flex: 0.75 }}>
            {aggregatesDataLoading ? (
              <Box sx={{ height: "100%", position: "relative" }}>
                <LoadingAnimation sx={{ transform: "scale(0.35)" }} />
              </Box>
            ) : (
              <Stack direction="row" justifyContent="space-around">
                <MeasureAggregate
                  title={translate("Mean")}
                  measure={measure}
                  data={aggregatesData?.mean}
                  loading={aggregatesDataLoading}
                  loaded={aggregatesDataLoaded}
                />
                <MeasureAggregate
                  title={translate("Standard deviation")}
                  measure={measure}
                  data={aggregatesData?.stdev}
                  loading={aggregatesDataLoading}
                  loaded={aggregatesDataLoaded}
                />
                <MeasureAggregate
                  title={translate("Min")}
                  measure={measure}
                  data={aggregatesData?.min}
                  loading={aggregatesDataLoading}
                  loaded={aggregatesDataLoaded}
                />
                <MeasureAggregate
                  title={translate("Max")}
                  measure={measure}
                  data={aggregatesData?.max}
                  loading={aggregatesDataLoading}
                  loaded={aggregatesDataLoaded}
                />
              </Stack>
            )}
          </Card>
          <Card
            sx={{
              display: "flex",
              flex: 0.25,
              justifyContent: "center",
              overflow: "visible",
            }}
          >
            <CurrentValueContent measure={measure} />
          </Card>
        </Stack>
      )}
    </>
  );
}

type NumericDetailsProps = {
  measure: Measure;
  timeControl: TimeControl;
  setTimeControl: (timeControl: TimeControl) => void;
  onMenuClick: () => void;
  sx?: SxProps;
};

export default function NumericDetails({
  measure,
  timeControl,
  setTimeControl,
  onMenuClick,
  sx,
}: NumericDetailsProps) {
  const [seriesData, setSeriesData] = useState<any[]>([]);
  const [granularity, setGranularity] = useState<Duration>();
  const [seriesDataLoading, setSeriesDataLoading] = useState<boolean>(false);
  const [seriesDataLoaded, setSeriesDataLoaded] = useState<boolean>(false);
  const [seriesDataError, setSeriesDataError] = useState<boolean>(false);

  const startEndTimeControl = useMemo(() => {
    return toStartEndTimeControl(timeControl);
  }, [timeControl]);

  useEffect(() => {
    const loadDataSeries = async (measureId: string) => {
      try {
        setSeriesDataError(false);
        setSeriesDataLoaded(false);
        setSeriesDataLoading(true);
        const response = await dataService.getMeasureHistory(
          measureId,
          startEndTimeControl.start,
          startEndTimeControl.end
        );
        setGranularity(
          response.headers.granularity
            ? Duration.fromISO(response.headers.granularity)
            : undefined
        );
        setSeriesData(response.data);
        setSeriesDataLoading(false);
        setSeriesDataLoaded(true);
      } catch (e) {
        setSeriesDataError(true);
        setSeriesDataLoading(false);
        setSeriesDataLoaded(false);
      }
    };
    if (measure?.identifier) {
      loadDataSeries(measure?.identifier);
    }
  }, [measure?.identifier, startEndTimeControl]);

  return (
    <Stack gap={2} sx={{ flex: 1 }}>
      <Aggregates measure={measure} timeControl={timeControl} />
      <Card sx={{ flex: 1 }}>
        {seriesDataLoading ? (
          <Box sx={{ height: 300, position: "relative" }}>
            <LoadingAnimation sx={{ transform: "scale(0.35)" }} />
          </Box>
        ) : seriesDataError ? (
          <Error500 />
        ) : (
          <MeasureChart
            measure={measure}
            data={seriesData}
            granularity={granularity}
            timeControl={timeControl}
            onMenuClick={() => onMenuClick()}
            setTimeControl={setTimeControl}
            sx={{
              height: "100%",
              minHeight: "500px",
            }}
          />
        )}
      </Card>
    </Stack>
  );
}
