import {
  Autocomplete,
  Box,
  InputAdornment,
  Stack,
  TextField,
} from "@mui/material";
import { ReactNode, useMemo } from "react";
import { Equipment } from "src/@types";
import { useLocales } from "src/hooks";
import Iconify from "src/components/generic/Iconify";
import { useSelector } from "src/redux/store";

type AutocompleteEquipment = {
  label: string;
  value: string;
  group: string;
};

type Props = {
  searchFilter: string;
  onSearchFilterChange: (value: string) => void;
  equipmentsFilter: Equipment[];
  onEquipmentsFilterChange: (value: Equipment[]) => void;
  children?: ReactNode;
};

export default function LogbookTableToolbar({
  searchFilter,
  onSearchFilterChange,
  equipmentsFilter,
  onEquipmentsFilterChange,
  children,
}: Props) {
  const { translate } = useLocales();
  const equipmentsState = useSelector((state) => state.equipments);

  const equipmentsToOptions = (equipments: Equipment[]) =>
    equipments.map((equipment) => ({
      label: equipment.label,
      // label: `${equipment.location.label} ${equipment.label}`,
      value: equipment.identifier,
      group: equipment.location.label,
    })) as AutocompleteEquipment[];

  const optionsToEquipments = (equipments: AutocompleteEquipment[]) =>
    equipmentsState.equipments.filter((e) =>
      equipments.map((e) => e.value).includes(e.identifier)
    );

  const equipmentsOptions = useMemo(
    () =>
      equipmentsToOptions(equipmentsState.equipments).sort((a, b) =>
        a.group > b.group ? 1 : b.group > a.group ? -1 : 0
      ),
    [equipmentsState.equipments]
  );

  return (
    <Stack
      direction={{ xs: "column", sm: "row" }}
      justifyContent="space-between"
      alignItems="stretch"
      gap={2}
      sx={{ p: 3 }}
    >
      <Stack
        direction={{ xs: "column", sm: "row" }}
        width={{ xs: "100%", lg: "75%" }}
        gap={2}
      >
        <TextField
          fullWidth
          value={searchFilter}
          onChange={(event) => onSearchFilterChange(event.target.value)}
          placeholder={translate(
            "Search for equipments, measure label or machine name"
          )}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Iconify
                  icon={"eva:search-fill"}
                  sx={{ color: "text.disabled", width: 20, height: 20 }}
                />
              </InputAdornment>
            ),
          }}
        />
        <Autocomplete
          multiple
          sx={{ minWidth: "50%" }}
          value={equipmentsToOptions(equipmentsFilter)}
          onChange={(event, values) =>
            onEquipmentsFilterChange(optionsToEquipments(values))
          }
          options={equipmentsOptions}
          isOptionEqualToValue={(option, value) => option.value === value.value}
          groupBy={(opt) => opt.group}
          renderInput={(params) => (
            <TextField {...params} label={translate("Equipements")} />
          )}
        />
      </Stack>
      <Box sx={{ flex: 1 }}>{children}</Box>
    </Stack>
  );
}
