import { Box, Link, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Duration } from "luxon";
import { useState } from "react";
import { isDesktop, isMobile } from "react-device-detect";
import { sprintf } from "sprintf-js";
import Iconify from "src/components/generic/Iconify";
import MenuPopover from "src/components/generic/MenuPopover";
import { useLocales } from "src/hooks";
import { useSelector } from "src/redux/store";

export default function DataTimer() {
  const theme = useTheme();
  const { translate } = useLocales();
  const socketIoState = useSelector((state) => state.socketIo);
  const measuresState = useSelector((state) => state.measures);
  const [open, setOpen] = useState<HTMLElement | null>(null);

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  return (
    <Box display="flex" alignItems="center">
      <Link
        onClick={(evt) => isMobile && handleOpen(evt)}
        onMouseEnter={(evt) => isDesktop && handleOpen(evt)}
        onMouseLeave={(evt) => isDesktop && handleClose()}
        underline="none"
        sx={{ cursor: "pointer", lineHeight: 0 }}
      >
        {!measuresState.dataLoading ? (
          <Iconify
            icon="material-symbols:alarm-outline"
            width={24}
            height={24}
            style={{ color: theme.palette.action.active }}
          />
        ) : (
          <Iconify
            icon="material-symbols:alarm-on-outline"
            width={24}
            height={24}
            style={{ color: theme.palette.primary.main }}
          />
        )}
      </Link>
      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          pointerEvents: isDesktop ? "none" : undefined,
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          {/*<Typography variant="body2" sx={{ color: "text.secondary" }} noWrap>*/}
          <Typography variant="body2" noWrap>
            {socketIoState.nextLoadIn
              ? sprintf(
                  translate("Data refresh in %s seconds"),
                  Math.round(
                    Duration.fromISO(socketIoState.nextLoadIn).as("seconds")
                  )
                )
              : translate("No timer data for now.")}
          </Typography>
        </Box>
      </MenuPopover>
    </Box>
  );
}
