import { Box, Container } from "@mui/material";
import { DateTime } from "luxon";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { sprintf } from "sprintf-js";
import { Patrol } from "src/@types";
import { PatrolOverview as PatrolOverviewCmp } from "src/components/app/patrols";
import HeaderBreadcrumbs from "src/components/generic/HeaderBreadcrumbs";
import LoadingAnimation from "src/components/generic/LoadingAnimation";
import Page from "src/components/generic/Page";
import { useFormat, useLocales, useStore } from "src/hooks";
import { useSelector } from "src/redux/store";
import { PATH_DASHBOARD } from "src/routes/paths";

export default function PatrolOverview() {
  const { patrolId = "" } = useParams();
  const authState = useSelector((state) => state.auth);
  const { formatDate } = useFormat();
  const navigate = useNavigate();
  const { translate } = useLocales();
  const settingsState = useSelector((state) => state.settings);
  const { getStore } = useStore();
  const { enqueueSnackbar } = useSnackbar();

  const [patrol, setPatrol] = useState<Patrol>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fn = async () => {
      const store = await getStore();
      if (!patrolId) return;
      // Patrol loading
      let loadedPatrol = patrol;
      if (patrol?._id !== patrolId) {
        setLoading(true);
        loadedPatrol = await store.Patrols.findOne({ _id: patrolId });
        if (!loadedPatrol) {
          navigate(PATH_DASHBOARD.patrols.list);
          enqueueSnackbar(translate("Patrol was not found"), {
            variant: "error",
          });
          return;
        }
        if (
          !loadedPatrol.done &&
          loadedPatrol.operator._id !== authState.user?._id
        ) {
          navigate(PATH_DASHBOARD.patrols.list);
          enqueueSnackbar(translate("Patrol is not editable"), {
            variant: "error",
          });
          return;
        }
        console.log("Loaded patrol", loadedPatrol);
        setPatrol(loadedPatrol);
        setLoading(false);
      }
    };
    fn();
  }, [patrolId]);

  if (!patrol) {
    return null;
  }

  return (
    <Page
      title={translate("Patrol overview")}
      sx={{ height: "100%", overflowY: "auto" }}
    >
      <Container
        maxWidth={settingsState.themeStretch ? false : "xl"}
        sx={{
          height: "100%",
          overflowY: "auto",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <HeaderBreadcrumbs
          heading={sprintf(
            translate("Patrol %s on %s"),
            patrol.model.label,
            formatDate(patrol.startTime, DateTime.DATETIME_MED)
          )}
          links={[
            {
              name: translate("Home"),
              href: PATH_DASHBOARD.root,
            },
            {
              name: translate("Tools"),
            },
            {
              name: translate("Patrols"),
              href: PATH_DASHBOARD.patrols.list,
            },
            {
              name: sprintf(
                translate("Patrol %s on %s"),
                patrol.model.label,
                formatDate(patrol.startTime, DateTime.DATETIME_MED)
              ),
            },
          ]}
          // action={
          //   <Stack>
          //     <IconButton
          //       onClick={() =>
          //         navigate(
          //           PATH_DASHBOARD.patrols.one(
          //             patrol?._id || "",
          //             displayModeState === "view" ? "edit" : "view",
          //             measure?.identifier || ""
          //           )
          //         )
          //       }
          //       title={
          //         displayModeState === "view"
          //           ? translate("Edit")
          //           : translate("View")
          //       }
          //     >
          //       <Iconify
          //         icon={
          //           displayModeState === "view"
          //             ? "eva:edit-outline"
          //             : "eva:eye-outline"
          //         }
          //       />
          //     </IconButton>
          //   </Stack>
          // }
        />
        {loading ? (
          <Box marginTop={"20vh"}>
            <LoadingAnimation
              sx={{ backgroundColor: "#ffffff00", transform: "scale(0.7)" }}
            />
          </Box>
        ) : (
          <PatrolOverviewCmp patrol={patrol} />
        )}
      </Container>
    </Page>
  );
}
