import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useState } from "react";
import { Outlet } from "react-router-dom";
import { HEADER, NAVBAR } from "src/config";
import { useCollapseDrawer } from "src/hooks";
import { useSelector } from "src/redux/store";
import DashboardHeader from "./header";
import NavbarVertical from "./navbar/NavbarVertical";
import { Breakpoint } from "@mui/material";

// ----------------------------------------------------------------------

const breakpoint: Breakpoint = "lg";

type MainStyleProps = {
  collapseClick: boolean;
  fullHeight: boolean;
  breakpoint: Breakpoint;
};

const MainStyle = styled("main", {
  shouldForwardProp: (prop) =>
    !["collapseClick", "fullHeight", "breakpoint"].includes(prop as string),
})<MainStyleProps>(({ collapseClick, fullHeight, breakpoint, theme }) => ({
  flexGrow: 1,
  // paddingTop: HEADER.MOBILE_HEIGHT + 24,
  paddingTop: HEADER.MOBILE_HEIGHT + 8,
  paddingBottom: HEADER.MOBILE_HEIGHT + 24,
  // paddingBottom: 16,
  ...(fullHeight && {
    height: "100vh",
    overflowY: "auto",
  }),
  // [theme.breakpoints.up("lg")]: {
  [theme.breakpoints.up(breakpoint)]: {
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: HEADER.DASHBOARD_DESKTOP_HEIGHT + 24,
    // paddingBottom: HEADER.DASHBOARD_DESKTOP_HEIGHT + 24,
    paddingBottom: 32,
    // ...(fullHeight && {
    //   height: "100vh",
    //   overflowY: "auto",
    // }),
    width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH}px)`,
    transition: theme.transitions.create("margin-left", {
      duration: theme.transitions.duration.shorter,
    }),
    ...(collapseClick && {
      marginLeft: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
    }),
  },
}));

// ----------------------------------------------------------------------

type DashboardLayoutProps = {
  fullHeight: boolean;
};

export default function DashboardLayout({ fullHeight }: DashboardLayoutProps) {
  const { collapseClick, isCollapse } = useCollapseDrawer();
  const settingsState = useSelector((state) => state.settings);
  const [open, setOpen] = useState(false);

  const verticalLayout = settingsState.themeLayout === "vertical";

  if (verticalLayout) {
    return (
      <>
        <DashboardHeader
          onOpenSidebar={() => setOpen(true)}
          verticalLayout={verticalLayout}
          breakpoint={breakpoint}
        />

        <NavbarVertical
          isOpenSidebar={open}
          onCloseSidebar={() => setOpen(false)}
          breakpoint={breakpoint}
        />

        <Box
          component="main"
          sx={{
            // px: { lg: 2 },
            px: { [breakpoint]: 2 },
            pt: {
              xs: `${HEADER.MOBILE_HEIGHT + 24}px`,
              // lg: `${HEADER.DASHBOARD_DESKTOP_HEIGHT + 80}px`,
              [breakpoint]: `${HEADER.DASHBOARD_DESKTOP_HEIGHT + 80}px`,
            },
            pb: {
              xs: `${HEADER.MOBILE_HEIGHT + 24}px`,
              // lg: `${HEADER.DASHBOARD_DESKTOP_HEIGHT + 24}px`,
              [breakpoint]: `${HEADER.DASHBOARD_DESKTOP_HEIGHT + 24}px`,
            },
          }}
        >
          <Outlet />
        </Box>
      </>
    );
  }

  return (
    <Box
      sx={{
        // display: { lg: "flex" },
        // minHeight: { lg: 1 },
        display: { [breakpoint]: "flex" },
        minHeight: { [breakpoint]: 1 },
      }}
    >
      <DashboardHeader
        isCollapse={isCollapse}
        onOpenSidebar={() => setOpen(true)}
        breakpoint={breakpoint}
      />

      <NavbarVertical
        isOpenSidebar={open}
        onCloseSidebar={() => setOpen(false)}
        breakpoint={breakpoint}
      />

      <MainStyle
        className="Main-root"
        collapseClick={collapseClick}
        fullHeight={fullHeight}
        breakpoint={breakpoint}
      >
        <Outlet />
      </MainStyle>
    </Box>
  );
}
