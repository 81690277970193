import { Link, ListItemText, Tooltip, Typography } from "@mui/material";
import { NavLink as RouterLink } from "react-router-dom";
import { isExternalLink } from "..";
import { NavItemProps } from "../type";
import { DotIcon } from "./DotIcon";
import { ArrowIcon } from "./ArrowIcon";
import ListItem from "./ListItem";

type Props = Omit<NavItemProps, "isCollapse">;

export default function NavItemSub({
  item,
  open = false,
  active = false,
  onOpen,
}: Props) {
  const { label: title, path, info, children, disabled, caption, roles } = item;

  const renderContent = (
    <>
      <DotIcon active={active} />
      <ListItemText
        disableTypography
        primary={title}
        secondary={
          <Tooltip title={caption || ""} arrow>
            <Typography
              noWrap
              variant="caption"
              component="div"
              sx={{ textTransform: "initial", color: "text.secondary" }}
            >
              {caption}
            </Typography>
          </Tooltip>
        }
      />
      {info && info}
      {children && <ArrowIcon open={open} />}
    </>
  );

  if (children) {
    return (
      <ListItem
        onClick={onOpen}
        activeSub={active}
        subItem
        disabled={disabled}
        roles={roles}
      >
        {renderContent}
      </ListItem>
    );
  }

  return path !== undefined ? (
    isExternalLink(path) ? (
      <ListItem
        component={Link}
        href={path}
        target="_blank"
        rel="noopener"
        subItem
        disabled={disabled}
        roles={roles}
      >
        {renderContent}
      </ListItem>
    ) : (
      <ListItem
        component={RouterLink}
        to={path}
        activeSub={active}
        subItem
        disabled={disabled}
        roles={roles}
      >
        {renderContent}
      </ListItem>
    )
  ) : null;
}
