import { CardActionArea, Grid, RadioGroup } from "@mui/material";
import { styled } from "@mui/material/styles";
import Iconify from "../../Iconify";
import BoxMask from "./BoxMask";
import { setThemeMode } from "src/redux/slices/settings";
import { dispatch, useSelector } from "src/redux/store";
import { ThemeMode } from "../type";

// ----------------------------------------------------------------------

const BoxStyle = styled(CardActionArea)(({ theme }) => ({
  // height: 72,
  height: 32,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: theme.palette.text.disabled,
  border: `solid 1px ${theme.palette.grey[500_12]}`,
  borderRadius: Number(theme.shape.borderRadius) * 1.25,
}));

// ----------------------------------------------------------------------

export default function SettingMode() {
  const settingsState = useSelector((state) => state.settings);

  return (
    <RadioGroup
      name="themeMode"
      value={settingsState.themeMode}
      onChange={(evt, value) => dispatch(setThemeMode(value as ThemeMode))}
    >
      <Grid dir="ltr" container spacing={2.5}>
        {["light", "dark"].map((mode, index) => {
          const isSelected = settingsState.themeMode === mode;

          return (
            <Grid key={mode} item xs={6}>
              <BoxStyle
                sx={{
                  bgcolor: mode === "light" ? "common.white" : "grey.800",
                  ...(isSelected && {
                    color: "primary.main",
                    boxShadow: (theme) => theme.customShadows.z20,
                  }),
                }}
              >
                <Iconify
                  icon={index === 0 ? "ph:sun-duotone" : "ph:moon-duotone"}
                  width={24}
                  height={24}
                />
                <BoxMask value={mode} />
              </BoxStyle>
            </Grid>
          );
        })}
      </Grid>
    </RadioGroup>
  );
}
