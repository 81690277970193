import { Box, SxProps, useTheme } from "@mui/material";
import { UppyFile } from "@uppy/core";

import { Attachment, LocalAttachment, PatrolMeasure } from "src/@types";
import AttachmentsList from "src/components/generic/AttachmentsList";
import UppyDashboard from "src/components/generic/UppyDashboard";
import { useLocales } from "src/hooks";
import { useSelector } from "src/redux/store";
import BaseBlock from "./BaseBlock";
import { forwardRef } from "react";

type Props = {
  measure: PatrolMeasure;
  setMeasure: (meaasure: PatrolMeasure) => void;
  getAttachmentBaseUrl: (attachment: Attachment) => string;
  sx?: SxProps;
};

export default forwardRef<HTMLDivElement, Props>(function AttachmentsBlock(
  { measure, setMeasure, getAttachmentBaseUrl, sx }: Props,
  ref
) {
  const theme = useTheme();
  const { translate } = useLocales();
  const authState = useSelector((state) => state.auth);
  return (
    <BaseBlock
      ref={ref}
      label={translate("Attachments")}
      component={
        <Box
          sx={{
            p: 2,
            borderWidth: 1,
            borderStyle: "dashed",
            borderColor: theme.palette.text.secondary,
            borderRadius: 1,
          }}
        >
          <AttachmentsList
            attachments={measure.files || []}
            onFileRemoved={(attachment: Attachment | LocalAttachment) => {
              setMeasure({
                ...measure,
                files: (measure.files || []).filter(
                  (file) => file.id !== attachment.id
                ),
              });
            }}
          />
          <AttachmentsList
            attachments={measure.attachments || []}
            getBaseUrl={getAttachmentBaseUrl}
            onFileRemoved={(attachment: Attachment | LocalAttachment) => {
              setMeasure({
                ...measure,
                attachments: (measure.attachments || []).filter(
                  (file) => file.id !== attachment.id
                ),
              });
            }}
          />
          <UppyDashboard
            locale={authState.user?.language || "en"}
            onFileAdded={(addedFile: UppyFile) => {
              setMeasure({
                ...measure,
                files: [...(measure.files || []), addedFile],
              });
            }}
          />
        </Box>
      }
      sx={sx}
    />
  );
});
