import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Duration } from "luxon";
import { TimeControl } from "src/@types";
import { dispatch } from "../store";
import {
  TimeControlState,
  reducers,
  set as baseSet,
  load,
  TimeControlName,
  clear,
} from "./baseTimeControl";
import { loadData } from "./measures";

const timeControlName: TimeControlName = "measures";

const initialState: TimeControlState = {
  timeControl: {
    interval: Duration.fromISO("PT12H"),
  },
};

export const set = createAsyncThunk<void, TimeControl>(
  "measures-timecontrol-set",
  (value) => {
    dispatch(baseSet({ name: timeControlName, value }));
    dispatch(loadData(value));
  }
);

const slice = createSlice({
  name: "measuresTimeControl",
  initialState,
  reducers,
  extraReducers: (builder) => {
    builder
      .addCase(load.pending, (state, action) => {
        if (action.meta.arg.name !== timeControlName) return;
        console.log("Fetching measures time control...");
      })
      .addCase(load.fulfilled, (state, action) => {
        if (action.meta.arg.name !== timeControlName) return;
        console.log(
          "Fetched measures time control.",
          action.payload?.timeControl
        );
        if (action.payload) {
          state.timeControl = action.payload.timeControl;
        }
      })
      .addCase(load.rejected, (state, action) => {
        if (action.meta.arg.name !== timeControlName) return;
        console.error("Error while fetching measures time control.", action);
      })
      .addCase(clear.pending, (state, action) => {
        if (action.meta.arg !== timeControlName) return;
        console.log("Clearing measures time control...");
      })
      .addCase(clear.fulfilled, (state, action) => {
        if (action.meta.arg !== timeControlName) return;
        state.timeControl = initialState.timeControl;
        console.log("Cleared measures time control.", state.timeControl);
      })
      .addCase(clear.rejected, (state, action) => {
        if (action.meta.arg !== timeControlName) return;
        console.error("Error while clearing measures time control.", action);
      })
      // .addCase(baseSet.pending, (state, action) => {})
      .addCase(baseSet.fulfilled, (state, action) => {
        if (action.meta.arg.name !== timeControlName) return;
        state.timeControl = action.payload.timeControl;
        console.log("Set measures time control.", state.timeControl);
      })
      .addCase(baseSet.rejected, (state, action) => {
        if (action.meta.arg.name !== timeControlName) return;
        console.error("Error while setting measures time control.", action);
      });
  },
});

export default slice;
