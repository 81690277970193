import { useContext } from "react";
import { StoreContext } from "src/contexts/StoreContext";

// ----------------------------------------------------------------------

export default function useStore() {
  const context = useContext(StoreContext);

  if (!context)
    throw new Error("StoreContext must be use inside StoreContext Provider");

  return context;
}
