import { Box, BoxProps, List, ListSubheader, styled } from "@mui/material";
import { MenuItem } from "src/@types";
import NavListRoot from "./NavListRoot";

const ListSubheaderStyle = styled((props) => (
  <ListSubheader disableSticky disableGutters {...props} />
))(({ theme }) => ({
  ...theme.typography.overline,
  paddingTop: theme.spacing(3),
  paddingLeft: theme.spacing(2),
  paddingBottom: theme.spacing(1),
  color: theme.palette.text.primary,
  transition: theme.transitions.create("opacity", {
    duration: theme.transitions.duration.shorter,
  }),
}));

interface Props extends BoxProps {
  navConfig: MenuItem[];
  isCollapse?: boolean;
}

export default function NavSectionVertical({
  navConfig,
  isCollapse = false,
  ...other
}: Props) {
  return (
    <Box {...other}>
      {navConfig.map((group) => (
        <List key={group.label} disablePadding sx={{ px: 2 }}>
          <ListSubheaderStyle
            sx={{
              ...(isCollapse && {
                opacity: 0,
              }),
            }}
          >
            {group.label}
          </ListSubheaderStyle>

          {group.children?.map((list) => (
            <NavListRoot
              key={list.label + list.path}
              item={list}
              isCollapse={isCollapse}
            />
          ))}
        </List>
      ))}
    </Box>
  );
}
