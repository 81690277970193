import { Badge, Box, IconButton, TableCell, TableRow } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import numeral from "numeral";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Measure } from "src/@types/Measure";
import FavoriteIcon from "src/components/generic/FavoriteIcon";
import LabelBadge from "src/components/generic/LabelBadge";
import { useFormat, useLocales } from "src/hooks";
import { PATH_DASHBOARD } from "src/routes/paths";
import { isMeasureOld } from "src/utils/date";
import { convertOperators } from "src/utils/str";
import { dispatch, useSelector } from "src/redux/store";
import AlarmStatusLabel from "./AlarmStatusLabel";
import { toggle as toggleMeasure } from "src/redux/slices/favorites";
import MoreButton from "src/components/generic/MoreButton";

type Props = {
  measure: Measure;
  onMenuClick: () => void;
};

export default function AlarmTableRow({ measure, onMenuClick }: Props) {
  const { formatDate } = useFormat();
  const { translate } = useLocales();
  const theme = useTheme();
  const navigate = useNavigate();
  const favoritesState = useSelector((state) => state.favorites);
  const [measureState, setMeasureState] = useState<Measure | undefined>();
  const measuresState = useSelector((state) => state.measures);
  const equipmentsLocationsState = useSelector(
    (state) => state.equipmentsLocations
  );

  const displayAlarmStatusValue = () => {
    if (
      measure?.strongestAlarm?.status?.extractionsValues?.measureValue ===
      undefined
    )
      return translate("No data");
    if (measure.type === "boolean") {
      return measure?.strongestAlarm?.status?.extractionsValues
        ?.measureValue === false
        ? translate("False")
        : translate("True");
    }
    if (measure.type === "string") {
      return measure?.strongestAlarm?.status?.extractionsValues?.measureValue;
    }
    if (measure.type === "number") {
      const formattedValue = numeral(
        measure?.strongestAlarm?.status?.extractionsValues?.measureValue
      ).format("0.00");
      return measure.unit
        ? `${formattedValue} ${measure.unit}`
        : formattedValue;
    }
  };

  useEffect(() => {
    const foundMeasure = measuresState.measures.find(
      (m: Measure) => m.identifier === measure.identifier
    );
    if (foundMeasure) setMeasureState(foundMeasure);
  }, [measuresState.measures, measure.identifier]);

  if (!measure.strongestAlarm) {
    return null;
  }

  const locationLabel = equipmentsLocationsState.equipmentsLocations.find(
    (e) => e.identifier === measure.equipment?.locationId
  )?.label;

  return (
    <TableRow hover>
      <TableCell>
        <FavoriteIcon
          isFavorite={
            favoritesState.measuresIds.findIndex(
              (measureId) => measureId === measure.identifier
            ) !== -1
          }
          toggleFavorite={() => dispatch(toggleMeasure(measure.identifier))}
        />
      </TableCell>
      <TableCell>
        <AlarmStatusLabel
          priority={measure.strongestAlarm.priority}
          active={measure.strongestAlarm.status.active}
        />
      </TableCell>
      <TableCell>
        {formatDate(measure.strongestAlarm.status.lastChangeOn || "")}
      </TableCell>
      <TableCell>{locationLabel}</TableCell>
      <TableCell
        onClick={() =>
          navigate(PATH_DASHBOARD.measures.equipment(measure.equipmentId))
        }
        sx={{ cursor: "pointer" }}
      >
        <strong>{measure.equipment?.label}</strong>
      </TableCell>
      <TableCell
        onClick={() =>
          navigate(PATH_DASHBOARD.measures.details(measure.identifier))
        }
        sx={{ cursor: "pointer" }}
      >
        {measure.label}
      </TableCell>
      <TableCell sx={{ whiteSpace: "nowrap" }}>
        <LabelBadge
          sx={{ mr: 1 }}
          text={
            measure.strongestAlarm.metadata.measureMachineName ||
            measure.strongestAlarm.metadata.measureLabel
          }
        />
        {convertOperators(measure.strongestAlarm.metadata.measureTrigger)}
      </TableCell>
      <TableCell sx={{ whiteSpace: "nowrap" }}>
        {displayAlarmStatusValue()}
      </TableCell>
      <TableCell>
        <Box
          // onClick={() => (measureState ? setDrawerMeasure(measureState) : "")}
          onClick={onMenuClick}
        >
          <Badge
            color={"warning"}
            variant="dot"
            overlap="circular"
            invisible={measureState ? !isMeasureOld(measureState) : true}
          >
            <MoreButton />
          </Badge>
        </Box>
      </TableCell>
    </TableRow>
  );
}
