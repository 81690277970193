import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Duration } from "luxon";
import {
  TimeControlState,
  clear,
  load,
  reducers,
  set as baseSet,
  TimeControlName,
} from "./baseTimeControl";
import { TimeControl } from "src/@types";
import { dispatch } from "../store";

const timeControlName: TimeControlName = "logbook";

const initialState: TimeControlState = {
  timeControl: {
    interval: Duration.fromISO("P7D"),
  },
};

export const set = createAsyncThunk<void, TimeControl>(
  "logbook-timecontrol-set",
  (value) => {
    dispatch(baseSet({ name: timeControlName, value }));
  }
);

const slice = createSlice({
  name: "logbookTimeControl",
  initialState,
  reducers,
  extraReducers: (builder) => {
    builder
      .addCase(load.pending, (state, action) => {
        if (action.meta.arg.name !== timeControlName) return;
        console.log("Fetching logbook time control...");
      })
      .addCase(load.fulfilled, (state, action) => {
        if (action.meta.arg.name !== timeControlName) return;
        console.log(
          "Fetched logbook time control.",
          action.payload?.timeControl
        );
        if (action.payload) {
          state.timeControl = action.payload.timeControl;
        }
      })
      .addCase(load.rejected, (state, action) => {
        if (action.meta.arg.name !== timeControlName) return;
        console.error("Error while fetching logbook time control.", action);
      })
      .addCase(clear.pending, (state, action) => {
        if (action.meta.arg !== timeControlName) return;
        console.log("Clearing logbook time control...");
      })
      .addCase(clear.fulfilled, (state, action) => {
        if (action.meta.arg !== timeControlName) return;
        state.timeControl = initialState.timeControl;
        console.log("Cleared logbook time control.", state.timeControl);
      })
      .addCase(clear.rejected, (state, action) => {
        if (action.meta.arg !== timeControlName) return;
        console.error("Error while clearing logbook time control.", action);
      })
      // .addCase(baseSet.pending, (state, action) => {})
      .addCase(baseSet.fulfilled, (state, action) => {
        if (action.meta.arg.name !== timeControlName) return;
        state.timeControl = action.payload.timeControl;
        console.log("Set logbook time control.", state.timeControl);
      })
      .addCase(baseSet.rejected, (state, action) => {
        if (action.meta.arg.name !== timeControlName) return;
        console.error("Error while setting logbook time control.", action);
      });
  },
});

export default slice;
