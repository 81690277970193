import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
} from "@mui/material";
import { ReactNode } from "react";
import { useLocales } from "src/hooks";

type Props = {
  title: ReactNode;
  progress?: number;
  cancellable?: boolean;
  onCancel?: () => void;
  open: boolean;
  onClose: VoidFunction;
};

export default function ProgressDialog({
  title,
  progress,
  cancellable = false,
  onCancel,
  open,
  onClose,
}: Props) {
  const { translate } = useLocales();
  return (
    <Dialog fullWidth maxWidth="xs" open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <LinearProgress
          variant={progress === undefined ? "indeterminate" : "determinate"}
          value={progress}
          sx={{ flex: 1 }}
        />
      </DialogContent>
      {cancellable && onCancel ? (
        <DialogActions>
          <Button variant="outlined" color="inherit" onClick={() => onCancel()}>
            {translate("Cancel")}
          </Button>
        </DialogActions>
      ) : null}
    </Dialog>
  );
}
