import { Box, Button, Card, FormLabel, Stack } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useLocales } from "src/hooks";

const StyledFormLabel = styled(FormLabel)(({ theme }) => ({
  mb: 3,
  color: theme.palette.primary.main,
  fontWeight: "bold",
}));

export type StepProps = {
  title: string;
  goToPreviousStep?: () => void;
  actionComponent?: JSX.Element;
};

export default function Step({
  title,
  goToPreviousStep,
  actionComponent,
  children,
}: { children: JSX.Element } & StepProps) {
  const { translate } = useLocales();
  return (
    <Stack gap={2}>
      <Card sx={{ p: 3 }}>
        <Box>
          <StyledFormLabel>{title}</StyledFormLabel>
          {children}
        </Box>
      </Card>
      {(goToPreviousStep || actionComponent) && (
        <Stack direction="row" justifyContent="space-between">
          <Box>
            {goToPreviousStep ? (
              <Button
                color="primary"
                size="medium"
                style={{ textTransform: "none" }}
                onClick={() => goToPreviousStep()}
              >
                {`< ${translate("Back")}`}
              </Button>
            ) : null}
          </Box>
          <Box>{actionComponent ? <Box>{actionComponent}</Box> : null}</Box>
        </Stack>
      )}
    </Stack>
  );
}
