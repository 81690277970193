import { Box, Link, Stack, styled, TextField, Typography } from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers";
import saveAs from "file-saver";
import { DateTime } from "luxon";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { sprintf } from "sprintf-js";
import { Measure, TimeControl } from "src/@types";
import { StartEndTimeControl } from "src/@types/TimeControl";
import Iconify from "src/components/generic/Iconify";
import LoadingPanel from "src/components/generic/LoadingPanel";
import { useFormat, useLocales } from "src/hooks";
import { measureService } from "src/services";

type Props = {
  measure: Measure;
  timeControl: StartEndTimeControl;
};

export default function ExportSection({ measure, timeControl }: Props) {
  const { formatDate } = useFormat();
  const { translate } = useLocales();
  const { enqueueSnackbar } = useSnackbar();

  const [startDate, setStartDate] = useState<DateTime>(timeControl.start);
  const [endDate, setEndDate] = useState<DateTime>(timeControl.end);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setStartDate(timeControl.start);
    setEndDate(timeControl.end);
  }, [timeControl]);

  const LabelStyle = styled(Typography)(({ theme }) => ({
    ...theme.typography.body2,
    width: 140,
    fontSize: 13,
    flexShrink: 0,
    color: theme.palette.text.secondary,
  }));

  const exportToFile = async (
    measure: Measure,
    fileType: "csv" | "ods" | "xlsx",
    startDate: DateTime,
    endDate: DateTime
  ) => {
    try {
      setLoading(true);
      const response = await measureService.getExportFile(
        measure.identifier,
        startDate,
        endDate,
        fileType
      );
      const fileName = response.headers["filename"];
      // const fileName = response.headers["content-disposition"]
      //   .split("=")[1]
      //   .replace(/"/g, "");
      console.log("Exporting data to", fileName);
      saveAs(response.data, fileName);
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
      if (e.text) {
        const error = JSON.parse(await e.text());
        enqueueSnackbar(sprintf(translate("Unable to export: %s"), error), {
          variant: "error",
        });
      }
    }
  };

  const onKeyDown = (e: any) => {
    e.preventDefault();
  };

  return (
    <Stack gap={2} sx={{ px: 2, py: 2, position: "relative" }}>
      {loading ? (
        <LoadingPanel>
          <Box sx={{ textAlign: "center" }}>
            {translate("Please wait while exporting...")}
          </Box>
        </LoadingPanel>
      ) : null}
      <Stack>
        <LabelStyle>{translate("Start date")}</LabelStyle>
        {/* <Typography>{formatDate(startDate)}</Typography> */}
        <DateTimePicker
          value={startDate}
          onChange={(newDate: DateTime | null) =>
            newDate && setStartDate(newDate)
          }
          renderInput={(params: any) => (
            <TextField {...params} onKeyDown={onKeyDown} />
          )}
        />
      </Stack>
      <Stack>
        <LabelStyle>{translate("End date")}</LabelStyle>
        {/* <Typography>{formatDate(endDate)}</Typography> */}
        <DateTimePicker
          value={endDate}
          onChange={(newDate: DateTime | null) =>
            newDate && setEndDate(newDate)
          }
          renderInput={(params: any) => (
            <TextField {...params} onKeyDown={onKeyDown} />
          )}
        />
      </Stack>
      <Stack direction="row" justifyContent={"space-around"}>
        <Link
          title={translate("Export to XLSX file")}
          onClick={() => exportToFile(measure, "xlsx", startDate, endDate)}
        >
          <Iconify width={24} height={24} icon="bi:filetype-xlsx" />
          {translate("XLSX export")}
        </Link>

        <Link
          title={translate("Export to ODS file")}
          onClick={() => exportToFile(measure, "ods", startDate, endDate)}
        >
          <Iconify width={24} height={24} icon="bi:file-earmark-spreadsheet" />
          {translate("ODS export")}
        </Link>

        <Link
          title={translate("Export to CSV file")}
          onClick={() => exportToFile(measure, "csv", startDate, endDate)}
        >
          <Iconify width={24} height={24} icon="bi:filetype-csv" />
          {translate("CSV export")}
        </Link>
      </Stack>
    </Stack>
  );
}
