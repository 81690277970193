import { combineReducers } from "redux";
import { PersistConfig, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import {
  accessRights as accessRightsSlice,
  alarms as alarmsSlice,
  alarmsLogTimeControl as alarmsLogTimeControlSlice,
  alarmsOperators as alarmsOperatorsSlice,
  auth as authSlice,
  dashboard as dashboardSlice,
  equipments as equipmentsSlice,
  equipmentsLocations as equipmentsLocationsSlice,
  equipmentsTypes as equipmentsTypesSlice,
  favorites as favoritesSlice,
  languages as languagesSlice,
  logbook as logbookSlice,
  logbookTimeControl as logbookTimeControlSlice,
  measures as measuresSlice,
  measuresTimeControl as measuresTimeControlSlice,
  menu as menuSlice,
  organization as organizationSlice,
  patrols as patrolsSlice,
  patrolsTimeControl as patrolsTimeControlSlice,
  roles as rolesSlice,
  settings as settingsSlice,
  socketIo as socketIoSlice,
  users as usersSlice,
} from "./slices";
import { AuthState } from "./slices/auth";
import { FavoritesState } from "./slices/favorites";
import { SettingsState } from "./slices/settings";
import { TimeControlState, transforms } from "./slices/baseTimeControl";

const authPersistConfig: PersistConfig<AuthState> = {
  keyPrefix: "",
  key: "auth",
  storage,
  whitelist: ["user"],
};

const settingsPersistConfig: PersistConfig<SettingsState> = {
  keyPrefix: "",
  key: "settings",
  storage,
};

const alarmsLogTimeControlPersistConfig: PersistConfig<TimeControlState> = {
  keyPrefix: "",
  key: "alarmsLogTimeControl",
  storage,
  transforms: transforms,
};

const logbookTimeControlPersistConfig: PersistConfig<TimeControlState> = {
  keyPrefix: "",
  key: "logbookTimeControl",
  storage,
  transforms: transforms,
};

const measuresTimeControlPersistConfig: PersistConfig<TimeControlState> = {
  keyPrefix: "",
  key: "measuresTimeControl",
  storage,
  transforms: transforms,
};

const patrolsTimeControlPersistConfig: PersistConfig<TimeControlState> = {
  keyPrefix: "",
  key: "patrolsTimeControl",
  storage,
  transforms: transforms,
};

const favoritesPersistConfig: PersistConfig<FavoritesState> = {
  keyPrefix: "",
  key: "favorites",
  storage,
};

const rootReducer = combineReducers({
  accessRights: accessRightsSlice.reducer,
  alarms: alarmsSlice.reducer,
  alarmsLogTimeControl: persistReducer(
    alarmsLogTimeControlPersistConfig,
    alarmsLogTimeControlSlice.reducer
  ),
  alarmsOperators: alarmsOperatorsSlice.reducer,
  auth: persistReducer(authPersistConfig, authSlice.reducer),
  dashboard: dashboardSlice.reducer,
  equipments: equipmentsSlice.reducer,
  equipmentsLocations: equipmentsLocationsSlice.reducer,
  equipmentsTypes: equipmentsTypesSlice.reducer,
  favorites: persistReducer(favoritesPersistConfig, favoritesSlice.reducer),
  languages: languagesSlice.reducer,
  logbook: logbookSlice.reducer,
  logbookTimeControl: persistReducer(
    logbookTimeControlPersistConfig,
    logbookTimeControlSlice.reducer
  ),
  measures: measuresSlice.reducer,
  measuresTimeControl: persistReducer(
    measuresTimeControlPersistConfig,
    measuresTimeControlSlice.reducer
  ),
  menu: menuSlice.reducer,
  organization: organizationSlice.reducer,
  patrols: patrolsSlice.reducer,
  patrolsTimeControl: persistReducer(
    patrolsTimeControlPersistConfig,
    patrolsTimeControlSlice.reducer
  ),
  roles: rolesSlice.reducer,
  settings: persistReducer(settingsPersistConfig, settingsSlice.reducer),
  socketIo: socketIoSlice.reducer,
  users: usersSlice.reducer,
});

export { rootReducer };
