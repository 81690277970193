import { SwipeableDrawerProps } from "@mui/material";
import { Box, SwipeableDrawer, useTheme } from "@mui/material";
import Scrollbar from "src/components/generic/Scrollbar";

export type RightDrawerProps = Omit<SwipeableDrawerProps, "onOpen"> & {
  onOpen?: React.ReactEventHandler<{}>;
};

export default function ({
  open,
  onOpen,
  children,
  ...props
}: RightDrawerProps) {
  const theme = useTheme();

  return (
    <SwipeableDrawer
      {...props}
      open={open}
      onOpen={onOpen ? onOpen : () => {}}
      anchor={props.anchor}
      PaperProps={{ sx: { width: { xs: 1, sm: 480 } }, ...props.PaperProps }}
    >
      <Box sx={{ height: "calc(100% - env(safe-area-inset-bottom))" }}>
        <Scrollbar sx={{ height: 1 }}>{children}</Scrollbar>
      </Box>
    </SwipeableDrawer>
  );
}
